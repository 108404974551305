import React, { useRef, useEffect, useState } from 'react';
import Moveable from 'react-moveable';

function Draggable({ children, style, onDrag, position }) {
    const targetRef = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [isSvg, setIsSvg] = useState(false);

    useEffect(() => {
        if (targetRef.current) {
            // Check if we're dealing with SVG
            setIsSvg(targetRef.current.ownerSVGElement != null);
            setIsReady(true);
            
            if (position) {
                if (isSvg) {
                    targetRef.current.setAttribute('transform', 
                        `translate(${position.x}, ${position.y})`
                    );
                } else {
                    targetRef.current.style.transform = 
                        `translate(${position.x}px, ${position.y}px)`;
                }
            }
        }
    }, [position, isSvg]);

    const WrapperComponent = isSvg ? 'g' : 'div';

    // return (
    //   <>
    //     {children}
    //   </>
    // )
    return (
        <>
            <WrapperComponent
                ref={targetRef}
                style={{ 
                    position: isSvg ? undefined : 'relative',
                    width: isSvg ? undefined : 'fit-content',
                    touchAction: 'none',
                    ...style 
                }}
            >
                {children}
            </WrapperComponent>
            {isReady && (
                <Moveable
                    target={targetRef.current}
                    draggable={true}
                    resizable={false}
                    rotatable={false}
                    hideDefaultLines={true}
                    origin={false}   
                    renderDirections={[]}
                    throttleDrag={0}
                    svgOrigin={isSvg ? '0 0' : undefined}
                    onDrag={({ target, beforeTranslate }) => {
                        const [x, y] = beforeTranslate;
                        if (isSvg) {
                            target.setAttribute('transform', `translate(${x}, ${y})`);
                        } else {
                            target.style.transform = `translate(${x}px, ${y}px)`;
                        }
                        if (onDrag) {
                            onDrag({ x, y });
                        }
                    }}
                />
            )}
        </>
    );
}

export default Draggable;
