import { isSafari } from '../../functions/browser-check';

export const styles = (theme) => ({
    formControl: {
        margin: '8px 0px 8px 0px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    graph: {
        height: '100%',
        width: '100%',
        minHeight: '100%',
        maxHeight: '100%',
        overflow: 'overlay',
    },
    border: {
        boxSizing: 'border-box',
        borderBottom: 'solid 1px #969696',
        borderRight: 'solid 1px #969696',
        borderLeft: 'solid 1px #969696',
    },
    graphExport: {
        padding: 0,
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        backgroundColor: 'white'
    },
    graphEdit: {
        padding: 0,
        float: 'left',
        boxSizing: 'border-box',
        backgroundColor: '#E2E2E2'
    },
    treeBorder: {
        boxSizing: 'border-box'
    },
    zoomMenu: {
        marginTop: '-15vh',
        marginLeft: '2%',
        position: 'relative',
        display: 'block',
        background: '#F2F2F2',
        width: '82px',
        height: '48px',
        border: '1px solid #DCDCDC',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '3px',
    },
    icon: {
        padding: '3px',
    },
    button: {
        padding: '6px 3px 6px 3px'
    },
    counter: {
        position: 'absolute',
        padding: isSafari ? '0px 0px 3px 1.7px' : '4px 0px 3px 1.7px',
        background: 'transparent',
        border: 'none',
        width: '15px',
        height: isSafari ? undefined : '8px',
        fontFamily: 'Inter',
        fontSize: 16,
        '&:focus': {
            'outline': 'none'
        }
    },
    datePickers: {
        marginTop: '3px'
    },
    datePicker: {
        paddingTop: '30px'
    },
});
