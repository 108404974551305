import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axios-requests';
import config from '../../../../config/envConfig';
import LineageRegionDialog from '../Dialogs/LineageRegionDialog';
import { fetchLineageRegions } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const EditLineageRegion = ({ lineageRegion, currentLineage, setInfoDialog, setInfo, setAction, fetchLineageRegions }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const updateLineageRegion = async (oldKey, newRegion) => {
        try {
            const url = `${config.serverLink}/api/admin/updateLineageRegion`;
            const body = { oldRegionKey: oldKey, newRegion, lineage: currentLineage };
            await postAxios(url, body);
            setInfo(`Region ${newRegion.key} for lineage: ${currentLineage} was updated sucessfully.`);

        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during updating ${newRegion.key}: ${message}.`);
        }
        finally {
            handleCloseDialog();
            setAction('Update lineage region');
            setInfoDialog(true);
            fetchLineageRegions();
        }
    };

    return <>
        <IconButton className={classes.button} onClick={handleClick}>
            <EditIcon />
        </IconButton>
        <LineageRegionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateLineageRegion={updateLineageRegion} oldLineageRegion={lineageRegion} />
    </>;
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchLineageRegions
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(EditLineageRegion);
