import { useState } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Menu,
    Divider,
    IconButton,
    MenuItem,
} from '@mui/material';
import { linksMenuStyles } from '../userMenu/styles';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import config from '../../config/envConfig';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
        color: 'black',
        fontSize: '13px'
    },
    dividerUp: {
        margin: '24px 24px 24px 24px'
    },
}));

const Hamburger = () => <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="1" x2="15" y2="1" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="1" y1="7.05505" x2="15" y2="7.05506" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="1" y1="13" x2="15" y2="13" stroke="white" strokeWidth="2" strokeLinecap="round" />
</svg>;

export default function MenuOutsideLinks({headerLinks}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLinkClick = (link) => {
        handleClose();
        if (link.internal) {
            // Handle internal link scrolling
            const element = document.querySelector(link.value);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Handle external links
            window.location.href = link.value;
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <IconButton onClick={handleClick} size="small" style={{ margin: 0, padding: 0 }}>
                    <Hamburger />
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: linksMenuStyles
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {headerLinks.map((link) => (
                    <MenuItem 
                        key={`mi-${link.value}`}
                        onClick={() => handleLinkClick(link)}
                    >
                        <span className={classes.link}>
                            {link.label}
                        </span>
                    </MenuItem>
                ))}
                <Divider className={classes.dividerUp} />
                <MenuItem>
                    <Link to={`${config.frontendPrefix}/signin`} className={classes.link}>
                        Sign in
                    </Link>
                </MenuItem>
            </Menu>
        </>
    );
};

