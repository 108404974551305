import { setGlobalDevModeChecks } from 'reselect';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
// import { thunk } from 'redux-thunk';
import rootReducer from './reducers/_rootReducer';
import { rootEpic } from './epics/_rootEpic';
import { createEpicMiddleware } from 'redux-observable';
import {
    SET_COMPONENT_STATUS,
    SET_LABEL_MOVEMENT,
    SET_RENDER_STATUS,
    SET_STRAINS_TREE_DIMENSIONS,
    FETCH_NODE_REQUEST,
    FETCH_NODE_SUCCESS,
    SET_ALERT_STATUS,
    CORRECT_LABEL_POSITIONS,
    SET_ACTIVE_LEGEND_OPTION,
    SELECT_POINT,
    SELECT_NODE_DATA
} from './actions/actionTypes';
import { setAntigenicInitalState } from './reducers/antigenicReducer';
import { setFitnessInitialState } from './reducers/fitnessReducer';
import { setAlertInitialState } from './reducers/alertReducer';
import { setCladesInitialState } from './reducers/cladesReducer';
import { setCustomTreeDataInitialState } from './reducers/customTreeDataReducer';
import { setFrequenciesInitialState } from './reducers/frequenciesReducer';
import { setGenotypeInitialState } from './reducers/genotypeReducer';
import { setGeomapInitialState } from './reducers/geoMapReducer';
import { setLineagesInitialState } from './reducers/lineagesReducer';
import { setModelDataInitalState, setModelsInitialState } from './reducers/modelsReducer';
import { setMetadataInitialState } from './reducers/metadataReducer';
import { setNodeDataInitialState } from './reducers/nodeDataReducer';
import { setParametersInitialState } from './reducers/parametersReducer';
import { setPredictionsInitialState } from './reducers/predictionsReducer';
import { setRenderInitialState } from './reducers/renderStatusReducer';
import { setSettingsInitialState } from './reducers/settingsReducer';
import { setTreeDataInitialState } from './reducers/treeDataReducer';
import { setUserInitialState } from './reducers/userReducer';
import { setUiInitialState } from './reducers/uiReducer';
import { setVaccinesInitialState } from './reducers/vaccinesReducer';
import dateToStringMiddleware from './middleware/DateToStringMiddleware';
import config from '../config/envConfig';
export const history = createBrowserHistory();

export function configureAppStore(initState) {
    const epicMiddleware = createEpicMiddleware({
        dependencies: {
            history: history,
        },
    });

    setAlertInitialState(initState.alert);
    setAntigenicInitalState(initState.antigenic);
    setFitnessInitialState(initState.fitness);
    setCladesInitialState(initState.cladeData);
    setCustomTreeDataInitialState(initState.customTreeData);
    setFrequenciesInitialState(initState.frequenciesData);
    setGenotypeInitialState(initState.genotype);
    setGeomapInitialState(initState.geomap);
    setLineagesInitialState(initState.lineages);
    setModelDataInitalState(initState.modelData);
    setModelsInitialState(initState.models);
    setMetadataInitialState(initState.metadata);
    setNodeDataInitialState(initState.nodeData);
    setParametersInitialState(initState.parameters);
    setPredictionsInitialState(initState.predictionsData);
    setRenderInitialState(initState.render);
    setSettingsInitialState(initState.settings);
    setTreeDataInitialState(initState.treeData);
    setUserInitialState(initState.user);
    setUiInitialState(initState.ui);
    setVaccinesInitialState(initState.vaccines);

    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
            immutableCheck: false
        }).concat(
            dateToStringMiddleware,
            epicMiddleware
        ),
        preloadedState: initState,
        devTools:
            config.REACT_APP_SHOW_REDUX === false
                ? false
                : {
                    actionSanitizer: (action) =>
                        action.type === 'INIT_STRAIN_TREE_SUCCCESS' && action.payload
                            ? { ...action, payload: '<<LONG_BLOB>>' }
                            : action,
                    stateSanitizer: (state) => (state.data ? { ...state, data: '<<LONG_BLOB>>' } : state),
                    actionsDenylist: [
                        SET_ACTIVE_LEGEND_OPTION,
                        SET_COMPONENT_STATUS,
                        SET_RENDER_STATUS,
                        SET_LABEL_MOVEMENT,
                        SELECT_NODE_DATA,
                        FETCH_NODE_REQUEST,
                        FETCH_NODE_SUCCESS,
                        SET_STRAINS_TREE_DIMENSIONS, 
                        SET_ALERT_STATUS,
                        CORRECT_LABEL_POSITIONS,
                        SELECT_POINT
                    ]
                }
    });


    epicMiddleware.run(rootEpic);


    // Run only the first time the selector is called. (default)
    setGlobalDevModeChecks({ inputStabilityCheck: 'always' });
    return store;
}
