import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { fetchAxios } from '../../functions/axios-requests';
import config from '../../config/envConfig';
import {
    FETCH_VACCINE_PROTECTION_VALUES_REQUEST, FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST,
} from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import { handleErrors } from '../operators/error';
import { 
    fetchVaccineAntigenicCladeFrequenciesError, 
    fetchVaccineAntigenicCladeFrequenciesSuccess, 
    fetchVaccineAntigenicCladePredictionsError, 
    fetchVaccineAntigenicCladePredictionsSuccess, 
    fetchVaccineDefaultSelectionsError, 
    fetchVaccineDefaultSelectionsSuccess, 
    fetchVaccineProtectionValuesError, 
    fetchVaccineProtectionValuesSuccess 
} from '../actions/vaccinesActions';

export const fetchVaccineProtectionValuesEpic = (action$) => action$.pipe(
    ofType(FETCH_VACCINE_PROTECTION_VALUES_REQUEST),
    mergeMap(action => {
        const { lineage, vaccinesSerumType } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/vaccines`, { lineage, vaccinesSerumType });
        // console.log('[fetchVaccineProtectionValuesEpic] lineage', {lineage, url});
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVaccineProtectionValuesSuccess(response.data)),
                handleErrors(fetchVaccineProtectionValuesError, 'An error has occurred during fetching vaccine protection values')
            );
    })
);

export const fetchVaccineDefaultSelectionsEpic = (action$) => action$.pipe(
    ofType(FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { lineage, vaccinesSerumType } = action.payload;
   
        const url = prepareUrl(`${config.serverLink}/api/vaccines/defaultSelections`, { lineage, vaccinesSerumType });

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVaccineDefaultSelectionsSuccess(response.data)),
                handleErrors(fetchVaccineDefaultSelectionsError, 'An error has occurred during fetching vaccine default selections')
            );
    })
);

export const fetchVaccineAntigenicCladeFrequenciesEpic = (action$) => action$.pipe(
    ofType(FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST),
    mergeMap(action => {
        const { lineage, strainSubset, vaccinesTrackedProtectionDate } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/frequencies/vaccinesCladeFrequencies`, { lineage, strainSubset, vaccinesTrackedProtectionDate });
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVaccineAntigenicCladeFrequenciesSuccess(response.data)),
                handleErrors(fetchVaccineAntigenicCladeFrequenciesError, 'An error has occurred during fetching vaccine antigenic clade frequencies')
            );
    })
);

export const fetchVaccineAntigenicCladePredictionsEpic = (action$) => action$.pipe(
    ofType(FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST),
    mergeMap(action => {
        const { lineage, strainSubset, vaccinesTrackedProtectionDate, vaccinesPredictedProtectionDate, vaccinesModel } = action.payload;
        //console.log('[fetchVaccineAntigenicCladePredictionsEpic] action.payload', {lineage, strainSubset, vaccinesTrackedProtectionDate, vaccinesPredictedProtectionDate, vaccinesModel});
        const url = prepareUrl(`${config.serverLink}/api/frequencies/vaccinesCladePredictions`, { lineage, strainSubset, vaccinesTrackedProtectionDate, vaccinesPredictedProtectionDate, vaccinesModel });
        // console.log('[fetchVaccineAntigenicCladePredictionsEpic] url', {url});
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchVaccineAntigenicCladePredictionsSuccess(response.data)),
                handleErrors(fetchVaccineAntigenicCladePredictionsError, 'An error has occurred during fetching vaccine antigenic clade predictions')
            );
    })
);
