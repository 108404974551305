// React & MUI
import React, { useEffect, useState } from 'react';
import {
    FormGroup, Divider, Button, Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchLineage } from '../../../redux/actions/lineagesActions';
import { fetchMutationClasses, fetchVpMethods } from '../../../redux/actions/treeDataActions';
import { fetchMutationsPositionsDictionary } from '../../../redux/actions/genotypeActions';
import { fetchModels, fetchModelTypes } from '../../../redux/actions/modelActions';
import { setSettings, setSettingsParameters, setSettingsMetadata } from '../../../redux/actions/settingsActions';
import { getEditableParameters, getSelectSettingsOptions } from '../../../redux/selectors/settingsSelector';

// Components
import Alert from './utils/Alert';
import ClearCache from './utils/ClearCache';
import CheckboxInput from '../../../components/Common/CheckboxInput';
import SelectInput from '../../../components/Common/SelectInput';
import DatePickerInput from '../../../components/Common/DatePickerInput';
import NumberInput from '../../../components/Common/NumberInput';
import ZoomNodeIdSelect from './utils/ZoomNodeIdSelect';

// Utils
import { daysToDate, dateToDays } from '../../../functions/utils';
import { styles } from './styles';
import { parametersToEdit } from './parametersToEdit';
import { lineagesNamesSelector } from '../../../redux/selectors/lineagesSelector';

const parametersToEditArray = Object.keys(parametersToEdit).sort();
const alertState = { open: false, type: '' };

const tabs = {
    strainTree: 'Strain Tree',
    frequencies: 'Frequencies',
    antigenic: 'Antigenic',
    fitness: 'Fitness',
    vaccines: 'Vaccines',
    geomap: 'Geomap',
};

const ParametersPanel = ({
    parameters, lineages, modules,
    lineage, fetchLineage, options, fetchVpMethods, setSettings,
    fetchModels, fetchMutationClasses,
    lineageStatus, modelTypesStatus,
    fetchModelTypes, setSettingsParameters, setSettingsMetadata, fetchMutationsPositionsDictionary
}) => {
    const [openAlert, setOpenAlert] = useState(alertState);
    const [validationErrors, setValidationErrors] = useState({});
    const classes = styles();
   
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        fetchVpMethods({ lineage, settings: true });
        fetchModels({ lineage, colorBy: 'antigenic', settings: true });
        fetchModels({ lineage, colorBy: 'antigenicFitness', settings: true });
        fetchModels({ lineage, colorBy: 'fitness', modelRegionId: parameters.modelRegionId, modelType: parameters.modelType, settings: true });
        fetchMutationClasses({ lineage, settings: true });
        fetchMutationsPositionsDictionary({ lineage, settings: true });
    }, [lineageStatus]);

    

    useEffect(() => {
        if (lineageStatus !== 'loaded' || !lineage) return;
        fetchModelTypes({ lineage, modelRegionId: parameters.modelRegionId, colorBy: 'fitness', settings: true });
    }, [parameters.modelRegionId, lineageStatus]);

    useEffect(() => {
        const modelType = parameters.modelType || parameters.modelType;
        if (modelTypesStatus === 'loaded' && modelType)
            fetchModels({ lineage, colorBy: 'fitness', modelRegionId: parameters.modelRegionId, modelType, settings: true });
    }, [modelTypesStatus]);

    const handleOpenAlert = (type) => {
        setOpenAlert({
            open: true,
            type
        });
    };

    const handleCloseAlert = () => {
        // window.history.back();
        setOpenAlert(alertState);
    };

    const handleChangeLineage = lineage => {
        setSettingsParameters({ lineage });
        fetchLineage({ lineage, settings: true, intro: false });
    };

    const handleChangeModules = (type) => (checked) => {
        const _modules = { ...modules };
        _modules[type] = checked;

        setSettingsMetadata({ modules: _modules });
    };

    const handleChangeCheckbox = (type) => (checked) => {

        if (type === 'showCladeBar' && !checked) {
            setSettingsParameters({
                [type]: checked,
                showCladeBarLabels: false
            });
            return;
        }

        if (type === 'showCladeBarLabels' && checked) {
            setSettingsParameters({
                [type]: checked,
                showCladeBar: true
            });
            return;
        }

        setSettingsParameters({
            [type]: checked,
        });
    };

    const handleChangeNumber = (type) => (value) => {
        setSettingsParameters({ [type]: value });
    };

    const handleChangeDate = (type, isDateNumber) => (e) => {
        if (!e)
            setSettingsParameters({ [type]: null });

        const value = isDateNumber ? dateToDays(e) : e;
        setSettingsParameters({ [type]: value });
        // setStateParameters({ ...stateParameters, [type]: value });
    };

    const handleChangeSelect = (type) => value => {
        // console.log('[handleChangeSelect] type', type, value);
        if (type === 'mutgene') {
            // console.log('[handleChangeSelect] options.mutposition[value]', options.mutposition[value]);
            setSettingsParameters({ [type]: value, mutposition: options.mutposition[value]?.[0] || '' });
            return;
        }

        if (type === 'modelType') {
            fetchModels({ lineage, modelRegionId: parameters.modelRegionId, modelType: value, colorBy: 'fitness', settings: true });
            setSettingsParameters({ [type]: value }); //, modelId: value  });
            return;
        }
        setSettingsParameters({ [type]: value });
    };

    // Callback function to update validation status
    const handleValidationChange = (field, isValid) => {
        setValidationErrors(prev => ({ ...prev, [field]: !isValid }));
    };


    const isFormValid = () => {
        return Object.values(validationErrors).every(error => error === false);
    };

    const handleSubmit = async () => {
        if (!isFormValid()) {
            handleOpenAlert(false);
            return;
        }
        const _parameters = Object.keys(parameters)
            .reduce((acc, k) => {
                const p = parameters[k];
                acc[k] = (p instanceof Date) ? p.toISOString().split('T')[0] : p;
                return acc;
            }, {});
        setSettings({ parameters: _parameters, lineage, modules, handleOpenAlert });
    };

    const showSelect = (value, options) => options && options.length > 0 && options.includes(value);
  
    // console.log('mutgene', parameters['mutgene'], options['mutgene']);
    return (
        <div className={classes.root}>
            {lineageStatus === 'loaded' &&
                <div className={classes.content}>
                    <Grid container columnSpacing={2} pt={2} justifyContent="space-between" width='100%' /*sx={{ paddingRight: 2 }}*/ >
                        {lineage && lineages.includes(lineage) &&
                            <Grid key={`lineage`} xs={4}>
                                <ClearCache />
                                <SelectInput
                                    id='lineage'
                                    label='Current Lineage'
                                    options={lineages}
                                    onChange={handleChangeLineage}
                                    value={lineage}
                                    getOptionValue={(option) => option}
                                    getOptionLabel={(option) => option}
                                />
                            </Grid>
                        }
                        <Grid key={`button`} sx={{ marginLeft: 'auto' }}>
                            <Button className={`${classes.buttonUp} ${classes.button}`} onClick={handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    <FormGroup className={classes.group}>
                        <Typography pt={2} className={classes.title}>Tabs</Typography>
                        <Divider className={classes.divider} />

                        <Grid pb={2} container columnSpacing={2} className={classes.section}>
                            {modules && Object.keys(tabs).map(((element, index) => {
                                const on = element === 'strainTree' || element === 'frequencies';
                                const value = modules[element] || on;
                                // console.log('MODULE', element, value);
                                return (
                                    <Grid key={`item-${index}`} size={2} >
                                        <CheckboxInput
                                            id={element}
                                            label={tabs[element]}
                                            value={value}
                                            onChange={handleChangeModules(element)}
                                            disabled={on}
                                        />
                                    </Grid>
                                );
                            }))}
                        </Grid>

                        <Typography pt={2} className={classes.title}>Options parameters</Typography>
                        <Divider className={classes.divider} />
                        <Grid container columnSpacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'select').map((element, index) => {
                                return (options[element] && options[element].length > 0 &&
                                    <Grid key={`item-${index}-${element}`} size={{md: 3, sm: 4, xs: 12}}>
                                        <SelectInput
                                            id={element}
                                            options={options[element]}
                                            onChange={handleChangeSelect(element)}
                                            value={parameters[element]}
                                            label={element}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid container pb={2} columnSpacing={2} className={classes.section}>  
                            
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'genotype').map((element, index) => 
                                <Grid key={`item-${index}-${element}`} size={{md: 3, sm: 6, xs: 12}} >
                                    {element !== 'mutposition' && options[element] && options[element].length > 0 &&
                                        <SelectInput
                                            options={options[element]}
                                            onChange={handleChangeSelect(element)}
                                            value={parameters[element]}
                                            id={element}
                                            label={element}
                                            onValidateChange={(isValid) => handleValidationChange(element, isValid)}
                                            required={false}
                                        />
                                    }
                                    {element === 'mutposition' && options.mutposition && options.mutposition[parameters.mutgene] &&
                                        <SelectInput
                                            options={options['mutposition'][parameters.mutgene]}
                                            onChange={handleChangeSelect('mutposition')}
                                            value={parameters['mutposition']}
                                            id={element}
                                            label={element}
                                            onValidateChange={(isValid) => handleValidationChange(element, isValid)}
                                            required={false}
                                        />}
                                </Grid>
                            )}
                        </Grid>

                        <Typography pt={2} className={classes.title}>Date parameters</Typography>
                        <Divider className={classes.divider} />
                        <Grid pb={2} container columnSpacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'date').map((element, index) => {
                                const isDateNumber = typeof parameters[element] === 'number';
                                const value = isDateNumber ? daysToDate(parameters[element]) : parameters[element] || null;
                                return (
                                    <Grid size={{md: 3, sm: 6, xs: 12}} key={`datepicker-${index}`}>
                                        <DatePickerInput
                                            id={element}
                                            label={element}
                                            value={value}
                                            onAccept={handleChangeDate(element, isDateNumber)}
                                
                                            clearable={['strainCutOffDate', 'submissionDate'].includes(element)}
                                            onValidateChange={(isValid) => handleValidationChange(element, isValid)}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Typography pt={2} className={classes.title}>Boolean parameters</Typography>
                        <Divider className={classes.divider} />

                        <Grid pb={2} container columnSpacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'boolean').map((element, index) => {
                                return (
                                    <Grid key={`item-${index}`} size={{md: 3, sm: 6, xs: 12}} >
                                        <CheckboxInput
                                            id={element}
                                            label={element}
                                            value={parameters[element] || false}
                                            onChange={handleChangeCheckbox(element)}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Typography pt={2} className={classes.title}>Numeric parameters</Typography>
                        <Divider className={classes.divider} />
                        <Grid pb={2} container columnSpacing={2} className={classes.section}>
                            {parametersToEditArray.filter(type => ['float', 'number'].includes(parametersToEdit[type])).map((element, index) => {
                                return (
                                    <Grid key={`item-${index}-${element}`} size={{md: 3, sm: 6, xs: 12}} >
                                        <NumberInput   
                                            key={`number-${index}-${element}`}
                                            id={element}
                                            label={element}
                                            value={parameters[element] || undefined}
                                            required={true}
                                            onAccept={handleChangeNumber(element)}
                                            allowFloat={parametersToEdit[element] === 'float'}
                                            onValidateChange={(isValid) => handleValidationChange(element, isValid)}
                                        />
                                    </Grid>
                                );
                            })
                            }
                            {parametersToEditArray.filter(type => parametersToEdit[type] === 'nodeId').map((element, index) => {
                                return (
                                    <Grid key={`item-${index}-${element}`} size={{md: 3, sm: 6, xs: 12}}>
                                        <ZoomNodeIdSelect
                                            id={element}
                                            label={element}
                                            value={parameters.zoomNodeId}
                                            required={false}
                                            lineage={lineage}
                                            onAccept={handleChangeNumber(element)}
                                            onValidateChange={(isValid) => handleValidationChange(element, isValid)}
                                        />
                                    </Grid>
                                );
                            })                
                            }
                        </Grid>

                        <Typography pt={2} className={classes.title}>Default model</Typography>
                        <Divider className={classes.divider} />
                        <Grid pb={2} container columnSpacing={2}>
                        
                            {showSelect(parameters.modelType, options.modelType) &&
                                <Grid size={{md: 3, sm: 6, xs: 12}} >
                                    <SelectInput
                                        options={options.modelType}
                                        onChange={handleChangeSelect('modelType')}
                                        value={parameters.modelType}
                                        id='modelType'
                                        label='modelType'
                                    />
                                </Grid>
                            }
                            {!showSelect(parameters.modelType, options.modelType) &&
                                <Grid xs={12} >
                                    <Typography className={classes.text}>No models for this lineage</Typography>
                                </Grid>
                            }
                            <Grid size={{md: 3, sm: 6, xs: 12}} /*className={classes.modelId}*/ >
                                {showSelect(parameters.modelId, options.modelId) &&
                                    <SelectInput
                                        options={options.modelId}
                                        id='modelId'
                                        label='modelId'
                                        onChange={handleChangeSelect('modelId')}
                                        value={parameters.modelId}
                                    />
                                }
                                
                            </Grid>
        
                        </Grid>

                        <Typography pt={2}className={classes.title}>Default antigenic model</Typography>
                        <Divider className={classes.divider} />

                        <Grid pb={2} container columnSpacing={2}>
                            
                            {showSelect(parameters.antigenicModelId, options.antigenicModelId) &&
                                <Grid size={{md: 3, sm: 6, xs: 12}}>
                                    <SelectInput
                                        options={options.antigenicModelId}
                                        onChange={handleChangeSelect('antigenicModelId')}
                                        id='antigenicModelId'
                                        label='antigenicModelId'
                                        value={parameters.antigenicModelId}
                                    />                
                                </Grid>
                            }
                            {!showSelect(parameters.antigenicModelId, options.antigenicModelId) &&
                                <Grid xs={12} >
                                    <Typography className={classes.text}>No antigenic models for this lineage</Typography>
                                </Grid>
                            }   
                        </Grid>
                        <Typography pt={2} className={classes.title}>Default antigenic fitness model</Typography>
                        <Divider className={classes.divider} />
                        <Grid container columnSpacing={2} className={classes.lastSection}> 
                            {showSelect(parameters.antigenicFitnessModelId, options.antigenicFitnessModelId) &&
                                <Grid size={{md: 3, sm: 6, xs: 12}} >
                                    <SelectInput
                                        options={options.antigenicFitnessModelId}
                                        onChange={handleChangeSelect('antigenicFitnessModelId')}
                                        id='antigenicFitnessModelId'
                                        label='antigenicFitnessModelId'
                                        value={parameters.antigenicFitnessModelId}
                                    />
                                </Grid>
                            }
                            {!showSelect(parameters.antigenicFitnessModelId, options.antigenicFitnessModelId) &&
                                <Grid size={{ xs: 12}} >
                                    <Typography className={classes.text}>No antigenic fitness models for this lineage</Typography>
                                </Grid>
                            }
                        </Grid>
                    </FormGroup>
                    <Grid container spacing={2}>
                        <Grid key={`save`} size={{ xs: 12}}>
                            <Button className={`${classes.button} ${classes.buttonDown}`} onClick={handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    <Alert openAlert={openAlert} handleCloseAlert={handleCloseAlert} />
                </div>}
        </div>
    );
};

const mapStateToProps = (state) => {
    //const options = {};
    const lineages = lineagesNamesSelector(state);
    const metadata = state.settings.metadata;
    const parameters = getEditableParameters(state); //filterParameters(state.settings.parameters, parametersToEditArray);
    const options = getSelectSettingsOptions(state);

   
    return {
        lineages,
        // lineagesStatus: state.lineages.lineagesStatus,
        lineageStatus: state.settings.lineages?.lineageStatus,
        parameters,
        modules: metadata.modules,
        lineage: state.settings.parameters.lineage,
        options,
        // modelIdItems,
        // modelIdItemsOptions,
        // antigenicModelIdItems,
        // antigenicModelIdItemsOptions,
        modelTypesStatus: state.settings.models.modelTypesStatus,
        // antigenicSegmentsCount

    };
};


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchVpMethods,
            fetchLineage,
            fetchMutationsPositionsDictionary,
            fetchModels,
            // fetchAntigenicModelsList,
            setSettings,
            fetchModelTypes,
            fetchMutationClasses,
            setSettingsParameters,
            setSettingsMetadata
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ParametersPanel);
