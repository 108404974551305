import React, { useState, useEffect } from 'react';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { styles } from './styles';
import Grid from '@mui/material/Grid2';
import { fetchAxios } from '../../../../functions/axios-requests';
import appConfig from '../../../../config/appConfig';
import config from '../../../../config/envConfig';
import Autocomplete from '@mui/material/Autocomplete';
import { spliceScaleId } from './functions';
// import { spliceScaleId } from './functions';

// const filter = createFilterOptions();
const antigenicOptions = [
    'absolute',
    'drop',
    'fold_reduction'
];

const ScaleIdConfigurator = ({ handleScaleIdChange, newScale, error, edit }) => {
    const [options, setOptions] = useState([]);
    const [isAntigenic, setIsAntigenic] = useState(false);
    const [measure, setMeasure] = useState('');
    const [additionalMeasure, setAdditionalMeasure] = useState('');
    const [idString, setIdString] = useState('');
    const classes = styles();

    useEffect(() => {
        const defaultOptions = Object.keys(appConfig.measures)
            .filter(key => appConfig.measures[key].colorBy === true)
            .sort();
        setOptions(defaultOptions);

        if (edit) {
            const initValues = spliceScaleId(newScale.scaleId);
            setMeasure(initValues.measure);
            setIdString(initValues.idString);
            if (initValues.additionalMeasure)
                setAdditionalMeasure(initValues.additionalMeasure);
        }

    }, []);

    useEffect(() => {
        if (newScale.lineage === 'none') {
            const defaultOptions = Object.keys(appConfig.measures).map(key => key)
                .filter(key => appConfig.measures[key].colorBy === true)
                .sort();
            setOptions(defaultOptions);
        } else {
            const url = `${config.serverLink}/api/admin/getMeasuresForLineage?lineage=${newScale.lineage}`;
            fetchAxios(url).then(function (response) {
                setOptions(response.data.measures.sort());
            }).catch(function (error) {
                console.log(error);
            });
        }
    }, [newScale.lineage]);

    const handleChangeAdditionalAntigenic = (e, v) => {
        const newAdditionalmeasure = v;
        setAdditionalMeasure(newAdditionalmeasure);
        const scaleId = isAntigenic ? `${measure}.${newAdditionalmeasure}.${idString}` : `${measure}.${idString}`;
        handleScaleIdChange(scaleId);
    };

    const handleChangeMeasure = (e, v) => {
        const newMeasure = v;
        setMeasure(newMeasure);
        const scaleId = newMeasure === 'antigenic' ? `${newMeasure}.${additionalMeasure}.${idString}` : `${newMeasure}.${idString}`;
        handleScaleIdChange(scaleId);
        setIsAntigenic(newMeasure === 'antigenic');
    };

    const handleChangeId = (e) => {
        const id = e.target.value;
        setIdString(id);
        const scaleId = isAntigenic ? `${measure}.${additionalMeasure}.${id}` : `${measure}.${id}`;
        handleScaleIdChange(scaleId);
    };

    return (
        <Grid container>
            <Grid size={isAntigenic ? 4 : 6} >
                <Autocomplete
                    freeSolo
                    id="Scale type"
                    disableClearable
                    required
                    options={options}
                    value={measure}
                    getOptionLabel={option => option}
                    onInputChange={handleChangeMeasure}
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            required
                            className={classes.measurePicker}
                            label="Scale type"
                            error={error.scaleType.status}
                            helperText={error.scaleType.message}
                        />
                    )}
                />
            </Grid>
            {isAntigenic &&
                <Grid size={isAntigenic ? 4 : 6} style={{ paddingLeft: '15px' }}>
                    <Autocomplete
                        freeSolo
                        id="Scale type"
                        disableClearable
                        options={antigenicOptions}
                        getOptionLabel={option => option}
                        onInputChange={handleChangeAdditionalAntigenic}
                        value={additionalMeasure}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                required
                                className={classes.measurePicker}
                                label="Scale type"
                                error={error.antigenicScaleType.status}
                                helperText={error.antigenicScaleType.message}
                            />
                        )}
                    />
                </Grid>
            }
            <Grid size={isAntigenic ? 4 : 6} style={{ textAlign: 'end' }}>
                <StyledTextField
                    id="name"
                    type="text"
                    label="Scale name"
                    required
                    autoComplete='off'
                    className={classes.measurePicker}
                    value={idString || ''}
                    onChange={handleChangeId}
                    error={error.id.status}
                    helperText={error.id.message}
                />
            </Grid>
        </Grid>
    );
};

export default ScaleIdConfigurator;
