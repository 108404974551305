import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isNil } from 'lodash';

import { selectNodeData } from '../../redux/actions/nodeActions';
import { setSearchStrainMode } from '../../redux/actions/parametersActions';
import { 
    fetchSelectedStrain, 
    setSelectedStrain, 
    setStrainSearchStatus, 
    fetchStrainsList, 
    setStrainsList 
} from '../../redux/actions/treeDataActions';

import { getTreeNodeAttrs } from '../../redux/selectors/treeDataSelector';
import { prepareUrl } from '../../functions/url-parameters';
import { shouldFetch } from '../../functions/data-helpers';
import { fetchAxios } from '../../functions/axios-requests';

import AutocompleteInput from '../Common/AutocompleteInput';
import config from '../../config/envConfig';

const defaultSearchId = 'searchStrain';

const TreeStrainSearch = (props) => {
    const {
        label = 'Search strain', 
        searchId = defaultSearchId,
        strainId,
        lineage,
        strainSearchStatus,
        selectNodeData,
        setSearchStrainMode,
        fetchSelectedStrain,
        treeAttrs,
        setStrainSearchStatus,
        setSelectedStrain,
        zoomNodeId,
        strainSubset,
        multiple = false
    } = props;
    const [errorTxt, setErrorTxt] = useState('');
    const showNodeInfo = useRef(false);
    // const [selectedOption, setSelectedOption] = useState(multiple ? [] : null);


    const fetchOptionLabel = useCallback(async (strainId) => {
        if (isNil(strainId)) return '';
        let name = treeAttrs[strainId]?.name;
        if (!name) {
            try {
                const response = await fetchAxios(`${config.serverLink}/api/tree/name/${lineage}/${strainId}`);
                name = response.data.name;
            } catch (error) {
                console.error('Error fetching strain name:', error);
                name = '';
            }
        }
        return name;
    },[lineage]);



    useEffect(() => {
        if (!strainSearchStatus || strainSearchStatus === 'loading' || strainSearchStatus === 'none') {
            setErrorTxt('');
            return;
        }

        // If we have a strainId and the status indicates results are final
        if (strainId != null && !shouldFetch(strainSearchStatus)) {
            const statuses = Object.fromEntries(strainSearchStatus.split(',').map(k => [k, true]));
            const errorMessages = [];

            if (statuses.not_found) {
                setSearchStrainMode(false);
                errorMessages.push('Strain not found.');
            }
            if (statuses.not_in_scope) {
                setSearchStrainMode(false);
                errorMessages.push('Strain not in the selected branch.');
            }
            if (statuses.not_in_subset) {
                setSearchStrainMode(false);
                errorMessages.push('Strain not in the selected subset.');
            }

            if (statuses.found && showNodeInfo.current) {
                setSearchStrainMode(true);
                selectNodeData({ nodeId: strainId });
            }

            setErrorTxt(errorMessages.join('\n'));
        }
    }, [strainSearchStatus, strainId]);

    const fetchOptions = useCallback(async (inputValue) => {
        try {
            const url = prepareUrl(`${config.serverLink}/api/tree/search`, {
                lineage,
                strainsTxt: inputValue.toUpperCase()
            });
            const response = await fetchAxios(url);
            return response.data.strainsList;
        } catch (error) {
            console.error('Error fetching options:', error);
            throw error;
        }
    }, [lineage]);

    const handleSelectedStrainMemo = useCallback((ids) => {
        if (isNil(ids)) {
            setSelectedStrain({ searchId, strainId: null });
            selectNodeData();
            return;
        }

        const selectedIds = Array.isArray(ids) ? ids : [ids];
        const id = selectedIds[0];

        setSearchStrainMode(true);
        if (!treeAttrs[id]) {
            fetchSelectedStrain({ lineage, strainId: id, searchId, zoomNodeId, strainSubset });
        } else {
            setStrainSearchStatus({ strainSearchStatus: 'found', searchId });
            setSelectedStrain({ searchId, strainId: id });
            selectNodeData({ nodeId: id });
        }
        showNodeInfo.current = true;
    }, [lineage, searchId, zoomNodeId, strainSubset, multiple]);



    const getOptionLabelMemo = useCallback((option) => option?.n ?? '', []);
    const isOptionEqualToValueMemo = useCallback((option, value) => {
        // console.log('isOptionEqualToValueMemo', { option, value });
        const result = !!value && option.id === value.id;
        return result;
    }, []);

    const onMouseEnterMemo = useCallback((value) => {
        selectNodeData({ nodeId: value, nodeType: 'leaf', onlyHighlight: true });
        // console.log('onMouseEnterMemo', { value });
    }, [selectNodeData]);

    const onMouseLeaveMemo = useCallback(() => {
        selectNodeData({ onlyHighlight: true });
        // console.log('onMouseLeaveMemo');
    }, [selectNodeData]);

    return (
        <AutocompleteInput
            id={searchId}
            label={label}
            valueIds={strainId}
            fetchOptions={fetchOptions}
            fetchOptionLabel={fetchOptionLabel}
            onSelect={handleSelectedStrainMemo}
            getOptionLabel={getOptionLabelMemo}
            isOptionEqualToValue={isOptionEqualToValueMemo}
            onMouseEnter={onMouseEnterMemo}
            onMouseLeave={onMouseLeaveMemo}
            errorTxt={errorTxt}
            formatId={id => Number(id) || id}
            multiple={multiple}
        />
    );
};

TreeStrainSearch.propTypes = {
    // Required props
    fetchStrainsList: PropTypes.func.isRequired,
    searchId: PropTypes.string, 
    setStrainsList: PropTypes.func.isRequired,
    selectNodeData: PropTypes.func.isRequired,
    setSearchStrainMode: PropTypes.func.isRequired,
    fetchSelectedStrain: PropTypes.func.isRequired,
    treeAttrs: PropTypes.object.isRequired,
    setStrainSearchStatus: PropTypes.func.isRequired,
    setSelectedStrain: PropTypes.func.isRequired,
    label: PropTypes.string,

    // Optional props
    strainId: PropTypes.number,
    lineage: PropTypes.string,
    loading: PropTypes.bool,
    strainSearchStatus: PropTypes.string,
    strainsListStatus: PropTypes.string,
    strainName: PropTypes.string,
    zoomNodeId: PropTypes.number,
    strainSubset: PropTypes.string,
    multiple: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const { parameters, treeData } = state;
    const { strainId, lineage, zoomNodeId, strainSubset } = parameters;
    const treeAttrs = getTreeNodeAttrs(state);

    const searchId = ownProps.searchId || defaultSearchId;
    return {
        strainId,
        lineage,
        zoomNodeId, 
        strainSubset,
        strainName: strainId != null && treeAttrs[strainId]?.name || '',
        strainSearchStatus: treeData.strainSearchStatuses?.[searchId],
        strainsListStatus: treeData.strainsListStatuses?.[searchId],
        loading: treeData.strainsListStatuses?.[searchId] === 'loading' ||
                 treeData.strainSearchStatuses?.[searchId] === 'loading',
        treeAttrs
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setSearchStrainMode,
            fetchSelectedStrain,
            setSelectedStrain,
            setStrainSearchStatus,
            fetchStrainsList,
            setStrainsList
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TreeStrainSearch);
