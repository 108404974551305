import { produce } from 'immer';
import { emptyObject, daysToDate } from '../../functions/utils';
import alertsConfig from '../../config/alertsConfig.json';

import {
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_RECALCULATED_TREE_ERROR,
    ZOOM_TREE_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_ALERT_STATUS,
    FETCH_VP_METHODS_ERROR,
    FETCH_SUBSET_TREE_ERROR,
    FETCH_TREE_FREQS_ERROR,
    FETCH_STRAINS_LIST_ERROR,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_MUT_POS_DICT_ERROR,
    FETCH_GENOTYPE_DATA_ERROR,
    FETCH_PREDICTIONS_ERROR,
    FETCH_MODEL_DATA_ERROR,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_TYPES_ERROR,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_ERROR,
    FETCH_GEO_DATA_ERROR,
    FETCH_FREQUENCIES_ERROR,
    FETCH_CLADES_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    FETCH_ANTIGENIC_CLADES_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_MODEL_DATA_SUCCESS,
    SET_MODEL_TYPE,
    INIT_STRAIN_TREE_SUCCESS,
    INIT_STRAIN_TREE_ERROR,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_FREQUENCIES_SUCCESS,
    SIGNIN_SUCCESS,
    INTERNAL_SERVER_ERROR,
    REQUEST_TIMEOUT,
    NETWORK_ERROR,
} from '../actions/actionTypes';

let alertInitialState = {};
export const setAlertInitialState = (state) => {
    alertInitialState = state;
};

const setShow = (errors, infos) => {
    return Object.keys(errors).length > 0 || Object.keys(infos).length > 0;
};

const changeActionTypeIntoInfo = (actionType) => {
    const ret = actionType.replaceAll('_', ' ').toLowerCase();
    return ret.charAt(0).toUpperCase() + ret.slice(1);
};

const adjustAlertInfoAndMessage = (alert, actionType, messageVar) => {
    const _alert = { ...alert };
    if (actionType) {
        const formattedAction = changeActionTypeIntoInfo(actionType);
        _alert.input = formattedAction;
    }
    if (messageVar) {
        const message = _alert.message.replace('{X}', messageVar);
        _alert.message = message;
    }
    return _alert;
};

const addAlerts = (draft, alertId, messageVar, alertType, actionType) => {
    const alerts = alertType === 'error' ? alertsConfig.errors : alertsConfig.infos;

    const alert = adjustAlertInfoAndMessage(alerts[alertId], actionType, messageVar);
    const { modules } = alert;
    if (!modules) return;
    modules.forEach((module) => {
        if (!draft[module]) {
            draft[module] = { show: false, errors: {}, infos: {} };
        }
        const modAlerts = alertType === 'error' ? draft[module].errors : draft[module].infos;
        modAlerts[alertId] = alert;
        draft[module].show = setShow(draft[module].errors, draft[module].infos);
    });
};

const removeAlerts = (draft, alertId, alertType) => {
    const alerts = alertType === 'error' ? alertsConfig.errors : alertsConfig.infos;
    const alert = alerts[alertId];

    if (!alert) return;
    const { modules } = alert;
    modules.forEach((module) => {
        if (!draft[module]) {
            draft[module] = { show: false, errors: {}, infos: {} };
        }
        const modAlerts = alertType === 'error' ? draft[module].errors : draft[module].infos;
        delete modAlerts[alertId];
        draft[module].show = setShow(draft[module].errors, draft[module].infos);
    });
};

const setErrors = (draft, errorId, show = true, actionType = null, messageVar = null) => {
    if (show) {
        addAlerts(draft, errorId, messageVar, 'error', actionType);
    } else {
        removeAlerts(draft, errorId, 'error');
    }
};

const setInfos = (draft, infoId, show = true, actionType = null, messageVar = null) => {
    if (show) {
        addAlerts(draft, infoId, messageVar, 'info', actionType);
    } else {
        removeAlerts(draft, infoId, 'info');
    }
};

export const alertReducer = (state = alertInitialState, action) =>
    produce(state, (draft) => {
        // console.log('alertReducer', action.type);
        switch (action.type) {
            case INIT_STRAIN_TREE_ERROR: {
                setErrors(draft, action.payload.error || 'strainTreeEmptyData');
                break;
            }

            case FETCH_SUBSET_TREE_SUCCESS: {
                const { visibleNodes } = action.payload;
                setErrors(draft, 'emptySubsetTree', emptyObject(visibleNodes));
                break;
            }

            case INIT_STRAIN_TREE_SUCCESS: {
                const { visibleNodes } = action.payload;
                setErrors(draft, 'strainTreeEmptyData', emptyObject(visibleNodes));
                break;
            }
            case FETCH_TREE_FREQS_SUCCESS: {
                const { origPredictionBaseline, parameters } = action.payload;

                const origDateString = daysToDate(origPredictionBaseline).toLocaleDateString();
                const showPredictionBaseline = origPredictionBaseline !== parameters.predictionBaseline;

                if (!showPredictionBaseline) {
                    setInfos(draft, 'predictionBaseline', false);
                } else {
                    setInfos(draft, 'predictionBaseline', true, null, origDateString);
                }
                break;
            }
            case SET_ALERT_STATUS: {
                // const { id, status, messageVar } = action.payload;
                // console.log(id, status, messageVar);
                // const info = alertsConfig.infos[id];
                // const error = alertsConfig.errors[id];
                // if (info) {
                //     setInfos(draft, id, status, null, messageVar);
                // } else if (error) {
                //     setErrors(draft, id, status, null, messageVar);
                // }
                break;
            }

            case FETCH_STRAINS_LIST_ERROR:
            case FETCH_SUBSET_TREE_ERROR:
            case FETCH_TREE_FREQS_ERROR:
            case FETCH_VP_METHODS_ERROR:
            case FETCH_MUT_POS_DICT_ERROR:
            case FETCH_GENOTYPE_DATA_ERROR:
            case FETCH_MODEL_DATA_ERROR:
            case FETCH_MODELS_ERROR:
            case FETCH_MODEL_TYPES_ERROR:
            case FETCH_LINEAGES_ERROR:
            case FETCH_LINEAGE_ERROR:
            case FETCH_CLADES_ERROR:
            case FETCH_CUSTOM_TREE_ATTRS_ERROR:
            case FETCH_RECALCULATED_TREE_ERROR:
            case FETCH_MUTATION_CLASSES_ERROR:
            case ZOOM_TREE_ERROR: {
                const errorId = action.payload.error || 'fetchError';
                setErrors(draft, errorId, true, action.type);
                break;
            }
            case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR:
            case FETCH_ANTIGENIC_RAW_MODEL_ERROR:
            case FETCH_ANTIGENIC_CLADES_ERROR: {
                const errorId = action.payload.error || 'fetchError';
                setErrors(draft, errorId, true, action.type);
                break;
            }
            case FETCH_MODEL_DATA_SUCCESS: {
                const isError = emptyObject(action.payload.treeModel);
                setErrors(draft, 'noModelError', isError);
                break;
            }
            case SET_MODEL_TYPE: {
                setErrors(draft, 'noModelError', false);
                break;
            }
            case FETCH_FREQUENCIES_ERROR: {
                setErrors(draft, 'fetchFrequenciesError', true, action.type);
                break;
            }
            case FETCH_PREDICTIONS_ERROR: {
                setErrors(draft, 'fetchPredictionsError', true, action.type);
                break;
            }

            case FETCH_FREQUENCIES_SUCCESS: {
                const { frequencies, strainSubset } = action.payload;
                const noDataError = !frequencies[strainSubset] || frequencies[strainSubset].length === 0;
                setErrors(draft, 'fetchFrequenciesNoData', noDataError, action.type);
                break;
            }
            case SIGNIN_SUCCESS:
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return alertInitialState;
            }
            case FETCH_GEO_DATA_ERROR: {
                setErrors(draft, 'fetchGeoMapError', true, action.type);
                break;
            }
            case NETWORK_ERROR:
            case INTERNAL_SERVER_ERROR:
            case REQUEST_TIMEOUT: {
                const { error } = action.payload;
                draft.error = error;
                break;
            }
            default:
                break;
        }
    });
