import { useCallback, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

export const useDrag = (onDrag, element) => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const startPosition = useRef({ x: 0, y: 0 });

    const handleMouseDown = useCallback((e) => {
        setIsDragging(true);
        startPosition.current = {
            x: e.clientX - dragOffset.x,
            y: e.clientY - dragOffset.y
        };
    }, [dragOffset]);

    const handleMouseMove = useCallback((e) => {
        if (!isDragging) return;
        
        const newX = e.clientX - startPosition.current.x;
        const newY = e.clientY - startPosition.current.y;
        
        setDragOffset({ x: newX, y: newY });
        onDrag({ x: newX, y: newY });
    }, [isDragging, onDrag]);

    const handleMouseUp = useCallback(() => {
        if (!isDragging) return;
        setIsDragging(false);
    }, [isDragging]);

    useEventListener('mousemove', handleMouseMove, element || document, isDragging);
    useEventListener('mouseup', handleMouseUp, element || document, isDragging);

    return {
        isDragging,
        dragOffset,
        onMouseDown: handleMouseDown
    };
}; 
