import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    root: {
        backgroundColor: '#e2e2e2',
        height: 'calc(100vh - 184px)',
        maxHeight: 'calc(100vh - 184px)',
        overflow: 'overlay'
    },
    content: {
        margin: '0px 15px 15px 15px',
        backgroundColor: 'white',
        height: 'calc(100% - 100px)',
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    item: {
        position: 'relative',
        height: '100%',
        overflowY: 'overlay',
        maxHeight: '80vh',
    },
    search: {
        width: 350,
        marginLeft: '15px',
        marginRight: '15px'
    },
    searchAuto: {
        width: 350,
        marginRight: '15px'
    },
    searchcont: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    buttonCross:{
        padding: 0,
        margin: 0
    },
    formControl: {
        width: 350,
        margin: '15px 0px 8px 15px'
    },
    saveButton: {
        margin: '15px',
        backgroundColor: '#e0e0e0',
        color: 'black',
        padding: '5px 12px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            color: 'black',
        },
    },
    cancelButton: {
        backgroundColor: '#e2e2e2',
        color: 'black',
        textTransform: 'none',
        padding: '5px 12px',
        '&:hover': {
            backgroundColor: '#e2e2e2',
            color: 'black',
        },
    },
    searchSelect: {
        width: '350px',
        marginTop: '15px',
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        '& input:-internal-autofill-selected': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            // fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        }
    },
}));
