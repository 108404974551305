import { makeStyles } from '@mui/styles';
import { isFirefox } from '../../functions/browser-check';

export const styles = makeStyles({
    rootExport: {
        flexGrow: 1,
        margin: '50px'
    },
    map: {
        height: 'calc(100% - 20px)',
        margin: '0px 20px 0px 20px'
    },
    mapExport: {
        height: '100%',
        width: '100%',
        margin: 0
    },
    mapMobile: {
        height: '100%',
    },
    mapMobileHidden: {
        height: '100%',
        opacity: 0
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },

    border: {
        boxSizing: 'border-box',
        border: 'solid 1px #c1c1c1'
    },
    item: {
        position: 'relative',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'overlay'
    },
    mapSidebar: {
        position: 'absolute',
        right: 0,
        height: 'calc(100% - 106px)',
        overflow: 'hidden',
        padding: '0px 20px 0px 0px'
    },
    mapSidebarLeft: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        height: '100%',
        maxHeight: '100%',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        width: '360px'
    },
    mapSidebarRight: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        right: 0,
        backgroundColor: 'white',
        height: '100%',
        boxSizing: 'border-box',
        width: '360px'
    },
    hidden: {
        width: '32px'
    },
    noData: {
        marginTop: '5px',
        marginLeft: '5px',
        fontFamily: 'Inter Bold'
    },
    noDataIcon: {
        marginLeft: '10px',
        marginTop: '10px',
    },
});
