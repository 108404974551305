// Action Types
import {
    FETCH_ANTIGENIC_MODEL_REQUEST,
    FETCH_ANTIGENIC_MODEL_SUCCESS,
    FETCH_ANTIGENIC_MODEL_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_REQUEST,
    FETCH_ANTIGENIC_RAW_MODEL_SUCCESS,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST,
    FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    FETCH_ANTIGENIC_CLADES_REQUEST,
    FETCH_ANTIGENIC_CLADES_SUCCESS,
    FETCH_ANTIGENIC_CLADES_ERROR,
    SELECT_ANTIGENIC_VALUES,
    SET_ANTIGENIC_HIDDEN_ALPHA_CLADE,
    SET_ANTIGENIC_HIDDEN_RHO_CLADE,
    UNHIDE_ALPHA_CLADE,
    UNHIDE_RHO_CLADE,
    RESET_ANTIGENIC_HIDDEN_CLADES,
    RESET_ANTIGENIC_MODEL,
    RESET_ANTIGENIC_CLADES,
    SET_PARAMETERS,
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';
import { getParametersObject } from '../../functions/url-parameters';

// const fetchAntigenicModelsList = (payload) => received(FETCH_ANTIGENIC_MODELS_LIST_REQUEST, payload)

// const fetchAntigenicModelsListSuccess = (payload) => received(FETCH_ANTIGENIC_MODELS_LIST_SUCCESS, payload)

// const fetchAntigenicModelsListError = (payload) => received(FETCH_ANTIGENIC_MODELS_LIST_ERROR, payload)

const fetchAntigenicModel = (payload) => received(FETCH_ANTIGENIC_MODEL_REQUEST, payload);

const fetchAntigenicModelSuccess = (payload) => received(FETCH_ANTIGENIC_MODEL_SUCCESS, payload);

const fetchAntigenicModelError = (payload) => received(FETCH_ANTIGENIC_MODEL_ERROR, payload);

const fetchAntigenicRawModel = (payload) => received(FETCH_ANTIGENIC_RAW_MODEL_REQUEST, payload);

const fetchAntigenicRawModelSuccess = (payload) => received(FETCH_ANTIGENIC_RAW_MODEL_SUCCESS, payload);

const fetchAntigenicRawModelError = (payload) => received(FETCH_ANTIGENIC_RAW_MODEL_ERROR, payload);

const fetchAntigenicObservedData = (payload) => received(FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST, payload);

const fetchAntigenicObservedDataSuccess = (payload) => received(FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS, payload);

const fetchAntigenicObservedDataError = (payload) => received(FETCH_ANTIGENIC_OBSERVED_DATA_ERROR, payload);

const setParameters = ({
    lineage,
    modelId,
    antigenicDataType,
    antigenicTiterType,
}) => received(SET_PARAMETERS, getParametersObject({
    lineage,
    modelId,
    antigenicDataType,
    antigenicTiterType,
}));

const fetchAntigenicClades = (payload) => received(FETCH_ANTIGENIC_CLADES_REQUEST, payload);

const fetchAntigenicCladesSuccess = (payload) => received(FETCH_ANTIGENIC_CLADES_SUCCESS, payload);

const fetchAntigenicCladesError = (payload) => received(FETCH_ANTIGENIC_CLADES_ERROR, payload);

const selectAntigenicValues = ({ alpha, rho }) => received(SELECT_ANTIGENIC_VALUES, { alpha, rho });

const setAntigenicHiddenAlphaClade = (alpha) => received(SET_ANTIGENIC_HIDDEN_ALPHA_CLADE, { alpha });

const setAntigenicHiddenRhoClade = (rho) => received(SET_ANTIGENIC_HIDDEN_RHO_CLADE, { rho });

const unhideAlphaClade = (alpha) => received(UNHIDE_ALPHA_CLADE, { alpha });

const unhideRhoClade = (rho) => received(UNHIDE_RHO_CLADE, { rho });

const resetAntigenicHiddenClades = () => received(RESET_ANTIGENIC_HIDDEN_CLADES, {});

const resetAntigenicModel = (payload) => received(RESET_ANTIGENIC_MODEL, payload);

const resetAntigenicClades = () => received(RESET_ANTIGENIC_CLADES, {});

export {
    fetchAntigenicModel,
    fetchAntigenicModelSuccess,
    fetchAntigenicModelError,
    fetchAntigenicRawModel,
    fetchAntigenicRawModelSuccess,
    fetchAntigenicRawModelError,
    fetchAntigenicObservedData,
    fetchAntigenicObservedDataSuccess,
    fetchAntigenicObservedDataError,
    setParameters,
    fetchAntigenicClades,
    fetchAntigenicCladesSuccess,
    fetchAntigenicCladesError,
    selectAntigenicValues,
    setAntigenicHiddenAlphaClade,
    setAntigenicHiddenRhoClade,
    resetAntigenicHiddenClades,
    resetAntigenicModel,
    resetAntigenicClades,
    unhideAlphaClade,
    unhideRhoClade
};
