// Action Types
import {
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_ERROR,
    SIGNOUT_REQUEST,
    SIGNOUT_SUCCESS,
    SIGNOUT_ERROR,
    GET_SIGNED_USER_REQUEST,
    GET_SIGNED_USER_SUCCESS,
    GET_SIGNED_USER_ERROR,
    SET_USER_PREFERENCES
} from './actionTypes';

// Base Actions
import { received, request } from '../baseActions';

export const signIn = (user) => received(SIGNIN_REQUEST, user);
export const signInSuccess = (res) => received(SIGNIN_SUCCESS, res);
export const signInError = (error) => received(SIGNIN_ERROR, error);

export const signOut = (payload) => received(SIGNOUT_REQUEST, payload);
export const signOutSuccess = (res) => received(SIGNOUT_SUCCESS, res);
export const signOutError = (error) => received(SIGNOUT_ERROR, error);

export const getSignedUser = () => request(GET_SIGNED_USER_REQUEST);
export const getSignedUserSuccess = (res) => received(GET_SIGNED_USER_SUCCESS, res);
export const getSignedUserError = (error) => received(GET_SIGNED_USER_ERROR, error);

export const setUserPreferences = (payload) => received(SET_USER_PREFERENCES, payload);
