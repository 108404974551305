import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { FETCH_GEO_DATA_REQUEST, } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import { fetchGeoDataError, fetchGeoDataSuccess } from '../actions/geomapActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';

export const fetchGeoMapDataEpic = (action$) => action$.pipe(
    ofType(FETCH_GEO_DATA_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/geomap`, action.payload);
        // console.log(`fetchGeoData => url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchGeoDataSuccess(response.data)),
                handleErrors(fetchGeoDataError, 'An error has occurred during downloading map data')
            );
    })
);
