import { createSelector } from 'reselect';
import { emptyObject } from '../../functions/utils';
import { getStrainsLists } from './treeDataSelector';

const getVaccineCandidates = ({ metadata }) => metadata.vaccineCandidates;

export const getLabeledVaccineCandidates = createSelector(
    getVaccineCandidates,
    (vaccineCandidates) =>
        vaccineCandidates
            .map((v) => ({ ...v, vaccine: true }))
            .sort((s1, s2) => s1.n.localeCompare(s2.n))
);

export const getLabeledStrainsListWithVaccineCandidates = createSelector(
    [getVaccineCandidates, getStrainsLists],
    (vaccineCandidates, strainsLists) => {
        const vcDict = (vaccineCandidates || []).reduce((acc, v) => {
            acc[v.id] = 1;
            return acc;
        }, {});

        const list = Object.keys(strainsLists).reduce(
            (_strainsLists, searchId) => {
                _strainsLists[searchId] = strainsLists[searchId]
                    ? strainsLists[searchId]
                        .map((s) => ({
                            ...s,
                            vaccine: !emptyObject(vcDict[s.id]),
                        }))
                        .sort((s1, s2) => s1.n.localeCompare(s2.n))
                    : undefined;

                return _strainsLists;
            },
            {}
        );

        return list;
    }
);
