import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { selectZoomNodeError, selectZoomNodeSuccess } from '../actions/zoomActions';
import { ZOOM_TREE_REQUEST } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';


export const selectZoomNodeEpic = (action$) => action$.pipe(
    ofType(ZOOM_TREE_REQUEST),
    mergeMap(action => {
        const { zoomNodeId, zoomType, colorBy, strainSubset, strainHighlight, lineage /*, modelRegionId, modelId */ } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/tree/zoom`, { zoomNodeId, colorBy, strainSubset, strainHighlight, lineage /*, modelRegionId, modelId*/ });
        // console.log(url);
        return from(fetchAxios(url))
            .pipe(
                map(response => selectZoomNodeSuccess({  ...response.data, zoomType})),
                handleErrors(selectZoomNodeError, 'An error has occurred during downloading zoomed node data.')
            );
    })
);
