import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutocompleteInput from '../../Common/AutocompleteInput';
import { getAllFerretRefStrainsWithLabAndClade, sortedFreqFerretStrainsSelector, vaccineStrainId } from '../../../redux/selectors/vaccinesSelector';
import { setParameters } from '../../../redux/actions/parametersActions';
import { vaccinesDefaultsStatusSelector } from '../../../redux/selectors/statusSelector';

const defaultSearchId = 'ferret_refstrains';

const FerretReferenceStrainsSelector = ({
    label = 'Ferret reference strains',
    searchId = defaultSearchId,
    vaccinesRefStrains,
    refStrainsNames,
    initStrainsList,
    setParameters,
    loaded
}) => {

    // console.log('[ReferenceStrainsSelector] vaccinesRefStrains', vaccinesRefStrains);
    
    const handleSelectedStrain = (selectedStrains) => {
        const _selectedStrains = selectedStrains.map(strain => {
            const { refid, lab } = JSON.parse(strain);
            return [refid, lab];
        });
        setParameters({vaccinesFerretRefStrains: _selectedStrains});
    };

    const initStrainsListWithLab = useMemo(() => {
        const list = (initStrainsList||[]).map(strain => ({
            id: vaccineStrainId(strain),
            name: `${strain.name} (${strain.lab})`,
        })).sort((a, b) => a.name.localeCompare(b.name));
        return list;
    }, [initStrainsList]);

    // console.log('[ReferenceStrainsSelector] initStrainsListWithLab', initStrainsListWithLab);

    const fetchOptionLabel = useCallback(async (strainLabId) => {
        const { refid, lab } = JSON.parse(strainLabId);
        const name = refStrainsNames?.[refid]?.name;
        return `${name} (${lab})`;
    }, [refStrainsNames]);
    
    const getOptionLabelMemo = useCallback((option) => option?.name ?? '', []);
    
    const isOptionEqualToValueMemo = useCallback((option, value) => {
        if (!option || !value) return false;
        return option.id === value.id;
    }, []);

    if (!loaded) return null;

    return (
        <AutocompleteInput
            id={searchId}
            label={label}
            initialOptions={initStrainsListWithLab}
            getOptionLabel={getOptionLabelMemo}
            isOptionEqualToValue={isOptionEqualToValueMemo}
            valueIds={vaccinesRefStrains}
            onSelect={handleSelectedStrain}
            fetchOptionLabel={fetchOptionLabel}
            shouldFetchOptions={false}
            multiple={true}
            showMinTextLength={false}
        />
    );
};

FerretReferenceStrainsSelector.propTypes = {
    searchId: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    vaccinesRefStrains: PropTypes.array,
    treeAttrs: PropTypes.object,
    initStrainsList: PropTypes.array,
    setParameters: PropTypes.func.isRequired,
    label: PropTypes.string
};

const mapStateToProps = (state) => {
    const vaccinesDefaultsStatus = vaccinesDefaultsStatusSelector(state);
    return {
        vaccinesRefStrains: sortedFreqFerretStrainsSelector(state),
        refStrainsNames: state.vaccines.vaccinesData.refStrains,
        initStrainsList: getAllFerretRefStrainsWithLabAndClade(state),
        loaded: state.vaccines.vaccinesDataStatus === 'loaded' 
            && vaccinesDefaultsStatus === 'loaded',
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FerretReferenceStrainsSelector);
