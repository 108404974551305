// External imports
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';

// Internal imports - Components
import NumberInput from '../Common/NumberInput';

const SigmaAgSelector = ({ sigmaAg, updateModelParameter, index }) => {
   
    const updateSigmaAg = (value) => {
        updateModelParameter(value, index);
    };
 
    return (
        <Grid size={6}>
            <NumberInput
                id="sigmaAg"
                label="SigmaAg"
                value={sigmaAg}
                onAccept={updateSigmaAg}
                allowFloat={true}
            />
        </Grid>
    );
};

SigmaAgSelector.propTypes = {
    // Required props
    index: PropTypes.number.isRequired,
    sigmaAg: PropTypes.number.isRequired,
    updateModelParameter: PropTypes.func.isRequired
};



export default SigmaAgSelector;
