import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
} from '@mui/material';

import { useStyles, dialog } from './styles';
import { setParameters } from '../../redux/actions/parametersActions';
import CheckboxInput from '../Common/CheckboxInput';
import NumberInput from '../Common/NumberInput';


const dimensionsTabs = new Set(['strainTree', 'frequencies', 'vaccines']);
const legendTabs = new Set(['strainTree', 'frequencies', 'vaccines', 'geomap']);

const PDFDimensions = (props) => {
    const {
        handleCloseDialog,
        savePDF,
        setParameters,
        exportLegend,
        exportDimensions,
        filename
    } = props;

    const classes = useStyles();
    // Default dimensions
    const [dimensions, setDimensions] = useState({
        height: exportDimensions?.height || 450,
        width: exportDimensions?.width || 800
    });

    // const handleChange = (e, type) => {
    //     const { value } = e.target;
    //     const regex = /^[0-9]*$/;

    //     // Strip a leading zero unless it's the only digit
    //     let input = value;
    //     if (input[0] === '0' && input.length > 1) {
    //         input = input.substring(1);
    //     }

    //     // If the user clears the input entirely, default to '0'
    //     if (input === '') {
    //         input = '0';
    //     }

    //     // Only update state if the input is purely numeric
    //     if (regex.test(input)) {
    //         setDimensions((prev) =>
    //             type === 'height'
    //                 ? { ...prev, height: input }
    //                 : { ...prev, width: input }
    //         );
    //     }
    // };

    const handleWidthChange = (value) => {
        setDimensions((prev) => ({ ...prev, width: value }));
    };

    const handleHeightChange = (value) => {
        setDimensions((prev) => ({ ...prev, height: value }));
    };

    const handleCheckboxChange = () => {
        setParameters({ exportLegend: !exportLegend });
    };

    return (
        <Dialog open onClose={handleCloseDialog} sx={dialog}>
            <DialogTitle id="dimensions-dialog-title">Export Options</DialogTitle>

            <DialogContent>
                {dimensionsTabs.has(filename) && (
                    <>
                        <DialogContentText>
              Please specify the dimensions of the PDF you wish to download:
                        </DialogContentText>
                        <NumberInput
                            id="width"
                            label="Width"
                            value={dimensions.width}
                            onAccept={handleWidthChange}
                            min={0}
                            required={true}
                        />
                        <NumberInput
                            id="height"
                            label="Height"
                            value={dimensions.height}
                            onAccept={handleHeightChange}
                            min={0}
                            required={true}
                            readOnly={filename === 'vaccines'}

                        />
                    </>
                )}

                {legendTabs.has(filename) && (
                    <CheckboxInput
                        id="exportLegend"
                        label="Export legend"
                        value={exportLegend}
                        onChange={handleCheckboxChange}
                    />
                )}
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
          Cancel
                </Button>
                <Button
                    className={classes.confirm}
                    onClick={() => savePDF(dimensions)}
                    id="download-pdf"
                >
          Download
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PDFDimensions.propTypes = {
    /** Closes the PDFDimensions dialog */
    handleCloseDialog: PropTypes.func.isRequired,
    /** Called to trigger a PDF download, receiving the chosen dimensions */
    savePDF: PropTypes.func.isRequired,
    /** Toggles the 'exportLegend' parameter in Redux */
    setParameters: PropTypes.func.isRequired,
    /** Whether or not a legend should be exported (from Redux) */
    exportLegend: PropTypes.bool.isRequired,
    /** Initial dimension override (if any) */
    exportDimensions: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
    }),
    /** Used to conditionally render dimension fields and legend checkbox */
    filename: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    exportLegend: state.parameters.exportLegend
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setParameters }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PDFDimensions);
