import React from 'react';
import { makeStyles } from '@mui/styles';

const styles = {
    lineButtons: {
        marginTop: '2px',
        marginBottom: '5px',
        backgroundColor: '#B5B2B2',
        marginLeft: '26px',
        marginRight: '0px',
        height: '46px'
    },
    lineButtonsMobile: {
        marginTop: '-5px',
        marginBottom: '5px',
        backgroundColor: '#B5B2B2',
        marginLeft: '9px',
        marginRight: '0px',
        height: '44px'
    }
};
const useStyles = makeStyles(styles);

const MenuSeparator = ({isMobile}) => {
    const classes = useStyles();
   
    return <svg className={isMobile ? classes.lineButtonsMobile : classes.lineButtons} xmlns="http://www.w3.org/2000/svg" width="1" height="72" viewBox="0 0 1 72" fill="#B5B2B2">
        <path d="M1 0L1 72" stroke="#B5B2B2" />
    </svg>;
};

export default MenuSeparator;
