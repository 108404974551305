// Start of Selection
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';

// Custom Components
import DatePickerInput from '../Common/DatePickerInput';
import { styles } from '../Tree/styles';

// Redux Actions
import { setParameters } from '../../redux/actions/parametersActions';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { setAlertStatus } from '../../redux/actions/alertActions';

// Redux Selectors
import { trackingFromSelector, trackingToSelector } from '../../redux/selectors/parametersSelector';

// Utils
import { subtractYears } from '../../functions/utils';

const TrackingFromPicker = ({
    trackingFrom,
    trackingTo,
    intro,
    resetFrequencies,
    setParameters,
    setAlertStatus
}) => {
    const now = new Date();

    const minDate = useMemo(() => (intro ? subtractYears(trackingTo, 10) : undefined), [intro, trackingTo]);

    useEffect(() => {
        const errorStatus = trackingTo < trackingFrom;
        setAlertStatus({
            status: errorStatus,
            model: 'frequencies',
            id: 'trackingToTrackingFromError',
            messageVar: trackingFrom,
        });
    }, [trackingTo, trackingFrom, setAlertStatus]);

    const handleAccept = (value) => {
        setParameters({ trackingFrom: value });
        resetFrequencies();
    };

    return (
        <DatePickerInput
            id='trackingFrom'
            value={trackingFrom || now}
            label="Tracking from"
            onAccept={handleAccept}
            minDate={minDate}
            maxDate={trackingTo}
            allowSameDateSelection
            disableToolbar
        />
    );
};

TrackingFromPicker.propTypes = {
    // Data
    trackingFrom: PropTypes.instanceOf(Date),
    trackingTo: PropTypes.instanceOf(Date),
    intro: PropTypes.bool,
    
    // Actions
    setParameters: PropTypes.func.isRequired,
    resetFrequencies: PropTypes.func.isRequired,
    setAlertStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        trackingFrom: trackingFromSelector(state),
        trackingTo: trackingToSelector(state),
        intro: state.parameters.intro,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            resetFrequencies,
            setAlertStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackingFromPicker));
