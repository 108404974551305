import { Grid2 as Grid } from '@mui/material';
import React from 'react';
import DatePickerInput from '../../Common/DatePickerInput';
import { connect } from 'react-redux';
import { setParameters } from '../../../redux/actions/parametersActions';

import { bindActionCreators } from 'redux';
import { vaccinesPredictedProtectionDateSelector } from '../../../redux/selectors/parametersSelector';
import { resetVaccinesPredictions } from '../../../redux/actions/vaccinesActions';
const PredictedProtectionDateSelector = ({ vaccinesPredictedProtectionDate, setParameters, resetVaccinesPredictions }) => {
    
    const handleChange = (value) => {
        setParameters({ vaccinesPredictedProtectionDate: value });
        resetVaccinesPredictions();
    };
    return <Grid size={6}>
        <DatePickerInput
            label='Predicted protection date'
            value={vaccinesPredictedProtectionDate}
            onChange={handleChange}
        />
    </Grid>;
};

const mapStateToProps = (state) => ({
    vaccinesPredictedProtectionDate: vaccinesPredictedProtectionDateSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        resetVaccinesPredictions
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(PredictedProtectionDateSelector);
