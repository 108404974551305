import { Grid2 as Grid } from '@mui/material';
import React from 'react';
import DatePickerInput from '../../Common/DatePickerInput';
import { connect } from 'react-redux';
import { setParameters } from '../../../redux/actions/parametersActions';

import { bindActionCreators } from 'redux';
import { vaccinesTrackedProtectionDateSelector } from '../../../redux/selectors/parametersSelector';
import { resetVaccinesFrequencies } from '../../../redux/actions/vaccinesActions';

const TrackedProtectionDateSelector = ({ vaccinesTrackedProtectionDate, setParameters, resetVaccinesFrequencies }) => {
    const handleChange = (value) => {
        setParameters({ vaccinesTrackedProtectionDate: value });
        resetVaccinesFrequencies();
    };
    return <Grid size={6}>
        <DatePickerInput
            label='Tracked protection date'
            value={vaccinesTrackedProtectionDate}
            onChange={handleChange}
        />
    </Grid>;
};

const mapStateToProps = (state) => {
    return {
        vaccinesTrackedProtectionDate: vaccinesTrackedProtectionDateSelector(state), 
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        resetVaccinesFrequencies
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(TrackedProtectionDateSelector);
