import { isFirefox } from '../../functions/browser-check';

export const styles = () => ({
    rootExport: {
        flexGrow: 1,
        height: '100%',
        maxHeight: '100%',
        padding: '24px',
        width: '100%'
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    containerExport: {
        height: '100%',
        maxHeight: '100%',
    },
    item: {
        height: '100%',
        maxHeight: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
    },
    itemExport: {
        height: '100%',
        maxHeight: '100%',
        flex: '1 0 auto'
    },
    legend: {
        // padding: '30px 0px 0px 0px',
        // height: '100%',
        width: '50%',
        float: 'right'
    },
    legendExport: {
        // position: 'absolute',
        top: '24px',
        right: '24px',
        paddingBottom: '24px'
    },
    seqChart: {
        padding: 0
    },
    cladeSidebarLeft: {
        height: '100%',
        maxHeight: '100%',
        // padding: '0px 0px 0px 20px',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        overflowY: 'hidden',
        width: '360px'
    },
    cladeSidebarRight: {
        right: 0,
        maxHeight: '100%',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        width: '360px',
        overflowY: 'hidden'
    },
    hidden: {
        width: '32px',
        backgroundColor: 'white'
    },
});
