import React, { useState } from 'react';
import { Button } from '@mui/material';
import { styles } from '../../Users/styles';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axios-requests';
import RoleDialog from '../Dialogs/RoleDialog';

const AddNewRole = ({ setLoaded, setInfoDialog, setInfo, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const addNewRole = async (role) => {
        try {
            const url = `${config.serverLink}/api/admin/addNewRole`;
            const body = { role };
            await postAxios(url, body);
            setInfo(`Role ${role.name} added sucesfully.`);
        }
        catch (error) {
            console.error('Error updating user:', error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during adding role ${role.name}: ${message}`);
        }
        finally {
            setAction('Add role');
            setLoaded(false);
            handleCloseDialog();
            setInfoDialog(true);
        }
    };   

    return (
        <>
            <Button className={classes.addButton} onClick={handleClick}>
                Add role
            </Button>
            <RoleDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} onSubmit={addNewRole} mode={'add'} />
        </>
    );
};

export default AddNewRole;
