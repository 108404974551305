import { treeD3 } from '../../d3/TreeD3';


// Tree D3 helper functions
export const getX = id => treeD3.x(+id);
export const getY = id => treeD3.y(+id);
export const getXBranch = id => treeD3.xBranch(+id);
export const getTransformBranchNodes = id => treeD3.transformBranchNodes(+id, 'MutationsClassesLabels');

export const getYOrder = val => treeD3.yOrder(val);
