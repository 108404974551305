// External imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { connect } from 'react-redux';

// Internal imports - Actions
import { setParameters } from '../../redux/actions/parametersActions';

// Internal imports - Selectors
import { getRegionsWithLabels } from '../../redux/selectors/parametersSelector';
import { getAntigenicModelTypes, getAntigenicSegmentsNamesForFitness, getAntigenicSegmentsFields } from '../../redux/selectors/metadataSelector';

// Internal imports - Components
import SigmaAgSelector from './SigmaAgSelector';
import TauSelector from './TauSelector';
import { AntigenicModelInput } from '../ModelSelector/AntigenicModelInput';
import SelectInput from '../Common/SelectInput';

// Internal imports - Utils & Styles
// import { styles } from './styles';
import { checkModelState, fetchModelTypes, fetchModels } from './functions';
import { getSegments } from '../../functions/data-helpers';


const SingleModelSelector = (props) => {
    const {
        // Parameters
        antigenicModelTypes, antigenicSegmentsFields, modelRegionId, modelType, modelId, 
        lineage, lineageStatus, sigmaAg, tau,

        // Other props
        invalid, idIncomplete, colorBy, regions, index,

        // Actions
        updateModelId, updateModelParameters
    } = props;

    const antigenicSegmentsNames = props.antigenicSegmentsNames || [];
    // const classes = styles();
    const [modelTypeOptions, setModelTypeOptions] = useState();
    const [modelIdOptions, setModelIdOptions] = useState();
    const [collaboratingCenter, setColaboratingCenter] = useState('');
    const [strainPropagation, setStrainPropagation] = useState('');
    const [refStrainPropagation, setRefStrainPropagation] = useState('');
    const [assay, setAssay] = useState('');
    const [assayOptions, setAssayOptions] = useState([]);
    const [collaboratingCenterOptions, setColaboratingCenterOptions] = useState([]);
    const [strainPropagationOptions, setStrainPropagationOptions] = useState([]);
    const [refStrainPropagationOptions, setRefStrainPropagationOptions] = useState([]);

    const [error, setError] = useState(false);
    const [errorSource, setErrorSource] = useState(null);

    const antigenicSegmentsDict = {
        assay: {
            value: assay,
            options: assayOptions,
            setAction: setAssay,
            setOptions: setAssayOptions
        },
        collaboratingCenter: {
            value: collaboratingCenter,
            options: collaboratingCenterOptions,
            setAction: setColaboratingCenter,
            setOptions: setColaboratingCenterOptions
        },
        strainPropagation: {
            value: strainPropagation,
            options: strainPropagationOptions,
            setAction: setStrainPropagation,
            setOptions: setStrainPropagationOptions
        },
        refStrainPropagation: {
            value: refStrainPropagation,
            options: refStrainPropagationOptions,
            setAction: setRefStrainPropagation,
            setOptions: setRefStrainPropagationOptions
        }
    };

    const isAntigenicModel = (modelType) => (antigenicModelTypes || []).includes(modelType);

    useEffect(() => {
        return () => {
            setModelTypeOptions(null);
            setModelIdOptions(null);
            setColaboratingCenter(null);
            setStrainPropagation(null);
            setRefStrainPropagation(null);
            setAssay(null);
            setAssayOptions(null);
            setColaboratingCenterOptions(null);
            setStrainPropagationOptions(null);
            setRefStrainPropagationOptions(null);
            setError(false);
            setErrorSource(null);
        };
    }, []);

    useEffect(() => {
        if (!modelRegionId || lineageStatus !== 'loaded')
            return;
        const fetch = async () => {
            const modelTypes = await fetchModelTypes(lineage, modelRegionId);
            // console.log('[SingleModelSelector] modelTypes', modelTypes);
            setModelTypeOptions(modelTypes || []);
            if (modelType) {
                const modelIds = await fetchModels(lineage, modelRegionId, modelType);
                setModelIdOptions(modelIds || []);
                if (isAntigenicModel(modelType)) updateComplexSelectors({ modelId, modelRegionId, modelType }, modelIds, false);
            }
        };

        fetch();

    }, [lineageStatus]);

    const updateComplexSelectors = (mod, modelIds, newModel) => {
        const options = getSegments(modelIds, antigenicSegmentsNames);
        const segments = mod.modelId.split('-').reduce((acc, value, index) => ({ ...acc, [antigenicSegmentsNames[index]]: value }), {});

        antigenicSegmentsNames.forEach(segmentName => {
            antigenicSegmentsDict[segmentName].setAction(newModel || !segments[segmentName] ? '' : segments[segmentName]);
            const ops = options[`${segmentName}s`];
            antigenicSegmentsDict[segmentName].setOptions(ops);
        });
    };

    const handleModelRegionChange = async (value) => {

        const updatedModel = {
            modelRegionId: value,
            modelType: '',
            modelId: '',
            sigmaAg,
            tau,
        };

        setColaboratingCenter('');
        setStrainPropagation('');
        setRefStrainPropagation('');
        setAssay('');
        setError(false);
        setErrorSource(null);
        const modelTypes = await fetchModelTypes(lineage, value);
        setModelTypeOptions(modelTypes);
        // console.log('[SingleModelSelector] modelTypes', modelTypes);
        updateModelId(index, updatedModel, false, true);
    };

    const handleModelTypeChange = async (value) => {
        const updatedModel = {
            modelRegionId: modelRegionId,
            modelType: value,
            modelId: '',
            sigmaAg,
            tau,
        };
        setColaboratingCenter('');
        setStrainPropagation('');
        setRefStrainPropagation('');
        setAssay('');
        setError(false);
        setErrorSource(null);

        updateModelId(index, updatedModel, false, true);

        if (value && value.length) {
            const modelIds = await fetchModels(lineage, modelRegionId, value, colorBy);
            setModelIdOptions(modelIds);

            if (isAntigenicModel(value)) updateComplexSelectors(updatedModel, modelIds, true);
        }
    };

    const commitIdChange = async (modelId, idIncomplete) => {
        const updatedModel = {
            modelRegionId: modelRegionId,
            modelType: modelType,
            modelId: modelId,
            sigmaAg,
            tau,
        };

        const valid = !idIncomplete && checkModelState(updatedModel, modelTypeOptions, modelIdOptions);
        // console.log('commitIdChange', updatedModel, valid, idIncomplete);
        updateModelId(index, updatedModel, valid ? false : true, idIncomplete);
    };

    const handleModelIdChange = async (value) => {
        await commitIdChange(value);
    };

    const handleAntigenicParamChange = (name) => async (value) => {
        // console.log('handleAntigenicParamChange', {name, value});
        const getModelIdFromSegments = (name, value) => {
            const val = {
                collaboratingCenter: name === 'collaboratingCenter' ? value : collaboratingCenter,
                assay: name === 'assay' ? value : assay,
                strainPropagation: name === 'strainPropagation' ? value : strainPropagation,
                refStrainPropagation: name === 'refStrainPropagation' ? value : refStrainPropagation
            };
            const _modelId = antigenicSegmentsNames.map(segmentName => val[segmentName]).join('-');
            return _modelId;
        };
        const id = getModelIdFromSegments(name, value);
        console.log('handleAntigenicParamChange', {name, value, id});
        antigenicSegmentsDict[name].setAction(value);

        const idSet = !id.split('-').some(el => el.length === 0);
        const nodata = idSet && !modelIdOptions?.includes(id);
        if (nodata) {
            setError(true);
            setErrorSource(name);
        } else {
            setError(false);
            setErrorSource(null);
        }

        await commitIdChange(id, !idSet);
    };

    return (
        <>
            {regions && (
                <>
                    <Grid container columnSpacing={2} rowSpacing={0}>
                        <Grid size={6}>
                            <SelectInput
                                id="modelRegionId"
                                label="Model region"
                                value={modelRegionId}
                                onChange={handleModelRegionChange}
                                options={regions}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.label}
                            />  
                        </Grid>
                        {modelTypeOptions && (modelTypeOptions || []).includes(modelType) &&
                        <Grid size={6}>
                            <SelectInput
                                id="modelType"
                                label="Model type"
                                value={modelType}
                                onChange={handleModelTypeChange}
                                options={modelTypeOptions}
                                getOptionValue={option => option}
                                getOptionLabel={option => option}
                            />
                        </Grid>
                        }
                        {modelType && modelIdOptions && !isAntigenicModel(modelType) && modelIdOptions.includes(modelId) &&
                        <Grid size={6}>
                            <SelectInput
                                id="modelId"
                                label="Model id"
                                value={modelId}
                                onChange={handleModelIdChange}
                                options={modelIdOptions}
                                getOptionValue={option => option}
                                getOptionLabel={option => option}
                            />
                        </Grid>
                        }
                    </Grid>
                    <Grid container columnSpacing={2} rowSpacing={0}>
                        { modelType && modelIdOptions && isAntigenicModel(modelType) &&
                    antigenicSegmentsFields.map(({ name, label }) => 
                        <AntigenicModelInput
                            key={name}
                            name={name}
                            label={label}
                            value={antigenicSegmentsDict[name].value}
                            options={antigenicSegmentsDict[name].options}
                            error={error && errorSource === name}
                            changeHandler={handleAntigenicParamChange(name)}
                        />
                    )}
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <SigmaAgSelector 
                            sigmaAg={sigmaAg} 
                            updateModelParameter={updateModelParameters('sigmaAg')} 
                            index={index} 
                        />
                        <TauSelector 
                            tau={tau} 
                            updateModelParameter={updateModelParameters('tau')} 
                            index={index} 
                        />
                    </Grid>
                </>
            )}   
            {invalid && !idIncomplete && modelId && (
                <Grid size={12}>
                    <FormHelperText error={true}>
                        No such model: {modelId}
                    </FormHelperText>
                </Grid>
            )}
        </>
    );
};

SingleModelSelector.propTypes = {
    // Required props
    index: PropTypes.number.isRequired,
    lineageStatus: PropTypes.string.isRequired,
    updateModelId: PropTypes.func.isRequired,
    updateModelParameters: PropTypes.func.isRequired,

    // Optional props
    antigenicModelTypes: PropTypes.arrayOf(PropTypes.string),
    antigenicSegmentsFields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string
    })),
    antigenicSegmentsNames: PropTypes.arrayOf(PropTypes.string),
    colorBy: PropTypes.string,
    idIncomplete: PropTypes.bool,
    invalid: PropTypes.bool,
    lineage: PropTypes.string,
    modelId: PropTypes.string,
    modelRegionId: PropTypes.string,
    modelType: PropTypes.string,
    regions: PropTypes.arrayOf(PropTypes.shape({ 
        id: PropTypes.string, 
        label: PropTypes.string 
    })),
    sigmaAg: PropTypes.number,
    tau: PropTypes.number
};

const mapStateToProps = (state) => ({
    antigenicModelTypes: getAntigenicModelTypes(state),
    antigenicSegmentsFields: getAntigenicSegmentsFields(state)['fitness'],
    antigenicSegmentsNames: getAntigenicSegmentsNamesForFitness(state),
    lineage: state.parameters.lineage,
    lineageStatus: state.lineages.lineageStatus,
    regions: getRegionsWithLabels(state)
});

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleModelSelector);
