import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axios-requests';
import config from '../../config/envConfig';
import { fetchModelsError, fetchModelsSuccess, fetchModelError, fetchModelSuccess, fetchModelTypesError, fetchModelTypesSuccess } from '../actions/modelActions';
import {
    // FETCH_FITNESS_DATA_REQUEST,
    FETCH_MODELS_REQUEST, FETCH_MODEL_DATA_REQUEST, FETCH_MODEL_TYPES_REQUEST
} from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';


export const fetchModelTypesEpic = (action$) => action$.pipe(
    ofType(FETCH_MODEL_TYPES_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { lineage, modelRegionId, colorBy, settings } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/models/types`, { lineage, modelRegionId, colorBy });
        console.log(`fetchModel, url = ${url}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchModelTypesSuccess({ ...response.data, colorBy, settings })),
                handleErrors(fetchModelTypesError, 'An error has occurred during downloading of model types', { colorBy })
            );
    })
);


export const fetchModelsEpic = (action$) => action$.pipe(
    ofType(FETCH_MODELS_REQUEST),
    mergeMap(action => {
        const { lineage, modelRegionId, modelType, colorBy, settings } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/models`, { lineage, modelRegionId, colorBy, modelType });

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchModelsSuccess({ ...response.data, colorBy, settings })),
                handleErrors(fetchModelsError, 'An error has occurred during downloading models', { colorBy })
            );
    })
);

export const fetchModelEpic = (action$) => action$.pipe(
    ofType(FETCH_MODEL_DATA_REQUEST),
    mergeMap(action => {

        const { lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/models/model`, { lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy });
        //console.log(`fetchModel, url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchModelSuccess({...response.data, colorBy })),
                handleErrors(fetchModelError, 'An error has occurred during downloading model data', { colorBy })
            );
    })
);


