import React from 'react';
import { daysToDate } from '../../../functions/utils';
import { numFormat } from '../../../functions/formats';
import { makeStyles } from '@mui/styles';
import SelectInput from '../../Common/SelectInput';



const styles = makeStyles(() => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px 8px 0px',
    },
    tooltip: {
        margin: 0,
        padding: 0,
        fontSize: '15px',
        height: '23px'
    },
    dot: {
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px'
    }
}));

const DiscreteScaleTooltip = ({ domain, colors, classes, formatLabel }) => {
    return (
        <div>
            {colors && colors.map((color, index) => {
                return (
                    <div key={`tooltipItem-${index}`}>
                        <span style={{ backgroundColor: color }} className={classes.dot}></span>
                        {domain && (
                            <>
                                {index === 0 && (
                                    <>-&infin; &le; {formatLabel(domain[0], 3)}</>
                                )}
                                {index === colors.length - 1 && (
                                    <>{domain[index-1]} &le; &infin;</>
                                )}
                                {index > 0 && index < colors.length - 1 && (
                                    <>{domain[index-1]} &le; {formatLabel(domain[index], 3)}</>
                                )}
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const ContinuousScaleTooltip = ({ domain, colors, index, formatLabel }) => {
    let gradientStyle = 'linear-gradient(0.25turn, ';
    colors.forEach((color, i) => {
        gradientStyle += i === colors.length - 1 ? `${color})` : `${color}, `;
    });

    return (
        <div key={`tooltipItem-${index}`}>
            <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
            {domain && <div style={{ display: 'flex' }}>
                <div>{formatLabel(domain[0], 3)}</div>
                <div style={{ marginLeft: 'auto' }}>{formatLabel(domain[domain.length - 1], 3)}</div>
            </div>}
        </div>
    );
};

const TooltipContent = ({ scale, index, domain, classes, formatLabel }) => {
    if (!scale) return null;
    const discrete = scale?.discrete;
    const colors = scale?.range || scale?.colors;
    
    return (
        <div style={{ whiteSpace: 'pre-line' }}>
            {discrete ? (
                <DiscreteScaleTooltip 
                    domain={domain}
                    colors={colors}
                    classes={classes}
                    formatLabel={formatLabel}
                />
            ) : (
                <ContinuousScaleTooltip
                    domain={domain}
                    colors={colors}
                    index={index}
                    formatLabel={formatLabel}
                />
            )}
        </div>
    );
};

export const ColorScaleSelectorBase = ({
    id,
    label,
    value,
    onChange,
    options,
    getOptionLabel,
    getOptionValue,
    scales,
    measureDomains,
    isTimeScale,
}) => {

    // console.log('[ColorScaleSelectorBase]', {id, label, scales});
    const classes = styles();

    const formatLabel = (value, precision) => {
        if (isTimeScale)
            return daysToDate(value).toLocaleDateString();
    
        return numFormat(value, precision);
    };


    const getOptionTooltipContent = (scaleId, index) => {
        const scale = scales?.[scaleId];
        if (!scale) return null;
        return <TooltipContent 
            index={index} 
            scale={scale}
            measureDomain={measureDomains?.[scaleId]}
            classes={classes}
            formatLabel={formatLabel}
        />;
    };


    return (
        <SelectInput
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            getTooltip={getOptionTooltipContent}
            // classTooltipName={classTooltipName}
        />
      
    );
};
  