
import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { postAxios, fetchAxios } from '../../functions/axios-requests';
import {
    FETCH_MEASURE_SCALES_DOMAINS_REQUEST,
    SET_LINEAGE_SETTINGS_REQUEST,
    FETCH_ALL_SCALES_REQUEST,
    FETCH_CUSTOM_TREE_SUBSETS_REQUEST,
    FETCH_LINEAGE_REGIONS_REQUEST,
    FETCH_MUTATION_CLASSES_ARRAY_REQUEST,
    FETCH_LINEAGE_MUTATION_GENES_REQUEST,
    FETCH_ALL_MEASURES_REQUEST,
} from '../actions/actionTypes';
import {
    setSettingsSuccess,
    fetchMeasureScalesDomainsSuccess,
    fetchMeasureScalesDomainsError,
    fetchAllScalesSuccess,
    fetchAllScalesError,
    fetchCustomTreeSubsetsSuccess,
    fetchCustomTreeSubsetsError,
    fetchLineageRegionsSuccess,
    fetchLineageRegionsError,
    fetchLineageMutationGenesError,
    fetchLineageMutationGenesSuccess,
    fetchMutationClassesArraySuccess,
    fetchMutationClassesArrayError,
    fetchAllMeasuresError,
    fetchAllMeasuresSuccess,
    setSettingsError,
    // fetchConfigError,
} from '../actions/settingsActions';

import config from '../../config/envConfig';
import { prepareUrl } from '../../functions/url-parameters';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';

export const setSettings = action$ => action$.pipe(
    ofType(SET_LINEAGE_SETTINGS_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/admin/setNewSettings`);
        const { handleOpenAlert, ...payload } = action.payload;

        return from(postAxios(url, payload))
            .pipe(
                map(response => {
                    handleOpenAlert(response.status, response.data);
                    return setSettingsSuccess({ ...response.data, parameters: payload.parameters });
                }),
                handleErrors(setSettingsError, 'Saving settings failed')
            );
    })
);

export const fetchAllScalesEpic = (action$) => action$.pipe(
    ofType(FETCH_ALL_SCALES_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getAllScales`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchAllScalesSuccess(response.data)),
                handleErrors(fetchAllScalesError, 'An error has occurred during loading scales')
            );
    })
);
export const fetchCustomTreeSubsetsEpic = (action$) => action$.pipe(
    ofType(FETCH_CUSTOM_TREE_SUBSETS_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getCustomTreeSubsets`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchCustomTreeSubsetsSuccess(response.data)),
                handleErrors(fetchCustomTreeSubsetsError, 'An error has occurred during loading custom tree subsets')
            );
    })
);

export const fetchLineageRegionsEpic = (action$) => action$.pipe(
    ofType(FETCH_LINEAGE_REGIONS_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getLineagesRegions`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchLineageRegionsSuccess(response.data)),
                handleErrors(fetchLineageRegionsError, 'An error has occurred during loading lineage regions')
            );
    })
);

export const fetchMeasureScalesDomainsEpic = (action$) => action$.pipe(
    ofType(FETCH_MEASURE_SCALES_DOMAINS_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { colorBy, freqCategory, geoMapColorBy } = action.payload;
        const measure = colorBy || freqCategory || geoMapColorBy;
        const url = prepareUrl(`${config.serverLink}/api/measures/measureScalesDomains`, action.payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchMeasureScalesDomainsSuccess({ ...response.data, measure })),
                handleErrors(fetchMeasureScalesDomainsError, 'An error has occurred during loading measure scales domains')
            );
    })
);

export const fetchLineageMutationGenesEpic = (action$) => action$.pipe(
    ofType(FETCH_LINEAGE_MUTATION_GENES_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getLineagetMutationGenes`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchLineageMutationGenesSuccess(response.data)),
                handleErrors(fetchLineageMutationGenesError, 'An error has occurred during loading mutation genes')
            );
    })
);

export const fetchMutationClassesArrayEpic = (action$) => action$.pipe(
    ofType(FETCH_MUTATION_CLASSES_ARRAY_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getMutationClassesArray`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchMutationClassesArraySuccess(response.data)),
                handleErrors(fetchMutationClassesArrayError, 'An error has occurred during loading mutation classes')
            );
    })
);

export const fetchAllMeasuresEpic = (action$) => action$.pipe(
    ofType(FETCH_ALL_MEASURES_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/admin/getAllMeasures`);
 
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchAllMeasuresSuccess(response.data)),
                handleErrors(fetchAllMeasuresError, 'An error has occurred during loading measures')
            );
    })
);
