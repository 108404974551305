import React, { useState, useEffect } from 'react';
import { daysToDate, sanitizeId } from '../../../functions/utils';
import { numFormat } from '../../../functions/formats';
import Decimal from 'decimal.js';
import { useStyles } from './styles';
import { Typography } from '@mui/material';

const emptyValues = [null, undefined, ''];
const incompleteValues = ['-', '-.', '.', '-,', ','];


   
const InputMinMax = ({
    type,
    scaleId,
    value,
    prcWidth,
    isTimeScale,
    onAccept,
    min,
    max,
    precision,
}) => {

    const inputId = type && scaleId ? `${type}_${scaleId}` : undefined;
    const tdClass = `${(type && scaleId) || 'internal'}Td`;
    const typographyClass = `${(type && scaleId) || 'internal'}Typography`;
    const classes = useStyles();

    // Format label according to time or numeric format.
    const formatLabel = (val, prec) =>
        isTimeScale ? daysToDate(val).toLocaleDateString() : numFormat(val, prec);


    // Initialize the display value using numFormat if a value exists.
    const [numberValue, setNumberValue] = useState(
        value != null && value !== undefined
            ? formatLabel(value, precision) 
            : ''
    );
    const [error, setError] = useState(false);


    // Update value if the error is gone after min or max change
    useEffect(() => {
        if (error) {
            const { isValid, parsedValue } = validateNumber(numberValue);
            if (isValid) {
                setError(false);
                if (isValid && onAccept) {
                    onAccept(parsedValue);
                }
            }
        }
    }, [min, max]);

    // Update numberValue (formatted string representation) after value change
    useEffect(() => {
        setNumberValue(value !== null && value !== undefined 
            ? formatLabel(value, precision) 
            : '');
    }, [value, precision]);
      
    const validateNumber = (val) => {
        if (emptyValues.includes(val)) {
            setError(true);
            return { isValid: false, parsedValue: null };
        }

        if (incompleteValues.includes(val)) {
            setError(false);
            return { isValid: true, parsedValue: null };
        }

        let numValue;
        try {
            // Replace spaces and then replace comma with period before parsing.
            const normalizedVal = val.replace(/\s/g, '').replace(',', '.');
            numValue = new Decimal(normalizedVal);

        } catch {
            setError(true);
            return { isValid: false, parsedValue: null };
        }

        if (max !== undefined && numValue.greaterThan(max)) {
            setError(true);
            return { isValid: false, parsedValue: numValue.toNumber() };
        }
        if (min !== undefined && numValue.lessThan(min)) {
            setError(true);
            return { isValid: false, parsedValue: numValue.toNumber() };
        }

        setError(false);
        return { isValid: true, parsedValue: numValue.toNumber() };
    };



    const handleKeyDown = (e) => {
        setError(false);
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
        if (allowedKeys.includes(e.key)) {
            return;
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAccept(e.target.value);
        }
        const currentValue = e.target.value;
        if (e.key === '-') {
            if (currentValue.includes('-') || e.target.selectionStart !== 0) {
                e.preventDefault();
            }
            return;
        }
        if (e.key === '.' || e.key === ',') {
            if (currentValue.includes('.') || currentValue.includes(',')) {
                e.preventDefault();
            }
            return;
        }
        if (!/^\d$/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        // Allow clearing the input.
        if (newValue === '') {
            setNumberValue('');
            return;
        }
        const regex = /^-?(\d+)?([.,]\d*)?$/;
        if (regex.test(newValue)) {
            setNumberValue(newValue);
        }
    };
    

    const handleAccept = (val) => {
        const { isValid, parsedValue } = validateNumber(val);
        // console.log('handleAccept', { isValid, parsedValue, value: val });
        if (isValid && onAccept) {
            onAccept(parsedValue);
        }
    };

    // When the input gains focus, remove formatting (e.g. remove extra spaces).
    const handleFocus = (e) => {
        // Remove spaces and any extra padding
        const unformatted = e.target.value.replace(/\s/g, '').trim();
        setNumberValue(unformatted);
    };

    // On blur, validate the input and reapply the numFormat formatting.
    const handleBlur = (e) => {
        const currentVal = e.target.value;
        handleAccept(currentVal);
        const formatted = numFormat(currentVal, precision);
        setNumberValue(formatted);
    };

    return (
        <td className={classes[tdClass]} style={{ width: `${prcWidth}%` }}>
            <Typography className={classes[typographyClass]} style={{ width: '100%' }}>
                {scaleId ? (
                    isTimeScale ? (
                        <div style={{ width: '100%' }} className={type ? classes[`${type}Input`] : ''}>
                            {value}
                        </div>
                    ) : (
                        <input
                            id={sanitizeId(inputId)}
                            className={`${type ? classes[`${type}Input`] : ''} ${error ? 'red' : ''}`}
                            type="text"
                            value={numberValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                           
                        />
                    )
                ) : (
                    <>{numberValue}</>
                )}
            </Typography>
        </td>
    );
};

export default InputMinMax;
