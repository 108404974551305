import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Custom components
import SelectInput from '../Common/SelectInput';

// Config
import appConfig from '../../config/appConfig';

// Redux actions
import { setParameters } from '../../redux/actions/parametersActions';

const frequencyTypeOptions = [
    { key: 'sublineage', label: 'Sublineage' },
    { key: 'clade', label: 'Clade' },
];

const FrequencyTypeSelector = props => {
    const { frequencyType, setParameters, chartLayout } = props;

    const handlefrequencyTypeChange = value => {
        setParameters({ frequencyType: value });
    };

    if (chartLayout === 'stacked') {
        return null;
    }

    return (
        <SelectInput
            id="frequencyType"
            label="Frequency type"
            value={frequencyType}
            onChange={handlefrequencyTypeChange}
            options={frequencyTypeOptions}
            getOptionValue={el => el.key}
            getOptionLabel={el => el.label}
        />
    );
};

FrequencyTypeSelector.propTypes = {
    frequencyType: PropTypes.string.isRequired,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = ({ parameters }) => ({
    frequencyType: parameters.frequencyType || appConfig.default.frequencyType,
    chartLayout: parameters.chartLayout || appConfig.default.chartLayout,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyTypeSelector);
