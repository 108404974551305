import React, { useState, useEffect } from 'react';
import { Tab, Box, IconButton, Button } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { connect } from 'react-redux';
import { setParameters, setSelectedModels } from '../../redux/actions/parametersActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';

import { ExpandIcon, HideIcon } from '../Alerts/styles';
import SingleModelSelector from './SingleModelSelector';
import { styles } from './styles';
import { cloneDeep } from 'lodash';
import { bindActionCreators } from 'redux';

const emptyModel = {
    modelRegionId: 'ALL',
    modelType: '',
    modelId: '',
    sigmaAg: 1,
    tau: 30
};

const ButtonInTabs = ({ className, onClick, children }) => {
    return <Button className={className} onClick={onClick}>{children}</Button>;
};

const MultipleModelsSelector = ({ selectedModels, setSelectedModels, chartLayout, strainSubset, resetPredictions }) => {

    // console.log('[MultipleModelsSelector], selectedModels = ', selectedModels);
    const classes = styles();
    const [modelTabs, setModelTabs] = useState([]);
    const [value, setValue] = useState('1');

    useEffect(() => {
        setModelTabs(cloneDeep(selectedModels));
    }, []);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleClick = () => {
        const newTabs = [...modelTabs, emptyModel];
        setModelTabs(newTabs);
        setValue(newTabs.length.toString());
    };

    const handleClose = (index) => (e) => {
        e.stopPropagation();
        const newTabs = cloneDeep(modelTabs);
        newTabs.splice(index, 1);
        setValue('1');
        setModelTabs(newTabs);
        const selectedModels = newTabs.filter(({ modelRegionId, modelType, modelId }) => modelRegionId.length > 0 && modelType.length > 0 && modelId.length > 0);
        setSelectedModels({ selectedModels, strainSubset });
    };

    const updateModelState = (index, model) => {
        const newTabs = cloneDeep(modelTabs);
        newTabs[index] = model;
        //console.log(newTabs)
        setModelTabs(newTabs);
    };

    const updateSelectedModels = (index, model, invalid, idIncomplete) => {
        const _selectedModels = cloneDeep(selectedModels);

        // console.log('[updateSelectedModels]', index, model, invalid, _selectedModels);
        //if (!valid) _selectedModels.splice(index, 1);
        //else 

        const _model = { ...model, invalid, idIncomplete };
        const newTabs = cloneDeep(modelTabs);
        newTabs[index] = _model;
        //console.log(newTabs)
        setModelTabs(newTabs);
        if (!selectedModels[index])
            _selectedModels.push(_model);
        else
            _selectedModels[index] = _model;
        // console.log('[updateSelectedModels] selectedModels = ', _selectedModels);
        setSelectedModels({ selectedModels: _selectedModels, strainSubset });
    };

    const updateModelParameters = (type) => (value, index) => {

        // Only update if the new value is different from the current one
        if (selectedModels[index][type] === value) return;
        resetPredictions();
        const _selectedModels = cloneDeep(selectedModels);
        const _model = { ..._selectedModels[index], [type]: value };
        // console.log(_model);
        const newTabs = cloneDeep(modelTabs);
        newTabs[index] = _model;

        setModelTabs(newTabs);
        if (!selectedModels[index])
            _selectedModels.push(_model);
        else
            _selectedModels[index] = _model;

        setSelectedModels({ selectedModels: _selectedModels, strainSubset });
    };

    // console.log('[MultipleModelSelector], modelTabs = ',modelTabs);
    return (
        <>
            {
                chartLayout === 'nonstacked' ?
                    <Box pt={2} >
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} variant='fullWidth'>
                                    {modelTabs.length > 0 && modelTabs.map((el, index) => (
                                        <Tab key={`tab-${index}`} style={{ fontSize: '12px', padding: 0 }} value={(index + 1).toString()}
                                            label={index !== 0 ?
                                                <span >
                                                    {`Model ${index + 1}`}
                                                    <IconButton component="div" onClick={handleClose(index)} >
                                                        <HideIcon />
                                                    </IconButton>
                                                </span>
                                                :
                                                `Model ${index + 1}`
                                            }
                                        />
                                    ))}

                                    {modelTabs.length < 3 && modelTabs.length > 0 &&
                                        <ButtonInTabs onClick={handleClick} className={classes.add} >
                                            <ExpandIcon />
                                        </ButtonInTabs>
                                    }
                                </TabList>
                            </Box>

                            {modelTabs.length > 0 && modelTabs.map((el, index) => (
                                <TabPanel key={`tab-${index}`} value={(index + 1).toString()} className={classes.tabPanel}>
                                    <SingleModelSelector
                                        index={index}
                                        updateModelParameters={updateModelParameters}
                                        updateModelId={updateSelectedModels}
                                        {...modelTabs[index]}
                                        updateModelState={updateModelState}
                                    />
                                </TabPanel>
                            )
                            )}
                        </TabContext>
                    </Box>
                    :
                    <Box pt={2}>
                        {modelTabs.length > 0 &&
                            <SingleModelSelector
                                index={0}
                                updateSelectedModelsNumParams={updateModelParameters}
                                updateSelectedModels={updateSelectedModels}
                                {...modelTabs[0]}
                                updateModelState={updateModelState}
                            />
                        }
                    </Box>
                    

            }
        </>

    );
};

const mapStateToProps = (state) => {
    const { selectedModels, chartLayout, strainSubset } = state.parameters;
    return ({
        selectedModels,
        chartLayout,
        strainSubset
    });
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setSelectedModels,
        resetPredictions
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MultipleModelsSelector);
