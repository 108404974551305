import { isFirefox } from '../../functions/browser-check';

export const styles = () => ({
    rootExport: {
        flexGrow: 1,
        height: '100%',
        maxHeight: '100%',
        padding: '24px'
    },
    container: {
        height: '100%',
        maxHeight: '100%',
        width: '100vw'
    },
    containerExport: {
        height: '100%',
        maxHeight: '100%',
    },
    item: {
        height: '100%',
        maxHeight: '100%',
        padding: '0px 20px 15px 20px',
        overflow: isFirefox ? 'auto' : 'overlay',
    },
    itemMobile: {
        height: '100%',
        maxHeight: '100%',
        padding: '0px 20px 15px 0px',
        overflow: isFirefox ? 'auto' : 'overlay',
    },
    treeSidebarRight: {
        right: 0,
        height: '100%',
        overflowY: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        width: '360px'
        // maxWidth: '400px'
    },
    treeSidebarLeft: {
        left: 0,
        height: '100%',
        maxHeight: '100%',
        overflowY: 'hidden',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        width: '360px'
    },
    hidden: {
        width: '32px'
    },
    itemExport: {
        height: '100%',
        maxHeight: '100%',
    },
    legend: {
        width: '50%',
        float: 'right'
    },
    legendExport: {
        position: 'absolute',
        top: '24px',
        right: '24px',
        paddingBottom: '24px',
        maxWidth: '190px'
    },
    info: {
        display: 'inline-block'
    },
    button: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        textTransform: 'none',
        marginBottom: '15px',
        marginRight: '5px',
        '&:hover': {
            backgroundColor: '#FFFFFF'
        }
    }
});
