// React and PropTypes
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI Components
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';

// Local imports
import { HideIcon } from '../Alerts/styles';
import { dateFormat } from '../../functions/formats';
import { parse, isSameDay } from 'date-fns';

const useStyles = makeStyles({
    formControl: {
        margin: '8px 0px',
    },
});

const DatePickerInput = (props) => {
    const {
        id,
        value,
        onAccept,
        label = 'Select Date',
        minDate,
        maxDate,
        fullWidth = true,
        clearable = false,
        error: propError = false,
        errorText: propErrorText,
        helperText: propHelperText,
        onValidate,
        onValidateChange,
        ...rest
    } = props;

    const [error, setError] = useState(propError || false);
    const [errorText, setErrorText] = useState(propErrorText || '');
    const [helperText, setHelperText] = useState(propHelperText || '');
    const classes = useStyles();

    const required = !clearable;

    const setErrorState = (error, errorText, helperText) => {
        setError(error);
        setErrorText(error ? errorText : '');
        setHelperText(helperText || '');
        onValidateChange?.(!error);
    };

 
    const validateDate = async (date) => {
        // Treat null/invalid date as null
        if (!date|| isNaN(date.getTime())) {
            if (required) {
                setErrorState(true, 'Required field', '');
                return false;
            }
            if (onValidate) {
                const { error, errorText, helperText } = await onValidate(date);
                setErrorState(error, errorText, helperText);
                return !error;    
            }
            setErrorState(false, '', '');
            return true; 
        }
    
        // Check range
        const inRange = (!minDate || date >= minDate) && (!maxDate || date <= maxDate);
        if (!inRange) {
            setErrorState(true, 'Date is out of range', '');
            return false;
        }
        // Custom validation
        if (onValidate) {
            const { error, errorText, helperText } = await onValidate(date);
            setErrorState(error, errorText, helperText);
            return !error;
        }

        // All good
        setErrorState(false, '', '');
        return true;
    };

    const handleAcceptDate = async (date) => {
        // If invalid date, treat as null
        if (date && isNaN(date.getTime())) {
            date = null;
        }

        const isValid = await validateDate(date);
        if (!isValid || isSameDay(value, date)) return;

        onAccept?.(date);
    };

    // Helper function to parse input and accept date
    const acceptDateFromInput = (inputValue) => {
        let parsedDate = null;
        if (inputValue) {
            try {
                parsedDate = parse(inputValue, dateFormat, new Date());
                if (isNaN(parsedDate.getTime())) {
                    parsedDate = null;
                }
            } catch {
                parsedDate = null;
            }
        }
        handleAcceptDate(parsedDate);
    };

    const handleClear = () => {
        handleAcceptDate(null);
    };


    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            acceptDateFromInput(event.target.value);
        }
    };

    const handleBlur = (event) => {
        acceptDateFromInput(event.target.value);
    };

    // Add initial validation here
    useEffect(() => {
        // Validate the initially provided value
        (async () => {
            await validateDate(value);
        })();
         
    }, []);
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                className={classes.formControl}
                minDate={minDate}
                maxDate={maxDate}
                format={dateFormat}
                label={label}
                value={value}  
                onAccept={handleAcceptDate}
                {...rest}
                fullWidth={fullWidth}
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                        letterSpacing: '-0.5px',
                    },
                }}
                slotProps={{
                    textField: {
                        id: id,
                        variant: 'standard',
                        onKeyDown: handleKeyDown,
                        onBlur: handleBlur,
                        error,
                        helperText: error ? errorText : helperText,
                        InputProps: clearable
                            ? {
                                endAdornment: (
                                    <IconButton style={{ padding: 0 }} onClick={handleClear}>
                                        <HideIcon />
                                    </IconButton>
                                ),
                            }
                            : undefined,
                    },
                    inputAdornment: { position: 'start' },
                }}
            />
        </LocalizationProvider>
    );
};

DatePickerInput.propTypes = {
    /** The currently selected date */
    value: PropTypes.instanceOf(Date),
    /** Callback fired when the date is accepted */
    onAccept: PropTypes.func.isRequired,
    /** Label for the date picker */
    label: PropTypes.string,
    /** Minimum selectable date */
    minDate: PropTypes.instanceOf(Date),
    /** Maximum selectable date */
    maxDate: PropTypes.instanceOf(Date),
    /** If true, shows a clear button to reset the date */
    clearable: PropTypes.bool,
    /** If true, the input will take up the full width of its container */
    fullWidth: PropTypes.bool,
   
};

export default DatePickerInput;
