// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid2';
import PropTypes from 'prop-types';

// Internal imports
import { getAntigenicSegmentsCount } from '../../functions/data-helpers';
import { showSelect } from './functions';
import { resetModelData } from '../../redux/actions/modelActions';
import { resetAntigenicModel } from '../../redux/actions/antigenicActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { setParameters } from '../../redux/actions/parametersActions';
import SelectInput from '../Common/SelectInput';

const ModelIdSelector = props => {
    const { antigenicSegmentsCount, models, modelId, modelContext,
        resetModelData, resetAntigenicModel, resetPredictions, setParameters } = props;

    const handleModelIdChange = (value) => {
        const modelIdVar = (modelContext === 'antigenic') 
            ? 'antigenicModelId' 
            : (modelContext === 'antigenicFitness'
                ? 'antigenicFitnessModelId' 
                : 'modelId'
            );
        setParameters({ [modelIdVar]: value });
        if (modelContext === 'strainTree')
            resetModelData();
        if (modelContext === 'antigenic')
            resetAntigenicModel();
        if (modelContext === 'frequencies')
            resetPredictions();
    };

    return <>
        {antigenicSegmentsCount === 0 && models &&
            <Grid size={12}>
                {modelId && showSelect(modelId, models) &&        
                    <SelectInput
                        id="model"
                        label="Model id"
                        value={modelId}
                        onChange={handleModelIdChange}
                        options={models}
                        getOptionValue={option => option}
                        getOptionLabel={option => option}
                        // className={classes.formControl}
                    />  
                }
            </Grid>
        }
    </>;
};

ModelIdSelector.propTypes = {
    antigenicSegmentsCount: PropTypes.number.isRequired,
    modelContext: PropTypes.string.isRequired,
    modelId: PropTypes.string,
    models: PropTypes.arrayOf(PropTypes.string),
    resetAntigenicModel: PropTypes.func.isRequired,
    resetModelData: PropTypes.func.isRequired,
    resetPredictions: PropTypes.func.isRequired,
    setParameters: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { colorBy } = state.parameters;
    const { modelContext } = ownProps;

    // Determine model context and variable
    const effectiveModelContext = modelContext;
    const modelVariable = (modelContext === 'antigenic' || modelContext === 'antigenicFitness')
        ? modelContext
        : ((modelContext === 'frequencies') ? 'fitness' : colorBy);

    // Get model ID based on context
    const modelId = modelContext === 'antigenic' 
        ? state.parameters.antigenicModelId 
        : state.parameters.modelId;

    return {
        modelId,
        models: state.models.models[modelVariable],
        antigenicSegmentsCount: getAntigenicSegmentsCount(modelId),
        modelContext: effectiveModelContext
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    resetAntigenicModel,
    setParameters,
    resetPredictions,
    resetModelData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModelIdSelector);
