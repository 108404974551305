import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { select } from 'd3-selection';
import { symbol, symbolTriangle } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { continousScaleStyles } from './styles';
import { setMeasureDomain } from '../../../redux/actions/parametersActions';
import { setAlertStatus } from '../../../redux/actions/alertActions';
import { bindActionCreators } from 'redux';
import { getNodeWidth } from '../../../functions/css-helpers';
import { emptyObject, sanitizeId } from '../../../functions/utils';
import { getMeasureScaleParamName } from '../../../functions/data-helpers';
import { getMeasure, getColorByMeasure } from '../../../redux/selectors/metadataSelector';
import { getCurrentColorScalesForMeasures } from '../../../redux/selectors/parametersSelector';
import { getMetadataMeasuresWithScales } from '../../../redux/selectors/rangeDataSelector';
import { connect } from 'react-redux';
import InputMinMax from './InputMinMax';

// Helper to sanitize IDs


const ContinousScale = (props) => {
    const {
        domain,
        scaleId,
        isTimeScale,
        value,
        setMeasureDomain,
        renderLegend,
        paramForMeasure,
        ticks,
        colorScale,
        lineage,
        discrete,
        min,
        max,
        precision,
        measureName,
        colorScaleRange,
        title,
        inContainer,
    } = props;


    const svgRef = useRef(null);
    const classes = continousScaleStyles();
    const ticksCount = ticks || 3;
    const safeId = sanitizeId(scaleId);


    // Update range values when min or max update.
    const rangeVals = useMemo(() => {
        if (emptyObject(min) || emptyObject(max)) return [];
        const diff = (max - min) / (ticksCount - 1);
        const values = [];
        for (let i = 1; i < ticksCount - 1; i++) {
            values.push(min + i * diff);
        }
        return values;
    }, [min, max, ticksCount]);

    // Update label strings on mount and when min/max change.
    useEffect(() => {
        showPositionOnLegend();
    }, [min, max, value]);


    // Update legend when domain-related props change.
    useEffect(() => {
        prepareLegend();
    }, [discrete, colorScale, domain, lineage, measureName]);

 
    useEffect(() => {
        setGradientColors();
        showPositionOnLegend();
    }, [colorScaleRange]);

    // Legend helper functions.
    const setGradientColors = useCallback(() => {
        if (!renderLegend) return;
        const gradient = select(`#legend-gradient_${safeId}`);
        gradient.selectAll('stop').remove();
        for (let i = 0; i < colorScaleRange.length; i++) {
            gradient
                .append('svg:stop')
                .attr('offset', i / (colorScaleRange.length - 1))
                .attr('stop-color', colorScaleRange[i]);
        }
    }, [renderLegend, safeId, colorScaleRange]);

    const showPositionOnLegend = useCallback(() => {
        if (min === undefined || max === undefined) return;
        const mountNode = svgRef.current;
        const width = mountNode ? getNodeWidth(mountNode) : 0;
        const positionScale = scaleLinear().domain([min, max]).range([0, width]).clamp(true);
        const render = !emptyObject(value) && renderLegend;
        const pos = render ? positionScale(value) : 0;
        const opacity = render ? '1' : '0';
        select(`#legend-pointer_${safeId}`)
            .attr('opacity', opacity)
            .attr('transform', `translate(${pos}, -8) rotate(-180)`);
    }, [min, max, value, renderLegend, safeId]);

    const prepareLegend = useCallback(() => {
        const svg = select(`#legend_svg_${safeId}`);
        let defs = svg.select('defs');
        if (defs.empty()) {
            defs = svg.append('svg:defs');
            defs
                .append('svg:linearGradient')
                .attr('id', `legend-gradient_${safeId}`)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .attr('spreadMethod', 'pad');
        }
        const rect = svg.select('rect');
        if (rect.empty()) {
            svg
                .append('svg:rect')
                .attr('id', `color_legend_${safeId}`)
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', '100%')
                .attr('height', '100%')
                .style('fill', `url(#legend-gradient_${safeId})`);
        }
        const pointer = svg.select('path');
        if (pointer.empty()) {
            svg
                .append('svg:path')
                .attr('id', `legend-pointer_${safeId}`)
                .attr('fill', '#B4B4B4')
                .attr('opacity', emptyObject(value) ? '0' : '1')
                .attr('d', symbol().size([40]).type(symbolTriangle)())
                .attr('transform', 'translate(0, -8) rotate(-180)');
        }
    }, [safeId, value]);

  
    const handleAccept = (type) => (value) => {
        setMeasureDomain({ measureName, min, max, [type]: value, paramForMeasure });
    };

    const prcWidth = 100 / (rangeVals.length + 2);

    return (
        <div
            className={`svg-bg ${inContainer ? classes.containedRoot : classes.root}`}
            style={{ display: renderLegend ? 'block' : 'none' }}
        >
            {title && <p>{title}</p>}
            <svg id={`legend_svg_${safeId}`} ref={svgRef} className={classes.legend} />
            {renderLegend && (
                <div id="legend_values">
                    <table className={classes.valuesTable}>
                        <tbody>
                            <tr>
                                <InputMinMax
                                    type="min"
                                    scaleId={scaleId}
                                    prcWidth={prcWidth}
                                    value={min}
                                    isTimeScale={isTimeScale}
                                    precision={precision}
                                    onAccept={handleAccept('min')}
                                    max={max}
                                />
                                {rangeVals.map((v, i) => (
                                    <InputMinMax
                                        key={`${scaleId}_${i}`}
                                        prcWidth={prcWidth}
                                        value={v}
                                        isTimeScale={isTimeScale}
                                        precision={precision}
                                    />
                                ))}
                                <InputMinMax
                                    type="max"
                                    scaleId={scaleId}
                                    prcWidth={prcWidth}
                                    value={max}
                                    isTimeScale={isTimeScale}
                                    precision={precision}
                                    min={min}
                                    onAccept={handleAccept('max')}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { lineage, colorBy } = state.parameters;
    const { valueGetter, scaleId } = ownProps;
    const measureName = ownProps.measureName || colorBy;
    const measure = getMeasure(state, measureName) || getColorByMeasure(state);

    const paramForMeasure = getMeasureScaleParamName(measure);
    const value = valueGetter ? valueGetter(state) : null;
    const isValueString = typeof value === 'string' || value instanceof String;
    const measuresScales = getCurrentColorScalesForMeasures(state);
    const colorScale = measuresScales[measureName];

    const measuresWithScales = getMetadataMeasuresWithScales(state);
    const domain = measuresWithScales[measureName]?.scale.domain;
    const colorScaleRange = measuresWithScales[measureName]?.scale.range;
    const discrete = measuresWithScales[measureName]?.scale.discrete;
    const renderLegend = colorScaleRange !== undefined;
    const min = domain && domain.length && domain[0];
    const max = domain && domain.length && domain[domain.length - 1];

    return {
        min,
        max,
        colorScaleRange,
        renderLegend,
        paramForMeasure,
        value: isValueString ? 0 : value,
        colorScale,
        lineage,
        discrete,
        domain,
        scaleId,
        isTimeScale: measure && measure.time,
        measureName,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setMeasureDomain,
            setAlertStatus,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ContinousScale);
