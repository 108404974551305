import { ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { SESSION_TIMEOUT } from '../actions/actionTypes';
import { setSessionTimeout } from '../actions/sessionActions';

export const sessionTimeoutEpic = (action$, state$) =>
    action$.pipe(
        ofType(SESSION_TIMEOUT),
        withLatestFrom(state$),
        mergeMap(([_action, state]) => {
            const userStatus = state.user.userStatus;
            if (userStatus === 'loaded') {
                return of(
                    setSessionTimeout({
                        errorWebsiteText: 'Your session has expired. Please sign in again.',
                        errorWebsiteType: 'session',
                    })
                );
            } else {
                return of(
                    setSessionTimeout({
                        errorWebsiteText: 'Your session has expired.',
                        errorWebsiteType: 'sessionPublic',
                    })
                );
            }
        })
    );
