import React from 'react';
import SingleModelSelector from '../MultipleModelsSelector/SingleModelSelector';
import { setParameters } from '../../redux/actions/parametersActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetVaccinesPredictions } from '../../redux/actions/vaccinesActions';
import { isNil } from 'lodash';

const VaccinesModelSelector = (props) => {
    const { vaccinesModel, setParameters, resetVaccinesPredictions } = props;

    const updateModelId = (_index, model, invalid, idIncomplete) => {
        const _model = {
            ...model,
            invalid: invalid || false,
            idIncomplete: idIncomplete || false
        };
        setParameters({ vaccinesModel: _model });
        if (!invalid && !idIncomplete) {
            resetVaccinesPredictions();
        }
    };

    const updateModelParam = (type) => (value) => {
        // Only update if the new value is different from the current one
        if (vaccinesModel[type] === value) return;
      
        const _model = { ...vaccinesModel, [type]: value };
        setParameters({ vaccinesModel: _model });
      
        if (!isNil(value)) {
            resetVaccinesPredictions();
        }
    };
      
    

    return <SingleModelSelector 
        id="vaccines-model-selector"
        updateModelId={updateModelId} 
        updateModelParameters={updateModelParam} 
        {...vaccinesModel}
    />;
};



const mapStateToProps = (state) => {
    const { vaccinesModel, strainSubset } = state.parameters;
    return ({
        vaccinesModel,
        strainSubset,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    resetVaccinesPredictions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesModelSelector);
