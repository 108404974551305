import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import FrequenciesChart from '../../components/Charts/FrequenciesChart';
import FrequenciesLegend from '../../components/ColorLegend/Legends/FrequenciesLegend';
import SequencesChart from '../../components/Charts/SequencesChart';
import ChartOptions from '../../components/Charts/options/ChartOptions';
import ExportableComponent from '../Export/ExportableComponent';
// import { fetchModels, fetchModelTypes } from '../../redux/actions/modelActions';
import { fetchFrequencies } from '../../redux/actions/frequenciesActions';
import { fetchPredictions } from '../../redux/actions/predictionsActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import { fetchLineages } from '../../redux/actions/lineagesActions';
// import { fetchTCellAntigenicityOptions } from '../../redux/actions/treeDataActions';
import { frequenciesStatusSelector } from '../../redux/selectors/frequenciesSelector';
import { getFrequencyCategories } from '../../redux/selectors/metadataSelector';
import { getPredictionsStatus, getPredictionsStatuses, getSelectedModelsToFetch } from '../../redux/selectors/statusSelector';
import { trackingToSelector, trackingFromSelector, predictionBaselineSelector, getScaleNameForFreqCategory } from '../../redux/selectors/parametersSelector';
// import { fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import appConfig from '../../config/appConfig';
import { shouldFetch } from '../../functions/data-helpers';
import { RENDER_STATUS, VIEWS_NAMES } from '../../config/consts';
import { styles } from './styles';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import { bindActionCreators } from 'redux';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import PointInfo from '../../components/Charts/pointInfo/PointInfo';
import { dynamicStyles } from '../../assets/GlobalStyles/dynamicStyles';
import { getIsMobile } from '../../functions/utils';
const useStyles = makeStyles(styles);

// Sidebar component
const ChartSidebar = ({className, isMobile=false, intro=false}) => {
    // console.log('[ChartSidebar]', {className, isMobile});
    return (isMobile ?
        <Grid size={12} className={className}>
            <PointInfo />
            <ChartOptions intro={intro} />
        </Grid> :
        <Grid {...(isMobile && { size: 12})} className={className}>
            <PointInfo />
            <ChartOptions intro={intro} />
        </Grid>
    );
};
  
// ChartsSection component
const ChartsSection = ({ className }) => {
    // console.log('[ChartsSection]', {className, isMobile});
    return (
        <Grid size='grow' /*{...(isMobile && { xs: 12})}*/ className={className}>
            <ExportableComponent filename="frequencies">
                <>
                    <FrequenciesChart id='freq-chart' viewName={VIEWS_NAMES.CLADE} />
                    <SequencesChart
                        viewName={VIEWS_NAMES.CLADE}
                        title="Curated sequence counts (per week, smoothened)"
                        type="seq"
                    />
                    <SequencesChart
                        viewName={VIEWS_NAMES.CLADE}
                        title="Reported case counts (per day, smoothened)"
                        type="case"
                    />
                    <div style={{ margin: '0 20px', paddingBottom: '15px' }}>
                        <MetaInformations />
                    </div>
                </>
            </ExportableComponent>
        </Grid>
    );};

const Frequencies = (props) => {
    const {
        intro = false, 
        lineage, predictionBaseline, trackingFrom, trackingTo, freqCategory,
        modelRegionId, strainSubset, exportLegend,
        mutgene, mutposition, sigmaAg, tau, showPrediction, gene, cladeType,
        hla, selectedModels, selectedModelsToFetch,

        lineageStatus, renderStatus, predictionsStatus, cladesStatus, frequenciesStatus,

        fetchFrequencies, fetchPredictions,
        fetchClades, submissionDate,

        exportMode, menuRight = true, hiddenMenu, hiddenMenuMobile,
        isMobile, scaleName, isScaleDiscrete, multiplicityMethod

    } = props;

    const classes = useStyles();

    const exportDone =
        renderStatus === RENDER_STATUS.DONE &&
        (predictionsStatus === 'loaded' || predictionsStatus === 'nodata' || !selectedModels || selectedModels.length === 0);

    const initComponentData = async () => {
        if (cladesStatus === 'error') return;
        await Promise.all([
            shouldFetch(cladesStatus) ? fetchClades({ lineage }) : null,
        ]);

    };

    const initFrequenciesData = async () => {
        if (shouldFetch(frequenciesStatus) && (freqCategory !== 'genotype' || (mutgene && mutposition))) {
            // console.log('should run fetch frequencies, selectedModels = ',selectedModels, selectedModelsToFetch);
            const _predictionBaseline = intro ? trackingTo : predictionBaseline;
            const _showPrediction = intro ? false : showPrediction;
            fetchFrequencies({
                lineage, 
                freqCategory,
                trackingFrom, 
                predictionBaseline: _predictionBaseline,
                trackingTo, 
                gene, 
                hla, 
                strainSubset, 
                // visibleBins, 
                mutgene, 
                mutposition, 
                showPrediction: _showPrediction,
                scaleName, 
                cladeType, 
                selectedModels: selectedModelsToFetch, 
                multiplicityMethod,
                submissionDate
            });
        }
    };

    const initPredictionsData = () => {
        // console.log('initPredictionsData modelType = ',modelType)
        fetchPredictions({
            lineage, freqCategory, predictionBaseline, trackingTo, /*sigmaAg, tau,*/ gene, hla, strainSubset,
            selectedModels: selectedModelsToFetch, scaleName
        });
    };

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        initComponentData();
    }, [lineageStatus]);

    // freqCategory === 'genotype'
    useEffect(() => {
        // console.log('useEffect', { freqCategory, scaleName, frequenciesStatus, isScaleDiscrete });
        if (lineageStatus !== 'loaded' || !isScaleDiscrete) return;
        if (shouldFetch(frequenciesStatus)) initFrequenciesData();
    }, [freqCategory, mutgene, mutposition, trackingFrom, predictionBaseline, modelRegionId, strainSubset, cladeType, frequenciesStatus, scaleName]);



    useEffect(() => {
        // console.log('predictionsStatus', predictionsStatus);
        if (lineageStatus !== 'loaded' || cladesStatus !== 'loaded' /*|| modelsStatus !== 'loaded'*/ || frequenciesStatus === 'loading' || frequenciesStatus === 'none' || !shouldFetch(predictionsStatus)) return;
        initPredictionsData();
    }, [sigmaAg, tau, trackingTo, predictionsStatus, frequenciesStatus]);

    return (
        <>
            {!exportMode && (
                <div style={dynamicStyles(isMobile).root}>
                    <ErrorAlert />
                    <Grid container className={classes.container}>
                        {
                            isMobile ?
                                !hiddenMenuMobile 
                                    ? <ChartSidebar isMobile={true} className={classes.cladeSidebar} intro={intro}/>
                                    : <ChartsSection isMobile={true} className={classes.item}/>
                                :
                                <>
                                    {!menuRight &&
                                        <ChartSidebar className={`${classes.cladeSidebarLeft} ${hiddenMenu ? classes.hidden : ''}`} intro={intro}/>
                                    }
                                    <ChartsSection isMobile={false} className={classes.item}/>
                                    {menuRight &&
                                      <ChartSidebar className={`${classes.cladeSidebarRight} ${hiddenMenu ? classes.hidden : ''}`} intro={intro}/>
                                    }
                                </>
                        }
                    </Grid>
                </div>
            )}
            {exportMode && (
                <div className={classes.rootExport}>
                    <div className={classes.containerExport}>
                        <div className={classes.itemExport}>
                            <FrequenciesChart />
                        </div>
                        { exportLegend && 
                            <div className={classes.legendExport}>
                                <FrequenciesLegend />
                            </div>
                        }
                        
                    </div>
                </div>
            )}
            {exportDone && <div id="exportDone" />}
        </>
    );
};


Frequencies.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        item: PropTypes.string,
        itemExport: PropTypes.string,
        legend: PropTypes.string,
        rootExport: PropTypes.string,
        containerExport: PropTypes.string,
    }),
    // modelsStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    lineage: PropTypes.string,
    freqCategory: PropTypes.string,
    lineageStatus: PropTypes.string,
    lineagesStatus: PropTypes.string,
    modelId: PropTypes.string,
    modelType: PropTypes.string,
    // regionId: PropTypes.string,
    modelRegionId: PropTypes.string,
    trackingFrom: PropTypes.instanceOf(Date),
    predictionBaseline: PropTypes.instanceOf(Date),
    trackingTo: PropTypes.instanceOf(Date),
    gene: PropTypes.string,
    hla: PropTypes.string,
    // binMethod: PropTypes.string,
    // binCnt: PropTypes.number,
    exportMode: PropTypes.bool,
    renderStatus: PropTypes.string,
    mutgene: PropTypes.string,
    mutposition: PropTypes.number,
    sigmaAg: PropTypes.number,
    tau: PropTypes.number,
    showPrediction: PropTypes.bool,
    mutationsPositionsDictStatus: PropTypes.string,
    exportParams: PropTypes.shape({}),
    // fetchModels: PropTypes.func,
    // fetchCladeFrequenciesRegions: PropTypes.func,
    frequenciesStatus: PropTypes.string,

    menuRight: PropTypes.bool,
    hiddenMenu: PropTypes.bool,
    hiddenMenuMobile: PropTypes.bool,
    isMobile: PropTypes.bool,
    scaleName: PropTypes.string,
    isScaleDiscrete: PropTypes.bool,
    multiplicityMethod: PropTypes.string,
    fetchLineages: PropTypes.func,
    fetchFrequencies: PropTypes.func,
    predictionsStatus: PropTypes.string,
    // modelTypesStatus: PropTypes.string,
    colorBy: PropTypes.string,
    zoomNodeId: PropTypes.number,
    strainSubset: PropTypes.string,
    // treeDataStatus: PropTypes.string,
    tcellAntigenicityOptionsStatus: PropTypes.string,
    cladeType: PropTypes.string,
    visibleBins: PropTypes.object,
    selectedModels: PropTypes.arrayOf(PropTypes.shape({ modelRegionId: PropTypes.string, modelType: PropTypes.string, modelId: PropTypes.string })),
    selectedModelsToFetch: PropTypes.arrayOf(PropTypes.shape({ modelRegionId: PropTypes.string, modelType: PropTypes.string, modelId: PropTypes.string })),
    freqMeasure: PropTypes.shape({ discrete: PropTypes.bool }),
    fetchTCellAntigenicityOptions: PropTypes.func,
    fetchPredictions: PropTypes.func,
    initStrainTree: PropTypes.func,
    fetchMutationsPositionsDictionary: PropTypes.func,
    fetchClades: PropTypes.func,
    // fetchModelTypes: PropTypes.func
};

const mapStateToProps = (state) => {
    //console.log('1.');
    const frequenciesStatus = frequenciesStatusSelector(state);
    const modelId = state.parameters.modelId;
    const predictionsStatuses = getPredictionsStatuses(state);
    const predictionsStatus = getPredictionsStatus(state);

    // console.log('[Frequencies] predictionStatus', predictionsStatus, predictionsStatuses);

    const { freqCategoriesMap } = getFrequencyCategories(state);
    const freqMeasure = freqCategoriesMap[state.parameters.freqCategory];
    //console.log('2.',state.frequenciesData);
    // console.log(`[Clade] = regionId = ${state.parameters.regionId}, frequenciesStatus = ${frequenciesStatus}, predictionsStatus = ${frequenciesStatus}`);

    //console.log(`[Frequencies.mapStateToProps]: frequenciesStatus = ${frequenciesStatus}, modelId = ${modelId}, predictionsStatus = ${predictionsStatus}`);
    const scaleName = getScaleNameForFreqCategory(state);
    const isScaleDiscrete = state.metadata.scales[scaleName]?.discrete || false;
    return {
        // modelsStatus: state.models.modelsStatus,
        // modelStatus: state.modelData.modelStatus,
        frequenciesStatus,
        lineage: state.parameters.lineage,
        lineageStatus: state.lineages.lineageStatus,
        lineagesStatus: state.lineages.lineagesStatus,
        // modelTypesStatus: state.models.modelTypesStatus.fitness,
        freqCategory: state.parameters.freqCategory,
        freqMeasure,
        // regionId: state.parameters.regionId || appConfig.default.regionId,
        modelRegionId: state.parameters.modelRegionId || appConfig.default.modelRegionId,
        modelId,
        modelType: state.parameters.modelType,
        trackingFrom: trackingFromSelector(state),
        predictionBaseline: predictionBaselineSelector(state),
        showPrediction: state.parameters.showPrediction,
        trackingTo: trackingToSelector(state), //state.parameters.trackingTo,
        cladeType: state.parameters.cladeType,
        sigmaAg: state.parameters.sigmaAg,
        tau: state.parameters.tau,
        gene: state.parameters.gene,
        hla: state.parameters.hla,
        // binMethod: state.parameters.binMethod,
        // binCnt: state.parameters.binCnt,
        colorBy: state.parameters.colorBy,
        zoomNodeId: state.parameters.zoomNodeId,
        strainSubset: state.parameters.strainSubset,
        predictionsStatus,
        predictionsStatuses,
        exportLegend: state.parameters.exportLegend,
        cladesStatus: state.cladeData.cladesStatus,
        exportMode: state.parameters.exportMode,
        renderStatus: state.render.renderStatus,
        tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
        visibleBins: state.parameters.visibleBins,
        mutgene: state.parameters.mutgene,
        mutposition: state.parameters.mutposition,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        menuRight: state.user.menuRight,
        hiddenMenu: state.ui.hiddenMenu,
        hiddenMenuMobile: state.ui.hiddenMenuMobile,
        isMobile: getIsMobile(),
        scaleName,
        isScaleDiscrete,
        selectedModels: state.parameters.selectedModels,
        selectedModelsToFetch:  
            getSelectedModelsToFetch(state),
        multiplicityMethod: state.parameters.multiplicityMethod,
        submissionDate: state.parameters.submissionDate
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFrequencies,
    fetchLineages,
    fetchPredictions,
    fetchClades
}, dispatch);

// const FrequenciesWithStyles = withStyles(styles)(Frequencies);

export default connect(mapStateToProps, mapDispatchToProps)(Frequencies);
// export { FrequenciesExport };
