import { produce } from 'immer';
import {
    FETCH_LINEAGE_SUCCESS,
    FETCH_VP_METHODS_SUCCESS,
    FETCH_MUT_POS_DICT_SUCCESS,
    FETCH_MODELS_SUCCESS,
    FETCH_MODEL_TYPES_SUCCESS,
    SET_LINEAGE_SETTINGS_REQUEST,
    SET_LINEAGE_SETTINGS_SUCCESS,
    SET_LINEAGE_SETTINGS_ERROR,
    FETCH_ALL_SCALES_SUCCESS,
    FETCH_MODEL_TYPES_REQUEST,
    FETCH_VP_METHODS_REQUEST,
    SET_SETTINGS_PARAMETERS,
    FETCH_LINEAGE_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    FETCH_MODELS_REQUEST,
    SET_SETTINGS_METADATA,
    FETCH_CUSTOM_TREE_SUBSETS_REQUEST,
    FETCH_CUSTOM_TREE_SUBSETS_SUCCESS,
    FETCH_CUSTOM_TREE_SUBSETS_ERROR,
    FETCH_LINEAGE_REGIONS_REQUEST,
    FETCH_LINEAGE_REGIONS_SUCCESS,
    FETCH_LINEAGE_REGIONS_ERROR,
    FETCH_LINEAGE_MUTATION_GENES_REQUEST,
    FETCH_LINEAGE_MUTATION_GENES_SUCCESS,
    FETCH_LINEAGE_MUTATION_GENES_ERROR,
    FETCH_MUTATION_CLASSES_ARRAY_REQUEST,
    FETCH_MUTATION_CLASSES_ARRAY_SUCCESS,
    FETCH_MUTATION_CLASSES_ARRAY_ERROR,
    FETCH_ALL_MEASURES_REQUEST,
    FETCH_ALL_MEASURES_SUCCESS,
    FETCH_ALL_MEASURES_ERROR
} from '../actions/actionTypes';
import { prepareParameters } from '../../functions/url-parameters';
import appConfig from '../../config/appConfig';

let settingsInitialState = {};
export const setSettingsInitialState = (state) => {
    settingsInitialState = state;
};

const GENES_LABELS = {
    genome: 'Nucleotide'
};

const GENES_ORDER = {
    genome: 1,
    default: 2
};

export const settingsReducer = (state = settingsInitialState, action) => {
    if (action.payload && !action.payload.settings
        && action.type !== SET_SETTINGS_PARAMETERS
        && action.type !== SET_SETTINGS_METADATA
        && action.type !== FETCH_ALL_SCALES_SUCCESS
        && action.type !== FETCH_LINEAGE_REQUEST
        && action.type !== FETCH_CUSTOM_TREE_SUBSETS_SUCCESS
        && action.type !== FETCH_LINEAGE_REGIONS_SUCCESS
        && action.type !== FETCH_CUSTOM_TREE_SUBSETS_REQUEST
        && action.type !== FETCH_CUSTOM_TREE_SUBSETS_ERROR
        && action.type !== FETCH_LINEAGE_MUTATION_GENES_SUCCESS
        && action.type !== FETCH_LINEAGE_MUTATION_GENES_ERROR
        && action.type !== FETCH_LINEAGE_MUTATION_GENES_SUCCESS
        && action.type !== FETCH_LINEAGE_MUTATION_GENES_ERROR
        && action.type !== FETCH_MUTATION_CLASSES_ARRAY_SUCCESS
        && action.type !== FETCH_MUTATION_CLASSES_ARRAY_ERROR
        && action.type !== FETCH_ALL_MEASURES_SUCCESS
        && action.type !== FETCH_ALL_MEASURES_ERROR
        && action.type !== FETCH_LINEAGE_SUCCESS
    ) return state;

    return produce(state, (draft) => {

        switch (action.type) {
            case FETCH_LINEAGE_REQUEST: {
                const { lineage } = action.payload;
                Object.assign(draft, settingsInitialState);
                draft.parameters = { ...settingsInitialState.parameters, lineage };
                draft.lineages = { ...settingsInitialState.lineages, lineageStatus: 'loading' };
                break;
            }
            case FETCH_LINEAGE_SUCCESS: {
                const { metadata, customMeasures, processingMetadata, parameters, scales, measureDomains, measureBins } = action.payload;
                const _parameters = prepareParameters(parameters);
                _parameters.zoomNodeId = _parameters.zoomNodeId || null;

                const customNodeMeasures = Object.keys(customMeasures.node || {}).reduce((acc, k) => {
                    acc[k] = { ...customMeasures.node[k], custom: true };
                    return acc;
                }, {});

                const customBranchMeasures = Object.keys(customMeasures.branch || {}).reduce((acc, k) => {
                    acc[k] = { ...customMeasures.branch[k], custom: true, branch: true };
                    return acc;
                }, {});

                const standardMeasures = draft.metadata.measures;
                const measures = {
                    ...standardMeasures,
                    ...customNodeMeasures,
                    ...customBranchMeasures
                };

                // 3. Process color options
                const _colorByOptions = metadata?.colorByOptions || appConfig.default.colorByOptions;
                const colorByOptions = Object.keys(measures)
                    .filter(colorBy => measures[colorBy] && _colorByOptions[colorBy])
                    .reduce((colorByOptions, colorBy) => ({
                        ...colorByOptions,
                        [colorBy]: true
                    }), {});

                // 4. Process scales and domains
                const measureDomainStatutes = Object.keys(measureDomains)
                    .reduce((acc, m) => ({ ...acc, [m]: 'loaded' }), {});

                const disreteScaleTypes = Object.keys(scales).reduce((acc, scaleName) => {
                    const scaleType = scaleName.split('.')[0];
                    acc[scaleType] = acc[scaleType] || scales[scaleName].discrete || false;
                    return acc;
                }, {});

                // 5. Update measures with scale information
                Object.keys(measures).forEach(m => {
                    const scaleType = measures[m].scaleType || m;
                    measures[m].discreteScale = disreteScaleTypes[scaleType];
                });

                // 6. Update state
                draft.metadata = {
                    ...draft.metadata,
                    ...metadata,
                    customMeasures,
                    measures,
                    measureBins,
                    processingMetadata,
                    colorByOptions,
                    measureScalesDomains: measureDomains,
                    scales,
                    measureDomainStatutes
                };

                draft.parameters = {
                    ...draft.parameters,
                    ..._parameters
                };

                draft.lineages.lineageStatus = 'loaded';
                break;
            }
            case SET_LINEAGE_SETTINGS_SUCCESS: {
                const { parameters } = action.payload;
                draft.parameters = Object.assign(draft.parameters || {}, parameters);
                draft.setSettingsStatus = 'success';
                break;
            }

            case FETCH_CUSTOM_TREE_SUBSETS_REQUEST: {
                draft.customSubsets.status = 'loading';
                break;
            }

            case FETCH_CUSTOM_TREE_SUBSETS_SUCCESS: {
                const { customSubsets } = action.payload;
                draft.customSubsets.status = 'loaded';
                draft.customSubsets.data = customSubsets;
                break;
            }

            case FETCH_CUSTOM_TREE_SUBSETS_ERROR: {
                draft.customSubsets.status = 'error';
                break;
            }

            case FETCH_LINEAGE_REGIONS_SUCCESS: {
                const { lineagesRegions } = action.payload;
                draft.lineageRegions.status = 'loaded';
                draft.lineageRegions.data = { ...lineagesRegions };
                break;
            }

            case FETCH_LINEAGE_REGIONS_REQUEST: {
                draft.lineageRegions.status = 'loading';
                break;
            }

            case FETCH_LINEAGE_REGIONS_ERROR: {
                draft.lineageRegions.status = 'error';
                break;
            }

            case FETCH_VP_METHODS_REQUEST: {
                draft.metadata.vpMethodsStatus = 'loading';
                break;
            }
            
            case FETCH_VP_METHODS_SUCCESS: {
                const { vpMethods } = action.payload;
                draft.metadata.vpMethods = vpMethods;
                draft.metadata.vpMethodsStatus = 'loaded';
                break;
            }

            case FETCH_MUT_POS_DICT_SUCCESS: {
                const { mutPositions } = action.payload;
                const genesList = Object.keys(mutPositions);
                const genotypeFilterGenesList = genesList
                    .map(e => ({ label: GENES_LABELS[e] || e, val: e, order: GENES_ORDER[e] || GENES_ORDER.default }))
                    .sort((a, b) => {
                        if (a.order === b.order) {
                            return a.label.localeCompare(b.label);
                        }
                        return a.order > b.order;
                    });

                draft.genotype = {
                    genotypeFilterGenesList,
                    mutPositions
                };
                break;
            }

            case FETCH_MODEL_TYPES_REQUEST: {
                const { colorBy } = action.payload;
                const _colorBy = colorBy || 'fitness';
                draft.models.modelTypesStatus[_colorBy] = 'loading';
                draft.models.modelTypes[_colorBy] = [];
                break;
            }

            case FETCH_MODEL_TYPES_SUCCESS: {
                const { modelTypes, colorBy } = action.payload;
                const _colorBy = colorBy || 'fitness';
                draft.models.modelTypes[_colorBy] = modelTypes;
                if (!modelTypes.includes(draft.parameters.modelType) && modelTypes.length > 0) {
                    draft.parameters.modelType = modelTypes[0];
                }
                draft.models.modelTypesStatus[_colorBy] = 'loaded';
                break;
            }

            case FETCH_MODELS_REQUEST: {
                const { colorBy } = action.payload; 
                draft.models.modelsStatus[colorBy] = 'loading';
                draft.models.models[colorBy] = [];
                break;
            }

            case FETCH_MODELS_SUCCESS: {
                const { models, colorBy } = action.payload;
                const _modelId = colorBy === 'antigenic' 
                    ? draft.parameters.antigenicModelId 
                    : draft.parameters.modelId;
                const modelIdVar = colorBy === 'antigenic' ? 'antigenicModelId' : 'modelId';
                if ((!_modelId || !models.includes(_modelId)) && models.length > 0) {
                    draft.parameters[modelIdVar] = models[0];
                }
                draft.models.models[colorBy] = models;
                draft.models.modelsStatus[colorBy] = 'loaded';
                break;
            }
           
            case SET_LINEAGE_SETTINGS_REQUEST: {
                draft.setSettingsStatus = 'processing';
                break;
            }

            case SET_LINEAGE_SETTINGS_ERROR: {
                draft.setSettingsStatus = 'error';
                break;
            }
            case FETCH_ALL_SCALES_SUCCESS: {
                const { colorScales, scalesPalette } = action.payload;
                draft.colorScales = colorScales;
                draft.scalesPalette = scalesPalette;
                break;
            }

            case FETCH_MUTATION_CLASSES_SUCCESS: {
                const { mutationClasses } = action.payload;
                draft.metadata.mutationClasses = mutationClasses;
                draft.metadata.mutationClassesStatus = 'loaded';
                break;
            }

            case SET_SETTINGS_PARAMETERS: {
                const parameters = action.payload;  
                draft.parameters = {
                    ...draft.parameters,
                    ...parameters
                };
                break;
            }

            case SET_SETTINGS_METADATA: {
                const metadata = action.payload;
                draft.metadata = {
                    ...draft.metadata,
                    ...metadata
                };
                break;
            }

            case FETCH_LINEAGE_MUTATION_GENES_REQUEST: {
                draft.lineageMutationGenes.status = 'loading';
                break;
            }

            case FETCH_LINEAGE_MUTATION_GENES_SUCCESS: {
                const { lineageMutationGenes } = action.payload;
                draft.lineageMutationGenes.status = 'loaded';
                draft.lineageMutationGenes.data = lineageMutationGenes;
                break;
            }

            case FETCH_LINEAGE_MUTATION_GENES_ERROR: {
                draft.lineageMutationGenes.status = 'error';
                break;
            }

            case FETCH_MUTATION_CLASSES_ARRAY_REQUEST: {
                draft.mutationClasses.status = 'loading';
                break;
            }

            case FETCH_MUTATION_CLASSES_ARRAY_SUCCESS: {
                const { mutationClasses } = action.payload;
                draft.mutationClasses.status = 'loaded';
                draft.mutationClasses.data = mutationClasses;
                break;
            }

            case FETCH_MUTATION_CLASSES_ARRAY_ERROR: {
                draft.mutationClasses.status = 'error';
                break;
            }

            case FETCH_ALL_MEASURES_REQUEST: {
                draft.measures.status = 'loading';
                break;
            }

            case FETCH_ALL_MEASURES_SUCCESS: {
                const { measures } = action.payload;
                draft.measures.status = 'loaded';
                draft.measures.data = measures;
                break;
            }           

            case FETCH_ALL_MEASURES_ERROR: {
                draft.measures.status = 'error';
                break;
            }

            default:                                
                break;
        }
    });
};
