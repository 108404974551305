// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { Button, Typography, IconButton, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Internal imports - Actions
import { resetAntigenicHiddenClades } from '../../redux/actions/antigenicActions';
import { setMeasureScale, setParameters } from '../../redux/actions/parametersActions';

// Internal imports - Config & Utils
import appConfig from '../../config/appConfig';
import { isFirefox } from '../../functions/browser-check';

// Internal imports - Components
import ModelSelector from '../ModelSelector/ModelSelector';
import ColorScaleSelector from '../OptionsSelector/ColorScaleSelector/ColorScaleSelector';
import AntigenicInfo from './AntigenicInfo';
import { HideIcon } from '../Alerts/styles';
import Alerts from '../Alerts/Alerts';
import ScalesLegend from '../ColorLegend/Legends/ScalesLegend';
import BuildSelector from '../OptionsSelector/LineageSelector/BuildSelector';
import CheckboxInput from '../Common/CheckboxInput';
import SelectInput from '../Common/SelectInput';

// Internal imports - Selectors
import { getComplexDomainParameterValues } from '../../redux/selectors/rangeDataSelector';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px 10px 15px',
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    formControl: {
        margin: '4px 0px',
        paddingRight: '8px',
        minWidth: '120px'
    },
    button: {
        width: '80px',
        height: '32px',
        marginTop: '5px',
        marginBottom: '10px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    hideButton: {
        padding: '0px',
        float: 'right'
    },
    buttonWrapper: {
        width: '100%',
        height: '30px'
    }
}));

const antigenicDataTypes = [
    { id: 'observed', label: 'Observed clade level' },
    { id: 'inferred', label: 'Inferred clade level' },
];

const AntigenicOptions = props => {
    const classes = useStyles();

    const { antigenicDataType, antigenicTiterType, hiddenAlphaCladesCnt, hiddenRhoCladesCnt, handleHideClick, permissions, showAntigenicTableValues, wrapAntigenicTableHeaders,
        measures, params, cellInfo,
        setParameters, setMeasureScale,
        resetAntigenicHiddenClades, undoDeleting } = props;

    const { alpha, rho, titer } = cellInfo || {}; 

    const handleChange = name => value => {
        setParameters({ [name]: value });

        if (name === 'antigenicTiterType') {
            const colorScale = measures['antigenic'].scale[name][value];
            setMeasureScale({ measure: 'antigenic', scaleName: colorScale, parameters: { ...params, [name]: value } });
        }
    };

    const handleReset = () => {
        resetAntigenicHiddenClades();
    };

    const handleUndo = () => {
        undoDeleting();
    };

    const _antigenicDataType = antigenicDataType;

    return (
        <form className={classes.root} autoComplete="off">
            <div className={classes.buttonWrapper}>
                <IconButton className={classes.hideButton} onClick={handleHideClick}>
                    <HideIcon />
                </IconButton>
            </div>
            <Alerts module="antigenic" />
            <BuildSelector />
            {permissions.dataTypeSelector &&
                <SelectInput
                    label="Data"
                    id="antigenicDataType"
                    value={_antigenicDataType}
                    onChange={handleChange('antigenicDataType')}
                    options={antigenicDataTypes}
                />
            }
            {permissions.modelSelector && 
                <ModelSelector fixedModelType="Antigenic" modelContext="antigenic" />
            }
            {permissions.titerTypeSelector &&
                <SelectInput
                    label="Titer type"
                    id="antigenicTiterType"
                    value={antigenicTiterType}
                    onChange={handleChange('antigenicTiterType')}
                    options={appConfig.titerTypes}
                />  
            }
            <CheckboxInput 
                id="showAntigenicTableValues"
                label="Show values" 
                value={showAntigenicTableValues}
                onChange={handleChange('showAntigenicTableValues')}
                className={classes.formControl}
            />

            <CheckboxInput
                id="wrapAntigenicTableHeaders"
                label="Wrap labels"
                value={wrapAntigenicTableHeaders}
                onChange={handleChange('wrapAntigenicTableHeaders')}
                className={classes.formControl}
            />

            <Typography style={{ fontSize: '15px' }}>
                DELETED: {hiddenAlphaCladesCnt} Rows / {hiddenRhoCladesCnt} Columns
            </Typography>

            <Box sx={{ marginTop: '5px' }}>
                <Grid container spacing={1}>
                    <Grid >
                        <Button className={classes.button} onClick={handleReset}>
                            Reset
                        </Button>
                    </Grid>
                    <Grid >
                        <Button className={classes.button} onClick={handleUndo}>
                            Undo
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <ColorScaleSelector colorBy='antigenic' />
            <ScalesLegend
                scaleId="antigenic"
                measureName="antigenic"
                valueGetter={() => titer} 
                ticks={3}
            />
            <ScalesLegend
                scaleId="alphaY"
                measureName="alphaY"
                valueGetter={({antigenic}) => alpha ? antigenic.antigenicClades?.alphas[alpha] : null} 
                ticks={3}
                title="Clade frequency"
            />
            <ScalesLegend
                scaleId="rhoR"
                measureName="rhoR"
                valueGetter={({antigenic}) => rho ? antigenic.antigenicClades?.rhos[rho] : null} 
                ticks={3}
                title="Immune cohort weight"
            />

            <AntigenicInfo cellInfo={cellInfo}/>
        </form>
    );
};

AntigenicOptions.propTypes = {
    // Required props
    measures: PropTypes.shape({ 
        label: PropTypes.string,
        antigenic: PropTypes.object 
    }).isRequired,
    params: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
    
    // Optional props
    antigenicDataType: PropTypes.string,
    antigenicTiterType: PropTypes.string,
    hiddenAlphaCladesCnt: PropTypes.number,
    hiddenRhoCladesCnt: PropTypes.number,
    showAntigenicTableValues: PropTypes.bool,
    wrapAntigenicTableHeaders: PropTypes.bool,
    
    // Functions
    setParameters: PropTypes.func.isRequired,
    resetAntigenicHiddenClades: PropTypes.func.isRequired,
    setMeasureScale: PropTypes.func.isRequired,
    undoDeleting: PropTypes.func.isRequired,
    handleHideClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    // Antigenic parameters
    antigenicDataType: state.parameters.antigenicDataType,
    antigenicTiterType: state.parameters.antigenicTiterType,
    showAntigenicTableValues: state.parameters.showAntigenicTableValues,
    wrapAntigenicTableHeaders: state.parameters.wrapAntigenicTableHeaders,
    
    // Hidden clades counts
    hiddenAlphaCladesCnt: Object.keys(state.parameters.hiddenAlphaClades).length,
    hiddenRhoCladesCnt: Object.keys(state.parameters.hiddenRhoClades).length,
    
    // Other state
    permissions: state.user.permissions.antigenicPermissions,
    measures: state.metadata.measures,
    params: getComplexDomainParameterValues(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    setMeasureScale,
    resetAntigenicHiddenClades
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AntigenicOptions);
