import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { FETCH_FREQUENCIES_REQUEST } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import { fetchFrequenciesSuccess, fetchFrequenciesError } from '../actions/frequenciesActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';

export const fetchFrequencies = (action$) => action$.pipe(
    ofType(FETCH_FREQUENCIES_REQUEST),
    mergeMap(action => {
        // console.log('frequencies epic, action.payload', action.payload);
        const { allRegions, strainSubset } = action.payload;
    
        // const strainSubset = payload.allRegions ? 'ALL' : action.payload.strainSubset;
  
        const url = prepareUrl(`${config.serverLink}/api/frequencies`, action.payload);//{ ...payload, strainSubset });//action.payload)         
        //console.log(`fetchFrequencies => url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchFrequenciesSuccess({ ...response.data, strainSubset})),
                handleErrors(fetchFrequenciesError, 'An error has occurred during downloading frequencies', { allRegions, strainSubset })
            );
    })
);
