import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { fetchAxios } from '../../functions/axios-requests';
import config from '../../config/envConfig';
import {
    FETCH_HUMAN_POOLS_REQUEST, FETCH_HUMAN_SEROLOGY_REQUEST,
} from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import { fetchHumanPoolsError, fetchHumanPoolsSuccess, fetchHumanSerologyDataError, fetchHumanSerologyDataSuccess } from '../actions/humanSerologyActions';
import { handleErrors } from '../operators/error';

export const fetchHumanPoolsEpic = (action$) => action$.pipe(
    ofType(FETCH_HUMAN_POOLS_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload.lineage;
        const url = prepareUrl(`${config.serverLink}/api/humanSerology/humanPools`, { lineage });
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchHumanPoolsSuccess(response.data)),
                handleErrors(fetchHumanPoolsError, 'An error has occurred during fetching human pools')
            );
    })
);

export const fetchHumanSerologyDataEpic = (action$) => action$.pipe(
    ofType(FETCH_HUMAN_SEROLOGY_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { lineage, humanPool, humanSerologyDataType, zoomNodeId, colorBy } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/tree/humanSerologyData`, { lineage, humanPool, humanSerologyDataType, zoomNodeId, colorBy });
        // console.log(`fetchHumanSerologyData, url = ${url}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchHumanSerologyDataSuccess(response.data)),
                handleErrors(fetchHumanSerologyDataError, 'An error has occurred during fetching human serology data')
            );
    })
);
