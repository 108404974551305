import React from 'react';
import { color } from 'd3-color';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { styles } from './styles';
import { getTreeNodeAttrs, getSelectedMutationClasses } from '../../../../redux/selectors/treeDataSelector';
import { getTransformBranchNodes, getXBranch, getY } from '../helpers/functions';
import { getSymbol } from '../../d3/symbolSigns';
import { selectNodeData } from '../../../../redux/actions/nodeActions';
import { treeD3 } from '../../d3/TreeD3';

const useStyles = makeStyles(styles);

const swidth = 10;

export const getSymbolPosition = (id, startPos, symbolsNumber, number) => {
    const diff = (symbolsNumber - 1) / 2;
    const midTrans = (startPos - diff + (number - 1) / 2) * swidth;
    const xBranchPos = getXBranch(id);
    const yPos = getY(id);
    return {
        xAnchor: xBranchPos + midTrans,
        yAnchor: yPos
    };
};

export const getMutClassId = (id, subType) => `${id}${subType ? `_${subType}` : ''}`;

const MutationsClassesLabelsSymbol = props => {
    const { id, mutClass, symbol, fill, startPos, number, symbolsNumber } = props;

    const diff = (symbolsNumber - 1) / 2;
    const symbolWidth = 10;
    const xtrans = (startPos - diff) * symbolWidth;

    return (
        <g>
            {[...Array(number)].map((_, i) => (
                <path d={getSymbol(symbol, false)()}
                    key={`${id}_${mutClass}_${i}`}
                    fill={fill}
                    stroke={color(fill).darker().formatHex()}
                    transform={`translate(${xtrans + i * symbolWidth}, 0)`} />
            ))}
        </g>
    );
};

const mapStateToPropsLabel = (state) => ({
    strainTreeWidth: state.ui.strainTreeWidth,
    strainTreeHeight: state.ui.strainTreeHeight,
    showCladeLabels: state.parameters.showCladeLabels
});

const _MutationsClassesLabelsSymbol = connect(mapStateToPropsLabel)(MutationsClassesLabelsSymbol);

const MutationsClassesLabelSymbols = (props) => {
    const { id, classNamePrefix, muts, symbolsNumber, selectedMutationClasses, selectNodeData } = props;

    const transformBranchNode = getTransformBranchNodes(id);

    const handleMouseEnter = () => {
        if (!treeD3.selectedNode) selectNodeData({ nodeId: +id });
    };

    const handleMouseLeave = () => {
        selectNodeData({ nodeId: null });
    };

    const classes = useStyles();


    return <g className={classes[`${classNamePrefix}Symbol`]} transform={transformBranchNode} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {muts.map((m) => (
            <_MutationsClassesLabelsSymbol key={`${m.id}_${m.mutClass}`} {...m}
                id={id}
                symbol={selectedMutationClasses[m.mutClass].symbol_sign}
                fill={selectedMutationClasses[m.mutClass].color}
                startPos={m.startPos}
                classNamePrefix={classNamePrefix}
                symbolsNumber={symbolsNumber}
            />)
        )
        }
    </g>;

};

const mapStateToProps = (state, ownProps) => {
    const treeAttrs = getTreeNodeAttrs(state);

    return {
        nodeData: treeAttrs[ownProps.id],
        selectedMutationClasses: getSelectedMutationClasses(state),
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        showCladeLabels: state.parameters.showCladeLabels,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    selectNodeData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MutationsClassesLabelSymbols);
