import { emptyObject } from './utils';

export const numFormat = (n, p) => (emptyObject(n) ? '' : n.toLocaleString(undefined, { maximumFractionDigits: p || 4 }));

export const dateFormat = new Intl.DateTimeFormat().formatToParts().map(({type}) => {
    switch(type) {
        case 'month': return 'MM';
        case 'day': return 'dd';
        case 'year': return 'yyyy';
        default: return '/';
    }
}).join('');

