import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { FETCH_PREDICTIONS_REQUEST, } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/url-parameters';
import { fetchPredictionsError, fetchPredictionsSuccess } from '../actions/predictionsActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { handleErrors } from '../operators/error';



export const fetchPredictions = (action$) => action$.pipe(
    ofType(FETCH_PREDICTIONS_REQUEST),
    mergeMap(action => {
        const { allRegions, strainSubset, selectedModels } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/frequencies/predictions`, action.payload);
        return from(fetchAxios(url))
            .pipe(
                map(response => {
                    // console.log('Received predictions response:', response.data);
                    return fetchPredictionsSuccess(response.data);
                }),
                handleErrors(fetchPredictionsError, 'An error has occurred during downloading predictions', { allRegions, strainSubset, selectedModels })
            );
    })
);
