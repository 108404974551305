import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

// =================================
// 1) INPUT SELECTORS
// =================================
const getFerretProtectionValues = ({ vaccines }) => vaccines?.vaccinesData?.ferretProtectionValues;
const getHumanProtectionValues = ({ vaccines }) => vaccines?.vaccinesData?.humanProtectionValues;   
const getAllRefStrains = ({ vaccines }) => vaccines?.vaccinesData?.refStrains;
const getClades = ({ cladeData }) => cladeData?.clades;
const getVaccinesFrequencies = ({ vaccines }) => vaccines?.vaccinesFrequencies;
const getVaccinesPredictions = ({ vaccines }) => vaccines?.vaccinesPredictions;

const getSelectedFerretStrains = ({ parameters }) => parameters?.vaccinesFerretRefStrains;

const getSelectedHumanStrains = ({ parameters }) => parameters?.vaccinesHumanRefStrains;

const getSelectedClades = ({ parameters }) => parameters?.vaccinesRhos;


// =================================
// 2) HELPER FUNCTIONS
// =================================

/**
 * Summation helper for frequencies/predictions.
 * For each item in data, it:
 *  1) Identifies a clade id
 *  2) Looks up freq/pred in frequencies/predictions
 *  3) Multiplies freq/pred by the protection value (protvalue)
 *  4) Accumulates into the final object keyed by `makeKey(item)`
 * 
 * @param {Array} data - Protection data array
 * @param {Object} frequencies - Frequencies keyed by clade
 * @param {Object} predictions - Predictions keyed by clade
 * @param {Function} makeKey - Function that returns a unique string key for the item
 * @returns {Object} e.g. { key1: { freqSum: number, predSum: number }, ... }
 */
function sumFrequenciesAndPredictions(data, frequencies, predictions, makeKey) {
    return data.reduce((acc, item) => {
        const {
            cladeid,
            protvalue = 0,
        } = item;

        const key = makeKey(item);

        if (!acc[key]) {
            acc[key] = { freqSum: 0, predSum: 0 };
        }

        const freq = frequencies[cladeid]?.y || 0;
        const pred = predictions[cladeid]?.y || 0;

        acc[key].freqSum += freq * protvalue;
        acc[key].predSum += pred * protvalue;

        return acc;
    }, {});
}

/**
 * Creates unique references from a protection data array.
 * `uniquenessKey` is a function that returns the string used to deduplicate.
 */
function createUniqueRefStrains(data, uniquenessKey, transform) {
    if (!data) return null;
    // 1) Map the array to a simpler shape
    // 2) uniqBy that shape using `uniquenessKey`
    // 3) transform each item if needed
    return uniqBy(
        data.map(transform),
        uniquenessKey
    );
}


export function vaccineStrainId(item) {
    return JSON.stringify({
        refid: item.refid,
        lab: item.lab,
        ...(item.season ? { season: item.season } : {})
    });
}
/**
 * Attaches name and antigenicCladeLabel from the bigger references/clades objects.
 * Only used if you want to attach more details to each "ref strain" item.
 */
function attachCladeInfo(strains, refStrains, clades) {
    if (!strains || !refStrains || !clades || !Object.keys(clades).length) {
        return null;
    }

    return strains.map((item) => {
        const { refid } = item;
        const { clade, name } = refStrains[refid] || {};
        const antigenicCladeId = clades[clade]?.cladeMapping?.antigenic_clade?.alpha;

        return {
            ...item,
            name,
            antigenicCladeLabel: antigenicCladeId ? clades[antigenicCladeId]?.label : '',
        };
    });
}

/**
 * Sorts a list of strain keys descending by .predSum in the frequency object.
 */
function sortStrainKeysByPredSum(strainKeys, freqObj) {
    return strainKeys.sort((a, b) => {
        const diff = (freqObj[b]?.predSum || 0) - (freqObj[a]?.predSum || 0);
        return diff;
    });
}

// =================================
// 3) DERIVED SELECTORS
// =================================

// ---------- (A) Clade Index Mapping ----------

const getCladeIndices = createSelector(
    getSelectedClades,
    (cladeIds) => {
        if (!cladeIds) return null;
        return [...cladeIds].sort().reduce((acc, id, index) => {
            acc[id] = index + 1;
            return acc;
        }, {});
    }
);

// ---------- (B) Filtered Ferret Data ----------

export const getSelectedFerretData = createSelector(
    [getFerretProtectionValues, getCladeIndices, getSelectedFerretStrains],
    (data, cladeIds, selectedFerretStrains) => {
        if (!data || !cladeIds || !selectedFerretStrains) return null;

        const selectedStrainsSet = new Set(
            selectedFerretStrains.map(([refid, lab]) => vaccineStrainId({refid, lab}))
        );

        return data.filter(({ cladeid, ...elem }) =>
            selectedStrainsSet.has(vaccineStrainId(elem)) && cladeIds[cladeid]
        );
    }
);

export const getSelectedHumanData = createSelector(
    [getHumanProtectionValues, getCladeIndices, getSelectedHumanStrains],
    (data, cladeIds, selectedHumanStrains) => {
        if (!data || !cladeIds || !selectedHumanStrains) return null;

        const selectedStrainsSet = new Set(
            selectedHumanStrains.map(([refid, lab, season]) => vaccineStrainId({refid, lab, season}))
        );

        return data.filter(({ cladeid, ...elem }) =>
            selectedStrainsSet.has(vaccineStrainId(elem)) && cladeIds[cladeid]
        );
    }
);

// ---------- (C) All Ferret & Human Ref Strains With Measurements ----------

const getFerretRefStrainsWithMeasurements = createSelector(
    [getFerretProtectionValues],
    (data) => createUniqueRefStrains(
        data,
        elem => vaccineStrainId(elem),
        ({ refid, lab }) => ({ refid, lab })
    )
);

const getHumanRefStrainsWithMeasurements = createSelector(
    [getHumanProtectionValues],
    (data) => createUniqueRefStrains(
        data,
        elem => vaccineStrainId(elem),
        ({ refid, lab, season }) => ({ refid, lab, season })
    )
);

// ---------- (D) All Ref Strains (Ferret & Human) With Clade Info ----------

export const getAllFerretRefStrainsWithLabAndClade = createSelector(
    [getFerretRefStrainsWithMeasurements, getAllRefStrains, getClades],
    (uniqueStrains, refStrains, clades) =>
        attachCladeInfo(uniqueStrains, refStrains, clades)
);

export const getAllHumanRefStrainsWithLabAndClade = createSelector(
    [getHumanRefStrainsWithMeasurements, getAllRefStrains, getClades],
    (uniqueStrains, refStrains, clades) =>
        attachCladeInfo(uniqueStrains, refStrains, clades)
);

// ---------- (E) Weighted Frequencies/Predictions (Ferret & Human) ----------

export const ferretCladeFrequenciesSelector = createSelector(
    [getFerretProtectionValues, getVaccinesFrequencies, getVaccinesPredictions],
    (data = [], frequencies, predictions) => {
        if (!data || !frequencies || !predictions) return null;

        return sumFrequenciesAndPredictions(
            data,
            frequencies,
            predictions,
            (item) => vaccineStrainId(item)
        );
    }
);

export const humanCladeFrequenciesSelector = createSelector(
    [getHumanProtectionValues, getVaccinesFrequencies, getVaccinesPredictions],
    (data = [], frequencies, predictions) => {
        if (!data || !frequencies || !predictions) return null;

        return sumFrequenciesAndPredictions(
            data,
            frequencies,
            predictions,
            (item) => vaccineStrainId(item)
        );
    }
);

// ---------- (F) Sorted Selected Strains (Ferret & Human) ----------

export const sortedFreqFerretStrainsSelector = createSelector(
    [getSelectedFerretStrains, ferretCladeFrequenciesSelector],
    (strains, frequencies) => {
        if (!strains || !frequencies) return null;

        // Build the "composite key" array
        const strainKeys = strains.map(
            ([refid, lab]) => vaccineStrainId({refid, lab})
        );

        return sortStrainKeysByPredSum(strainKeys, frequencies);
    }
);

export const sortedFreqHumanStrainsSelector = createSelector(
    [getSelectedHumanStrains, humanCladeFrequenciesSelector],
    (strains, frequencies) => {
        if (!strains || !frequencies) return null;

        // Build the "composite key" array
        const strainKeys = strains.map(([refid, lab, season]) =>
            vaccineStrainId({refid, lab, season})
        );

        return sortStrainKeysByPredSum(strainKeys, frequencies);
    }
);

// ---------- (G) Strain Indices (Ferret & Human) ----------

const getFerretStrainIndices = createSelector(
    sortedFreqFerretStrainsSelector,
    (sortedStrains) => {
        if (!sortedStrains) return null;
        return sortedStrains.reduce((acc, strainKey, index) => {
            acc[strainKey] = index + 1;
            return acc;
        }, {}); 
    }
);

const getHumanStrainIndices = createSelector(
    sortedFreqHumanStrainsSelector,
    (sortedStrains) => {
        if (!sortedStrains) return null;
        return sortedStrains.reduce((acc, strainKey, index) => {    
            acc[strainKey] = index + 1;
            return acc;
        }, {});
    }
);

// ---------- (H) Combine Data (Ferret & Human) ----------

export const vaccinesFerretDataSelector = createSelector(
    [
        getSelectedFerretData,
        getAllFerretRefStrainsWithLabAndClade,
        getFerretStrainIndices,
        getCladeIndices,
        getClades
    ],
    (selectedData, refStrains, strainIndices, cladeIndices, clades) => {
        if (!selectedData || !refStrains || !strainIndices || !cladeIndices) {
            return null;
        }
        const refStrainsDict = refStrains.reduce((acc, strain) => {
            acc[strain.refid] = strain;
            return acc;
        }, {});

        return selectedData.map((item) => {
            const { refid, cladeid } = item;
            const { name, antigenicCladeLabel } = refStrainsDict[refid] || {};

            const strainId = vaccineStrainId(item);
            return {
                ...item,
                strainId,
                strainIndex: strainIndices[strainId],
                cladeIndex: cladeIndices[cladeid],
                name,
                antigenicCladeLabel,
                cladeLabel: clades[cladeid]?.label,
                strainLabel: name
            };
        });
    }
);

export const vaccinesHumanDataSelector = createSelector(
    [
        getSelectedHumanData,
        getAllHumanRefStrainsWithLabAndClade,
        getHumanStrainIndices,
        getCladeIndices,
        getClades
    ],
    (selectedData, refStrains, strainIndices, cladeIndices, clades) => {
        if (!selectedData || !refStrains || !strainIndices || !cladeIndices) {
            return null;
        }

        const refStrainsDict = refStrains.reduce((acc, strain) => {
            acc[strain.refid] = strain;
            return acc;
        }, {});

        return selectedData.map((item) => {
            const { refid, lab, season, cladeid } = item;
            const { name, antigenicCladeLabel } = refStrainsDict[refid] || {};

            const strainId = vaccineStrainId(item);
            return {
                ...item,
                strainId,
                strainIndex: strainIndices[strainId],
                cladeIndex: cladeIndices[cladeid],
                name,
                season,
                antigenicCladeLabel,
                cladeLabel: clades[cladeid]?.label,
                strainLabel: season ? `${season} (${lab})` : `${name} (${lab})`
            };
        });
    }
);

export const vaccinesDataSelector = createSelector(
    [vaccinesFerretDataSelector, vaccinesHumanDataSelector],
    (ferretData, humanData) => {
        if (!ferretData && !humanData) return null;
        const data = [];
        if (ferretData) data.push(...ferretData.map(item => ({...item, sera_type: 'ferret', strainIndex: item.strainIndex })));
        const ferretStrainIndexMax = ferretData?.reduce((acc, item) => Math.max(acc, item.strainIndex), 0);
        const incHumanIndex = ferretStrainIndexMax ? ferretStrainIndexMax + 1 : 0;
        if (humanData) data.push(...humanData.map(item => ({...item, sera_type: 'human', strainIndex: item.strainIndex + incHumanIndex })));
        return data;
    }
);


// ---------- (J) Visible & Selected Clades ----------

const getProtectionValues = createSelector(
    [getFerretProtectionValues, getHumanProtectionValues],
    (ferretData, humanData) => {
        if (!ferretData && !humanData) return null;
        const validData = [];
        if (ferretData) validData.push(...ferretData);
        if (humanData) validData.push(...humanData);
        return validData;
    }
);

export const visibleCladesSelector = createSelector(
    getProtectionValues,
    (data) => {
        if (!data) return null;
        return data.reduce((acc, { cladeid }) => {
            acc[cladeid] = cladeid;
            return acc;
        }, {});
    }
);

export const selectedCladesSelector = createSelector(
    [(state) => state.parameters?.vaccinesRhos, visibleCladesSelector],
    (selectedClades, visibleClades) => {
        if (!selectedClades || !visibleClades) return null;
        const selectedSet = new Set(selectedClades);
        return Object.values(visibleClades).reduce((acc, clade) => {
            acc[clade] = selectedSet.has(clade);
            return acc;
        }, {});
    }
);


export const vaccinesfrequenciesStrains = createSelector([getFerretStrainIndices, getHumanStrainIndices, ferretCladeFrequenciesSelector, humanCladeFrequenciesSelector, getAllRefStrains, getClades],
    (ferretStrainIndexes, humanStrainIndexes, ferretFrequencies, humanFrequencies, referenceStrains, clades) => {
 
        const mapData = (strain, indices, indexInc) => {
            const [strain_id, elem] = strain;
            const strainIndex = indices[strain_id] + indexInc;
            const { refid, lab, ...rest } = JSON.parse(strain_id) || {};
            const refStrain = referenceStrains?.[refid] || {};
            const { name, clade } = refStrain;
            const cladeMapping = clades[clade]?.cladeMapping || {};
            const antigenicClade = cladeMapping.antigenic_clade || {};
            const antigenicCladeId = antigenicClade.alpha;
            const antigenicCladeLabel = antigenicCladeId && clades[antigenicCladeId] ? clades[antigenicCladeId].label : '';
            return { strain_id, ...elem, strainIndex, name, lab, ...rest, antigenicCladeLabel };
        };

        const ferretStrains = Object.entries(ferretFrequencies||{})
            .filter(([strain_id]) => ferretStrainIndexes?.[strain_id])
            .map(strain => mapData(strain, ferretStrainIndexes, 0))
            .sort((a, b) => a.strainIndex - b.strainIndex);

        const ferretCnt = Object.keys(ferretStrainIndexes||{}).length+1;
     
        const humanStrains = Object.entries(humanFrequencies||{})
            .filter(([strain_id]) => humanStrainIndexes?.[strain_id])
            .map(strain => mapData(strain, humanStrainIndexes, ferretCnt))
            .sort((a, b) => a.strainIndex - b.strainIndex);
        const frequenciesStrains = [...ferretStrains, ...humanStrains];

        return frequenciesStrains;
    });
