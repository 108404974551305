import { asDate, dateToDays, emptyObject, isNull, monthFirstDate, monthLastDate } from './utils';

export const prepareParamsUrl = (params = {}, exportMode = false) => {
    const dictParams = {}; // branchNodes: true };
    const allowNullParams = { strainCutOffDate: true };

    const disallowedParam = (p) => isNull(params[p]) && !allowNullParams[p];
    const objectParams = { vaccinesModel: true };
    const disallowedObjectParam = (p) => //!objectParams[p] &&
        typeof params[p] === 'object' &&
        !isNull(params[p]) &&
        !(params[p] instanceof Date || params[p] instanceof Array || objectParams[p] || dictParams[p]);

    

    const isObjectParam = (p) => typeof params[p] === 'object' && !isNull(params[p]) && !(params[p] instanceof Date);
    const paramsUrl = Object.keys(params)
        .sort()
        .reduce((tmpUrl, p) => {
            // console.log(`params: ${p} = ${params[p]}, disallowedParam = ${disallowedParam(p)}, disallowedObjectParam = ${disallowedObjectParam(p)}`);
            // console.log(`p = ${params[p]}, ${typeof params[p]}, array = ${params[p] instanceof Array}`);

            if (disallowedParam(p) || disallowedObjectParam(p))
            //(typeof params[p] === 'object' && !(params[p] instanceof Date) && !(params[p] instanceof Array) && !dictParams[p])

                return tmpUrl;
            let val = params[p];
            if (dictParams[p] && typeof params[p] === 'object')
                val = Object.keys(val)
                    .filter((k) => val[k])
                    .join(',');
            else if (params[p] instanceof Date) val = dateToDays(params[p]);
            else if (dateParams[p] && typeof params[p] === 'string' && !exportMode) val = dateToDays(new Date(params[p]));
            else if (params[p] instanceof Array || isObjectParam(p)) val = JSON.stringify(params[p]); //params[p].join(',');


            if (p === 'branchNodes' && params[p] instanceof Array) {
                // console.log(`${JSON.stringify(params[p])}, ${typeof params[p]}`);
                val = params[p] && params[p] instanceof Array ? params[p].join(',') : '';
            }
            const pTxt = `${tmpUrl.length > 0 ? '&' : '?'}${p}=${val}`;
            return `${tmpUrl}${pTxt}`;
        }, '');
    return paramsUrl;
};

export const prepareUrl = (urlTxt, params = {}, exportMode = false) => {
    const paramsUrl = prepareParamsUrl(params, exportMode);
    return `${urlTxt}${paramsUrl}`;
};


export const getParametersObject = (parameters) => {
    const _parameters = Object.keys(parameters).reduce((tmpParameters, p) => {
        if (parameters[p] !== undefined) tmpParameters[p] = parameters[p];
        return tmpParameters;
    }, {});
    return { parameters: _parameters };
};

const transformParamsToUrl = {
    editMode: p => JSON.stringify(p),
    hiddenAlphaClades: (p) => Object.keys(p).toString(),
    hiddenRhoClades: (p) => Object.keys(p).toString(),
    branchNodes: (p) => (p && p.length > 0 ? p.join(',') : ''), //(p ? Object.keys(p).toString() : ''), 
    visibleBins: p => JSON.stringify(p), //Object.keys(p).filter(key => p[key]).toString(),
    visibleMutationClassesLabels: p => JSON.stringify(p),
    visibleMutationClasses: p => JSON.stringify(p),
    calculatedDomain: p => JSON.stringify(p),
    mapParams: p => JSON.stringify(p),
    colorScales: p => JSON.stringify(p),
    selectedModels: p => JSON.stringify(p),
    vaccinesModel: p => JSON.stringify(p),
};


export const transformUrlToParams = {
    hiddenAlphaClades: (p) =>
        p.split(',').reduce((tmp, s) => {
            tmp[s] = true;
            return tmp;
        }, {}),
    hiddenRhoClades: (p) =>
        p.split(',').reduce((tmp, s) => {
            tmp[s] = true;
            return tmp;
        }, {}),
    branchNodes: (p) =>
        p.split(',').reduce((tmp, s) => {
            tmp.push(s);// tmp[s] = true;
            return tmp;
        }, []),
    visibleBins: p => JSON.parse(p),
    // (p) =>
    //     p.split(',').reduce((tmp, s) => {
    //         tmp[s] = true;
    //         return tmp;
    //     }, {}),
    editMode: p => JSON.parse(p),
    exportLegend: (p) => p === 'true',
    showLeaves: (p) => p === 'true',
    searchStrainMode: (p) => p === 'true',
    showInternalNodes: (p) => p === 'true',
    showColoredNodesOnly: (p) => p === 'true',
    showCladeLabels: (p) => p === 'true',
    showMutations: (p) => p === 'true',
    showMutationsGroups: (p) => p === 'true',
    showReferenceStrains: (p) => p === 'true',
    settings: p => p === 'true',
    antigenicTiterType: (p) => `${p}`,
    calculatedDomain: (p) => JSON.parse(p),
    colorScales: p => JSON.parse(p),
    mapParams: p => JSON.parse(p),
    visibleMutationClassesLabels: p => JSON.parse(p),
    visibleMutationClasses: p => JSON.parse(p),
    selectedModels: p => JSON.parse(p),
    vaccinesModel: p => JSON.parse(p)
};

export const dateParams = {
    trackingFrom: true,
    trackingTo: true,
    predictionBaseline: true,
    freqsFrom: true,
    freqsTo: true,
    strainCutOffDate: true,
    date: true, // last updated date,
    submissionDate: true,
    vaccinesTrackedProtectionDate: true,
    vaccinesPredictedProtectionDate: true,
};

export const prepareParameters = (parameters) => {

    const numberParams = {
        zoomNodeId: true,
        refClade: true,
        binCnt: true,
        width: true,
        height: true,
        sigmaAg: true,
        tau: true,
        seqSpan: true,
        freqSpan: true,
        deltaT: true,
        caseSpan: true,
        stdDays: true,
        seqStdDays: true,
        caseStdDays: true,
        showLeafNumber: true,
        cladeFrequencyThreshold: true,
        cladeActiveDays: true,
        mutationsThreshold: true,
        mutposition: true,
        minLogSpace: true,
        version: true,
    };
    const booleanParams = {
        exportMode: true,
        showCladeLabels: true,
        showLeaves: true,
        showInternalNodes: true,
        showColoredNodesOnly: true,
        showMutations: true,
        showMutationsGroups: true,
        showReferenceStrains: true,
        displayErrorBars: true,
        showPrediction: true,
        showVaccines: true,
        showReassortments: true,
        largePDF: true,
        logSpace: true,
        showCladeBar: true,
        showCladeBarLabels: true,
        displayGreyZone: true,
        intro: true,
        showAntigenicTableValues: true,
        useRegionalCorrections: true,
        wrapAntigenicTableHeaders: true,
        showLinks: true
    };
    
    const arrayParams = { 
        vaccinesFerretRefStrains: true, 
        vaccinesHumanRefStrains: true,
        vaccinesRhos: true, 
        refStrain: true
    };
    
    const commaSeparatedParams = { branchNodes: true };
    const params = emptyObject(parameters)
        ? {}
        : Object.keys(parameters).reduce((tmpParams, p) => {
            let val = parameters[p];
            
            if (p in dateParams && !emptyObject(parameters[p])) {
                
                if (dateParams[p] === 'MONTH_FIRST_DAY') val = monthFirstDate(val);
                else if (dateParams[p] === 'MONTH_LAST_DAY') val = monthLastDate(val);
                else val = asDate(val);

                if (val !== 'null') val = val.toISOString();
                else val = null;
            }
            else if (p in numberParams && (typeof val === 'string' || val instanceof String)) {
                if (val === '') val = null;
                else val = parseFloat(val);// 10);
            }
            else if (p in booleanParams && (typeof val === 'string' || val instanceof String)) val = val === 'true';
            else if (p in arrayParams && (typeof val === 'string' || val instanceof String)) val = JSON.parse(val);
            else if (p in commaSeparatedParams && (typeof val === 'string' || val instanceof String))
                val = val.split(',').reduce((acc, k) => {
                    acc[k] = true;
                    return acc;
                }, {});
            
            tmpParams[p] = val;
            return tmpParams;
        }, {});

    return params;
};

// const prepareSelectedModels = (selectedModels, parameters) => {
//     if (parameters.modelRegionId)
//         selectedModels[0].modelRegionId = parameters.modelRegionId;
//     if (parameters.modelType)
//         selectedModels[0].modelType = parameters.modelType;
//     if (parameters.modelId)
//         selectedModels[0].modelId = parameters.modelId;
//     if (parameters.sigmaAg)
//         selectedModels[0].sigmaAg = parameters.sigmaAg;
//     if (parameters.tau)
//         selectedModels[0].tau = parameters.modelId;

//     return selectedModels;
// };


export const prepareParametersToExportUrl = (parameters) =>
    Object.keys(parameters).reduce((tmpParameters, p) => {
        tmpParameters[p] = transformParamsToUrl[p]
            ? transformParamsToUrl[p](parameters[p])
            : parameters[p];
        return tmpParameters;
    }, {});



