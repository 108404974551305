import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { LogoIconDesktop, LogoIconMobile } from '../components/SvgIcons/LogoIcon';
import MenuButton from './MenuButton';
import UserMenu from './userMenu/UserMenu';
import { signOut } from '../redux/actions/userActions';
import { setHiddenMenu, setHiddenMenuMobile } from '../redux/actions/uiActions';
import auth from '../functions/auth-helper';
import LineageSelector from './MenuSelectors/LineageSelectorroV2';
import config from '../config/envConfig';
import MenuOutsideLinks from './MenuOutsideLinks/MenuOutsideLinks';
import HeaderOutsideLinks from './HeaderOutsideLinks/HeaderOutsideLinks';
import { useEventListener } from 'usehooks-ts';
import MenuMobileIcon from './MenuMobileIcon';
import MenuSeparator from './MenuSeparator';
import ModulesSelect from './MenuSelectors/ModulesSelect';
import styles from './styles';
import { getIsMobile } from '../functions/utils';

const headerLinks = [
    { value: '#data_sources', label: 'Data sources', internal: true },
    { value: 'https://previr.com/analysis#previr-app', label: 'Guide'},
    { value: 'https://previr.com/', label: 'Previr Center'}
];

const Header = (props) => {
    const { history, classes, setHiddenMenuMobile, isAuthenticated, isMobileInit, hiddenMenuMobile } = props;
    const [isMobile, setIsMobile] = useState(isMobileInit);
    const location = useLocation();
    const { pathname } = location;
    
    const isExportExpr = `${config.frontendPrefix}/export`;
    const isExportPath = pathname.startsWith(isExportExpr);

    const handleMenuOpenMobile = () => {
        setHiddenMenuMobile({ hiddenMenuMobile: !hiddenMenuMobile });
    };
    
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1000);
    };

    useEventListener('resize', handleResize);
    
    const RouterLink = React.forwardRef((linkProps, ref) => <Link ref={ref} to={`${config.frontendPrefix}/`} {...linkProps} />);
    RouterLink.displayName = 'RouterLink';
    
    if (isExportPath) return null;
    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar className={classes.toolbar} style={{ minHeight: isMobile ? '46px' : '60px'}}>
                <IconButton className={classes.logoButton} component={RouterLink} color="inherit" aria-label="Menu">
                    {isMobile ? <LogoIconMobile /> : <LogoIconDesktop />}
                </IconButton>
                <svg className={isMobile ? classes.lineMobile : classes.line} xmlns="http://www.w3.org/2000/svg" width="1" height="35" viewBox="0 0 1 35" fill="none">
                    <path d="M1 0L1 35" stroke="white" />
                </svg>
                <div className={isMobile ? classes.smallTextMobile : classes.smallTextDesktop}>
                        Predictive analysis of viral evolution
                </div>
                <div className={classes.grow} />
                {isMobile ? (
                    isAuthenticated 
                        ? <UserMenu history={history} isMobile/> 
                        : <MenuOutsideLinks headerLinks={headerLinks}/>
                ) : (
                    <>
                        <HeaderOutsideLinks headerLinks={headerLinks}/>
                        {isAuthenticated //&& modules 
                            ? <UserMenu history={history} /> 
                            : <MenuButton label="Sign in" routeName="signin" defaultOption variant="text" />}
                    </>
                )}
            </Toolbar>
            {
                !pathname.includes('/signin') && !pathname.includes('/settings') && !pathname.includes('/manage') && ( 
                    isMobile 
                        ?
                        <div className={classes.mobileMenu}>
                            {!pathname.includes('/signin') && 
                                <>
                                    <div className={classes.mobileMenuItems}>
                                        <LineageSelector />
                                        <MenuSeparator isMobile={isMobile}/>
                                        <ModulesSelect />
                                    </div>
                                    <MenuMobileIcon handleMenuOpenMobile={handleMenuOpenMobile}/>
                                </>
                            }
                        </div>
                        :
                        <div className={classes.desktopLinkMenu}>
                            <div className={classes.leftButtons}>
                                <LineageSelector />
                                <MenuSeparator isMobile={isMobile}/>
                                <ModulesSelect />
                            </div>
                        </div>
                )
            }
        </AppBar >
    );
};

Header.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }),
    classes: PropTypes.shape({ root: PropTypes.string, grow: PropTypes.string, logoButton: PropTypes.string }),
    modules: PropTypes.shape({
        strainTree: PropTypes.bool,
        predictions: PropTypes.bool,
        clades: PropTypes.bool,
        antigenic: PropTypes.bool,
    }),
    isAuthenticated: PropTypes.bool,
    signOut: PropTypes.func,
};
const mapStateToProps = ({ routes, metadata, user, ui }) => ({
    ...routes,
    modules: metadata.modules,
    isAuthenticated: user.status === 'loaded' && auth.isAuthenticated(),//user.isAuthenticated,
    permissionTabs: user.permissions.tabs,
    hiddenMenu: ui.hiddenMenu,
    hiddenMenuMobile: ui.hiddenMenuMobile,
    isMobileInit: getIsMobile()
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ signOut, setHiddenMenu, setHiddenMenuMobile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
