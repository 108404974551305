import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isNil } from 'lodash';
import { selectNodeData } from '../../redux/actions/nodeActions';
import { setReferenceClade, setReferenceStrain } from '../../redux/actions/parametersActions';
import { getStrainTreeStatus } from '../../redux/selectors/statusSelector';
import { fetchVaccineCandidates } from '../../redux/actions/treeDataActions';
import { emptyObject } from '../../functions/utils';
import { shouldFetch } from '../../functions/data-helpers';
import { prepareUrl } from '../../functions/url-parameters';
import { getLabeledVaccineCandidates } from '../../redux/selectors/strainsSelector';
import { getRawAntigenicReferenceStrains, getLabeledEpitopeCladesStrains } from '../../redux/selectors/antigenicDataSelector';
import { treeAttrsSelector, treeOrderDictSelector } from '../../redux/selectors/treeDataSelector';
import AutocompleteInput from '../Common/AutocompleteInput';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';


const defaultSearchId = 'antigenic';
const TreeAntigenicStrainSearch = ({
    label = 'Reference strain',
    searchId = defaultSearchId,
    lineage,
    vaccineCandidatesStatus,
    antigenicDataType,
    fetchVaccineCandidates,
    strainSearchStatus,
    refStrain,
    refClade,
    strainTreeLoading,
    loading,
    treeAttrs,
    clades,
    cladesStatus,
    initStrainsList,
    setReferenceStrain,
    setReferenceClade,
}) => {


    const shouldFetchOptions = useMemo(
        () => !['epitope_clades', 'raw_strain', 'observed_strain'].includes(antigenicDataType), 
        [antigenicDataType]);
    
    const isCladeLevel = useMemo(
        () => antigenicDataType === 'observed' || antigenicDataType === 'inferred',
        [antigenicDataType]);

    const multiple = useMemo(
        () => antigenicDataType === 'raw_strain' || antigenicDataType === 'observed_strain',
        [antigenicDataType]);

    const [errorTxt, setErrorTxt] = useState(null);
    
    useEffect(() => {
        if (shouldFetch(vaccineCandidatesStatus) && !strainTreeLoading) {
            fetchVaccineCandidates({ lineage });
        }
    }, [vaccineCandidatesStatus, strainTreeLoading, fetchVaccineCandidates, lineage]);

    useEffect(() => {
        if (strainSearchStatus && strainSearchStatus !== 'loading' /*&& strainSearchStatus !== 'none'*/) {
            showAntigenicSelectedStrain(refStrain);
        }
    }, [strainSearchStatus, refStrain]);


    const fetchOptions = useCallback(async (inputValue) => {
        try {
            const url = prepareUrl(`${config.serverLink}/api/tree/search`, {
                lineage,
                strainsTxt: inputValue?.toUpperCase()
            });
            const response = await fetchAxios(url);
            return response.data.strainsList;
       
        } catch (error) {
            console.error('Error fetching reference strains:', error);
            throw error;
        }
    }, [lineage]);



    const showAntigenicSelectedStrain = (id) => {
        if (loading || !id ||strainSearchStatus === 'none') {
            setReferenceClade(null);
            return;
        }

        if (isCladeLevel) {
            // console.log({id, treeAttrs: treeAttrs[id]});
            if (!isNil(id) && !isNil(treeAttrs[id])) {
                const alphaCladeId = clades[treeAttrs[id].clade]?.cladeMapping['antigenic_clade']?.alpha;
                const alpha = cladesStatus === 'loaded' && !emptyObject(clades) && alphaCladeId ? clades[alphaCladeId] : null;
                if (alpha) {
                    setReferenceClade(alphaCladeId);
                    setErrorTxt(null);
                    return;
                }

                setReferenceClade(null);
                setErrorTxt(`No such alpha clade: ${alphaCladeId}`);
                return;
            }
            setReferenceClade(null);
            setErrorTxt('No data for strain');
        }
    };

    const handleSelectedStrain = (selectedStrains) => {

        const refStrain = multiple ? selectedStrains /*.join(',')*/ : [selectedStrains];
        setReferenceStrain(refStrain, searchId);
        setErrorTxt(null);
    };

    const refCladeName = refClade && isCladeLevel
        ? `Clade: ${clades[refClade].label}`
        : null;

    const helperText = refCladeName;

    const fetchOptionLabel = useCallback(async (strainId) => {
        let name = treeAttrs[strainId]?.name;
        if (!name) {
            try {
                const response = await fetchAxios(`${config.serverLink}/api/tree/name/${lineage}/${strainId}`);
                name = response.data.name;
            } catch (error) {
                console.error('Error fetching strain name:', error);
                name = '';
            }
        }

        return name;
    });
    
    const getOptionLabelMemo = useCallback((option) => option?.n ?? '', []);
    const isOptionEqualToValueMemo = useCallback((option, value) => {
        if (!option || !value) return false;
        if (Array.isArray(value)) {
            return value.some(v => v.id == option.id);
        }
        return option.id == value.id;
    }, []);


    return (
        <AutocompleteInput
            id={searchId}
            label={label}
            initialOptions={initStrainsList}
            getOptionLabel={getOptionLabelMemo}
            isOptionEqualToValue={isOptionEqualToValueMemo}
            valueIds={!multiple && refStrain?.length > 0 ? refStrain[0] : refStrain}
            onSelect={handleSelectedStrain}
            fetchOptions={fetchOptions}
            fetchOptionLabel={fetchOptionLabel}
            shouldFetchOptions={shouldFetchOptions}
            multiple={multiple}
            showMinTextLength={false}
            formatId={id => Number(id) || id}
            helperText={helperText}
            errorTxt={errorTxt}
        />
    );
};

TreeAntigenicStrainSearch.propTypes = {
    searchId: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    strainSearchStatus: PropTypes.string,
    fetchStrains: PropTypes.func,
    setReferenceClade: PropTypes.func,
    setReferenceStrain: PropTypes.func,
    selectNodeData: PropTypes.func,
    label: PropTypes.string,
    treeOrderDict: PropTypes.shape({}),
    fetchVaccineCandidates: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    const { antigenicDataType, lineage, colorBy, modelId, zoomNodeId, refStrain, refClade } = state.parameters;
    const searchId = ownProps.searchId || defaultSearchId;
  
    return {
        lineage,
        colorBy,
        modelId,
        zoomNodeId,
        refStrain,
        refClade,
        treeAttrs: treeAttrsSelector(state),
        initStrainsList:
            antigenicDataType === 'epitope_clades'
                ? getLabeledEpitopeCladesStrains(state)
                : (antigenicDataType === 'raw_strain' || antigenicDataType === 'observed_strain')
                    ? getRawAntigenicReferenceStrains(state)
                    : getLabeledVaccineCandidates(state),
        searchedStrainList: state.treeData.strainsLists[searchId],
        treeOrderDict: treeOrderDictSelector(state),
        strainSearchStatus: state.treeData.strainSearchStatuses?.[searchId],
        cladesStatus: state.cladeData.cladesStatus,
        clades: state.cladeData.clades,
        vaccineCandidates: state.metadata.vaccineCandidates,
        vaccineCandidatesStatus: state.metadata.vaccineCandidatesStatus,
        antigenicObservedRefStrains: state.antigenic.antigenicObservedRefStrains,
        antigenicDataType,
        strainsListStatus: state.treeData.strainsListStatuses[searchId] || 'nodata',
        strainTreeLoading: getStrainTreeStatus(state),
        loading:
            state.metadata.vaccineCandidatesStatus === 'loading' ||
            (state.treeData.strainsListStatuses && state.treeData.strainsListStatuses[ownProps.searchId] === 'loading') ||
            state.treeData.strainSearchStatuses[ownProps.searchId] === 'loading',
        selectedRhos: state.antigenic.antigenicClades.selectedRhos,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setReferenceClade,
            setReferenceStrain,
            fetchVaccineCandidates
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(TreeAntigenicStrainSearch);
