import { createSelector } from 'reselect';

const getLineages = (state) => state.lineages.lineages;
// const getUserLineages = (state) =>
//     state.user && state.user.status === 'loaded' ? state.user.lineages : null;
// const getUserDefaultLineage = (state) =>
//     state.user && state.user.status === 'loaded'
//         ? state.user.defaultLineage
//         : null;
// const getLineage = (state) => state.parameters.lineage;
export const lineagesNamesSelector = createSelector(
    getLineages,
    (lineages) => lineages.map(({ lineage }) => lineage)
);

// export const getUserFilteredLineages = createSelector(
//     [getLineages, getUserLineages],
//     (lineages, userLineages) => {
//         if (!userLineages || userLineages.length === 0)
//             return lineages.map((name) => ({ id: name, label: name }));
//         const lineagesDict = lineages.reduce((acc, lineage) => {
//             acc[lineage] = true;
//             return acc;
//         }, {});

//         return userLineages
//             .filter((l) => lineagesDict[l])
//             .map((name) => ({ id: name, label: name }));
//     }
// );

export const lineagesSelector = createSelector([getLineages], (lineages) => {
    return lineages.map(({lineage, modules}) => ({ id: lineage, label: lineage, modules }));
});

// export const getUserLineage = createSelector(
//     [getUserLineages, getUserDefaultLineage, getLineage],
//     (userLineages, defaultLineage, lineage) => {
//         if (!userLineages) return lineage;
//         // return userLineages
//         return defaultLineage;
//     }
// );
