import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';


import { vaccinesDefaultsStatusSelector } from '../../redux/selectors/statusSelector';
import VaccinesChartD3 from './d3/VaccinesChartD3';
import { getNodeWidth } from '../../functions/css-helpers';

import { setComponentStatus } from '../../redux/actions/renderActions';

import { useEventListener } from 'usehooks-ts';
import { useDebouncedCallback } from '../../functions/custom-hooks';
import { sortedFreqFerretStrainsSelector, sortedFreqHumanStrainsSelector, vaccinesDataSelector, vaccinesfrequenciesStrains } from '../../redux/selectors/vaccinesSelector';
import { getIsMobile, getTextMetrics } from '../../functions/utils';
import { setParameters } from '../../redux/actions/parametersActions';
import { setVaccinesPointInfo } from '../../redux/actions/vaccinesActions';

const styles = () => ({
    graph: {
        padding: '0px 20px 20px 20px',
        height: 'calc(100% - 20px)',
    },
    border: {
        border: 'solid 1px #c1c1c1' 
    },
    graphMobile: {
        padding: '2px 0px 20px 0px',
    },
    graphExport: {
        padding: 0,
        height: '100%',
    },
});

const useStyles = makeStyles(styles);

const textFont = `10px 'Inter'`; 
const VaccinesChart = props => {

    const { exportMode, loading, hiddenMenu, isMobile, 
        vaccinesData, vaccinesFerretRefStrains, vaccinesHumanRefStrains, width, frequenciesStrains, /*ferretFrequencies,*/ cladeFrequencies /*, humanFrequencies */ } = props;

  
    const ferretRefStrainsCnt = useMemo(() => vaccinesFerretRefStrains?.length || 0, [vaccinesFerretRefStrains]);
    const humanRefStrainsCnt = useMemo(() => vaccinesHumanRefStrains?.length || 0, [vaccinesHumanRefStrains]);
    // console.log('[VaccinesChart]', { vaccinesFerretRefStrains, refStrainsCnt });
    // console.log('[VaccinesChart] frequencies', frequencies);
    const classes = useStyles();
    const _element = useRef();
    const parentRef = useRef();
    const componentId = 'VACCINES_CHART';
    const chartD3 = useRef(new VaccinesChartD3(componentId));
 
    
    const referenceStrains = useMemo(() => {
        const strains = vaccinesData 
            ?  vaccinesData?.reduce((acc, elem) => {
                if (!acc[elem.strainIndex]) acc[elem.strainIndex] = { 
                    ...elem,
                    textLength: getTextMetrics(elem.strainLabel, textFont).width,
                    cladeTextLength: getTextMetrics(`${elem.antigenicCladeLabel} (${elem.lab})`, textFont).width,
                };
                return acc;
            }, {})
            : {};
        return strains;
    }, [vaccinesData]);

    
    const sortedVaccinesData = useMemo(() => {
        const data = vaccinesData
            ?.sort((a, b) => {
                const freqA = cladeFrequencies[a.cladeid]?.y || 0;
                const freqB = cladeFrequencies[b.cladeid]?.y || 0;
                return a.strainIndex - b.strainIndex || freqA - freqB;
            })
            .map(d => ({ ...d, freq: cladeFrequencies[d.cladeid]?.y || 0 }));
        return data;
    }, [vaccinesData, cladeFrequencies]);

    const maxTextLength = useMemo(() => 
        Math.max(...Object.values(referenceStrains||{})
            .map(strain => Math.max(strain.textLength, strain.cladeTextLength))), 
    [referenceStrains]);

    const d3Props = useMemo(() => ({
        ...props,
        vaccinesData: sortedVaccinesData,
        ferretRefStrainsCnt,
        humanRefStrainsCnt,
        refStrainsCnt: ferretRefStrainsCnt + humanRefStrainsCnt + (ferretRefStrainsCnt && humanRefStrainsCnt ? 1 : 0),
        referenceStrains,
        frequenciesStrains,
        maxTextLength: maxTextLength < 0 ? 0 : maxTextLength
    }), [props, maxTextLength, frequenciesStrains]);


    useEffect(() => {
        chartD3.current.setProps(d3Props);
    }, [d3Props]);

    const updateDimensions = () => {
      
        if (_element.current) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            const _width = getNodeWidth(parentNode);
            const height = chartD3.current.getCalculatedHeight();
            const width = chartD3.current.getCalculatedWidth(_width);
            chartD3.current.resizeComponent(width, height);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        // console.log('debouncedResize', componentId);
        updateDimensions();
    }, 200);


    if (!exportMode) {
        useEventListener('resize', debouncedResize);
    }

    // Modify the initial useEffect to run when width/height are available
    useEffect(() => {
        const mountNode = _element.current;
        chartD3.current.setMountNode(mountNode);
        chartD3.current.setHeight(0);
        chartD3.current.setWidth(0);
        chartD3.current.prepareGraphArea();
    }, []); 

    //const isFirstRender = useRef(true);

    useEffect(() => { 
        chartD3.current.setProps(d3Props);
        if (loading) return;
        // if (isFirstRender.current) {
        //     isFirstRender.current = false;
        //     return;
        // }
        updateDimensions();
    }, [hiddenMenu, ferretRefStrainsCnt, humanRefStrainsCnt, maxTextLength, loading]);


    useEffect(() => {
        if (loading || !vaccinesData) {
            return;
        }
        // updateDimensions();
        chartD3.current.renderD3Component('vaccines', componentId);

    }, [loading, vaccinesData]);        


    const style = exportMode
        ? classes.graphExport
        : isMobile
            ? classes.graphMobile
            : classes.graph;


    return (
        <div className={style} ref={parentRef} style={{width: width}}>
            <svg className={`${classes.border} svg-bg`} id={componentId} ref={_element} />
        </div>
    );
};

// Mapping our Redux State to Props
VaccinesChart.propTypes = {
    loading: PropTypes.bool,
    lineage: PropTypes.string,
};



const mapStateToProps = (state) => {
    const { clades, cladesStatus } = state.cladeData;
    const { vaccineProtectionValues, 
        vaccinesDataStatus, vaccinesFrequenciesStatus, vaccinesPredictionsStatus } = state.vaccines;
    const vaccinesDefaultsStatus = vaccinesDefaultsStatusSelector(state);
    
    const { exportMode } = state.parameters;

    const loading = !(
        cladesStatus === 'loaded' && 
        vaccinesDataStatus === 'loaded' && 
        vaccinesDefaultsStatus === 'loaded' && 
        vaccinesFrequenciesStatus === 'loaded' && 
        vaccinesPredictionsStatus === 'loaded'
    );

    
    return ({
        clades,
        vaccineProtectionValues,
        cladesStatus,
        loading,
        exportMode,
        hiddenMenu: state.ui.hiddenMenu,
        isMobile: getIsMobile(),
        vaccinesData: vaccinesDataSelector(state),
        vaccinesFerretRefStrains: sortedFreqFerretStrainsSelector(state), 
        vaccinesHumanRefStrains: sortedFreqHumanStrainsSelector(state),
        cladesCnt: state.parameters.vaccinesRhos?.length || 0,
        frequenciesStrains: vaccinesfrequenciesStrains(state),
        cladeFrequencies: state.vaccines.vaccinesFrequencies,
        width: state.parameters.width,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setComponentStatus,
    setParameters,
    setVaccinesPointInfo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesChart);
