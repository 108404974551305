import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import { signIn } from '../../redux/actions/userActions';
import { signInStyles } from './styles/signInStyles';
import { WelcomeTitle } from './WelcomeTitle';
import { LoginForm } from './LoginForm';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { resetSession } from '../../redux/actions/sessionActions';
import Register from './Register/Register';
import { getIsMobile } from '../../functions/utils';

const SignIn = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [show, setShow] = useState('login');

    const { error, classes, resetSession, signIn, isMobile } = props;

    useEffect(() => {
        document.body.classList.remove('patient-view');
    }, []);

    const clickSubmit = () => {
        const user = {
            email: email || undefined,
            password: password || undefined
        };
        resetSession();
        signIn(user);
    };

    const handleChange = (e, name) => {
        if (name === 'email') {
            setEmail(e.target.value);
        } else if (name === 'password') {
            setPassword(e.target.value);
        }
        setErrorMsg(null);
    };

    const changeView = (value) => {
        setShow(value);
    };

    const setError = (error) => {
        console.log(error.message);
        setErrorMsg(error.message);
    };

    const err = error || errorMsg;

    return (
        <>
            <Card className={isMobile ? classes.cardMobile : classes.card} elevation={0}>
                <CardContent className={classes.cardContent}>
                    <WelcomeTitle isMobile={isMobile} classes={classes} />
                    {show === 'reset' && (
                        <ResetPassword
                            email={email}
                            handleChange={handleChange}
                            changeView={changeView}
                            setError={setError}
                            isMobile={isMobile}
                        />
                    )}
                    {show === 'login' && (
                        <LoginForm
                            email={email}
                            password={password}
                            err={err}
                            handleChange={handleChange}
                            clickSubmit={clickSubmit}
                            changeView={changeView}
                            isMobile={isMobile}
                        />
                    )}
                    {show === 'register' && (
                        <Register
                            changeView={changeView}
                            isMobile={isMobile}
                        />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

SignIn.propTypes = {
    error: PropTypes.string,
    dispatchSignIn: PropTypes.func,
    classes: PropTypes.shape({ submit: PropTypes.string, error: PropTypes.string, textField: PropTypes.string, title: PropTypes.string, card: PropTypes.string })
};

const mapStateToProps = ({ user }) => ({ ...user, isMobile: getIsMobile() });

const mapDispatchToProps = dispatch => bindActionCreators({
    signIn,
    resetSession
}, dispatch);

export default withStyles(signInStyles)(connect(mapStateToProps, mapDispatchToProps)(SignIn));
