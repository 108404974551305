import { RENDER_STATUS } from '../../../../config/consts';

export const styles =  () => ({
    // Clade Label styles
    cladeLabelBorder: {
        fill: '#ffffff',
        opacity: 0.75,
        stroke: '#4F4F4F',
        strokeWidth: 1,
        rx: 4,
        ry: 4
    },
    cladeLabelText: {
        fontSize: '12px',
        // font-family: "Inter", sans-serif; 
        dominantBaseline: 'text-before-edge',
        cursor: 'pointer',
    },
    cladeLabelAnchorPointCircle: {
        fill: '#ffffff',
        stroke: '#4F4F4F',
        strokeWidth: 1
    },

    // Mutation Label styles
    mutationLabelLink: {
        stroke: '#000000',
        strokeWidth: 1
    },
    mutationLabelBorder: {
        fill: '#ffffff',
        stroke: '#4F4F4F',
        opacity: 0.5,
        strokeWidth: 1,
        rx: 4,
        ry: 4
    },
    mutationLabelText: {
        fontSize: '8px',
        fontFamily: 'Inter',
    },
    mutationLabelSymbol: {
        strokeWidth: 1
    },

    // Generic Label styles
    label: {
        // opacity: props => props.renderStatus === 'DONE' ? 1 : 0,
        // cursor: props => props.isDragging ? 'grabbing' : 'pointer',
        pointerEvents: 'all'
    },
    labelRect: {
        fill: '#ffffff',
        stroke: '#4F4F4F',
        opacity: 0.5,
        strokeWidth: 1
    },
    labelText: {
        fontFamily: 'Inter',
        fontSize: '8px'
    },

    // Mutations Classes Label specific styles
    mutationsClassesLabel: {
        opacity: props => props.renderStatus === RENDER_STATUS.DONE ? 1 : 0,
        cursor: props => props.isDragging ? 'grabbing' : 'pointer',
        pointerEvents: 'all'
    },
    mutationsClassesLabelBorder: {
        fill: '#ffffff',
        stroke: '#4F4F4F',
        opacity: 0.5,
        strokeWidth: 1,
        rx: 4,
        ry: 4
    },
    mutationsClassesLabelText: {
        fontFamily: 'Inter',
        fontSize: '8px'
    }
});
