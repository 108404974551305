import React, { useState } from 'react';
import { Button } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import { postAxios } from '../../../../functions/axios-requests';
import config from '../../../../config/envConfig';
import { fetchCustomTreeSubsets } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubsetDialog from '../Dialogs/SubsetDialog';

const AddSubset = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { lineages, setInfo, setAction, setInfoDialog, fetchCustomTreeSubsets } = props;
    const classes = actionsStyles();

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }; 

    const addNewSubsetRule = async (subset) => {
        try {
            const url = `${config.serverLink}/api/admin/addCustomTreeSubsetRule`;
            const body = {
                lineage: subset.lineage,
                // newSubset: {
                key: subset.key,
                label: subset.label,
                rule: { name: subset.rule },
                showAll: subset.showAll,
                highlightAll: subset.highlightAll
                // }
            };
            await postAxios(url, body);  
            setInfo(`Subset ${subset.key} was added sucessfully.`);         
        } 
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during adding ${subset.key}.: ${message}`);
        }
        finally {
            handleCloseDialog();
            setAction('Add custom subset');
            setInfoDialog(true);
            fetchCustomTreeSubsets();
        }
    };

    const addNewSubsetFile = async (subset, file) => {
        try {
            const newSubset = {
                lineage: subset.lineage,
                key: subset.key,
                label: subset.label,
                showAll: subset.showAll,
                highlightAll: subset.highlightAll
            };
            const url = `${config.serverLink}/api/admin/addCustomTreeSubsetFile`;
            const formData = new FormData();
            formData.append('file', file, 'data.zip');

            for (const el of Object.keys(newSubset))
                formData.append(el, subset[el]);
      
            await postAxios(url, formData);
            setInfo(`Subset ${subset.key} was added sucessfully.`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during adding ${subset.key}.: ${message}`);
        }
        finally {
            handleCloseDialog();
            setAction('Add custom subset');
            setInfoDialog(true);
            fetchCustomTreeSubsets();

        }
    };



    return (
        <>
            <Button className={classes.addButton} onClick={() => setOpenDialog(true)}>
                Add subset
            </Button>
            <SubsetDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} addNewSubsetRule={addNewSubsetRule} addNewSubsetFile={addNewSubsetFile} lineages={lineages} />
        </>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCustomTreeSubsets,
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(AddSubset);
