import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import config from '../config/envConfig';
import { useEventListener } from 'usehooks-ts';
import { getIsMobile } from '../functions/utils';

const button = {
    marginLeft: 20,
    marginRight: 0,
    width: 170,
    height: 42,
    display: 'flex',
    padding: '2px',
    maxHeight: 42,
    minHeight: 32,
    boxShadow: 'none',
    textTransform: 'none',
    color: '#FFFFFF',
    alignItems: 'center'
};

const buttonMobile = {
    marginLeft: 10,
    marginRight: 5,
    width: '46%',
    height: 82,
    display: 'flex',
    padding: '2px',
    fontSize: '10px',
    maxHeight: 32,
    minHeight: 32,
    boxShadow: 'none',
    textTransform: 'none',
    border: '1px solid #b5b3b3',
    color: '#FFFFFF',
    alignItems: 'center'
};

const styles = {
    root: {
        boxShadow: 'none',
    },
    menuButton: {
        ...button,
        borderColor: '#b5b3b3',
        boxShadow: 'none',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    menuButtonMobile: {
        ...buttonMobile,
        borderColor: '#b5b3b3',
        boxShadow: 'none',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    selected: {
        ...button,
        backgroundColor: '#6F6CFF',
        boxShadow: 'none',
        color: '#EEEEEE',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            boxShadow: 'none',
            borderColor: 'white',
            color: '#FFFFFF',
        },
    },
    selectedMobile: {
        ...buttonMobile,
        backgroundColor: '#6F6CFF',
        boxShadow: 'none',
        color: '#EEEEEE',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            boxShadow: 'none',
            borderColor: 'white',
            color: '#FFFFFF',
        },
    },
    menuButtonHover: {
        ...button,
        width: 'auto',
        paddingTop: '12px',
        borderColor: '#b5b3b3',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        }
    },
    noBorderStyle: {
        border: 'none',
        '&:hover': {
            border: 'none',
        }
    },
    menuTitle: {
        padding: '2px 5px 0 5px',
        textWrap: 'auto',
        lineHeight: '12px',
        textAlign: 'center'
    },
    marginButton: {
        marginBottom: 10
    }
};



const MenuButton = (props) => {
    const { routeName, label, defaultOption, classes, variant, onClick, internalLink, externalLink } = props;
    const [isMobile, setIsMobile] = useState(getIsMobile());

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1000);
    };
    useEventListener('resize', handleResize);

    const location = useLocation();
    const routeLink = internalLink || externalLink || `${config.frontendPrefix}/${routeName}`;

    const RouterLink = React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={routeLink} {...linkProps} />
    ));
    // const ExternalRouterLink = (props) => <a href={routeLink} target="_blank" rel="noopener noreferrer" {...props} />; 
    const ExternalRouterLink = React.forwardRef((props, ref) => (
        <a ref={ref} href={props.href} target="_blank" rel="noopener noreferrer" {...props} />
    ));

    const InternalRouterLink = React.forwardRef((props, ref) => (
        <a ref={ref} href={props.href} {...props} />
    ));

    RouterLink.displayName = 'RouterLink';
    ExternalRouterLink.displayName = 'ExternalRouterLink';
    InternalRouterLink.displayName = 'InternalRouterLink';

    const selectedItem = (routeLink === location.pathname) || (location.pathname === '/' && defaultOption);
    const buttonVariant = variant || (selectedItem ? 'contained' : 'outlined');

    return (
        <Button
            id={routeName}
            variant={buttonVariant}
            component={internalLink ? InternalRouterLink : (externalLink ? ExternalRouterLink : RouterLink)}
            href={routeLink}
            className={`${variant === 'text' ? classes.menuButtonHover : (selectedItem ? isMobile ? classes.selectedMobile : classes.selected : isMobile ? classes.menuButtonMobile : classes.menuButton)} ${classes.marginButton}`}
            // style={ routeName === 'intro' ? { marginLeft: '10px' } : {} }
            classes={props.noborder ? { root: classes.noBorderStyle } : {}}
            onClick={onClick}
        >
            <Typography style={{ textTransform: 'none', fontSize: isMobile ? '10px' : '15px'}} className={classes.menuTitle} noWrap>
                {label}
            </Typography>
        </Button>
    );
};

MenuButton.propTypes = {
    routeName: PropTypes.string,
    label: PropTypes.string,
    defaultOption: PropTypes.bool,
    noborder: PropTypes.bool,
    location: PropTypes.shape({ pathname: PropTypes.string }),
    classes: PropTypes.shape({ menuTitle: PropTypes.string, menuButton: PropTypes.string }),
    onClick: PropTypes.func
};

export default withStyles(styles)(MenuButton);
