import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize } from 'lodash';

// Redux
import { setParameters, setMeasureScale } from '../../../redux/actions/parametersActions';
import { getComplexDomainParameterValues, getMetadataMeasuresWithScales } from '../../../redux/selectors/rangeDataSelector';
import { discreteMeasureScalesDomainsSelector } from '../../../redux/selectors/metadataSelector';

// Utils
import { getMeasureScaleParamName } from '../../../functions/data-helpers';

// Components
import { ColorScaleSelectorBase } from './ColorScaleSelectorBase';

const ColorScaleSelector = (props) => {
    const { measureDomains, measureName, scales, params,
        setMeasureScale, defaultScaleName, discrete, isTimeScale, paramValue, paramsScaleName, resetData } = props;

    const scalesOptions = useMemo(() => {
        return Object.keys(measureDomains).filter(k => !paramValue || k.match(`^${measureName}\.${paramValue}`));
    }, [measureDomains, measureName, paramValue]);

    const scalesLength = scalesOptions.length;

    useEffect(() => {
        !discrete && defaultScaleName && paramsScaleName !== defaultScaleName && setMeasureScale({ measure: measureName, scaleName: defaultScaleName, parameters: params });
    }, [measureName]);

    useEffect(() => {
        if (!discrete && paramsScaleName !== defaultScaleName) {
            setMeasureScale({ measure: measureName, scaleName: defaultScaleName, parameters: params });
        }
    }, [defaultScaleName]);

    const handleChange = (colorScale) => {
        setMeasureScale({ measure: measureName, scaleName: colorScale, parameters: params });
        if (resetData) resetData();
    };

    return (
        <>
            {
                scalesLength > 0 && scalesOptions.includes(defaultScaleName) && !discrete &&
                    <ColorScaleSelectorBase 
                        id={`${measureName}_scale`}
                        label='Scale'
                        value={defaultScaleName}
                        onChange={handleChange}
                        options={scalesOptions}
                        getOptionLabel={(scale)=> capitalize(scale.split('.').at(-1))}
                        getOptionValue={scale => scale}
                        scales={scales}
                        measureDomains={measureDomains}
                        isTimeScale={isTimeScale}
                        disabled={scalesOptions.length < 2}
                    />  
            }
        </>
    );
};

ColorScaleSelector.propTypes = {
    measureDomains: PropTypes.object.isRequired,
    measureName: PropTypes.string.isRequired,
    scales: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    setMeasureScale: PropTypes.func.isRequired,
    defaultScaleName: PropTypes.string,
    discrete: PropTypes.bool,
    isTimeScale: PropTypes.bool,
    paramValue: PropTypes.string,
    paramsScaleName: PropTypes.string,
    resetData: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    // Extract parameters from state
    const { colorBy, exportMode } = state.parameters;
    const { scales, measureScalesDomains: allMeasureScalesDomains } = state.metadata;
    
    // Process measure name and discrete flag
    const measureName = ownProps.colorBy || colorBy;
    const onlyDiscrete = ownProps.onlyDiscrete || false;

    // Get measure scales domains based on discrete flag
    const measureScalesDomains = onlyDiscrete 
        ? discreteMeasureScalesDomainsSelector(state) 
        : allMeasureScalesDomains;

    // Get measures and parameters
    const measures = getMetadataMeasuresWithScales(state);
    const params = getComplexDomainParameterValues(state);

    // Process selected measure
    const selectedMeasure = measures[measureName] || {};
    const paramName = getMeasureScaleParamName(state.metadata.measures[measureName]);
    const paramValue = paramName ? params[paramName] : undefined;
    const measureDomains = measureScalesDomains[measureName] || {};

    // Determine scale name and measure properties
    // console.log('mapStateToProps', { measureName, selectedMeasure });
    const defaultScaleName = measureDomains[selectedMeasure.scale?.scaleName] 
        ? selectedMeasure.scale.scaleName 
        : Object.keys(measureDomains)[0];
    const discrete = selectedMeasure.discrete || false;
    const isTimeScale = selectedMeasure.time || false;
    const paramsScaleName = state.parameters.colorScales[measureName];

    return {
        measureName,
        scales,
        measureScalesDomains,
        measureDomains,
        params,
        defaultScaleName,
        discrete,
        isTimeScale,
        paramValue,
        exportMode,
        paramsScaleName
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setMeasureScale,
    }, dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ColorScaleSelector);
