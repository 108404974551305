import { produce } from 'immer';
// Action Types
import {
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_ERROR,
    SIGNOUT_REQUEST,
    SIGNOUT_ERROR,
    GET_SIGNED_USER_REQUEST,
    GET_SIGNED_USER_SUCCESS,
    GET_SIGNED_USER_ERROR,
    SET_USER_PREFERENCES,
    SIGNOUT_SUCCESS,
    RESET_SESSION,
    SET_SESSION_TIMEOUT
} from '../actions/actionTypes';
import appConfig from '../../config/appConfig';
// import { emptyObject } from '../../functions/utils';
import defaults from '../../components/Admin/Roles/defaults';

let userInitialState = {};
export const setUserInitialState = (state) => {
    userInitialState = state;
};

export const userReducer = (state = userInitialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case RESET_SESSION: {
                if (!action.payload?.lineage) {
                    return userInitialState;
                }   
                break;
            }
            case SIGNIN_REQUEST:
            case GET_SIGNED_USER_REQUEST: {
                draft.status = 'loading';
                draft.error = null;
                break;
            }
            case SET_USER_PREFERENCES: {
                const { menuRight, defaultLineage, username } = action.payload;
                draft.menuRight = menuRight;
                draft.defaultLineage = defaultLineage;
                draft.username = username;
                break;
            }
            case SIGNIN_SUCCESS:
            case GET_SIGNED_USER_SUCCESS: {
                const { user } = action.payload;
                const { defaultLineage, lineages, permissions, role, username, menuRight, email } = user;
                let defaultLineageSettled = defaultLineage || appConfig.default.lineage;
           
                if (lineages && lineages.length > 0 && !lineages.map(({lineage}) => lineage).includes(defaultLineageSettled))
                    defaultLineageSettled = lineages[0].lineage;
            
                const mergePermissions = (role) => {
                    const mergeDefaults = (key) => {
                        return  ({ ...defaults[key], ...role[key] });
                    };
                    role.antigenicPermissions = mergeDefaults('antigenicPermissions');
                    role.frequenciesPermissions = mergeDefaults('frequenciesPermissions');
                    role.mapPermissions = mergeDefaults('mapPermissions');
                    role.strainTreePermissions = mergeDefaults('strainTreePermissions');

                    return role;

                };
                draft.status = email ? 'loaded' : 'none';
                draft.email = email;
                draft.defaultLineage = defaultLineageSettled;
                draft.lineages = lineages;
                draft.role = role;
                draft.username = username;
                draft.permissions = mergePermissions(permissions);
                draft.menuRight = menuRight;
                draft.error = null;
                break;
            }
            case SIGNIN_ERROR:
            case GET_SIGNED_USER_ERROR: {
                const { error } = action.payload;
                draft.status = 'error';
                draft.email = null;
                draft.defaultLineage = null;
                draft.error = error.message;
                break;
            }

            case SIGNOUT_REQUEST: {
                draft.status = 'signingOut';
                draft.error = null;
                draft.defaultLineage = null;
                break;
            }
            case SIGNOUT_SUCCESS: {
                Object.keys(draft).forEach(key => {
                    draft[key] = userInitialState[key];
                });
                draft.status = 'signedOut';
                break;
            }
            case SET_SESSION_TIMEOUT: {
                if (draft.status === 'loaded') {
                    draft.status = 'signingOut'; //temporary status for signed in users, user needs to click on the button on the alert to sign out
                }
                break;
            }
          
            case SIGNOUT_ERROR: {
                draft.status = 'error';
                draft.defaultLineage = null;
                break;
            }
          
            default:
                break;
        }
    });
