import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setParameters } from '../../redux/actions/parametersActions';
import { StyledRegionalFreqButton } from './StyledRegionalFreqButton';
import { prepareParametersToExportUrl } from '../../functions/url-parameters';

const mapStateToProps = ({ parameters }) => {
    const _parameters = prepareParametersToExportUrl(parameters);
    return ({
        parameters: _parameters
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StyledRegionalFreqButton);
