import React, { useState } from 'react';
import { Button } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import LineageRegionDialog from '../Dialogs/LineageRegionDialog';
import { postAxios } from '../../../../functions/axios-requests';
import config from '../../../../config/envConfig';
import { fetchLineageRegions } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const AddLineageRegion = ({ currentLineage, setInfo, setInfoDialog, setAction, fetchLineageRegions }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const addNewLineageRegion = async (newRegion) => {
        try {
            const url = `${config.serverLink}/api/admin/addLineageRegion`;
            const body = {
                lineage: currentLineage,
                newRegion
            };
            await postAxios(url, body);
            setInfo(`Region ${newRegion.key} for lineage: ${currentLineage} was added sucessfully.`);

        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during adding ${newRegion.key}: ${message}.`);
        }
        finally {
            handleCloseDialog();
            setAction('Add lineage region');
            setInfoDialog(true);
            fetchLineageRegions();
        }
    };

    return (
        <>
            <Button className={classes.addButton} onClick={() => setOpenDialog(true)}>
                Add region
            </Button>
            <LineageRegionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} addNewLineageRegion={addNewLineageRegion} />
        </>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchLineageRegions
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(AddLineageRegion);
