import { makeStyles } from '@mui/styles';

export const actionsStyles = makeStyles(() =>({
    button: {
        padding: '0px'
    },
    add: {
        marginLeft: '10px'
    },
    addButton: {
        margin: '15px',
        backgroundColor: '#6F6CFF',
        color: 'white',
        padding: '5px 12px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    }
}));
