import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import ConfirmDialog from '../../ConfirmDialog';
import { postAxios } from '../../../../functions/axios-requests';

const DeleteRole = (props) => {
    const { roleName, setLoaded, setInfoDialog, setInfo, setAction } = props;
    const [openDialog, setOpenDialog] = useState(false);

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };



    const deleteRole = async () => {
        try {
            const url = `${config.serverLink}/api/admin/deleteRole`;
            const body = { name: roleName };
            await postAxios(url, body);
            setInfo(`Role ${roleName} deleted sucesfully.`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during deleting "${roleName}": ${message}`);
        }
        finally {
            setAction('Delete role');
            setLoaded(false);
            handleCloseDialog();
            setInfoDialog(true);
        }
    };
    return (
        <>
            <IconButton onClick={handleClick}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog email={roleName} handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteRole} role={true}/>
        </>

    );
};

export default DeleteRole;
