
import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableContainer, TableHead, TableRow,
    TablePagination, Button,
} from '@mui/material';

import AddSubsetAction from './actions/subset/AddSubsetAction';
import SubsetCells from './actions/subset/SubsetCells';

import ScalesCells from './actions/scales/ScalesCells';
import AddScaleAction from './actions/scales/AddScaleAction';

import AddMutationClassAction from './actions/mutationClasses/AddMutationClassAction';
import MutationClassesCells from './actions/mutationClasses/MutationClassesCells';

import RegionsCells from './actions/regions/RegionsCells';
import AddNewRegionAction from './actions/regions/AddNewRegionAction';

import AddMeasureAction from './actions/measures/AddMeasureAction';
import MeasuresCells from './actions/measures/MeasuresCells';

import TableHeader from './elements/TableHeader';
import TableSearchElement from './elements/TableSearchElement';
import { styles } from './styles';
import Info from '../../pages/SettingsPanel/Info';
import { getBiggerForSortTable } from '../../functions/utils';

const CustomTable = (props) => {
    const { searchOptions, orderInitState, headers, tableData, searchState, searchLabels, name, currentLineage } = props;
    const classes = styles();
    const [orderBy, setOrderBy] = useState({ ...orderInitState, key: 'asc' });
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');
    const [searched, setSearched] = useState({ ...searchState });

    useEffect(() => {
        const sorted = sortData([...tableData], headers[0].name, 'asc');
        setDisplayedTableData(sorted);
        filterafterSearchClicked(searched);
    }, [tableData]);

    const filterafterSearchClicked = (updatedSearched) => {
        let filteredRows = [...tableData];

        Object.keys(searchState).map((element) => {
            const searchValue = updatedSearched !== undefined ? updatedSearched[element] : searched[element];

            if (searchValue !== 'all' && searchValue)
                filteredRows = filteredRows.filter((row) => {
                    return row[element].toLowerCase().includes(searchValue.toLowerCase());
                });
        });

        setDisplayedTableData(filteredRows);
        setPage(0);
    };

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e, type) => {
        const updatedSearched = { ...searched, [type]: e ? e : '' };
        setSearched(updatedSearched);
        filterafterSearchClicked(updatedSearched);
    };

    const clearField = (type) => {
        setSearched(prevState => {
            const updatedSearched = { ...prevState, [type]: '' };
            filterafterSearchClicked(updatedSearched);
            return updatedSearched;
        });
    };

    const cancelSearch = () => {
        setSearched({ ...searchState });
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc';

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted);
    };

    const cellComponentDict = {
        subsets: { component: SubsetCells, action: AddSubsetAction },
        scales: { component: ScalesCells, action: AddScaleAction }, 
        mutClasses: { component: MutationClassesCells, action: AddMutationClassAction },
        regions: { component: RegionsCells, action: AddNewRegionAction},
        measures: { component: MeasuresCells, action:  AddMeasureAction }
    };
    

    const CellComponent = cellComponentDict[name].component;
    const ActionComponent = cellComponentDict[name].action;

    return (
        <>
            <div className={classes.searchcont}>
                {Object.keys(searchState).map(searchElement => (
                    <TableSearchElement
                        key={`search-${searchElement}`}
                        options={searchOptions[searchElement].sort()}
                        name={searchElement}
                        value={searched[searchElement]}
                        requestSearch={requestSearch}
                        label={searchLabels[searchElement]}
                        clearField={clearField}
                    />
                ))}
            </div>
            <Button className={classes.saveButton} onClick={cancelSearch}>
                Reset
            </Button>
            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {headers.map(header => (
                                <TableHeader
                                    key={`header-${header.name}`}
                                    handleSort={handleSort}
                                    label={header.label}
                                    name={header.name}
                                    order={orderBy[header.name]}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (rowsPerPage > 0
                                ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : displayedTableData
                            ).map((row, index) => (
                                <TableRow key={`${row.key}_${index}`} >
                                    <CellComponent 
                                        row={row}
                                        setInfo={setInfo}
                                        setInfoDialog={setInfoDialog}
                                        setAction={setAction}
                                        currentLineage={currentLineage}
                                    />
                                </TableRow>
                            ))
                        }
                        <ActionComponent  
                            setInfo={setInfo}
                            setInfoDialog={setInfoDialog}
                            setAction={setAction}
                            currentLineage={currentLineage}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={displayedTableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </>
    );
};

export default CustomTable;
