import { dateToReduxDateFormat } from '../../functions/utils';
import { dateParams } from '../../functions/url-parameters';

const dateToStringMiddleware = () => next => action => {
    const transformDates = obj => {
        for (const key in obj) {
            if (obj[key] instanceof Date && dateParams[key]) {
                obj[key] = dateToReduxDateFormat(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                transformDates(obj[key]);
            }
        }
    };

    if (action.payload) {
        transformDates(action.payload);
    }

    return next(action);
};

export default dateToStringMiddleware;
