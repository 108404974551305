
import React from 'react';
import { TableRow } from '@mui/material';
import { connect } from 'react-redux';
import AddNewScale from '../../../../pages/SettingsPanel/Scales/Actions/AddNewScale';

const AddScaleAction = (props) => {
    const { setInfo, setInfoDialog, setAction, colorScales, scalesPalette } = props;

    return (
        <TableRow>
            <td>
                <AddNewScale
                    colorScales={colorScales}
                    scalesPalette={scalesPalette}
                    setInfo={setInfo}
                    setInfoDialog={setInfoDialog}
                    setAction={setAction}
                />
            </td>
        </TableRow>
    );
};

const mapStateToProps = (state) => {
    const { colorScales, scalesPalette } = state.settings;

    return {
        colorScales,
        scalesPalette,
    };
};

export default connect(mapStateToProps)(AddScaleAction);
