import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { prepareUrl } from '../../functions/url-parameters';

export const fetchModelTypes = async ( lineage, modelRegionId ) => {
    try {
        const colorBy = 'fitness';
        const url = prepareUrl(`${config.serverLink}/api/models/types`, { lineage, modelRegionId, colorBy });
        // console.log('[fetchModelTypes] url', url);
        const result = await fetchAxios(url);
        const modelTypes = result.data.modelTypes || [];
        return [ '', ...modelTypes ];
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const fetchModels = async (lineage, modelRegionId, modelType) => {
    try {
        const colorBy = 'fitness';
        const url = prepareUrl(`${config.serverLink}/api/models`, { lineage, colorBy, modelRegionId, modelType });
        const result = await fetchAxios(url);
        const models = result.data.models || [];
        return ['', ...models];    

    } catch (error) {
        console.error(error);
        return [];
    }
};

export const checkModelState = (model, modelTypeOptions, modelIdOptions) => { 
    let good = true;
    if (!model.modelId || !model.modelType)
        good = false;
    if (!modelTypeOptions?.includes(model.modelType) || !modelIdOptions?.includes(model.modelId))
        good = false;

    return good;
};

