import React, { useState } from 'react';
import { Button } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import { postAxios } from '../../../../functions/axios-requests';
import config from '../../../../config/envConfig';
import { fetchMutationClassesArray } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MutClassDialog from '../Dialogs/MutClassDialog';

const AddNewMutationClass = ({ lineages, setInfo, setInfoDialog, setAction, mutationGenes, fetchMutationClassesArray }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    // const handleClick = () => {
    //     setOpenDialog(true);
    // };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const addMutClass = (body,) => {
        const keys = Object.keys(body.positions);
        let result = {};

        keys.forEach(key => {
            body.positions[key].forEach(range => {
                for (let i = range.min; i <= range.max; i++) {
                    result[i] = true;
                }
            });
            body.positions[key] = result;
            result = {};
        });

        const url = `${config.serverLink}/api/admin/addMutationClass`;
        postAxios(url, { mutClass: { ...body } }).then((response) => {
            if (response.data.status) {
                handleCloseDialog();
                setInfoDialog(true);
                setAction('Update mutation class');
                fetchMutationClassesArray();
                setInfo(`Mutation class ${body.name} was added sucessfully`);
            } else {
                handleCloseDialog();
                setInfoDialog(true);
                setAction('Update mutation class');
                fetchMutationClassesArray();
                setInfo(`Mutation class ${body.name} wasn't added`);
            }
        });
    };

    return (
        <>
            <Button className={classes.addButton} onClick={() => setOpenDialog(true)}>
                Add mutation class
            </Button>
            <MutClassDialog mutationGenes={mutationGenes} lineages={lineages} addMutClass={addMutClass} handleCloseDialog={handleCloseDialog} openDialog={openDialog} isAdd={true} />
        </>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMutationClassesArray
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(AddNewMutationClass);
