// Action Types
import {
    FETCH_VACCINE_PROTECTION_VALUES_REQUEST,
    FETCH_VACCINE_PROTECTION_VALUES_SUCCESS,
    FETCH_VACCINE_PROTECTION_VALUES_ERROR,
    FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST,
    FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS,
    FETCH_VACCINE_DEFAULT_SELECTIONS_ERROR,
    RESET_VACCINES_DATA,
    TOGGLE_SELECTED_VACCINE_CLADES,
    REVERT_SELECTED_VACCINE_CLADES,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_SUCCESS,
    FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_ERROR,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_SUCCESS,
    FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_ERROR,
    RESET_VACCINES_FREQUENCIES,
    RESET_VACCINES_PREDICTIONS,
    RESET_VACCINE_DEFAULT_SELECTIONS,
    SET_VACCINES_POINT_INFO
} from './actionTypes';

// Base Actions
import { received, request } from '../baseActions';

export const fetchVaccineProtectionValues = (lineage, vaccinesSerumType) => received(FETCH_VACCINE_PROTECTION_VALUES_REQUEST, { lineage, vaccinesSerumType });
export const fetchVaccineProtectionValuesSuccess = (res) => received(FETCH_VACCINE_PROTECTION_VALUES_SUCCESS, res);
export const fetchVaccineProtectionValuesError = (error) => received(FETCH_VACCINE_PROTECTION_VALUES_ERROR, error);

export const fetchVaccineDefaultSelections = (lineage, vaccinesSerumType) => received(FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST, { lineage, vaccinesSerumType });
export const fetchVaccineDefaultSelectionsSuccess = (res) => received(FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS, res);
export const fetchVaccineDefaultSelectionsError = (error) => received(FETCH_VACCINE_DEFAULT_SELECTIONS_ERROR, error);
export const resetVaccineDefaultSelections = () => received(RESET_VACCINE_DEFAULT_SELECTIONS);

export const resetVaccinesData = () => received(RESET_VACCINES_DATA);
export const resetVaccinesFrequencies = () => received(RESET_VACCINES_FREQUENCIES);
export const resetVaccinesPredictions = () => request(RESET_VACCINES_PREDICTIONS);
export const toggleSelectedVaccineClades = (cladeId) => received(TOGGLE_SELECTED_VACCINE_CLADES, { cladeId });
export const revertSelectedVaccineClades = (selectedClades) => received(REVERT_SELECTED_VACCINE_CLADES, { selectedClades });

export const fetchVaccineAntigenicCladeFrequencies = (payload) => received(FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_REQUEST, payload);
export const fetchVaccineAntigenicCladeFrequenciesSuccess = (res) => received(FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_SUCCESS, res);
export const fetchVaccineAntigenicCladeFrequenciesError = (error) => received(FETCH_VACCINE_ANTIGENIC_CLADE_FREQUENCIES_ERROR, error);

export const fetchVaccineAntigenicCladePredictions = (payload) => received(FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_REQUEST, payload);
export const fetchVaccineAntigenicCladePredictionsSuccess = (res) => received(FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_SUCCESS, res);
export const fetchVaccineAntigenicCladePredictionsError = (error) => received(FETCH_VACCINE_ANTIGENIC_CLADE_PREDICTIONS_ERROR, error);

export const setVaccinesPointInfo = (pointInfo) => received(SET_VACCINES_POINT_INFO, pointInfo);
// const fetchModel = (payload) => received(FETCH_MODEL_DATA_REQUEST, payload);
// const fetchModelSuccess = (res) => received(FETCH_MODEL_DATA_SUCCESS, res);
// const fetchModelError = (error) => received(FETCH_MODEL_DATA_ERROR, error);

