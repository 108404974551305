// React imports
import React, { useRef } from 'react';

// Redux imports
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectNodeData } from '../../../../redux/actions/nodeActions';
import { setLabelMovement } from '../../../../redux/actions/renderActions';

// MUI imports
import { makeStyles } from '@mui/styles';
import { styles } from './styles';

// Custom imports
import { treeD3 } from '../../d3/TreeD3';
import { useEventListener } from 'usehooks-ts';
import { getSymbolPosition } from './MutationsClassesLabelSymbols';
import { RENDER_STATUS } from '../../../../config/consts';
import { useDrag } from '../../hooks/useDrag';

const useStyles = makeStyles(styles);



// Constants
const rMargin = 3;
export const w = 8;
export const h = 4;

export const defaultXYPositions = (id, index, labelWidth, labelHeight, startPos, symbolsNumber, number) => {
    const symbolPosition = getSymbolPosition(id, startPos, symbolsNumber, number);
    // console.log('[defaultXYPositions] symbolPosition', {id, symbolPosition});
    const x = symbolPosition.xAnchor;
    const y = symbolPosition.yAnchor;
    return { x: x - (labelWidth || 0) / 2, y: y + (labelHeight / 2) + rMargin + ((index + 1) * (labelHeight || 0)) };
};

const MutationsClassesLabelText = (props) => {

    const _element = useRef();

    const { id, classNamePrefix, mutClass, mutations, xAnchor, yAnchor, x, y, labelHeight, labelWidth, renderStatus,
        setLabelMovement } = props;

    const classes = useStyles();

    const handleDrag = (data) => {
        setLabelMovement({ id, type: classNamePrefix, subType: mutClass, xMod: data.x, yMod: data.y });
    };

    const { isDragging, dragOffset, onMouseDown } = useDrag(handleDrag, _element.current);

    const xMove = (x || xAnchor) - xAnchor + dragOffset.x;
    const yMove = (y || yAnchor) - yAnchor + dragOffset.y;

    return (
        <g id={`${classNamePrefix}_${id}`} 
            className={classes.mutationsClassesLabel} 
            ref={_element}
            transform={`translate(${xMove}, ${-labelHeight + yMove})`}
            style={{
                opacity: renderStatus === RENDER_STATUS.DONE ? 1 : 0,
                cursor: isDragging ? 'grabbing' : 'pointer'
            }}
            onMouseDown={onMouseDown}
        >
            <rect className={classes.mutationsClassesLabelBorder}
                height={labelHeight} 
                width={labelWidth}
            />
            <text className={classes.mutationsClassesLabelText}
                transform={`translate(${w / 2}, ${h / 2 + labelHeight / 2})`}>
                <tspan textAnchor="start">{mutations}</tspan>
            </text>
        </g>
    );
};

const MutationsClassesLabel = (props) => {
    const { id, xAnchor, yAnchor } = props;

    const _element = useRef();

    const clearSelectedNode = () => {
        if (!treeD3.selectedNode) props.selectNodeData();
    };
    const selectedNode = () => {
        if (!treeD3.selectedNode) props.selectNodeData({ nodeId: +id });
    };

    useEventListener('mouseover', selectedNode, _element);
    useEventListener('mouseleave', clearSelectedNode, _element);

    return (
        <g ref={_element} transform={`translate(${xAnchor}, ${yAnchor})`}>
            <g>
                <MutationsClassesLabelText {...props}/>
            </g>
        </g>
    );
};

const mapStateToPropsLabel = (state, { id, mutClass, classNamePrefix }) => {
    const labelPos = state.render.labels?.[classNamePrefix]?.[`${id}_${mutClass}`] || {};
    const renderStatus = state.render.viewToRender?.components?.mutationsClasses || RENDER_STATUS.NONE;
    const { x, y, xMod, yMod, labelWidth, labelHeight, xAnchor, yAnchor, initialized } = labelPos;

    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        x,
        y,
        xMod: xMod || 0,
        yMod: yMod || 0,
        labelWidth: labelWidth || 0,
        labelHeight: labelHeight || 0,
        xAnchor: xAnchor || 0,
        yAnchor: yAnchor || 0,
        initialized,
        renderStatus
    };
};

const mapDispatchToPropsLabel = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setLabelMovement
        },
        dispatch,
    );

const _MutationClassesLabel = connect(mapStateToPropsLabel, mapDispatchToPropsLabel)(MutationsClassesLabel);

const MutationsClassesLabels = (props) => {
    const { id, muts, ...otherProps } = props;

    return (
        <g>
            {muts.filter(({ showLabel }) => showLabel)
                .map(({ mutClass, mutations, startPos, number }, index) => (
                    <_MutationClassesLabel
                        key={`text_${mutClass}_${id}`}
                        id={id}
                        mutClass={mutClass}
                        mutations={mutations}
                        index={index}
                        startPos={startPos}
                        number={number}
                        {...otherProps}
                    />
                ))}
        </g>
    );
};

export default MutationsClassesLabels;
