import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router';
import { useEventListener } from 'usehooks-ts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import appConfig from '../../config/appConfig';
import { resetSession } from '../../redux/actions/sessionActions';
import { lineagesSelector } from '../../redux/selectors/lineagesSelector';
import { treeD3 } from '../../components/Tree/d3/TreeD3';
import { styles } from './styles';
import Grid from '@mui/material/Grid2';
import { getIsMobile } from '../../functions/utils';

const LineageSelector = ({ resetSession, lineage, lineages, classes, lineagesStatus }) => {
    const [pathogen, setPathogen] = useState('');
    const [openList, setOpenList] = useState(false);
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const buttonRef = useRef(null);
    const listRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const pathname = `/${useLocation().pathname.split('/').pop()}`;
    const module = appConfig.pathnameToModule[pathname];

    const lineageModuleDict = useMemo(() => {
        return lineages.reduce((acc, item) => {
            acc[item.id] = item.modules[module];
            return acc;
        }, {});
    }, [lineages, module]);

    useEffect(() => {
        if (lineagesStatus !== 'loading') {
            // console.log('lineage', lineage);
            setPathogen(lineage ? lineage.split('_')[0] : '');
        }
    }, [lineage, lineagesStatus]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                listRef.current &&
                !listRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setOpenList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEventListener('resize', () => {
        setIsMobile(window.innerWidth < 1000);
    });

    const dictionary = useMemo(() => {
        if (lineagesStatus !== 'loaded') return [];
        return [...new Set(lineages.map(item => item.id.split('_')[0]))];
    }, [lineages, lineagesStatus]);

    const buildDictionary = useMemo(() => {
        if (lineagesStatus !== 'loaded') return {};
        return lineages.reduce((acc, item) => {
            const { id, modules } = item;
            const [key, ...rest] = id.split('_');
            if (!acc[key]) acc[key] = [];
            acc[key].push({build: rest.join('_'), valid: modules[module]});
            return acc;
        }, {});
    }, [lineages, lineagesStatus, module]);
    // console.log('buildDictionary', buildDictionary);

    const resetLineageURLParam = () => {
        const params = new URLSearchParams(location.search);
        params.delete('lineage');
        navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
    };

    const handleChange = async (newPathogene) => {
        const defaultBuilds = appConfig.defaultBuilds.filter(build => lineageModuleDict[`${newPathogene}_${build}`]);
        const newBuild = defaultBuilds.length ? defaultBuilds[0] : (buildDictionary[newPathogene].find(({valid}) => valid)?.build || buildDictionary[newPathogene][0].build);
        const newLineage = `${newPathogene}_${newBuild}`;
        await updateLineage(newLineage);
        resetLineageURLParam();
        setOpenList(false);
    };

    const updateLineage = async (lineage) => {
        treeD3.resetScales();
        await resetSession({ lineage });
    };

    const handleClick = () => {
        setOpenList(prevOpenList => !prevOpenList);
    };

    return (
        <Grid container className={isMobile ? classes.containerMobile : classes.container}>
            <Grid className={isMobile ? classes.labelMobile : classes.label}>Pathogen</Grid>
            <Grid size='grow'>
                <Button
                    ref={buttonRef}
                    id='lineage-select'
                    className={isMobile ? classes.blueMobile : classes.blue}
                    onClick={handleClick}
                >
                    <div className={isMobile ? classes.buttonLabelMobileOneLine : classes.buttonLabelOneLine}>
                        {appConfig.pathogensLabels[pathogen] || pathogen || 'loading...'}
                        <ArrowDropDownIcon className={`${classes.arrowIcon} ${openList ? classes.arrowIconOpen : ''}`} />
                    </div>

                    {openList && (
                        <ul className={isMobile ? classes.listMobile : classes.list} ref={listRef} id='lineage-list'>
                            {dictionary.map((option, index) => (
                                <li
                                    key={index}
                                    className={classes.listItem}
                                    onClick={() => handleChange(option)}
                                >
                                    {appConfig.pathogensLabels[option] || option}
                                </li>
                            ))}
                        </ul>
                    )}
                </Button>
            </Grid>
            
        </Grid>
    );
};

LineageSelector.propTypes = {
    lineage: PropTypes.string,
    lineages: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
    lineagesStatus: PropTypes.string,
    classes: PropTypes.object.isRequired,
    resetSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    lineages: lineagesSelector(state),
    lineagesStatus: state.lineages.lineagesStatus,
    lineage: state.parameters.lineage || '',
});

const mapDispatchToProps = {
    resetSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LineageSelector));
