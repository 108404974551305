export const styles = () => ({
    label: {
        // opacity: props => props.renderStatus ? 1 : 0,
        // cursor: props => props.isDragging ? 'grabbing' : 'pointer',
        pointerEvents: 'all'
    },
    labelBorder: {
        fill: '#ffffff',
        opacity: 0.75,
        stroke: '#4F4F4F',
        strokeWidth: 1,
        rx: 4,
        ry: 4
    },
    labelText: {
        fontFamily: 'Inter',
        fontSize: '12px',
        cursor: 'pointer',
        // dominantBaseline: 'text-before-edge'
    },
    cladeLabelAnchorPointCircle: {
        fill: '#ffffff',
        stroke: '#4F4F4F',
        strokeWidth: 1
    },
    cladeLabelLink: {
        stroke: '#000000',
        strokeWidth: 1
    }
});
