import appConfig from '../config/appConfig';

export const isNull = (value) => value === null || value === undefined;

export const emptyObject = (obj) => isNull(obj) || (Object.keys(obj).length === 0 && obj.constructor === Object);

export const emptyObjectOrArray = (value) => {
    if (Array.isArray(value)) {
        return value.length === 0;
    } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
    }
    return false;
};

const isEmpty = (value) => typeof value === 'undefined' || value === null || value === false;

export const isNumeric = (value) => !isEmpty(value) && !Number.isNaN(Number(value));


export const treePostOrder = (rootNode, callback) => {
    // log(`[treePostOrder]: ${rootNode.id}`);
    let node = rootNode;
    let parent = null;
    const nodes = [{ node, parent }];
    // log(`[treePostOrder]: ${JSON.stringify(nodes)}`);
    const next = [];
    let children;
    let i;
    let n;
    // log(nodes);
    let val;
    while ((val = nodes.pop())) {
        // log(`1. [treePostOrder] val = ${val}`);
        node = val.node;
        parent = val.parent;
        // log(`2. [treePostOrder] ${node.id}, ${parent ? parent.id : ''}, ${nodes.length}`);
        next.push({ node, parent });
        children = node.children;
        if (children) {
            for (i = 0, n = children.length; i < n; ++i) {
                nodes.push({ node: children[i], parent: node });
            }
        }
    }
    while ((val = next.pop())) {
        // log(`3. [treePostOrder] val = ${val}`);
        node = val.node;
        parent = val.parent;
        callback(node, parent);
    }
    return rootNode;
};

export const treePreOrder = (rootNode, callback) => {
    let node = rootNode;
    let parent = null;
    const nodes = [{ node, parent }];
    // const next = [];
    let children;
    let i;
    let val;
    while ((val = nodes.pop())) {
        node = val.node;
        parent = val.parent;
        callback(node, parent);
        // next.push(node);
        children = node.children;
        if (children) {
            for (i = 0; i < children.length; ++i) {
                nodes.push({ node: children[i], parent: node });
            }
        }
    }
    return rootNode;
};

export const minNumber = (n1, n2) => {
    if (isNull(n1) && isNull(n2)) return null;
    if (isNull(n1)) return n2;
    if (isNull(n2)) return n1;
    return Math.min(n1, n2);
};

export const maxNumber = (n1, n2) => {
    if (isNull(n1) && isNull(n2)) return null;
    if (isNull(n1)) return n2;
    if (isNull(n2)) return n1;
    return Math.max(n1, n2);
};

export const titleCase = (str) => (str || '').toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());

// const daysToDate = (d) => new Date(1900, 0, d, 0, 0, 0, 0);


export const daysToDate = (days) => {
    const startDate = new Date(Date.UTC(1900, 0, 1)); // Starting from January 1, 1900
    const resultDate = new Date(startDate.getTime() + (days - 1) * (1000 * 3600 * 24)); // Subtracting 2 because the startDate is already day 1 and JavaScript dates are 0-indexed for days
    return resultDate;
};

export const dateToDays = (d) => {
    if (emptyObject(d) || Number.isNaN(d)) return null;
    d.setHours(0, 0, 0, 0);
    const startDate = new Date(Date.UTC(1900, 0, 1, 0, 0, 0, 0)); // Starting from January 1, 1900
    const timeDiff = d - startDate;
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    return Math.round(daysDiff + 1);
};

export const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
};


export const yearFirstDate = (d) => new Date(daysToDate(d).getFullYear(), 0, 1);

export const quarterFirstDate = (d) => {
    const date = daysToDate(d);
    const m = date.getMonth();
    const mF = Math.floor(m / 3) * 3;
    // console.log(`quarterFirstDate = ${new Date(date.getFullYear(), mF, 1)}, year = ${date.getFullYear()}, month = ${mF}`);
    return new Date(date.getFullYear(), mF, 1);
};

export const yearLastDate = (d) => new Date(daysToDate(d).getFullYear() + 1, 0, 0);

export const quarterLastDate = (d) => {
    const date = daysToDate(d);
    const m = date.getMonth();
    const mF = (Math.floor(m / 3) + 1) * 3;
    return new Date(date.getFullYear(), mF, 0);
};

export const monthFirstDate = (d) => {
    const date = asDate(d); // instanceof Date ? d : daysToDate(d);
    const m = date.getMonth();
    return new Date(date.getFullYear(), m, 1);
};

export const monthLastDate = (d) => {
    // const date = daysToDate(d);
    const date = d instanceof Date ? d : daysToDate(d);
    const m = date.getMonth();
    return new Date(date.getFullYear(), m + 1, 0);
};

const isDateString = d => typeof d === 'string' && Date.parse(d);

const isNumberString = d => typeof d === 'number' || (typeof d === 'string' && /^-?\d+$/.test(d.trim()));


export const asDate = d => {
    if (d instanceof Date) return d;
    if (isNumberString(d)) return daysToDate(d);
    if (isDateString(d)) return getDateFromString(d);
    return d;
};


export const getAcademicYear = (t) => {
    const d = daysToDate(t);
    const firstYearDay = new Date(d.getFullYear(), 9, 1);
    const year = d.getTime() < firstYearDay.getTime() ? d.getFullYear() : d.getFullYear() + 1;
    return year;
};


export const isFunction = (arg) => typeof arg === 'function';

export const pick =
    (...props) =>
        (o) =>
            props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});


// export const getTextMetrics = (text, font) => {
//     const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
//     const context = canvas.getContext('2d');
//     context.font = font;
//     const metrics = context.measureText(text);

//     const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
//     //let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
//     //console.log(metrics.width, fontHeight, actualHeight);
//     return { width: metrics.width, height: fontHeight };
// };


/**
 * Measures a single line of text using a hidden <canvas>.
 * 
 * @param {string} text - The text to measure
 * @param {string} font - CSS font string, e.g. "12px 'Inter'"
 * @returns {{ width: number, height: number }}
 */
export function getTextMetrics(text, font = '12px \'Inter\'') {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = font;
  
    // measureText gives us width.
    const metrics = ctx.measureText(text);
  
    // Some browsers provide actual ascent/descent metrics.
    // If your target browsers support these properties, use them:
    const ascent =  metrics.fontBoundingBoxAscent || 0;//metrics.actualBoundingBoxAscent || 0;
    const descent = metrics.fontBoundingBoxDescent || 0;//metrics.actualBoundingBoxDescent || 0;
    const width = metrics.width;
    const height = ascent + descent;
  
    return { width, height };
}
  
/**
   * Measures multiline text by splitting lines and summing widths/heights.
   * 
   * @param {string[]} lines - Each item is one line of text
   * @param {string} font - The font to use
   * @param {number} lineSpacing - Extra spacing between lines
   * @returns {{ width: number, height: number }}
   */
export function getMultilineTextMetrics(lines, font = '12px \'Inter\'', lineSpacing = 1) {
    let totalHeight = 0;
    let maxWidth = 0;
  
    // Re-use the same canvas/context to avoid overhead:
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = font;
  
    for (const line of lines) {
        const metrics = ctx.measureText(line);
        const ascent = metrics.fontBoundingBoxAscent || 0;
        const descent = metrics.fontBoundingBoxDescent || 0;
        const lineHeight = ascent + descent;
  
        if (metrics.width > maxWidth) {
            maxWidth = metrics.width;
        }
        // Add this line's height plus some spacing
        totalHeight += lineHeight + lineSpacing;
    }
  
    // You may want to remove the spacing after the last line:
    if (lines.length > 0) {
        totalHeight -= lineSpacing;
    }
  
    return { width: maxWidth, height: totalHeight };
}

export const getTrimmedText = (text, font, maxWidth) => {
    const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;

    let metrics = context.measureText(text);
    let lineWidth = metrics.width;

    if (lineWidth <= maxWidth) {
        return { text: text, width: lineWidth };
    }

    let truncatedText = text;

    while (lineWidth > maxWidth && truncatedText.length > 0) {
        truncatedText = truncatedText.slice(0, -1);
        metrics = context.measureText(truncatedText + '...');
        lineWidth = metrics.width;
    }

    return truncatedText + '...';
};

// const checkTreeOrder = (tree, treeFreqs, treeAttrs, treeOrderDict) => {
//     let treeOrderOk = true;
//     const maxTimeDict = {};
//     treePostOrder(tree, (node) => {
//         if (!node.children) maxTimeDict[node.id] = treeAttrs[node.id].time;
//         else {
//             maxTimeDict[node.id] = Math.max(...node.children.map((c) => maxTimeDict[c.id]));
//         }
//     });

//     treePostOrder(tree, (node) => {
//         if (!node.children) return true;
//         const childrenIds = node.children
//             .map((c) => c.id)
//             .sort(
//                 (c1, c2) => (treeFreqs[c1] || 0) - (treeFreqs[c2] || 0) || maxTimeDict[c1] - maxTimeDict[c2] || c1 - c2,
//             );
//         const childrenOrders = childrenIds.map((c) => treeOrderDict[c]).filter((c) => c !== null && c !== undefined);

//         let prevOrder = null;
//         let branchOk = true;

//         childrenOrders.forEach((c) => {
//             branchOk = branchOk && (prevOrder === null || c > prevOrder);
//             prevOrder = c;
//         });
//         if (!branchOk) {
//             console.log(childrenOrders);
//             console.log(childrenIds.map((c) => ({ freq: treeFreqs[c], maxTime: maxTimeDict[c] })));
//             console.log(`${node.id} => ${childrenOrders}, branchOk = ${branchOk}`); // / ${Math.sum(...childrenIds.map(c => treeFreqs[c] || 0))}`);
//         }
//         treeOrderOk = treeOrderOk && branchOk;
//     });
//     return treeOrderOk;
// };

export const compare = (a, b, prop) => {
    const _a = prop ? a[prop] : a;
    const _b = prop ? b[prop] : b;
    return _a < _b
        ? -1
        : _a > _b ? 1 : 0;
};

// const arrayFromObjProps = (obj, props) => {
//     return props.map(prop => obj[prop]);
// };

// export const stratify = (data) => {
//     const hashTable = data.reduce((acc, aData) => {
//         // eslint-disable-next-line no-unused-vars
//         const { p, ...nodeData } = aData;
//         acc[aData.id] = nodeData;
//         return acc;
//     }, {});

//     const dataTree = [];
//     data.map((aData) => {
//         if (!aData.p) {
//             dataTree.push(hashTable[aData.id]);
//         } else {
//             if (!hashTable[aData.p].children) hashTable[aData.p].children = [];
//             hashTable[aData.p].children.push(hashTable[aData.id]);
//         }
//     });
//     return dataTree.length > 0 ? dataTree[0] : null;
// };


export const getBiggerForSortTable = (a, b, type, asc) => {
    const nameA = type !== 'scale' ? a[type].toUpperCase() : (typeof a.scale === 'string') ? a.scale.toUpperCase() : '(parameterized)'.toUpperCase();
    const nameB = type !== 'scale' ? b[type].toUpperCase() : (typeof b.scale === 'string') ? b.scale.toUpperCase() : '(parameterized)'.toUpperCase();
    if (nameA < nameB) {
        return asc ? -1 : 1;
    }
    if (nameA > nameB) {
        return asc ? 1 : -1;
    }
    return 0;
};

// A simple debounce function
// const debounce = (func, wait) => {
//     let timeout;
//     return (...args) => {
//         const later = () => {
//             clearTimeout(timeout);
//             func(...args);
//         };
//         clearTimeout(timeout);
//         timeout = setTimeout(later, wait);
//     };
// };

export const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

export const subtractYears = (date, years) => {
    if (!(date instanceof Date)) return null; // Check if date is not an instance of Date
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() - years);
    return newDate;
};

export const getDateFromString = dateString => dateString ? new Date(dateString) : null;

export const dateToString = date => date ? date.toISOString() : null;

export const dateToReduxDateFormat = date => dateToString(date);

export const reduxDateFormatToDate = rdate => getDateFromString(rdate);

export const sanitizeId = (id) => id.replace(/[^a-zA-Z0-9-]/g, '_');



const propToString = (val) => {
    if (typeof val === 'function') {
        //const funcStr = val.toString().slice(0, 50); // First 50 chars only
        return `[Function: ${val.name || 'anonymous'}]`;
    }
    if (val instanceof Object) return `Object, keys: ${Object.keys(val).length}`;
    return val;
};

export const printWhatChanged = (props, state, prevProps, prevState, prefix) => {
    const whatChanged = (props, prevProps) => {
        const merged = { ...(prevProps || {}), ...(props || {}) };
        return Object.keys(merged)
            .filter((p) => {
                // if (typeof props[p] === 'function' && typeof prevProps?.[p] === 'function') {
                //     // Compare function implementations
                //     return props[p].toString() !== prevProps[p].toString();
                // }
                return props[p] !== prevProps?.[p];
            })
            .map((key) => `${key}: ${propToString(prevProps?.[key])} => ${propToString(props[key])}`);
    };

    const whatChangedProps = whatChanged(props, prevProps);
    const whatChangedState = whatChanged(state, prevState);
    //this.counter += 1;

    //const _counter = this.counter;
    //let counter = 1;
    console.log(`[printWhatChanged] ${prefix||''}`, `${JSON.stringify(whatChangedProps, null, ' ')}, ${JSON.stringify(whatChangedState, null, ' ')}`);
    // Object.entries(props).forEach(([key, val]) => prevProps[key] !== val && console.log(`Prop '${key}' changed`));
    // if (state) {
    //     Object.entries(state).forEach(([key, val]) => prevState[key] !== val && console.log(` State '${key}' changed`));
    // }
   
};

// class MeasureTimer {
//     //startTime = null;
//     layerStartTimes = {};

//     // constructor() {
//     //     startTime = new Date().getTime();
//     // }
//     setLayerStartTime = (layer) => {
//         if (!this.layerStartTimes[layer])
//             this.layerStartTimes[layer] = new Date().getTime();
//     }
//     getLayerStartTime = (layer) => this.layerStartTimes[layer];
            
//     getLayerInterval = (layer) => {
//         if (!this.layerStartTimes[layer])
//             this.layerStartTimes[layer] = new Date().getTime();
//         return new Date().getTime() - this.layerStartTimes[layer];
//     }
//     resetLayer = (layer) => {
//         delete this.layerStartTimes[layer];
//     }

// }

// const measureTimer = new MeasureTimer();

export const getSymbolDimensions = (number = 1) => {
    // Get the symbol type index

    // Based on actual rendered dimensions
    const symbolWidth = 10;  // Observed from actual rendering
    const symbolHeight = 13.23; // Observed from actual rendering
    
    // Calculate total width including spacing between symbols
    // Use a smaller spacing that matches the actual rendering
    const effectiveSpacing = 0; // Symbols are rendered adjacent to each other
    const totalWidth = (symbolWidth * number) + (effectiveSpacing * (number - 1));
    
    return {
        symbolWidth: totalWidth,
        symbolHeight: symbolHeight
    };
};

export const getIsMobile = () => {
    return window.innerWidth < 1000;
};


export const getPathname = (location) => `/${location.pathname.split('/').pop()}`;

export const getCurrentModule = (location) => {
    const pathname = getPathname(location);
    return appConfig.pathnameToModule[pathname];
};
