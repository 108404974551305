import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableRow, IconButton } from '@mui/material';
import { emptyObject, getIsMobile } from '../../../functions/utils';
import { HideIcon } from '../../Alerts/styles';
import { bindActionCreators } from 'redux';
import { setVaccinesPointInfo } from '../../../redux/actions/vaccinesActions';
// import VaccinesLegend from '../../ColorLegend/Legends/VaccinesLegend';
import { numFormat } from '../../../functions/formats';

const styles = () => ({
    root: {
        backgroundColor: '#fff',
        height: 'calc(100vh - 70px)',
        maxHeight: 'calc(100vh - 70px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'none',
        paddingTop: '5px',
        width: '100%'
    },
    table: {
        borderCollapse: 'collapse',
        padding: '10px 14px',
        height: 'auto',
        '& tbody': {
            display: 'table',
        },
        width: '100%'
    },
    row: {
        fontSize: 12,
        height: 21,
    },
    headRow: {
        fontSize: 12,
        height: 21,
        position: 'relative',
        width: '100%'
    },
    cell: {
        border: 'none',
        // whiteSpace: 'nowrap',
        verticalAlign: 'top',
        lineHeight: '21px',
        fontFamily: 'Inter Bold',
    },
    cellValue: {
        fontWeight: 'normal',
        border: 'none',
        padding: '0 40px 0 15px',
        lineHeight: '21px',
        width: '100%',
        verticalAlign: 'top',
        textAlign: 'left',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
    },
    legendWrapper: {
        padding: '20px 15px 0 15px',
        overflowY: 'overlay',
    },
    divPadding: {
        height: '5px'
    },
    button: {
        position: 'absolute',
        padding: '0 22px 0 0 ',
        right: 0
    },
    infoMobile: {
        position: 'absolute',
        left: '17px',
        width: '90%',
        top: '142px',
        backgroundColor: 'white',
        boxShadow: '0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
        borderRadius: '10px'
    }
});

const useStyles = makeStyles(styles);

let rowId = 0;

const createData = (rowName, value) => {
    if (value !== undefined && value !== '') {
        rowId += 1;
        return { rowId: `${rowId}_${rowName}`, rowName, value };
    }
    return null;
};

const PointInfo = (props) => {
    // console.log('[PointInfo]', props);
    const { pointData, isMobile, setVaccinesPointInfo } = props;
    if (!pointData) return null;
    const { name, antigenicCladeLabel, cladeLabel, lab, protvalue, diffprotvalue, inferred, freqSum, predSum, sera_type, season } = pointData;
    // console.log('[PointInfo]', pointData);
    const classes = useStyles();
        

    const rows = [
        createData('Reference strain', name?.split('_')[0]),
        createData('EPI', name?.split('_').slice(1).join('_')),
        createData('Season', season),
        createData('Sera type', sera_type),
        createData('Lab', lab),
        createData('Clade', antigenicCladeLabel),
        createData('Rho', cladeLabel),
       
      
        createData('Protection', numFormat(protvalue, 6)),
        createData('Diff protection', numFormat(diffprotvalue, 6)),
        createData('Inferred', cladeLabel ? (inferred ? 'Yes' : 'No') : ''),
        createData('Weighted tracked frequency', freqSum ? numFormat(freqSum, 6) : ''),
        createData('Weighted predicted frequency', predSum ? numFormat(predSum,6) : ''),
    ].filter(d => d !== null && d !== undefined); // .filter(row => !emptyObject(row.value));

    const handleClick = () => {
        setVaccinesPointInfo();
    };

    return (
        <>
            {!emptyObject(pointData) && (
                isMobile ?
                    <div className={classes.infoMobile}>
                        <Table className={classes.table}>
                            <TableBody >
                                <TableRow className={classes.divPadding} />
                                <TableRow className={classes.row}>
                                    <IconButton className={classes.button} onClick={handleClick} >
                                        <HideIcon />
                                    </IconButton>
                                </TableRow>
                                {rows.map(row => (
                                    <TableRow className={classes.row} key={row.rowId}>
                                        <TableCell className={classes.cellValue} component="td" scope="row">
                                            <span className={classes.cell}>
                                                {`${row.rowName}: `}
                                            </span>
                                            <span >
                                                {row.value}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    :
                    <div className={classes.root}>
                        <Table className={classes.table}>
                            <TableBody style={{ width: '100%' }}>
                                <TableRow className={classes.divPadding} />
                                <TableRow className={classes.headRow}>
                                    <TableCell className={classes.cellValue} colSpan={2}>
                                        <IconButton className={classes.button} onClick={handleClick}>
                                            <HideIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                                {rows.map(row => (
                                    <TableRow className={classes.row} key={row.rowId}>
                                        <TableCell className={classes.cellValue} component="td" scope="row">
                                            <span className={classes.cell}>
                                                {`${row.rowName}: `}
                                            </span>
                                            <span >
                                                {row.value}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                     
                        {/* <div className={classes.legendWrapper}> 
                            <VaccinesLegend fullHeight={true} valueGetter={() => freqCategoryValue}/>
                        </div> */}
                    </div>
            )}
        </>
    );
};

const mapStateToProps = ({vaccines}) => {
    const { vaccinesPointInfo } = vaccines;

    return ({
        pointData: vaccinesPointInfo || {},
        isMobile: getIsMobile(),
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        setVaccinesPointInfo,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PointInfo);
