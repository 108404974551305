// External imports
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Internal imports
import NumberInput from '../Common/NumberInput';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchRecalculatedTree } from '../../redux/actions/treeDataActions';
import { setAlertStatus } from '../../redux/actions/alertActions';
import { getDisplayedSequencesNumber } from '../../redux/selectors/treeDataSelector';

/**
 * Component for selecting the number of sequences to display in the tree
 * Includes alert handling for large differences between requested and actual displayed sequences
 */
const ShowLeafNumberSelector = ({
    showLeafNumber,
    displayedSequencesNumber,
    setParameters,
    fetchRecalculatedTree,
    lineage,
    colorBy,
    refStrain,
    // ignoreStrainCutOffDate,
    setAlertStatus
}) => {
    // Handle alerts based on difference between requested and displayed sequences
    useEffect(() => {       
        if (displayedSequencesNumber === 0) {
            setAlertStatus({
                id: 'fetchSequences',
                status: false,
                model: 'strainTree'
            });
        } else {
            const diff = Math.abs(displayedSequencesNumber - showLeafNumber);
            const status = diff > 50;
            setAlertStatus({
                id: 'fetchSequences',
                status,
                model: 'strainTree',
                ...(status && { messageVar: displayedSequencesNumber.toString() })
            });
        }
    }, [showLeafNumber, displayedSequencesNumber, setAlertStatus]);
    
    // Handle changes to sequence number selection
    const setShowLeafNumber = (value) => {
        const params = {
            showLeafNumber: value,
            lineage,
            colorBy,
            refStrain,
            // ignoreStrainCutOffDate,
        };
        setParameters({ showLeafNumber: value });
        fetchRecalculatedTree(params);
    };
    
    return (
        <NumberInput
            id="show-leaf-number"
            label="Displayed sequences"
            value={showLeafNumber}
            onAccept={setShowLeafNumber}
            increment={100}
            min={0}
        />  
    );
};

ShowLeafNumberSelector.propTypes = {
    // Parameters
    showLeafNumber: PropTypes.number,
    displayedSequencesNumber: PropTypes.number,
    lineage: PropTypes.string,
    colorBy: PropTypes.string,
    refStrain: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    // ignoreStrainCutOffDate: PropTypes.bool,
    // Actions
    setParameters: PropTypes.func.isRequired,
    fetchRecalculatedTree: PropTypes.func.isRequired,
    setAlertStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    // Tree display parameters
    showLeafNumber: state.parameters.showLeafNumber,
    displayedSequencesNumber: getDisplayedSequencesNumber(state),
    // Tree configuration
    lineage: state.parameters.lineage,
    colorBy: state.parameters.colorBy,
    refStrain: state.parameters.refStrain,
    // ignoreStrainCutOffDate: state.parameters.ignoreStrainCutOffDate,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setParameters,
    fetchRecalculatedTree,
    setAlertStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowLeafNumberSelector);
