import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useEventListener } from 'usehooks-ts';
import config from '../../config/envConfig';
import auth from '../../functions/auth-helper';
import { styles } from './styles';
import Grid from '@mui/material/Grid2';
import { getIsMobile } from '../../functions/utils';

const tabModulesRoutes = [
    { module: 'strainTree', permission: 'strainTree', routeName: '', label: 'Tree', default: true, intro: true, introRouteName: 'intro' },
    { module: 'frequencies', permission: 'frequencies', routeName: 'frequencies', label: 'Frequencies', intro: true, introRouteName: 'intro-freq'},
    { module: 'antigenic', permission: 'antigenic', routeName: 'antigenic', label: 'Antigenic'},
    { module: 'fitness', permission: 'fitness', routeName: 'fitness', label: 'Fitness'},
    { module: 'vaccines', permission: 'vaccines', routeName: 'vaccines', label: 'Vaccines'},
    { module: 'geomap', permission: 'map', routeName: 'geomap', label: 'Map', intro: true, introRouteName: 'intro-map'},
    // { module: 'populationImmunity', permission: 'strainTree', routeName: 'population', label: 'Population immunity', intro: true, introRouteName: 'population'}
];

const ModulesSelect = ({ classes, modules, isAuthenticated, permissionTabs }) => {
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [openList, setOpenList] = useState(false);
    const buttonRef = useRef(null);
    const listRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();



    useEventListener('resize', () => {
        setIsMobile(window.innerWidth < 1000);
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                listRef.current &&
                !listRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setOpenList(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const currentRoute = location.pathname.replace(`${config.frontendPrefix}/`, '');
    const matchedModule = tabModulesRoutes.find(m => m.routeName === currentRoute || m.introRouteName === currentRoute);

    const handleChange = (option) => {
        const route = `${config.frontendPrefix}/${!isAuthenticated ? option.introRouteName : option.routeName}`;
        navigate(route);
    };

    const renderModules = () => {
        if (!modules) return null;
        return tabModulesRoutes.map((m, index) => {
            const { module, permission, label, intro } = m;

            if (!isAuthenticated && !intro) return null;
            if (isAuthenticated && (!modules[module] || !permissionTabs[permission])) return null;

            return (
                <li
                    className={classes.listItem}
                    key={index}
                    id={module}
                    onClick={() => handleChange(m)}
                >
                    {label}
                </li>
            );
        });
    };

    const handleClick = () => {
        setOpenList(!openList);
    };

    return (
        <Grid container className={isMobile ? classes.containerMobile : classes.container}>
            <Grid className={ isMobile ? classes.labelMobile : classes.label}>Display</Grid>
            <Grid size='grow'>
                <Button
                    className={isMobile ? classes.blueMobile : classes.blue}
                    onClick={handleClick}
                    ref={buttonRef}
                    id='display'
                >
                    <div className={isMobile ? classes.buttonLabelMobileOneLine : classes.buttonLabelOneLine}>
                        {matchedModule?.label}
                        <ArrowDropDownIcon className={`${classes.arrowIcon} ${openList ? classes.arrowIconOpen : ''}`} />
                    </div>

                    {openList && (
                        <ul className={isMobile ? classes.listMobile : classes.list} ref={listRef}>
                            {renderModules()}
                        </ul>
                    )}
                </Button>
            </Grid>
            
        </Grid>
    );
};

ModulesSelect.propTypes = {
    classes: PropTypes.shape({
        container: PropTypes.string,
        containerMobile: PropTypes.string,
        label: PropTypes.string,
        labelMobile: PropTypes.string,
        blue: PropTypes.string,
        blueMobile: PropTypes.string,
        buttonLabelOneLine: PropTypes.string,
        buttonLabelMobileOneLine: PropTypes.string,
        arrowIcon: PropTypes.string,
        arrowIconOpen: PropTypes.string,
        list: PropTypes.string,
        listMobile: PropTypes.string,
        listItem: PropTypes.string
    }).isRequired,
    modules: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    permissionTabs: PropTypes.object.isRequired
};

const mapStateToProps = ({ routes, metadata, user }) => ({
    ...routes,
    modules: metadata.modules,
    isAuthenticated: user.status === 'loaded' && auth.isAuthenticated(),
    permissionTabs: user.permissions.tabs,
});

export default connect(mapStateToProps)(withStyles(styles)(ModulesSelect));
