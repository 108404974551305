import React, { Component } from 'react';
import { connect } from 'react-redux';
import { select } from 'd3-selection';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { getSelectedMutationGroups } from '../../redux/selectors/metadataSelector';
import { drawSymbolElement } from './d3/symbolSigns';
import LabelFlagIcon from '../SvgIcons/LabelFlagIcon';
import { isFirefox } from '../../functions/browser-check';
import MutClassIcon from '../SvgIcons/MutClassIcon';

const styles = (theme) => ({
    root: {
        background: 'white',
        minWidth: '180px',
        bottom: 130,
        zIndex: '2',
        maxHeight: '300px',
        overflowY: isFirefox ? 'auto' : 'overlay',
        width: '100%',
        padding: '9px 0 20px'

    },
    rootExport: {
        background: 'white',
        width: '190px',
        padding: 0,
        paddingBottom: '24px'
    },
    title: {
        paddingBottom: '33px',
        marginLeft: '-17px',
    },
    titleExport: {
        paddingBottom: '6px',
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
        borderBottom: '1px solid #9f9f9f',
    },
    legend: {
        overflow: 'visible',
        color: 'white',
        fontSize: '12px',
        width: '100%',
        marginTop: '5px',
    },
    legendExport: {
        overflow: 'visible'
    },
    legendWrapper: {
        height: 'auto',
        maxHeight: '234px',
        overflow: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        borderBottom: '1px solid #9f9f9f',
        maxWidth: '100%'
    },
    legendWrapperExport: {
        height: 'auto',
        overflow: 'visible',
        maxWidth: '100%'
    },
    legendText: {
        color: 'black',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: '26px',
    },
    legendTextExport: {
        color: 'black',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: '18px',
        fontSize: '10px'
    },
    legendElement: {
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer'
    },
    legendCircleWrapper: {
        height: '26px',
        width: '28px',
        display: 'flex',
        alignItems: 'center'
    },
    legendCircleWrapperExport: {
        height: '18px',
        display: 'flex',
        alignItems: 'center'
    },
    legendFlagWrapper: {
        height: '26px',
        paddingRight: '5px',
        display: 'flex',
        alignItems: 'center'
    },
    legendDataLoadWrapper: {
        height: '28px',
        paddingRight: '4px',
        display: 'flex',
        alignItems: 'center'

    },
    legendCircle: {
        ///borderRadius: '50%',
        width: 15,
        height: 15,
        marginRight: 9,
        flexShrink: 0,
    },
    legendCircleExport: {
        borderRadius: '50%',
        width: 12,
        height: 12,
        marginRight: 4,
        flexShrink: 0,
    },
});



class MutationClassesColorLegend extends Component {
    _element = React.createRef();
    legendWrapper = React.createRef();

    componentDidMount() {
        this.displayLegendSymbols();
    }

    componentDidUpdate() {
        this.displayLegendSymbols();
    }

    displayLegendSymbols() {
        const { mutationClasses } = this.props;

        (mutationClasses || []).forEach(elem => {
            const id = `${elem.key}_symbol`;
            const legendElement =  select(`#${id}`).selectAll('path.legendElem').data([elem.key]);
            legendElement.enter().call(drawSymbolElement(elem))
        })
    }

    render() {
        const { classes, exportMode, mutationClasses, visibleMutationClasses, mutationsGroup } = this.props;

        return (
            <>
                {!exportMode && mutationClasses && (
                    <div className={`${classes.root} active`} style={{ display: mutationClasses ? 'block' : 'none' }}>
                        <div className={classes.titleExport}>Mutations</div>
                        <div id="legendWrapper" ref={this.legendWrapper} className={classes.legendWrapper}>
                            {mutationClasses.map(mutClass => (
                                <div key={mutClass.key}
                                    className={classes.legendElement}
                                    id={mutClass.key}
                                    >

                                    <div className={classes.legendCircleWrapper}>
                                        <MutClassIcon mutclass={mutClass.key} mutationClasses={mutationClasses}/>
                                    </div>
                                    <div className={classes.legendFlagWrapper}>
                                        <LabelFlagIcon disabled={!visibleMutationClasses[mutationsGroup][mutClass.key]} mutclass={mutClass.key}/>
                                    </div>
                                    <div className={classes.legendText}
                                        style={{
                                            fontSize:'12px',
                                            // textDecoration: color.key === value || activeLegendOption.value === color.key ? 'underline' : 'none'
                                        }}>
                                        {mutClass.label}
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {/* {exportMode && (
                    <div className={`${classes.rootExport}`} style={{ display: colorData ? 'block' : 'none' }}>
                        <div id="legendWrapper" ref={this.legendWrapper} className={classes.legendWrapperExport}>
                            {data.map(color => (
                                <div className={classes.legendElement} id={color.key} key={color.key}>
                                    <div className={classes.legendCircleWrapperExport}>
                                        <div className={classes.legendCircleExport} style={{ backgroundColor: color.color }} />
                                    </div>
                                    <div className={classes.legendTextExport}
                                        style={{ fontSize: exportMode ? '10px' : colorBy === 'loc' ? '14px' : '12px' }}>
                                        {color.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )} */}
            </>
        );
    }
}

MutationClassesColorLegend.propTypes = {
    exportMode: PropTypes.bool
};


const mapStateToProps = (state) => {

    const { exportMode } = state.parameters;
    //const mutationsGroups = state.metadata.mutationClasses;
    const visibleMutationClasses = state.parameters.visibleMutationClasses;
    const mutationsGroup = state.parameters.mutationsGroup;
    //const mutationClasses = !emptyObject(mutationsGroups) && mutationsGroup && mutationsGroups[mutationsGroup] ? mutationsGroups[mutationsGroup] : null;
    const mutationClasses = getSelectedMutationGroups(state)
    // console.log(mutationClasses);
    return ({
        mutationClasses,
        visibleMutationClasses,
        mutationsGroup,
        exportMode
    });
};



export default connect(mapStateToProps)(withStyles(styles)(MutationClassesColorLegend));
