import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { prepareUrl } from '../../functions/url-parameters';
import {
    FETCH_ANTIGENIC_MODEL_REQUEST,
    FETCH_ANTIGENIC_RAW_MODEL_REQUEST,
    FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST
} from '../actions/actionTypes';
import {
    fetchAntigenicModelSuccess,
    fetchAntigenicModelError,
    fetchAntigenicRawModelSuccess,
    fetchAntigenicRawModelError,
    fetchAntigenicObservedDataSuccess,
    fetchAntigenicObservedDataError,
    // fetchAntigenicModelsListError
} from '../actions/antigenicActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';

export const fetchAntigenicModelEpic = (action$) => action$.pipe(
    ofType(FETCH_ANTIGENIC_MODEL_REQUEST),
    mergeMap(action => {
        const { lineage, antigenicModelId, antigenicDataType, antigenicTiterType } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/antigenic/model`, {
            lineage,
            antigenicModelId,
            antigenicDataType,
            antigenicTiterType,
        });
        //console.log(`[fetchAntigenicModelEpic]: url = ${url}, antigenicDataType = ${antigenicDataType}`);

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchAntigenicModelSuccess(response.data)),
                handleErrors(fetchAntigenicModelError, 'An error has occurred during downloading antigenic model data')
            );
    })
);

export const fetchAntigenicRawModelEpic = (action$) => action$.pipe(
    ofType(FETCH_ANTIGENIC_RAW_MODEL_REQUEST),
    mergeMap(action => {
        const { lineage, antigenicModelId, antigenicDataType, antigenicTiterType } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/antigenic/rawModel`, {
            lineage,
            antigenicModelId,
            antigenicDataType,
            antigenicTiterType
        });

        return from(fetchAxios(url))
            .pipe(
                map(response => fetchAntigenicRawModelSuccess(response.data)),
                handleErrors(fetchAntigenicRawModelError, 'An error has occurred during downloading antigenic models')
            );
    })
);


export const fetchAntigenicObservedDataEpic = (action$) => action$.pipe(
    ofType(FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST),
    mergeMap(action => {
        const { lineage, antigenicModelId } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/antigenic/observedData`, { lineage, antigenicModelId });
        // console.log(`[fetchAntigenicObservedDataEpic]: url = ${url}`);
        return from(fetchAxios(url))
            .pipe(
                map(response => fetchAntigenicObservedDataSuccess(response.data)),
                handleErrors(fetchAntigenicObservedDataError, 'An error has occurred during downloading antigenic observed data')
            );
    })
);
