import { select } from 'd3-selection';
import D3Component from '../../D3Component/D3Component';
import {
    getScaledValue,
    initVaccinesChartScales,
} from '../../../functions/scales';
import { drawChartAxes } from './chartAxes';
import { RENDER_STATUS } from '../../../config/consts';
import removeCursor from '../../../assets/images/cursorRemove.svg';
import { vaccineStrainId } from '../../../redux/selectors/vaccinesSelector';

const missingDataColor = '#dee0e2';

class VaccinesChartD3 extends D3Component {
    constructor(componentId) {
        super();
        this.componentId = componentId;
        this.exportWidthLabelsPadding = 200;
        this.componentName = 'vaccinesChart';
        this.padding = { top: 20, bottom: 50, left: 0, right: 20, middle:40, title: 30 };
        this.paddingExport = { top: 20, bottom: 50, left: 0, right: 20, middle:40, title: 30 };
    
        this.grad = null;
    
        this.blocked = false;
    
        this.sectionHeight = 50;
        this.strainTextOffset = 5;
    }

    additionalPropsActions = (props) => {
        this.padding.left = (props.maxTextLength || 0) + this.strainTextOffset*2;
        this.paddingExport.left = (props.maxTextLength || 0) + this.strainTextOffset*2;
    }
   

    // getWidth = () =>  this.width;

    xScale = () => 'xVaccinesScale';

    yScale = () => 'yVaccinesScale';

    yAllScale = () => 'yAllVaccinesScale';
   

    // curve = () => ((this.props && this.props.plotType === 'frequencies') ? curveMonotoneX : curveLinear);

    yVal = (d) => d;

    x = (valAttr) => d => getScaledValue(this.xScale(), d[valAttr]);

    y = d => this.sectionHeight * (d.strainIndex-1) + getScaledValue(this.yScale(), d.cladeIndex);

    yStrain = d => getScaledValue(this.yAllScale(), d.strainIndex-0.5);

    getCalculatedHeight = () =>  this.props.refStrainsCnt 
        ?   this.sectionHeight * (this.props.refStrainsCnt + 1.5 )
            + this.getPadding().top + this.getPadding().bottom + this.getPadding().title
        : 0;
    
    getCalculatedWidth = (width) => this.props.refStrainsCnt 
        ? width
        : 0;

    // Graph specific elements
    prepareGraphElements = () => {
        // console.log('prepareGraphElements');
        const svg = select(this.mountNode).select('g.graph'); 

        // Title Layer
        if (!svg.select('#title').size()) {
            const titleLayer = svg.append('g').attr('id', 'title');   

            const title = 'Protection'; 
            titleLayer.append('g')
                .attr('id', `${this.componentName}_title`)
                .append('text')
                .attr('transform', `translate(0, ${this.getPadding().title})`)
                .text(title);

            const titleDiff = 'Differential protection';
            titleLayer.append('g')
                .attr('id', `${this.componentName}_titleDiff`) 
                .append('text')
                .attr('transform', `translate(0, ${this.getPadding().title})`)
                .text(titleDiff);
        }

        // Axes Layer
        if (!svg.select('#axes').size()) {
            const axesSvg = svg.append('g').attr('id', 'axes');

            axesSvg.append('rect')
                .attr('id', 'vaccinesAxis_background')
                .attr('pointer-events', 'all')
                .attr('rx', 7)
                .style('fill', '#FFFFFF');

            const protAxes = axesSvg.append('g').attr('id', 'protAxes');
            const diffAxes = axesSvg.append('g').attr('id', 'diffAxes');

            protAxes.append('g').attr('id', `${this.componentName}XAxis`);
            protAxes.append('g').attr('id', `${this.componentName}ThresholdRange`);
            protAxes.append('g').attr('id', `${this.componentName}YAxis`);

            diffAxes.append('g').attr('id', `${this.componentName}DiffXAxis`);
            diffAxes.append('g').attr('id', `${this.componentName}DiffYAxis`);
        }

        // Strains Layer
        if (!svg.select(`#strainsLayer`).size()) {
            svg.append('g').attr('id', 'strainsLayer');
        }

        // Plot Layer
        if (!svg.select(`#${this.componentName}Plot`).size()) {
            const plot = svg.append('g')
                .attr('id', `${this.componentName}Plot`)
                .style('fill', '#FFFFFF');

           
            plot.append('g').attr('id', 'vaccinesLayer');
            plot.append('g').attr('id', 'vaccinesDiffLayer');
            plot.append('g').attr('id', 'frequenciesLayer');
            plot.append('g').attr('id', 'predictionsLayer');
            plot.append('g').attr('id', 'arrowsLayer');
        }

        svg.select('defs').append('marker')    
            .attr("id", "arrow")  // This ID will be reused for multiple lines
            .attr("viewBox", "0 0 10 10")
            .attr("refX", 9)  // Adjust arrow positioning
            .attr("refY", 5)
            .attr("markerWidth", 6)
            .attr("markerHeight", 6)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M 0 0 L 10 5 L 0 10 z")  // Triangle shape
            .style("fill", "black");  // Arrow color
    }

    translateGraphElements = () => {
        const { refStrainsCnt, ferretRefStrainsCnt } = this.props;
        const svg = select(this.mountNode);

        svg.select(`#${this.componentName}_title`).attr('transform', `translate(${this.padding.left}, 0)`);
        svg.select(`#${this.componentName}_titleDiff`).attr('transform', `translate(${this.padding.left+this.width/2+this.padding.middle/2}, 0)`);
        svg.select('#axes').attr('transform', `translate(${this.padding.left}, ${this.padding.top+this.padding.title})`);
        svg.select(`#diffAxes`).attr('transform', `translate(${this.width/2+this.padding.middle/2}, 0)`);
        svg.select('#strainsLayer').attr('transform', `translate(0, ${this.padding.top+this.padding.title})`);
        
        svg.select('#vaccinesLayer').attr('transform', `translate(${this.padding.left}, ${this.padding.top + this.padding.title + this.sectionHeight/2})`);
        svg.selectAll('g.vaccinesLayer').attr('transform', d => `translate(${this.x('protvalue')(d)}, ${this.y(d)})`);
        
        svg.select('#vaccinesDiffLayer').attr('transform', `translate(${this.padding.left+this.width/2+this.padding.middle/2}, ${this.padding.top + this.padding.title + this.sectionHeight/2})`);
        svg.selectAll('g.vaccinesDiffLayer').attr('transform', d => `translate(${this.x('diffprotvalue')(d)}, ${this.y(d)})`);
        
        svg.select('#frequenciesLayer').attr('transform', `translate(${this.padding.left}, ${this.padding.top + this.padding.title})`);
        svg.select('#frequenciesLayer').selectAll('g.freqLine').attr('transform', d =>  `translate(${this.x('freqSum')(d)}, ${this.yStrain(d)})`);
        svg.select('#predictionsLayer').attr('transform', `translate(${this.padding.left}, ${this.padding.top + this.padding.title})`);
        svg.select('#predictionsLayer').selectAll('g.predLine').attr('transform', d =>  `translate(${this.x('predSum')(d)}, ${this.yStrain(d)})`);
        svg.select('#arrowsLayer').attr('transform', `translate(${this.padding.left}, ${this.padding.top + this.padding.title})`);
        svg.select('#arrowsLayer').selectAll('g.arrow').attr('transform', d =>  `translate(${this.x('predSum')(d)}, ${this.yStrain(d)})`);
        drawChartAxes(svg, this.componentName,
            this.xScale(), this.yAllScale(),
            (this.width-this.padding.middle)/2, this.height,
            refStrainsCnt, ferretRefStrainsCnt,  this.sectionHeight);
    }


    clearChart = async () => { 
        // console.log(`[clearTree]`)
        const svg = select(this.mountNode).select('g.graph');
        await Promise.all([
            svg.select('#title').selectAll('g').remove(),
            // svg.select('#highlightedStrains').selectAll('g').remove(),
            // svg.select('#links').selectAll('path').remove(),
            // svg.select('#internalNodes').selectAll('g').remove(),
            // svg.select('#mutations').selectAll('g').remove(),
            // svg.select('#epitopeMutationsGroups').selectAll('g').remove(),
            // //svg.select('#mutationsClasses').selectAll('g').remove(),
            // svg.select('#branchNodes').selectAll('g').remove(),
            // //svg.select('#cladeLabels').selectAll('g').remove(),
            // svg.select('#reassortments').selectAll('g').remove(),
            // svg.select('#vaccines').selectAll('g').remove(),
            // svg.select('#refStrains').selectAll('g').remove(),
        ]);
    };

    removePlots = () => {
        // const svg = select(this.mountNode).select(`#${this.componentName}Plot`);
        // svg.selectAll('path.freqLayers').remove();
        // svg.selectAll('#predLayer g').remove();
        // svg.selectAll('path.freqStackedLayers').remove();
        // svg.selectAll('path.predStackedLayers').remove();
        // svg.selectAll('path.greyZoneLayers').remove();
        // svg.selectAll('path.freqStdLayers').remove();
        // svg.selectAll('path.predStdLayers').remove();
        // svg.select('g.predictionBaseline').remove();
        // select(this.mountNode).select('#dataPointsLayer').selectAll('g.dataPoints').remove();
    }

    reinitXYScales = () => {
        // console.log('reinitXYScales', this.componentId);
        const { cladesCnt, refStrainsCnt } = this.props;
        // console.log('reinitXYScales', {
        //     width: this.width, 
        //     resultingWidth: this.getWidth(), 
        //     paddingLeft: this.padding.left,
        //     paddingRight: this.padding.right,
        //     sectionHeight: this.sectionHeight, cladesCnt, refStrainsCnt});

        // console.log('[VaccinesChartD3] reinitXYScales', {width: this.width, height: this.height, padding: this.padding, cladesCnt, refStrainsCnt});
        if (this.width && cladesCnt && refStrainsCnt) {
            initVaccinesChartScales((this.width-this.padding.middle)/2, this.height, this.sectionHeight, cladesCnt, refStrainsCnt);
        }
    }

    setComponentState = (component, state) => {
        component.setState(state);
    }
   
    color = d => this.props.clades[d.cladeid]?.color;

    drawCladeDots = (svg, vaccinesData, layerId = 'vaccinesLayer', valAttr = 'protvalue') => {
        const vaccinesDataNode = svg.select(`#${layerId}`).selectAll('g').data(vaccinesData, d => `${d.refid}_${d.cladeIndex}`);
        // console.log('vaccinesDataNode', vaccinesDataNode);
       
        const nodeEnter = vaccinesDataNode.enter().append('g')
            .attr('id', d => `${d.refid}_${d.cladeIndex}`)
            .attr('class', layerId)
            // .style('pointer-events', 'none');
        nodeEnter.on('mouseenter', (e, d) => {
            this.props.setVaccinesPointInfo(d);
        })
        .on('mouseleave', () => {
            this.props.setVaccinesPointInfo(null);
        });
    
        vaccinesDataNode.exit().remove();
        nodeEnter.append('circle')
            .attr('r', 8)
            .style('opacity', 0.8);


        const nodeUpdate = nodeEnter.merge(vaccinesDataNode);

        nodeUpdate
            .attr('transform', d => `translate(${this.x(valAttr)(d)}, ${this.y(d)})`)
            .style('fill', d => d.inferred ? 'none' : this.color(d))
            .style('stroke', this.color)
            .style('stroke-width', 2)
            // .style('opacity', d => d.inferred ? 0 : 1);
        // const data = vaccinesData.;
    } 

    drawStrains = (svg, referenceStrains) => {
        const { setParameters } = this.props;
        // console.log('referenceStrains', referenceStrains);
        const strainsNode = svg.select('#strainsLayer').selectAll('g').data(Object.values(referenceStrains||{}), d => d.strainId);
        // console.log('vaccinesDataNode', vaccinesDataNode);
       
        const lineHeight = 16; // Distance between lines
        const verticalOffset = lineHeight / 2; // Half the line height to center

        // console.log('[drawStrains]', {vaccinesFerretRefStrains: this.props.vaccinesFerretRefStrains});
        const nodeEnter = strainsNode.enter().append('g')
            .attr('id', d => d.refid)
            .attr('class', 'strainsLayer')
            .attr('fill', '#000000')
            .style('font-size', '12px')
            .style('font-family', 'Inter')
            .style('text-anchor', 'end')
            .style('cursor', 'url(' + removeCursor + '), auto',)
            .attr('dominant-baseline', 'middle')
            .on('click', (_e, d) => {
                const _selectedStrains = d.sera_type === 'ferret' ? this.props.vaccinesFerretRefStrains : this.props.vaccinesHumanRefStrains;
                const selectedStrains = _selectedStrains
                    .filter(strain => strain !== vaccineStrainId(d))
                    .map(strain => {
                        const { refid, lab, season } = JSON.parse(strain);
                        return season ? [refid, lab, season] : [refid, lab];
                    });
                if (d.sera_type === 'ferret') {
                    setParameters({ vaccinesFerretRefStrains: selectedStrains });
                } else {
                    setParameters({ vaccinesHumanRefStrains: selectedStrains });
                }
            });

        // Add first line of text (name)
        nodeEnter.append('text')
            .attr('class', 'strain-name')
            .text(d => d.strainLabel)
            .attr('y', -verticalOffset) // Move up by half line height
            .style('font-size', '10px');

        // Add second line of text (lab)
        nodeEnter.append('text')
            .attr('class', 'strain-lab')
            .text(d => `${d.antigenicCladeLabel} (${d.lab})`)
            .attr('y', verticalOffset) // Move down by half line height
            .style('font-size', '10px');
            
        strainsNode.exit().remove();

        const nodeUpdate = nodeEnter.merge(strainsNode);
        nodeUpdate
            .attr('transform', d => `translate(${this.props.maxTextLength + this.strainTextOffset}, ${this.yStrain(d)})`)
        
    }


    drawFrequencies = (svg, frequenciesStrains, layerId = 'frequenciesLayer', valAttr = 'freqSum', className='freqLine', strokeWidth = '1px') => {
        // const { setParameters } = this.props;
        // console.log('referenceStrains', referenceStrains);
        const freqsNode = svg.select(`#${layerId}`)
            .selectAll('g')
            .data(frequenciesStrains, d => d.strain_id);

        const freqsNodeEnter = freqsNode.enter().append('g')
            .attr('id', d => d.strain_id)
            .attr('class', className);

        freqsNodeEnter.on('mouseenter', (e, d) => {
            this.props.setVaccinesPointInfo(d);
        })
        .on('mouseleave', () => {
            this.props.setVaccinesPointInfo(null);
        });
        freqsNodeEnter.append('path')
            .attr('stroke', '#000')
            .attr('stroke-width', strokeWidth)
            .attr('d', d => `M 0 -15 L 0 15`);
            
        freqsNode.exit().remove();

        const freqsNodeUpdate = freqsNodeEnter.merge(freqsNode);
        freqsNodeUpdate.attr('transform', d => `translate(${this.x(valAttr)(d)}, ${this.yStrain(d)})`);
    }


    
    drawFrequenciesArrows = (svg, frequenciesStrains, layerId = 'arrowsLayer', valAttr = 'predSum') => {
        const freqsNode = svg.select(`#${layerId}`)
            .selectAll('g')
            .data(frequenciesStrains, d => d.strain_id);

        const freqsNodeEnter = freqsNode.enter().append('g')
            .attr('id', d => d.strain_id)
            .attr('class', 'arrow');
        freqsNodeEnter.append('path')
            .attr("d", "M -10 -5 L 0 0 L -10 5 Z")  // Triangle shape pointing right
            .attr("fill", 'black');
            freqsNodeEnter.on('mouseenter', (e, d) => {
                this.props.setVaccinesPointInfo(d);
            })
            .on('mouseleave', () => {
                this.props.setVaccinesPointInfo(null);
            });
            
        freqsNode.exit().remove();

        const freqsNodeUpdate = freqsNodeEnter.merge(freqsNode);
        freqsNodeUpdate
            .attr('transform', d => {
                const x = this.x(valAttr)(d);
                const y = this.yStrain(d);
                // If freqSum is higher than predSum, rotate arrow 180 degrees
                const rotate = d.freqSum > d.predSum ? 180 : 0;
                return `translate(${x}, ${y}) rotate(${rotate})`;
            });
    }

   

    renderVaccinesChart = () => {
        const { vaccinesData, refStrainsCnt, ferretRefStrainsCnt, referenceStrains, frequenciesStrains } = this.props;

        // console.log('renderVaccinesChart', {refStrainsCnt, maxTextLength, paddingLeft: this.padding.left});

        // console.log('renderVaccinesChart', {vaccinesData});
        if (!vaccinesData) return;
        const svg = select(this.mountNode).select('g.graph');
        // svg.select('#vaccinesLayer').attr('transform', `translate(${this.padding.left}, 0)`);

        this.drawCladeDots(svg, vaccinesData, 'vaccinesLayer', 'protvalue');
        this.drawCladeDots(svg, vaccinesData.filter(d => d.sera_type === 'ferret'), 'vaccinesDiffLayer', 'diffprotvalue');
        this.drawStrains(svg, referenceStrains);
        this.drawFrequencies(svg, frequenciesStrains, 'frequenciesLayer', 'freqSum', 'freqLine', '1px');
        this.drawFrequencies(svg, frequenciesStrains, 'predictionsLayer', 'predSum', 'predLine', '2px');
        this.drawFrequenciesArrows(svg, frequenciesStrains, 'arrowsLayer');
        // // console.log('[renderNonStackedD3Component]', this.props.subsetId, width)
        drawChartAxes(svg, this.componentName,
            this.xScale(), this.yAllScale(),
            (this.width-this.padding.middle)/2, this.height,
            refStrainsCnt, ferretRefStrainsCnt, this.sectionHeight
        );
    }

    renderD3Component = async (viewName, componentId) => {
        const {setComponentStatus } = this.props;
        // console.log('[VaccinesChartD3] renderD3Component', {viewName, componentId}, this.props);
        setComponentStatus(viewName, componentId, RENDER_STATUS.START);
        this.reinitXYScales();
        this.renderVaccinesChart();
        setComponentStatus(viewName, componentId, RENDER_STATUS.DONE);
    };
  
}

export default VaccinesChartD3;
