import React from 'react';
import SelectInput from '../../Common/SelectInput';
import { bindActionCreators } from 'redux';
import { setParameters } from '../../../redux/actions/parametersActions';
import { connect } from 'react-redux';
import { resetVaccinesData } from '../../../redux/actions/vaccinesActions';

const serumTypes = [
    'CELL', 
    'EGG'
];

const VaccinesSerumType = (props) => {
    const { vaccinesSerumType, resetVaccinesData, setParameters} = props;

    const handleChange = (name) => (value) => {
        // resetVaccineDefaultSelections();
        resetVaccinesData();   
        setParameters({ [name]: value});

    };

    return <SelectInput
        id='vaccinesSerumType'
        label='Serum type'
        value={vaccinesSerumType}
        onChange={handleChange('vaccinesSerumType')}
        options={serumTypes}
    />;
};


const mapStateToProps = (state) => ({
    vaccinesSerumType: state.parameters.vaccinesSerumType,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        // resetVaccineDefaultSelections,
        resetVaccinesData,
        // resetVaccinesFrequencies,
        // resetVaccinesPredictions
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesSerumType);


