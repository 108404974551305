import { PATHOGEN_TYPES } from '../config/dictionaries';
import appConfig from '../config/appConfig';
import { isPlainObject } from 'lodash';
// export const isAntigenicModel = (modelId, models) => models && models.includes(modelId);
// export const isValidModelId = (modelId, models, modelTypes) => modelTypes.filter((mt) => mt !== 'Antigenic').includes(modelId) || isAntigenicModel(modelId, models);
export const getPathogenType = (pathogen) => appConfig.pathogenTypes[pathogen]?.value || PATHOGEN_TYPES.INFLUENZA.value;

export const shouldFetch = (status) => {
    return !status || status === 'none' || status === 'refetchNeeded';
};

export const isLoadedOrNA = (status) => {
    return !status || status === 'loaded' || status === 'NA';
};

export const isColorByModel = (colorBy) => (colorBy === 'fitness' || colorBy === 'advance' || colorBy === 'flux');

export const getAntigenicSegmentsCount = modelId => {
    if (!modelId) return 0;
    const cnt = (modelId.match(/-/g) || []).length;
    return cnt ? cnt + 1 : cnt; //modelId.split('-').length;
};




export const getAntigenicSegments = (modelId, antigenicSegmentsCount) => {
    if (!modelId) return modelId;
    let strainPropagation = null;
    let refStrainPropagation = null;
    let collaboratingCenter = null;
    let assay = null;

    switch (antigenicSegmentsCount) {
        case 4: {
            [
                strainPropagation,
                refStrainPropagation,
                collaboratingCenter,
                assay,
            ] = modelId.split('-');
            break;
        }
        case 3: {
            [
                strainPropagation,
                collaboratingCenter,
                assay
            ] = modelId.split('-');
            break;
        }
        case 2: {
            [
                strainPropagation,
                collaboratingCenter,
            ] = modelId.split('-');
            break;
        }
    }
    return { strainPropagation, refStrainPropagation, collaboratingCenter, assay };

};

export const getAntigenicSegmentsByName = (modelId, segmentNames) =>
    (modelId || '').split('-').reduce((acc, value, index) => ({ ...acc, [segmentNames[index]]: value }), {});


export const getSegments = (modelIds, segmentNames) => {
    // console.log('[getSegments]', modelIds, segmentNames);
    const valueSet = (modelIds || []).reduce((acc, modelId) => {
        const { collaboratingCenter, assay, strainPropagation, refStrainPropagation } = getAntigenicSegmentsByName(modelId, segmentNames);
        if (collaboratingCenter) acc.collaboratingCenters.add(collaboratingCenter);
        if (assay) acc.assays.add(assay);
        if (strainPropagation) acc.strainPropagations.add(strainPropagation);
        if (refStrainPropagation) acc.refStrainPropagations.add(refStrainPropagation);
        return acc;
    }, {
        collaboratingCenters: new Set(),
        assays: new Set(),
        strainPropagations: new Set(),
        refStrainPropagations: new Set(),
    });
    // console.log(valueSet)
    const res = Object.keys(valueSet).reduce((acc, key) => {
        acc[key] = [...valueSet[key]].map(id => ({ id }));
        return acc;
    }, {});
    // console.log(modelIds);
    // console.log(res);
    return res;
};

export const getMeasureScaleParamName = measure => measure && measure.scale && isPlainObject(measure.scale) ? Object.keys(measure.scale)[0] : undefined;


export const getAntigenicValue = (id, nodeClade, clades, antigenicModel, antigenicDataType) => {
    if (antigenicDataType === 'epitope_clades' || antigenicDataType === 'raw_strain') {
        return antigenicModel[id];
    }

    if (!clades || !clades[nodeClade] || !clades[nodeClade].cladeMapping?.antigenic_clade?.alpha) return null;

    const nodeAlpha = nodeClade && clades[nodeClade] ? clades[nodeClade].cladeMapping.antigenic_clade.alpha : null;
    return antigenicModel?.[nodeAlpha];
};
