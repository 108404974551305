import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus, resetComponentsStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
import { fetchReferenceStrains, resetReferenceStrains } from '../../../redux/actions/treeDataActions';
import { dateToDays, emptyObject } from '../../../functions/utils';
import { strainCutOffDateSelector } from '../../../redux/selectors/parametersSelector';

const viewToRender = 'strainTree';
const componentId = 'referenceStrains';

const ReferenceStrainsLayer = (props) => {
    const _element = useRef();
    const { loading, showReferenceStrains, referenceStrainNodes, renderStatus, setComponentStatus, fetchReferenceStrains, lineage, zoomNodeId, strainCutOffDate, strainSubset, resetReferenceStrains } = props;

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);


    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE || loading) return;
        startRender()
            .then(() => treeD3.translateReferenceStrains())
            .then(() => stopRender());
    }, [renderStatus]);


    useEffect(() => {
        if (showReferenceStrains) { 
            startRender();
            fetchReferenceStrains({ lineage, zoomNodeId, strainCutOffDate: dateToDays(strainCutOffDate), strainSubset });
        }
        if (!showReferenceStrains && !emptyObject(referenceStrainNodes)) {
            resetReferenceStrains();
            startRender()
                .then(() => treeD3.drawReferenceStrainsLayer(_element.current))
                .then(() => stopRender());
        }
    }, [showReferenceStrains, strainCutOffDate, zoomNodeId, strainSubset]);

    useEffect(() => {
        if (loading || !showReferenceStrains) return;
        startRender()
            .then(() => treeD3.drawReferenceStrainsLayer(_element.current))
            .then(() => stopRender());
    }, [loading]);

    return <g id="referenceStrains" transform={treeD3.translate(false)} ref={_element} />;
};

ReferenceStrainsLayer.propTypes = {
    layout: PropTypes.string,
    renderStatus: PropTypes.string,
    loading: PropTypes.bool,
    showReferenceStrains: PropTypes.bool,
    setComponentStatus: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    const { lineage, zoomNodeId, strainSubset } = state.parameters;
    const strainCutOffDate = strainCutOffDateSelector(state);

    return {
        loading,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        layout: state.parameters.layout,
        showReferenceStrains: state.parameters.showReferenceStrains,
        treeScaleTypeX: state.parameters.treeScaleTypeX,
        treeScaleTypeY: state.parameters.treeScaleTypeY,
        lineage,
        zoomNodeId,
        strainCutOffDate,
        strainSubset,
        referenceStrainNodes: state.treeData.referenceStrainNodes
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
            fetchReferenceStrains,
            resetReferenceStrains,
            resetComponentsStatus
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceStrainsLayer);
