import React from 'react';
import { FormControl, MenuItem } from '@mui/material';
import CustomLabel from '../../../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../../../assets/GlobalStyles/CustomSelect';   
import { capitalize } from 'lodash';

const SizeSelect = ({ value, onChange, className }) => {
    return (
        <FormControl
            fullWidth
            className={className}
        >
            <CustomLabel id={'size'} label={'Size'} />
            <CustomSelect
                value={value}
                onChange={onChange}
                inputProps={{
                    name: 'size',
                    id: 'size',
                }}
            >
                {[ 'small', 'medium', 'big' ].map((option) => (
                    <MenuItem key={option} value={option}>{capitalize(option)}</MenuItem>
                ))}
            
            </CustomSelect>
        </FormControl>
    );
};

export default SizeSelect;
