import { produce } from 'immer';
import {
    SET_COLOR_BY,
    SET_MODEL_TYPE,
    SET_REFERENCE_CLADE,
    SET_REFERENCE_STRAIN,
    SET_ANTIGENIC_HIDDEN_ALPHA_CLADE,
    SET_ANTIGENIC_HIDDEN_RHO_CLADE,
    RESET_ANTIGENIC_HIDDEN_CLADES,
    FETCH_LINEAGE_SUCCESS,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS,
    SET_PARAMETERS,
    INIT_STRAIN_TREE_SUCCESS,
    ZOOM_TREE_SUCCESS,
    FETCH_TREE_FREQS_SUCCESS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    TOGGLE_FREQUENCIES_OPTION,
    TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY,
    RESET_VISIBLE_BINS,
    FETCH_FREQUENCIES_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    SET_MUTATION_CLASSES,
    SET_MEASURE_SCALE,
    SET_MEASURE_DOMAIN,
    GET_SIGNED_USER_SUCCESS,
    FETCH_SELECTED_STRAIN_REQUEST,
    SET_SEARCH_STRAIN_MODE,
    UNHIDE_ALPHA_CLADE,
    UNHIDE_RHO_CLADE,
    SELECT_NODE_DATA,
    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_MODEL_TYPES_SUCCESS,
    FETCH_MODELS_SUCCESS,
    SET_MODEL_REGION_ID,
    SET_SELECTED_MODELS,
    SET_SHOW_MUTATIONS_GROUPS,
    SET_LAYOUT_PARAMETERS,
    SET_SHOW_CLADE_LABELS,
    SIGNIN_SUCCESS,
    SET_BRANCH_NODES,
    SET_SELECTED_STRAIN,
    FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST,
    FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS,
    TOGGLE_SELECTED_VACCINE_CLADES,
    REVERT_SELECTED_VACCINE_CLADES,
    REVERT_SELECTED_FREQUENCIES_BINS,
} from '../actions/actionTypes';

import appConfig from '../../config/appConfig';
import { emptyObject } from '../../functions/utils';
import { prepareParameters } from '../../functions/url-parameters';
import { isNil, set } from 'lodash';

let parametersInitialState = {};
export const setParametersInitialState = (state) => {
    parametersInitialState = state;
};

export const parametersReducer = (state = parametersInitialState, action) => {

    if (action.payload && action.payload.settings) return state;
    
    return produce(state, draft => {
        switch (action.type) {
            case SIGNOUT_REQUEST: {
                return parametersInitialState;
                
            }
            case RESET_SESSION: {
                const lineage = action.payload?.lineage || parametersInitialState.lineage;
                Object.assign(draft, parametersInitialState, { lineage });
                break;
            }
           
            case SIGNIN_SUCCESS: {
                const { defaultLineage } = action.payload;
                draft.lineage = defaultLineage;
                break;
            }
            case FETCH_SELECTED_STRAIN_REQUEST: {
                const { strainId } = action.payload;
                draft.strainId = strainId;
                break;
            }

            case SET_SELECTED_STRAIN: {
                const { strainId } = action.payload;
                draft.strainId = strainId;
                draft.searchStrainMode = false;
                break;
            }
            case SET_SEARCH_STRAIN_MODE: {
                const { searchStrainMode } = action.payload;
                draft.searchStrainMode = searchStrainMode;
                break;
            }

            case SET_COLOR_BY: {
                const { colorBy } = action.payload;
                draft.colorBy = colorBy;
                break;
            }

            case SET_BRANCH_NODES: {
                const { branchNodes } = action.payload;
                draft.branchNodes = branchNodes;
                break;
            }

            case SET_MODEL_REGION_ID: {
                const { modelRegionId } = action.payload;
                draft.modelRegionId = modelRegionId;
                break;
            }
            case SET_MODEL_TYPE: {
                const { modelType } = action.payload;
                draft.modelType = modelType;
                break;
            }

            case SET_REFERENCE_CLADE: {
                const { refClade } = action.payload;
                draft.refClade = refClade;
                break;
            }
            case SET_REFERENCE_STRAIN: {
                const { refStrain } = action.payload;
                draft.refStrain = refStrain;
                break;
            }

            case SET_ANTIGENIC_HIDDEN_ALPHA_CLADE: {
                const { alpha } = action.payload;
                draft.hiddenAlphaClades[alpha] = true;
                break;
            }

            case SET_ANTIGENIC_HIDDEN_RHO_CLADE: {
                const { rho } = action.payload;
                draft.hiddenRhoClades[rho] = true;
                break;
            }

            case UNHIDE_ALPHA_CLADE: {
                const { alpha } = action.payload;
                delete draft.hiddenAlphaClades[alpha];
                break;
            }

            case UNHIDE_RHO_CLADE: {
                const { rho } = action.payload;
                delete draft.hiddenRhoClades[rho];
                break;
            }

            case RESET_ANTIGENIC_HIDDEN_CLADES: {
                draft.hiddenAlphaClades = {};
                draft.hiddenRhoClades = {};
                break;
            }

            case FETCH_FREQUENCIES_SUCCESS: {
                const { parameters, selectedBins } = action.payload;
          
                const _parameters = prepareParameters(parameters);
                if (draft.intro) {
                    _parameters.trackingTo = _parameters.freqsTo;
                    _parameters.trackingFrom = _parameters.freqsFrom;
                    _parameters.showPrediction = false;
                }
                Object.keys(_parameters).forEach(key => {
                    draft[key] = _parameters[key];
                });

                if (emptyObject(draft.visibleBins)) {
                    Object.keys(selectedBins).forEach(regId => {
                        Object.keys(selectedBins[regId]).forEach(bin => { draft.visibleBins[bin] = true; });

                    });
                }
                break;
            }

            case TOGGLE_FREQUENCIES_OPTION: {
                const { optionId } = action.payload;
                draft.visibleBins[optionId] = !draft.visibleBins[optionId];
                break;
            }

            case REVERT_SELECTED_FREQUENCIES_BINS: {
                Object.keys(draft.visibleBins).forEach(bin => { draft.visibleBins[bin] = !draft.visibleBins[bin]; });
                break;
            }

            case TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY: {
                const { id } = action.payload;
                draft.visibleMutationClassesLabels[id] = !(draft.visibleMutationClassesLabels[id] || false);
                break;
            }

            case RESET_VISIBLE_BINS: {
                const { selectedBins, strainSubset } = action.payload;
                if (!isNil(draft.visibleBins) && Object.keys(draft.visibleBins).length === 0) 
                    break;
                Object.keys(selectedBins[strainSubset]).forEach(bin => { draft.visibleBins[bin] = true; });
                break;
            }

            case FETCH_FREQUENCIES_REQUEST: {
                if (!draft.exportMode) draft.visibleBins = {};
                break;
            }


            case SET_SELECTED_MODELS: {
                const { selectedModels } = action.payload;
                draft.selectedModels = selectedModels;
                break;
            }

            case FETCH_LINEAGE_SUCCESS:
            case ZOOM_TREE_SUCCESS:
            case INIT_STRAIN_TREE_SUCCESS:
            case FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS:
            case FETCH_TREE_FREQS_SUCCESS:
            case SET_LAYOUT_PARAMETERS:
            case SET_PARAMETERS: {
                const { parameters } = action.payload;
                const _parameters = prepareParameters(parameters);
                // console.log('parametersReducer', parameters.zoomNodeId, typeof parameters.zoomNodeId, _parameters.zoomNodeId, typeof _parameters.zoomNodeId);
                if (action.type === FETCH_LINEAGE_SUCCESS && window.innerWidth < 786)
                    _parameters.showCladeBarLabels = false;

                Object.keys(_parameters).forEach(key => {
                    draft[key] = _parameters[key];
                });
                break;
            }

            case SET_SHOW_MUTATIONS_GROUPS: {
                const { showMutationsGroups } = action.payload;
                draft.showMutationsGroups = showMutationsGroups;
                break;
            }

            case SET_SHOW_CLADE_LABELS: {
                const { showCladeLabels } = action.payload;
                draft.showCladeLabels = showCladeLabels;
                break;
            }

            case SET_MEASURE_SCALE: {
                const { measure, scaleName } = action.payload;
                if (scaleName === draft.colorScales[measure]) 
                    break;
                draft.colorScales[measure] = scaleName;
                delete draft.calculatedDomain[measure];
                break;
            }

            case SET_MEASURE_DOMAIN: {
                const { measureName, min, max, paramForMeasure } = action.payload;
                if (paramForMeasure && draft[paramForMeasure] !== undefined) {
                    const value = draft[paramForMeasure];
                    const path = [`calculatedDomain`, measureName, paramForMeasure, value];
                    
                    // Initialize the nested object if it doesn't exist and set min and max
                    set(draft, [...path, 'min'], min);
                    set(draft, [...path, 'max'], max);
                } else {
                    const path = [`calculatedDomain`, measureName];
                    
                    // Initialize the measureName object if it doesn't exist and set min and max
                    set(draft, [...path, 'min'], min);
                    set(draft, [...path, 'max'], max);
                }

                break;
            }

            case FETCH_MUTATION_CLASSES_SUCCESS: {
                const { mutationClasses } = action.payload;
                if (draft.exportMode) break;

                draft.visibleMutationClasses = Object.keys(mutationClasses).reduce((acc, mutClass) => {
                    const mutation = Object.keys(mutationClasses[mutClass]).reduce((macc, mutName) => {
                        macc[mutName] = true;
                        return macc;
                    }, {});
                    acc[mutClass] = mutation;
                    return acc;
                }, {});
                break;
            }

            case SET_MUTATION_CLASSES: {
                const { mutType, mutClass } = action.payload;
                draft.visibleMutationClasses[mutType][mutClass] = !draft.visibleMutationClasses[mutType][mutClass];
                break;
            }

            case GET_SIGNED_USER_SUCCESS: {
                const { defaultLineage, lineages } = action.payload.user;

                // console.log(`[GET_SIGNED_USER_SUCCESS]`, { defaultLineage, lineages });
                // Determine the default lineage
                let defaultLineageSettled = defaultLineage || draft.lineage || appConfig.default.lineage;

                // If lineages are provided and the settled lineage isn't in them, use the first lineage
                if (Array.isArray(lineages) && lineages.length > 0 && !lineages.map(({lineage}) => lineage).includes(defaultLineageSettled)) {
                    defaultLineageSettled = lineages[0].lineage;
                }
                // Update the draft state with the settled lineage
                draft.lineage = defaultLineageSettled;
                break;
            }

            case SELECT_NODE_DATA: {
                const { nodeId, onlyHighlight } = action.payload || {};
                const _onlyHighlight = onlyHighlight || false;
                draft.searchStrainMode = nodeId && !_onlyHighlight ? draft.searchStrainMode : false;
                break;
            }

            case FETCH_HUMAN_POOLS_SUCCESS: {
                const { humanPools } = action.payload;
                // If humanPools are provided and non-empty
                if (Array.isArray(humanPools) && humanPools.length > 0) {
                    // If current humanPool is not set or not in the new humanPools, default to the first pool
                    if (!draft.humanPool || !humanPools.includes(draft.humanPool)) {
                        draft.humanPool = humanPools[0];
                    }
                }
                // No action needed if humanPools is empty or undefined
                break;
            }


            case FETCH_MODEL_TYPES_SUCCESS: {
                const { modelTypes } = action.payload;

                // Ensure modelTypes is a non-empty array
                if (Array.isArray(modelTypes) && modelTypes.length > 0) {
                    // If current modelType is not in the new modelTypes, set it to the first one
                    if (!draft.modelType || !modelTypes.includes(draft.modelType)) {
                        draft.modelType = modelTypes[0];
                    }
                    // Else, retain the current modelType
                } 
                break;
            }

            case FETCH_MODELS_SUCCESS: {
                const { models, colorBy } = action.payload;

                // Ensure models is a non-empty array
                if (Array.isArray(models) && models.length > 0) {
                    // Determine which model ID to update based on colorBy
                    const modelVar = (colorBy === 'antigenic') ? 'antigenicModelId' : 'modelId';
                    const currentModelId = draft[modelVar];
            
                    // Check if current modelId exists in the new models array
                    if (!models.includes(currentModelId)) {
                        // If not, set modelId to the first element of models
                        draft[modelVar] = models[0];
                    }
                    // If it exists, retain the current modelId
                } 
                break;
            }
            case FETCH_VACCINE_DEFAULT_SELECTIONS_REQUEST: {
                draft.vaccinesRhos = null;
                draft.vaccinesFerretRefStrains = null;
                draft.vaccinesHumanRefStrains = null;
                break;
            }
            case FETCH_VACCINE_DEFAULT_SELECTIONS_SUCCESS: {
                const vaccineDefaultSelections = action.payload;
                draft.vaccinesRhos = vaccineDefaultSelections.rhos;
                draft.vaccinesFerretRefStrains = vaccineDefaultSelections.ferret_refstrains;
                draft.vaccinesHumanRefStrains = vaccineDefaultSelections.human_refstrains;
                break;
            }

            case TOGGLE_SELECTED_VACCINE_CLADES: {
                const { cladeId } = action.payload;
                // console.log('TOGGLE_SELECTED_VACCINE_CLADES', { cladeId, type: typeof cladeId, vaccinesRhos: state.vaccinesRhos });
                if (state.vaccinesRhos.includes(cladeId)) {
                    // console.log('INCLUDES');
                    draft.vaccinesRhos = draft.vaccinesRhos.filter(id => id !== cladeId);
                } else {
                    // console.log('NOT INCLUDES');
                    draft.vaccinesRhos.push(cladeId);
                }
                break;
            }
            case REVERT_SELECTED_VACCINE_CLADES: {
                const { selectedClades } = action.payload;
                // console.log('REVERT_SELECTED_VACCINE_CLADES', { selectedClades });
                draft.vaccinesRhos = Object.entries(selectedClades).filter(([_, value]) => !value).map(([key]) => +key);
                // console.log('REVERT_SELECTED_VACCINE_CLADES =>', { vaccinesRhos: current(draft).vaccinesRhos });
                break;
            }

            default: {
                break;
            }
        }
    });
};
