import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditMeasureDialog from '../Dialogs/EditMeasureDialog';
import { postAxios } from '../../../../functions/axios-requests';
import { actionsStyles } from '../../Styles/actionsStyles';
import config from '../../../../config/envConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllMeasures } from '../../../../redux/actions/settingsActions';

const EditMeasure = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { measure, lineages, setInfoDialog, setInfo, setAction, fetchAllMeasures, colorScalesLength } = props;
    const classes = actionsStyles();

    const handleClick = async () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const updateMeasure = async (body) => {
        try {
            const url = `${config.serverLink}/api/admin/updateMeasure`;
            await postAxios(url, body);
            setInfo(`Measure ${measure.measure} was updated sucessfully`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during updating ${measure.measure}.: ${message}`);
        }
        finally {
            handleCloseDialog();
            setAction('Update measure');
            setInfoDialog(true);
            fetchAllMeasures();
        }
    };

    // console.log(measure.measure, openDialog, scales);
    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <EditIcon />
            </IconButton>
            {colorScalesLength > 0 && openDialog &&
                <EditMeasureDialog 
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    updateMeasure={updateMeasure}
                    measure={measure}
                    lineages={lineages} 
                />
            }
        </>
    );
};

const mapStateToProps = (state) => {
    const { colorScales } = state.settings;
    const { lineages } = state.lineages;

    // const scales = colorScales.map(scale => {
    //     const colors = typeof scale.range === 'string' ?
    //         scalesPalette[scale.range] : scale.range;
    //     return { id: scale.scaleId, lineage: scale.lineage, colors };
    // });

    return {
        // scales,
        lineages,
        colorScalesLength: colorScales.length
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllMeasures
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditMeasure);
