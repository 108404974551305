import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import PropTypes from 'prop-types';
import { titleCase } from '../../../functions/utils';
import { getSelectedMeasureGeoMapLegend, getSelectedMeasureTreeLegend } from '../../../redux/selectors/rangeDataSelector';
import { setActiveLegendOption } from '../../../redux/actions/nodeActions';
import { useStyles } from './styles';
import { splitDataToChunks } from '../Elements/functions';
import LegendElement from '../Elements/LegendElement';
import ChunkedLegendElement from '../Elements/ChunkedLegendElement';

const TreeLegend = ( { value, collSize, setActiveLegendOption, exportMode, colorBy, editMode, colorData, activeLegendOption, searchStrainMode, nodeInfo } ) => {
    const classes = useStyles();
    const legendWrapper = useRef(null);
    

    // if (legendWrapper.current) console.log(legendWrapper.current, 
    //     legendWrapper.current.getBoundingClientRect().height,
    //     legendWrapper.current?.parentNode.getBoundingClientRect().height,
    //     legendWrapper.current?.parentNode?.parentNode.getBoundingClientRect().height,
    // );

   
    // const space = legendWrapper.current?.parentNode.getBoundingClientRect().height;
    // const elemCnt = Math.floor(space / 26);
    // const maxHeight = 26 * elemCnt; //legendWrapper.current?.parentNode.getBoundingClientRect().height - 60;

    // Automatically scroll to the active legend option
    useEffect(() => {
        if (legendWrapper.current) {
            const activeElement = legendWrapper.current.querySelector(`#legend-${colorBy}-${value}`);
            console.log('[TreeLegend]', {activeElement});
            if (activeElement) {
                const offset = activeElement.getBoundingClientRect().top - legendWrapper.current.getBoundingClientRect().top;
                legendWrapper.current.scrollTop += offset;
            }
        }
    }, [value]);

    const data = useMemo(() => {
        return Object.keys(colorData || {})
            .map(key => ({
                key, 
                color: colorData[key].color,
                label: (colorBy === 'loc') ? titleCase(colorData[key].label) : (colorData[key].label || colorData[key].info || colorData[key].value)
            }));
    }, [colorData]);
 
    const colorChunks = exportMode && editMode ? splitDataToChunks(data, collSize) : [];
    // console.log('[TreeLegend]', {colorBy, value, colorData, data});
    const legendWrapperClassName = `${
        !exportMode 
            ? nodeInfo 
                ? classes.rootHeightNodeInfo 
                : classes.rootHeight 
            : classes.rootExport} ${classes.rootTreeLegend} active`;

    const legendWrapperInnerClassName = `${
        !exportMode
            ? nodeInfo
                ? classes.legendWrapperNodeInfo
                : classes.legendWrapperHeight
            : classes.legendWrapperExport
    } ${classes.legendWrapper}`;

    if (!colorData) return null;
    return (
        <>
            {!editMode &&
            <div ref={legendWrapper} className={legendWrapperClassName} /*style={{maxHeight}}*/ >
                <div id="legendWrapper" className={legendWrapperInnerClassName}>
                    {data.map((color) => {
                        const isActive = color.key === value || activeLegendOption.value === color.key;
                        const border = `1px solid ${isActive ? '#474747' : color.color}`;
                        const textDecoration = isActive ? 'underline' : 'none';
                        const fontSize = exportMode ? '10px' : colorBy === 'loc' ? '14px' : '12px';
                        const size = exportMode ? 12 : 17;
                        
                        return (
                            <LegendElement
                                key={`legend-${colorBy}-${color.key}`}
                                id={color.key}
                                selected={true}
                                fontSize={fontSize}
                                handleEnter={() => !searchStrainMode && setActiveLegendOption({ value: color.key, option: colorBy })}
                                handleLeave={() => !searchStrainMode && setActiveLegendOption({ value: '', option: colorBy })}
                                label={color.label}
                                color={color.color}
                                border={border}
                                textDecoration={textDecoration}
                                size={size}
                            />
                        );
                    })}
                </div>
            </div>
            }
            {exportMode && editMode && (
                <Grid id="legendWrapper" container columns={20} className={classes.legendEditWrapper}>
                    {colorChunks.map((colorChunk, index) => (
                        <Grid className={classes.legendEdit} key={`legend-chunk-${index}`} xs={Math.floor(20 / collSize)}>
                            {colorChunk.map(color => (
                                <ChunkedLegendElement key={color.key} color={color}/>
                            ))}
                        </Grid>
                    ))}
                </Grid>

            )}
        </>
    );

};


TreeLegend.propTypes = {
    colorBy: PropTypes.string,
    regions: PropTypes.shape({}),
    transmissions: PropTypes.shape({}),
    visibleTreeClades: PropTypes.shape({}),
    classes: PropTypes.PropTypes.shape({
        root: PropTypes.string,
        rootExport: PropTypes.string,
        title: PropTypes.string,
        titleExport: PropTypes.string,
        legendClass: PropTypes.string,
    }),
    exportMode: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const colorBy = ownProps.mapSelector
        ? state.parameters.geoMapColorBy
        : state.parameters.colorBy;
    const { exportMode, editMode } = state.parameters;

    //const measures = getMetadataMeasuresWithScales(state);
    const { activeLegendOption } = state.nodeData;
    const { searchStrainMode } = state.parameters;


    const { valueGetter } = ownProps;
    const value = valueGetter ? valueGetter(state) : null;

    //const selectedMeasure = measures[colorBy] || {};
    const colorData = ownProps.mapSelector
        ? getSelectedMeasureGeoMapLegend(state)
        : getSelectedMeasureTreeLegend(state);// selectedMeasure.scale?.range.data; //visibleRange;
   

    return ({
        colorBy,
        exportMode,
        editMode,
        colorData,
        value: `${value}`,
        activeLegendOption,
        searchStrainMode,

    });
};

const mapDispatchToProps = {
    setActiveLegendOption
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeLegend);
