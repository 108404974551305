import React, { useState } from 'react';
import { Button } from '@mui/material';
import AddUserDialog from '../Dialogs/AddUserDialog';
import { styles } from '../styles';
import { postAxios, fetchAxios } from '../../../../functions/axios-requests';
import config from '../../../../config/envConfig';

const AddUser = (props) => {
    const { setLoaded, setInfoDialog, setInfo, setAction } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [roles, setRoles] = useState([]);
    const classes = styles();

    const handleClick = async () => {
        await fetchRoles();
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchRoles = async () => {
        try {
            const url = `${config.serverLink}/api/admin/getAllRoles`;
            const response = await fetchAxios(url);
            const { roles } = response.data;
            setRoles(roles);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during fetching roles: ${message}`);
            handleCloseDialog();
            setInfoDialog(true);
        }
    };

    const addUser = async (user) => {
        try {
            const url = `${config.serverLink}/api/admin/addUser`;
            const body = { user };
            await postAxios(url, body);
            setInfo(`User ${user.email} added successfully.`);
        }
        catch (error) {
            console.error('Error adding user:', error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during adding ${user.email}: ${message}`);
        } finally {
            setAction('Add user');
            setLoaded(false);
            handleCloseDialog();
            setInfoDialog(true);
        }

    };

    return (
        <>
            <Button className={classes.addButton} onClick={handleClick}>
                Add user
            </Button>
            {roles.length > 0 && <AddUserDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} addUser={addUser} roles={roles} />}
        </>

    );
};

export default AddUser;
