import React from 'react';
import { connect } from 'react-redux';
import { getX, getY } from '../helpers/functions';

const CladeLabelLink = (props) => {
    const { xMod, yMod, xPos, x, yPos, y,  xAnchor, yAnchor, labelWidth, labelHeight, id } = props;

    const anchorPoint = { x: 0, y: 0 };

    //const xBranchPos = getXBranch(id);
    //const yPos = getY(id);

    const xDiff = x - xAnchor;
    const yDiff = y - yAnchor;

    // const branchWidth = (getScaledValue('freqScale', treeD3.nodeFrequency(id)) || 2) / 2;
    const getClosestCorner = () => {

        const x0 = xDiff + xMod;
        const y0 = yDiff + yMod - labelHeight;
        const corners = [
            { x: x0, y: y0 },
            { x: x0, y: y0 + labelHeight / 2 },
            { x: x0, y: y0 + labelHeight },
            { x: x0 + labelWidth / 2, y: y0 },
            { x: x0 + labelWidth / 2, y: y0 + labelHeight / 2 },
            { x: x0 + labelWidth / 2, y: y0 + labelHeight },
            { x: x0 + labelWidth, y: y0 },
            { x: x0 + labelWidth, y: y0 + labelHeight / 2 },
            { x: x0 + labelWidth, y: y0 + labelHeight },
        ];

        const getDistance = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

        const { selected } = corners.reduce((acc, c, index) => {
            const dist = getDistance(c, anchorPoint);
            if (!acc.dist || dist < acc.dist) {
                acc.selected = index;
                acc.dist = dist;
            }
            return acc;
        }, {});
        return corners[selected];
    };

    const linkDiagonal = () => {
        const corner = getClosestCorner();
        return `M${corner.x},${corner.y},L${anchorPoint.x},${anchorPoint.y}`;
    };

    // useEffect(() => {
    //     //console.log('[CladeLabelLink].rerenderLabels', rerenderLabels)
    //     if (rerenderLabels) {
    //         //console.log('[CladeLabelLink].back')
    //         onElementRendered();
    //     }
    // }, [onElementRendered]);

    return (
        <path
            transform={`translate(${xPos}, ${yPos})`}
            id={`${id}_cladeLabelLink`}
            style={{
                stroke: '#000000',
                strokeWidth: 1
            }}
            d={linkDiagonal()} />

    );
};


const mapStateToProps = (state, ownProps) => {
    //const treeAttrs = getTreeNodeAttrs(state);

    const labelPos = state.render.labels?.[ownProps.classNamePrefix]?.[ownProps.id] || {};
    const xPos = getX(ownProps.id);
    const yPos = getY(ownProps.id);
    return {
        xPos,
        yPos,
        ...labelPos,
        x: labelPos?.x || xPos,
        y: labelPos?.y || yPos,
        labelWidth: labelPos?.labelWidth || 0,
        labelHeight: labelPos?.labelHeight || 0,
        xMod: labelPos?.xMod || 0,
        yMod: labelPos?.yMod || 0,
        xAnchor: labelPos?.xAnchor || 0,
        yAnchor: labelPos?.yAnchor || 0,
    };
};

export default connect(mapStateToProps)(CladeLabelLink);
