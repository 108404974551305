import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, MenuItem } from '@mui/material';
import { DialogSX, styles } from '../../../../pages/SettingsPanel/Styles/dialogStyles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import { getMeasureScaleParamName } from '../../../../functions/data-helpers';
import { colorScalesSelector } from '../../../../redux/selectors/settingsSelector';
import { connect } from 'react-redux';
import { ColorScaleSelectorBase } from '../../../../components/OptionsSelector/ColorScaleSelector/ColorScaleSelectorBase';
import ShapeSelect from './components/ShapeSelect';
import SizeSelect from './components/SizeSelect';
import Checkboxes from './components/Checkboxes';   
import NameLabel from './components/NameLabel';

const dialogInitState = {
    measure: '',
    lineage: '',
    scaleType: '',
    scale: '',
    size: '',
    shape: '',
    label: '',
    discrete: false,
    numeric: false,
    colorBy: false,
    frequenciesChart: false,
    geoColorBy: false,
    xScale: false,
    yScale: false,
    ignoreStrainCutOffDate: false,
    show: 'allValues',
    element: 'node'
};

const EditMeasureDialog = ({ measure, scales, openDialog, handleCloseDialog, lineages, updateMeasure }) => {
    const [updatedMeasure, setUpdatedMeasure] = useState(dialogInitState);
    // const [scalesOptions, setScalesOptions] = useState([]);
    // const [scaleTypes, setScaleTypes] = useState([]);
    const [param, setParam] = useState();
    //const [isCustom, setIsCustom] = useState(false);
    const type = measure.element;
    const classes = styles();


    const scaleTypes = useMemo(() => {
        const opts = Object.values(scales).map(el => el.id.split('.')[0]);
        return [...new Set(opts)];
    }, [scales]);

    useEffect(() => {
        let scaleType = measure?.scale?.split('.')[0];
        if (typeof measure?.scale !== 'string') {
            scaleType = 'antigenic';
            const paramName = getMeasureScaleParamName(measure);
            const paramValues = Object.keys(measure.scale[paramName]);
            setParam({ paramName, paramValues });
        }
        // setIsCustom(measure.custom ? true : false);
        setUpdatedMeasure({
            measure: measure.measure,
            label: measure.label,
            scaleType,
            scale: measure.scale,
            size: measure?.size,
            shape: measure?.shape,
            show: typeof measure?.show !== 'object' && measure?.show || 'none',
            lineage: measure?.lineage || 'none',
            discrete: measure?.discrete || false,
            numeric: measure?.numeric || false,
            colorBy: measure?.colorBy || false,
            frequenciesChart: measure?.frequenciesChart || false,
            geoColorBy: measure?.geoColorBy || false,
            xScale: measure?.xScale || false,
            yScale: measure?.yScale || false,
            element: measure?.element || 'node',
            ignoreStrainCutOffDate: typeof measure?.ignoreStrainCutOffDate !== 'object' && measure?.ignoreStrainCutOffDate || false,
            custom: measure?.custom || false,
        });

    }, []);

    const scalesOptions = useMemo(() => {
        const { lineage, scaleType} = updatedMeasure;
        const scalesOpts = Object.values(scales)
            .filter((scale) => {
                const good =
                    (!scale.lineage ||
                        (scale.lineage && scale.lineage === lineage)) &&
                    scale.id.split('.')[0] === scaleType;
                return good;
            })
            .map((scale) => scale.id);
        return [...new Set(scalesOpts)];
    }, [updatedMeasure]);

    const handleChange = (name) => (value) => setUpdatedMeasure({ ...updatedMeasure, [name]: value });
    const handleCheckboxChange = (type) => (checked) => setUpdatedMeasure({ ...updatedMeasure, [type]: checked });
    const handleScaleChangeParametrized = (type) => (value) =>
        setUpdatedMeasure({ ...updatedMeasure, 
            scale: { 
                [param.paramName]: { 
                    ...updatedMeasure.scale[param.paramName], 
                    [type]: value 
                } 
            } 
        });

    const handleSubmit = () => {
        const body = {
            updatedMeasure,
        };
        updateMeasure(body);
        setParam('');
        setUpdatedMeasure({ ...dialogInitState });
    };

    const _scale = typeof updatedMeasure.scale == 'string' ? updatedMeasure.scale : '';
    // const xs = 12;

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={DialogSX}>
            <DialogTitle id="confirm-dialog-title">
                Edit measure: {measure.measure}
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <NameLabel
                    measure={updatedMeasure}
                    onChange={handleChange}
                />
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'plotType'} label={'Lineage'} />
                    <CustomSelect
                        value={updatedMeasure.lineage}
                        onChange={handleChange('lineage')}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        <MenuItem key={'empty'} value={'none'} >{'(all)'}</MenuItem>
                        {lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}

                    </CustomSelect>
                </FormControl>
                {measure.custom && <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'show'} label={'Show'} />
                    <CustomSelect
                        value={updatedMeasure.show}
                        onChange={handleChange('show')}
                        inputProps={{
                            name: 'show',
                            id: 'show',
                        }}
                    >
                        <MenuItem key={'none'} value={'none'} >none</MenuItem>
                        <MenuItem key={'allValues'} value={'allValues'} >allValues</MenuItem>
                        <MenuItem key={'valueChange'} value={'valueChange'} >valueChange</MenuItem>
                    </CustomSelect>
                </FormControl>}
                {measure.custom && <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'element'} label={'Element'} />
                    <CustomSelect
                        value={updatedMeasure.element}
                        onChange={handleChange('element')}
                        inputProps={{
                            name: 'element',
                            id: 'element',
                        }}
                    >
                        <MenuItem key={'node'} value={'node'} >Node</MenuItem>
                        <MenuItem key={'branch'} value={'branch'} >Branch</MenuItem>
                    </CustomSelect>
                </FormControl>}
                <Checkboxes
                    measure={updatedMeasure}
                    handleCheckboxChange={handleCheckboxChange}
                />

                {param ?
                    <>
                        {
                            scalesOptions.length > 0 && param.paramValues.map((elem) => {
                                const filteredOptions = scalesOptions.filter(opt => opt.includes(elem));

                                return (
                                    <div key={elem}>
                                        <ColorScaleSelectorBase
                                            id={`${param.paramName}_scale`}
                                            label={`Scale for ${param.paramName}: ${elem}`}
                                            value={updatedMeasure.scale[param.paramName][elem]}
                                            onChange={handleScaleChangeParametrized(elem)}
                                            options={filteredOptions}
                                            getOptionLabel={(scale)=> scale}
                                            getOptionValue={scale => scale}
                                            scales={scales}
                                            isTimeScale={false}
                                            disabled={scalesOptions.length < 2}
                                        />
                                    </div>

                                );
                            })
                        }
                    </>
                    :
                    <>
                        <FormControl fullWidth className={classes.formControlLineage}>
                            <CustomLabel id={'scaleType'} label={'Scale type'} />
                            <CustomSelect
                                value={updatedMeasure.scaleType}
                                onChange={handleChange('scaleType')}
                                inputProps={{
                                    name: 'scaleType',
                                    id: 'scaleType',
                                }}
                            >
                                {scaleTypes.length > 0 && scaleTypes.map(option =>
                                    (<MenuItem key={option} value={option} >{option}</MenuItem>)
                                )}
                            </CustomSelect>
                        </FormControl>
                        <ColorScaleSelectorBase
                            id={'scale'}
                            label={'Scale'}
                            value={_scale}
                            onChange={handleChange('scale')}
                            options={scalesOptions}
                            getOptionLabel={(scale)=> scale}
                            getOptionValue={scale => scale}
                            scales={scales}
                        />
                    </>
                }
                {type === 'branch' &&
                    <>
                        <SizeSelect
                            value={updatedMeasure.size}
                            onChange={handleChange('size')}
                            className={classes.formControlLineage}
                        />  
                        <ShapeSelect
                            value={updatedMeasure.shape}
                            onChange={handleChange('shape')}
                            className={classes.formControlLineage}
                        />  
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
const mapStateToProps = (state) => {
    const scales = colorScalesSelector(state);
    return { scales };
};

export default connect(mapStateToProps)(EditMeasureDialog);
