import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { actionsStyles } from '../../Styles/actionsStyles';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axios-requests';
import config from '../../../../config/envConfig';
import { fetchAllScales } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ScaleDialog from '../Dialogs/ScaleDialog';

const EditScale = ({ scale, scalesPalette, fetchAllScales, setInfoDialog, setInfo, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const updateScale = async (scale, domain, hasLineageChanged, hasIdChanged, oldScaleId) => {
        try {
            const url = `${config.serverLink}/api/admin/updateScale`;
            const body = { scale, domain, hasLineageChanged, hasIdChanged, oldScaleId };
            await postAxios(url, body);
            setInfo(`Scale ${scale.scaleId} was updated sucessfully`);     
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during updating ${scale.scaleId}.: ${message}`);
        }
        finally {
            setAction('Update scale');
            setOpenDialog(false);
            setInfoDialog(true);
            fetchAllScales();
        };          
    };

    return (
        <>
            <IconButton className={classes.button} onClick={handleClick}>
                <EditIcon />
            </IconButton>
            { openDialog && scale && <ScaleDialog scale={scale} scalesPalette={scalesPalette} openDialog={openDialog} handleCloseDialog={handleCloseDialog} submit={updateScale} /> }
        </>);
};

const mapStateToProps = (state) => {
    const { scalesPalette } = state.settings;

    return {
        scalesPalette,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllScales
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditScale);
