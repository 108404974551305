import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutocompleteInput from '../../Common/AutocompleteInput';
import { getAllHumanRefStrainsWithLabAndClade, sortedFreqHumanStrainsSelector, vaccineStrainId } from '../../../redux/selectors/vaccinesSelector';
import { setParameters } from '../../../redux/actions/parametersActions';
import { vaccinesDefaultsStatusSelector } from '../../../redux/selectors/statusSelector';

const defaultSearchId = 'ferret_refstrains';

const HumanReferenceStrainsSelector = ({
    label = 'Human reference strains',
    searchId = defaultSearchId,
    vaccinesRefStrains,
    refStrainsNames,
    initStrainsList,
    setParameters,
    loaded
}) => {

    const handleSelectedStrain = (selectedStrains) => {
        const _selectedStrains = selectedStrains.map(strain => {
            const {refid, lab, season} = JSON.parse(strain);
            return [refid, lab, season];
        });
        setParameters({vaccinesHumanRefStrains: _selectedStrains});
    };

    const initStrainsListWithLab = useMemo(() => {
        const list = (initStrainsList||[]).map(strain => ({
            id: vaccineStrainId(strain),
            name: `${strain.season} (${strain.lab})`,
        })).sort((a, b) => a.name.localeCompare(b.name));
        return list;
    }, [initStrainsList]);


    const fetchOptionLabel = useCallback(async (strainLabId) => {
        const { lab, season } = JSON.parse(strainLabId);
        return `${season} (${lab})`;
    }, [refStrainsNames]);
    
    const getOptionLabelMemo = useCallback((option) => option?.name ?? '', []);
    
    const isOptionEqualToValueMemo = useCallback((option, value) => {
        if (!option || !value) return false;        
        return option.id === value.id;
    }, []);

    if (!loaded) return null;

    return (
        <AutocompleteInput
            id={searchId}
            label={label}
            initialOptions={initStrainsListWithLab}
            getOptionLabel={getOptionLabelMemo}
            isOptionEqualToValue={isOptionEqualToValueMemo}
            valueIds={vaccinesRefStrains}
            onSelect={handleSelectedStrain}
            fetchOptionLabel={fetchOptionLabel}
            shouldFetchOptions={false}
            multiple={true}
            showMinTextLength={false}
        />
    );
};

HumanReferenceStrainsSelector.propTypes = {
    searchId: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    vaccinesRefStrains: PropTypes.array,
    treeAttrs: PropTypes.object,
    initStrainsList: PropTypes.array,
    setParameters: PropTypes.func.isRequired,
    label: PropTypes.string
};

const mapStateToProps = (state) => {
    // const { lineage } = state.parameters;
    // const searchId = ownProps.searchId || defaultSearchId;
    const vaccinesDefaultsStatus = vaccinesDefaultsStatusSelector(state);
    return {
        // lineage,
        vaccinesRefStrains: sortedFreqHumanStrainsSelector(state),
        refStrainsNames: state.vaccines.vaccinesData.refStrains,
        initStrainsList: getAllHumanRefStrainsWithLabAndClade(state),
        loaded: state.vaccines.vaccinesDataStatus === 'loaded' 
            && vaccinesDefaultsStatus === 'loaded',
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(HumanReferenceStrainsSelector);
