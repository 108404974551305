import { useState, useEffect, useCallback } from 'react';

export const dynamicStyles = (isMobile) => ({
    root: {
        flexGrow: 1,
        height: isMobile ?  'calc(100vh - 80px)' : 'calc(100vh - 120px)' ,
        maxHeight: isMobile ?  'calc(100vh - 80px)' : 'calc(100vh - 120px)' ,
    },
    space: {
        marginBottom: '15px',
        height: isMobile ?  'calc(100vh - 80px)' : 'calc(100vh - 120px)' ,
        maxHeight: isMobile ?  'calc(100vh - 80px)' : 'calc(100vh - 120px)' 
    }
});

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
        height: typeof window !== 'undefined' ? window.innerHeight : 0,
    });
  
    useEffect(() => {
        if (typeof window === 'undefined') return;
  
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
  
        window.addEventListener('resize', handleResize);
        handleResize();
  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  
    return windowSize;
}

export function useElementSize(ref) {
    const [size, setSize] = useState({
        width: 0,
        height: 0
    });

    const handleResize = useCallback(() => {
        if (ref.current) {
            const { width, height } = ref.current.getBoundingClientRect();
            setSize({
                width,
                height
            });
        }
    }, [ref]);

    useEffect(() => {
        if (!ref.current) return;

        handleResize(); // Initial size

        // Create ResizeObserver
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, handleResize]);

    return size;
}
