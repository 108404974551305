import React, { useEffect, useState } from 'react';
import { styles } from '../Styles/panelStyles';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Table from '../../../components/Table/Table';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCustomTreeSubsets } from '../../../redux/actions/settingsActions';
import { shouldFetch } from '../../../functions/data-helpers';

const headers = [
    { name: 'key', label: 'Key' },
    { name: 'lineage', label: 'Lineage' },
    { name: 'label', label: 'Label' },
    { name: 'rule', label: 'Regex' },
    { name: 'actions', label: 'Actions' },
];

const orderInitState = {
    key: 'asc',
    lineage: 'none',
    label: 'none',
    rule: 'none',
    actions: 'none'
};

const searchState = {
    key: '',
    lineage: '',
    label: '',
};

const searchLabels = {
    key: 'Subset Key',
    lineage: 'Lineage',
    label: 'Label',
};

const searchOptionsState = {
    key: [],
    label: [],
    lineage: [],
};

function createData(subset) {
    const { key, label, showAll, highlightAll, lineage } = subset;
    const ruleString = subset.rule ? subset.rule.name : 'file based';

    return { key, label, lineage, rule: ruleString, showAll: showAll.toString(), highlightAll: highlightAll?.toString() };
}

const SubsetsPanel = (props) => {
    const { customSubsets, fetchCustomTreeSubsets, customSubsetsLength, status } = props;
    const [tableData, setTableData] = useState([]);
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);
    const classes = styles();

    useEffect(() => {
        if (shouldFetch(status))
            fetchCustomTreeSubsets();
    }, []);

    useEffect(() => {
        if (customSubsetsLength > 0)
            setTableSubsets();
    }, [customSubsets]);

    const setTableSubsets = () => {
        const rows = customSubsets.map((customSubset) => {
            return createData(customSubset);
        });
        const newOptions = {
            key: [...new Set(customSubsets.map(el => el.key))],
            label: [...new Set(customSubsets.map(el => el.label))],
            lineage: [...new Set(customSubsets.map(el => el.lineage))],
        };
        setSearchOptions({ ...newOptions });
        setTableData(rows);
    };

    return (
        <Grid container className={classes.container}>
            <Grid size={12} className={classes.item}>
                {tableData && tableData.length > 0 &&
                    <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                        <Table
                            name='subsets'
                            searchOptions={searchOptions}
                            orderInitState={orderInitState}
                            headers={headers}
                            tableData={tableData}
                            searchState={searchState}
                            searchLabels={searchLabels}
                        />
                    </Paper>
                }
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { customSubsets } = state.settings;

    return {
        status: customSubsets.status,
        customSubsets: customSubsets.data,
        customSubsetsLength: customSubsets.data?.length || 0,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCustomTreeSubsets,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubsetsPanel);
