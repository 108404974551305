import { PropTypes } from 'prop-types';

const RENDER_STATUS = {
    NONE: 'NONE',
    START: 'START',
    DONE: 'DONE',
    // DONE_PREPOSITIONING: 'DONE_PREPOSITIONING',
    // RERENDER_NEEDED: 'RERENDER_NEEDED'
};

const ZOOM_TYPES = {
    ZOOM_IN: 'ZOOM_IN',
    ZOOM_OUT: 'ZOOM_OUT',
    RESET_LAYOUT: 'RESET_LAYOUT',
    UPDATE_ZOOM_THRESHOLD: 'UPDATE_ZOOM_THRESHOLD',
    NONE: 'NONE'
};

const COMPONENTS = {
    FREQ_CHART: 'FREQ_CHART',
    SEQ_CHART: 'SEQ_CHART'
}


const EPSILON = 0.00000001;
// const ZOOM_STATUS = {

// }

const PDF_IMAGE_SIZE = {
    height: 390,
    width: 550
}

const LARGE_PDF_IMAGE_SIZE = {
    height: 2100,
    width: 1050
}

const VIEWS_NAMES = {
    CLADE: 'CLADE',
    REGIONAL_FREQ_REPORT: 'REGIONAL_FREQ_REPORT'
}

const VIEW_GRAPH_NR = {
    CLADE: 3,
    REGIONAL_FREQ_REPORT: 24
}

const THEME = {
    color: {
        white: "#FFFFFF",
        lightGray: "#EBEBEB",
        darkGray: "#393939",
        main: "#6F6CFF",
    },
    fontSize: {
        tableWeb: "14px",
        tableExport: "10px",
    },
};

const stringOrNumberProp = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

export { THEME, RENDER_STATUS, ZOOM_TYPES, EPSILON, PDF_IMAGE_SIZE, LARGE_PDF_IMAGE_SIZE, COMPONENTS, VIEWS_NAMES, VIEW_GRAPH_NR, stringOrNumberProp };
