import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { styles } from './styles';
import { RENDER_STATUS } from '../../../../config/consts';

const useStyles = makeStyles(styles);

const MutationClassesLabelLink = (props) => {
    const { classNamePrefix, x, y, xMod, yMod, labelWidth, labelHeight, xAnchor, yAnchor, renderStatus } = props;

    const classes = useStyles();

    const anchorPoint = { x: 0, y: 0 };


    const xDiff = x - xAnchor;
    const yDiff = y - yAnchor;


  
    const getClosestCorner = () => {
        const x0 = xDiff + xMod;
        const y0 = yDiff + yMod - labelHeight;
        const corners = [
            { x: x0, y: y0 },
            { x: x0, y: y0 + labelHeight / 2 },
            { x: x0, y: y0 + labelHeight },
            { x: x0 + labelWidth / 2, y: y0 },
            { x: x0 + labelWidth / 2, y: y0 + labelHeight / 2 },
            { x: x0 + labelWidth / 2, y: y0 + labelHeight },
            { x: x0 + labelWidth, y: y0 },
            { x: x0 + labelWidth, y: y0 + labelHeight / 2 },
            { x: x0 + labelWidth, y: y0 + labelHeight },
        ];

        const getDistance = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

        const { selected } = corners.reduce((acc, c, index) => {
            const dist = getDistance(c, anchorPoint);
            if (!acc.dist || dist < acc.dist) {
                acc.selected = index;
                acc.dist = dist;
            }
            return acc;
        }, {});
        return corners[selected];
    };
   
    const linkDiagonal = () => {
        const corner = getClosestCorner();
        return `M${corner.x},${corner.y},L${anchorPoint.x},${anchorPoint.y}`;
    };

    return (renderStatus === RENDER_STATUS.DONE && <g transform={`translate(${xAnchor}, ${yAnchor})`} >
        <path className={classes[`${classNamePrefix}Link`]} d={linkDiagonal()} />
    </g>);

};

const mapStateToPropsLink = (state, ownProps) => {

    const { id, mutClass, classNamePrefix } = ownProps;
    const labelPos = state.render.labels?.[classNamePrefix]?.[`${id}_${mutClass}`] || {};

    const { x, y, labelWidth, labelHeight, xMod, yMod, xAnchor, yAnchor } = labelPos;
    const renderStatus = state.render.viewToRender?.components?.mutationsClasses || RENDER_STATUS.NONE;
    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        showCladeLabels: state.parameters.showCladeLabels,
        x,
        y,
        labelWidth: labelWidth || 0,
        labelHeight: labelHeight || 0,
        xMod: xMod || 0,
        yMod: yMod || 0,
        xAnchor: xAnchor || 0,
        yAnchor: yAnchor || 0,
        renderStatus

    };
};

const _MutationClassesLabelLink = connect(mapStateToPropsLink)(MutationClassesLabelLink);


const MutationsClassesLabelLinks = (props) => {
    const { id, muts, classNamePrefix, rerenderLabels } = props;

    return <g>
        {muts.filter(({ showLabel }) => showLabel)
            .map(({ mutClass, mutations }, index) => (
                <_MutationClassesLabelLink
                    key={`link_${mutClass}_${id}`}
                    id={id}
                    mutClass={mutClass}
                    classNamePrefix={classNamePrefix}
                    mutations={mutations}
                    index={index}
                    rerenderLabels={rerenderLabels}
                />
            ))}
    </g>;
};


export default MutationsClassesLabelLinks;

