import { uniq } from 'lodash';

import {
    FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR,
    FETCH_VACCINE_CANDIDATES_REQUEST,
    FETCH_VACCINE_CANDIDATES_SUCCESS,
    FETCH_VACCINE_CANDIDATES_ERROR,
    FETCH_LINEAGE_SUCCESS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_VP_METHODS_REQUEST,
    FETCH_VP_METHODS_SUCCESS,
    FETCH_VP_METHODS_ERROR,
    FETCH_MUTATION_CLASSES_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_MEASURE_SCALES_DOMAINS_REQUEST,
    FETCH_MEASURE_SCALES_DOMAINS_SUCCESS,
    FETCH_LINEAGE_REQUEST,
    SET_MEASURE_SCALE,
    SET_PARAMETERS,
    RESET_GENOTYPE_STATUS
} from '../actions/actionTypes';
import { getMeasureScaleParamName } from '../../functions/data-helpers';
import { produce } from 'immer';

let metadataInitialState = { };
export const setMetadataInitialState = (state) => {
    metadataInitialState = state;
};

export const metadataReducer = (state = metadataInitialState, action) => {
    if (action.payload && action.payload.settings) return state; 
    return produce(state, (draft) => {

        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return metadataInitialState;
            }

            case FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST: {
                draft.tcellAntigenicityOptionsStatus = 'loading';
                break;
            }
            case FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS: {
                const { antigenicityOptions } = action.payload;
                draft.tcellAntigenicityOptions = antigenicityOptions;
                draft.tcellAntigenicityOptionsStatus = 'loaded';
                break;
            }
            case FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR: {
                draft.tcellAntigenicityOptionsStatus = 'error';
                break;
            }

            case FETCH_VP_METHODS_REQUEST: {
                draft.vpMethodsStatus ='loading';
                break;
            }
            case FETCH_VP_METHODS_SUCCESS: {
                const { vpMethods } = action.payload;
                return {
                    ...state,
                    vpMethods: uniq(state.vpMethods.concat(vpMethods)),
                    vpMethodsStatus: 'loaded'
                };
            }
            case FETCH_VP_METHODS_ERROR: {
                return {
                    ...state,
                    vpMethodsStatus: 'error'
                };
            }

            case FETCH_MUTATION_CLASSES_REQUEST: {
                return { ...state, mutationClassesStatus: 'loading' };
            }
            case FETCH_MUTATION_CLASSES_SUCCESS: {
                const { mutationClasses } = action.payload;
                draft.mutationClasses = { ...draft.mutationClasses, ...mutationClasses };
                draft.mutationClassesStatus = 'loaded';
                break;
            }
            case FETCH_MUTATION_CLASSES_ERROR: {
                draft.mutationClassesStatus = 'error';
                break;
            }
            case FETCH_VACCINE_CANDIDATES_REQUEST: {
                draft.vaccineCandidatesStatus = 'loading';
                break;
            }
            case FETCH_VACCINE_CANDIDATES_SUCCESS: {
                const { strainsList } = action.payload;
                draft.vaccineCandidates = strainsList || [];
                draft.vaccineCandidatesStatus = strainsList ? 'loaded' : 'uninitialized';
                break;
            }
            case FETCH_VACCINE_CANDIDATES_ERROR: {
                draft.vaccineCandidates = [];
                draft.vaccineCandidatesStatus = 'error';
                break;

            }
            case FETCH_LINEAGE_REQUEST: {
                draft.measures = metadataInitialState.measures;
                draft.measureScalesDomains = {};
                draft.measureDomainStatuses = {};
                break;
            }
            case FETCH_LINEAGE_SUCCESS: {
                const { metadata, customMeasures, processingMetadata, measureDomains, measures, measureBins, scales } = action.payload;
                const customNodeMeasures = Object.keys(customMeasures.node || {}).reduce((acc, k) => { acc[k] = { ...customMeasures.node[k], custom: true }; return acc; }, {});
                const customBranchMeasures = Object.keys(customMeasures.branch || {}).reduce((acc, k) => { acc[k] = { ...customMeasures.branch[k], custom: true, branch: true }; return acc; }, {});
                const newMeasures = { ...measures, ...customNodeMeasures, ...customBranchMeasures };
               
                const _colorByOptions = metadata && metadata.colorByOptions ? metadata.colorByOptions : { ...state.metadata.colorByOptions };
                const colorByOptions = Object.keys(newMeasures)
                    .filter(colorBy =>  newMeasures[colorBy] && (_colorByOptions[colorBy]))
                    .reduce((colorByOptions, colorBy) => { colorByOptions[colorBy] = true; return colorByOptions; }, {});

                const loadedMeasureDomains = Object.keys({ ...measureDomains, ...measureBins });
                const measureDomainStatuses = loadedMeasureDomains.reduce((acc, m) => { acc[m] = 'loaded'; return acc; }, {});
               
                const disreteScaleTypes = Object.keys(scales).reduce((acc, scaleName) => {
                    const scaleType = scaleName.split('.')[0];
                    acc[scaleType] = acc[scaleType] || scales[scaleName].discrete || false;
                    return acc;
                }, {});
                Object.keys(newMeasures).forEach(m => {
                    const scaleType = newMeasures[m].scaleType || m;
                    newMeasures[m].discreteScale = disreteScaleTypes[scaleType];
                });
              
                Object.assign(draft, metadata);
                draft.customMeasures = customMeasures;
                draft.measures = newMeasures;
                draft.measureBins = measureBins;
                draft.processingMetadata = processingMetadata;
                draft.colorByOptions = colorByOptions;
                draft.measureScalesDomains = measureDomains;
                draft.scales = scales;
                draft.measureDomainStatuses = measureDomainStatuses;
                break;
            }
        
           
            case FETCH_MEASURE_SCALES_DOMAINS_REQUEST: {
                const { colorBy, freqCategory, geoMapColorBy } = action.payload;
                const _colorBy =  colorBy || freqCategory || geoMapColorBy;
                if (!draft.measureDomainStatuses) {
                    draft.measureDomainStatuses = {};
                }
                draft.measureDomainStatuses[_colorBy] = 'loading';
                break;

            }
            case FETCH_MEASURE_SCALES_DOMAINS_SUCCESS: {
                const { measure, domains, bins } = action.payload;
                
                if (!draft.measureScalesDomains) {
                    draft.measureScalesDomains = {};
                }
                draft.measureScalesDomains[measure] = domains;
      
                if (!draft.measureBins) {
                    draft.measureBins = {};
                }
                draft.measureBins[measure] = { ...(draft.measureBins[measure] || {}), ...bins };

                if (!draft.measureDomainStatuses) {
                    draft.measureDomainStatuses = {};
                }
                draft.measureDomainStatuses[measure] = 'loaded';
                break;
            }

            case SET_MEASURE_SCALE: {
                const { measure, scaleName, parameters } = action.payload;
                const measures = draft.measures;
                const paramName = getMeasureScaleParamName(measures[measure]);
                const paramValue = paramName && parameters[paramName];
                let scaleChanged = false;
                if (paramName && paramValue) {
                    if (measures[measure].scale[paramName][paramValue] !== scaleName) {
                        scaleChanged = true;
                    }
                } else if (measures[measure].scale !== scaleName) {
                    scaleChanged = true;
                }

                if (!scaleChanged) break; // No change

                if (paramName) measures[measure].scale.paramName = paramName;
                if (paramName && paramValue) {
                    // console.log(measures[measure].scale[paramName][paramValue]);
                    measures[measure].scale[paramName][paramValue] = scaleName;
                } else {
                    measures[measure].scale = scaleName;
                }

                break;
            }
            case SET_PARAMETERS: {
                const { parameters } = action.payload;
                const { colorScale, colorBy } = parameters;

                if (!colorScale || !colorBy || !draft.measures[colorBy]) break;

                const measures = draft.measures;

                const paramName = getMeasureScaleParamName(measures[colorBy]);        
                const paramValue = paramName && parameters?.[paramName];

                if (paramName && paramValue)
                    measures[colorBy].scale[paramName][paramValue] = colorScale;
                else measures[colorBy].scale = colorScale;

                break;
            }
            case RESET_GENOTYPE_STATUS: {
                if (draft.measureDomainStatuses) {
                    draft.measureDomainStatuses.genotype = 'none';
                }
                if (draft.measureBins) {
                    draft.measureBins.genotype = {};
                }
                break;
            }
            default: {
                break;
            }
        };
    }
    );};
