// External imports
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal imports - Components
import SelectInput from '../Common/SelectInput';

// Internal imports - Config & Utils
import appConfig from '../../config/appConfig';

// Internal imports - Actions
import { setParameters, setReferenceStrain } from '../../redux/actions/parametersActions';

const antigenicDataTypes = appConfig.antigenicDataTypes;
const AntigenicDataTypeSelector = (props) => {
    const { antigenicDataType, refStrain, setParameters, setReferenceStrain } = props;

    const handleChange = antigenicDataType => {
        //if (antigenicDataType !== 'raw_strain' && `${refStrain || ''}`.match(','))
        //  setReferenceStrain(refStrain.split(',')[0], 'antigenic'); 
        if (antigenicDataType !== 'raw_strain' && refStrain && refStrain.length > 0)
            setReferenceStrain(refStrain.slice(0, 1), 'antigenic'); 
        setParameters({ antigenicDataType });
    };

    return  <SelectInput
        id="antigenicDataType"
        label="Data"
        value={antigenicDataType}
        onChange={handleChange}
        options={antigenicDataTypes}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.label}
    />;
};

const mapStateToProps = ({parameters}) => ({
    antigenicDataType: parameters.antigenicDataType,
    refStrain: parameters.refStrain,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            setReferenceStrain
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AntigenicDataTypeSelector);
