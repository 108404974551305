import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styles } from '../Styles/panelStyles';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { emptyObject } from '../../../functions/utils';
import Table from '../../../components/Table/Table';
import { fetchMutationClassesArray, fetchLineageMutationGenes } from '../../../redux/actions/settingsActions';

const headers = [
    { name: 'name', label: 'Name' },
    { name: 'lineage', label: 'Lineage' },
    { name: 'label', label: 'Label' },
    { name: 'mutGroup', label: 'Mutation group' },
    { name: 'symbol_sign', label: 'Symbol sign' },
    { name: 'actions', label: 'Actions' },
];

const orderInitState = {
    name: 'asc',
    lineage: 'none',
    label: 'none',
    mutGroup: 'none',
    symbol_sign: 'none',
    actions: 'none'
};

const searchState = {
    name: '',
    lineage: '',
    label: '',
    mutGroup: ''
};

const searchLabels = {
    name: 'Name',
    lineage: 'Lineage',
    label: 'Label',
    mutGroup: 'Mutation group'
};

const searchOptionsState = {
    name: [],
    label: [],
    lineage: [],
    mutGroup: [],
};

function createData(mutClass) {
    const { name, label, lineage, mutGroup, color, symbol_sign } = mutClass;
    return { name, label, lineage, mutGroup, color, symbol_sign, mutClass };
}

const MutationClassesPanel = ({ fetchMutationClassesArray, mutationClasses, mutationClassesLength, fetchLineageMutationGenes }) => {
    const [tableData, setTableData] = useState([]);
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);
    const classes = styles();

    useEffect(() => {
        fetchMutationClassesArray();
        fetchLineageMutationGenes();
    }, []);

    useEffect(() => {
        if (mutationClassesLength > 0)
            setTableMutationClasses();
    }, [mutationClasses]);

    const setTableMutationClasses = () => {
        const rows = mutationClasses.map(el => createData(el));

        const newOptions = {
            name: [...new Set(mutationClasses.map(el => el.name))],
            lineage: [...new Set(mutationClasses.map(el => el.lineage))],
            label: [...new Set(mutationClasses.map(el => el.label))],
            mutGroup: [...new Set(mutationClasses.map(el => el.mutGroup))],
        };
        setSearchOptions({ ...newOptions });
        setTableData(rows);
    };

    return (
        <Grid container className={classes.container}>
            <Grid size={12} className={classes.item}>
                {!emptyObject(mutationClasses) &&
                    <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                        <Table
                            name='mutClasses'
                            searchOptions={searchOptions}
                            orderInitState={orderInitState}
                            headers={headers}
                            tableData={tableData}
                            searchState={searchState}
                            searchLabels={searchLabels}
                        />
                    </Paper>
                }
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { data } = state.settings.mutationClasses;

    return {
        mutationClasses: data,
        mutationClassesLength: data.length
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMutationClassesArray,
            fetchLineageMutationGenes,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MutationClassesPanel);
