import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';

import { resetLabels, setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { getMutationClassesDataLabels } from '../../../redux/selectors/treeDataSelector';
import { treeD3 } from '../d3/TreeD3';
import MutationsClassesLabels, { defaultXYPositions, w, h } from '../labels/MutationsClassesLabel/MutationsClassesLabels';
import MutationsClassesLabelLinks from '../labels/MutationsClassesLabel/MutationsClassesLabelLinks';
import MutationsClassesLabelSymbols, { getSymbolPosition } from '../labels/MutationsClassesLabel/MutationsClassesLabelSymbols';
import { getSymbolDimensions, getTextMetrics } from '../../../functions/utils';
import { getYOrder } from '../labels/helpers/functions';

const viewToRender = 'strainTree';
const componentId = 'mutationsClasses';


const MutationsClassesLayer = (props) => {


    const { loading, mutationsGroup, renderStatus, setComponentStatus, mutationClassesData, resetLabels, visibleMutationClassesLabels /*, mutationGroupValuesStatus, resetLabels*/ }= props;

    const symbolsCnt = useMemo(() => { return mutationClassesData ? mutationClassesData.length : 0; }, [mutationClassesData]);


    const mutationsLabels = useMemo(() => {
        if (loading || !mutationClassesData || !mutationClassesData?.length || renderStatus !== RENDER_STATUS.NONE) return null;

        const labels = mutationClassesData.reduce((acc, { muts, id, symbolsNumber, minOrder, maxOrder }) => {
            muts.forEach((mut, index) => {
                const { mutClass, mutations, startPos, number, showLabel } = mut;
                const symbolProps = { 
                    ...getSymbolDimensions(number),
                    ...getSymbolPosition(id, startPos, symbolsNumber, number) 
                };
  
                const labelProps = {};
                if (showLabel) {
                    const { width, height } = getTextMetrics(mutations, `8px 'Inter'`);
                    const labelWidth = width + w;
                    const labelHeight = height + h;
                    labelProps.labelWidth = labelWidth;
                    labelProps.labelHeight = labelHeight;
                    const defaultPos = defaultXYPositions(id, index, labelWidth, labelHeight, startPos, symbolsNumber, number);
                    labelProps.x = defaultPos.x;
                    labelProps.y = defaultPos.y;
                    labelProps.minY = getYOrder(minOrder);
                    labelProps.maxY = getYOrder(maxOrder);
                    // console.log('[MutationsClassesLayer] SHOW_LABEL', {id, mutClass, showLabel});
                }   
                acc[`${id}_${mutClass}`] = { ...labelProps, ...symbolProps };
            });
            return acc;
        }, {});
        // console.log('[MutationsClassesLayer] mutationsLabels', {loading,renderStatus, mutationClassesData, labels});
        return labels;
    }, [mutationClassesData, renderStatus, loading]);

    // console.log('[MutationsClassesLayer] visibleMutationClassesLabels', {visibleMutationClassesLabels});
 
    const startRender = () => {
        if (mutationsLabels) {
            setTimeout(() => setComponentStatus(viewToRender, componentId, RENDER_STATUS.START, { labels: mutationsLabels, type: 'mutationLabel' }), 0);
            // reset labels if there are labels to render
            // if (mutationClassesData.filter(({labelHeight}) => labelHeight).length > 0) 
            // resetLabels();
        }
        else setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
        // }, 0);
    };


    // Reset the render status when the labels have changed
    useEffect(() => {
        if (Object.keys(mutationsLabels || {}).length > 0) {
            setComponentStatus(viewToRender, componentId, RENDER_STATUS.NONE);
            // resetLabels();
        }
        // else setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    }, [mutationsLabels]);

    const isFirstRender = useRef(true);
    useEffect(() => {
        // console.log('[MutationsClassesLayer] visibleMutationClassesLabels', {visibleMutationClassesLabels});
        if (visibleMutationClassesLabels && !isFirstRender.current) {
            // console.log('[MutationsClassesLayer] visibleMutationClassesLabels', {visibleMutationClassesLabels});
            resetLabels();
        }
        isFirstRender.current = false;
    }, [visibleMutationClassesLabels]);

    // Start the render when the labels have changed
    useEffect(() => {
        // console.log('[MutationsClassesLayer] useEffect', {loading, mutationsLabels, mutationClassesData: mutationClassesData.length});
        if (loading || renderStatus !== RENDER_STATUS.NONE /*|| !mutationsLabels*/) return;
        startRender();
    }, [loading, renderStatus]);

   

    // console.log('[MutationsClassesLayer]', { renderStatus });

    return <g id="mutationsClasses" transform={treeD3.translate(false)} >
        {!loading && symbolsCnt > 0 && renderStatus === RENDER_STATUS.DONE && (
            <>
                <g id="mutationClassesLabelLinks">
                    {
                        mutationClassesData.filter(({ showLabel }) => showLabel).map(({ id, mutClass, ...props }) => (
                            <MutationsClassesLabelLinks
                                key={`${id}_${mutationsGroup}_${mutClass}_link`}
                                id={id}
                                classNamePrefix="mutationLabel" {...props}
                            />
                        ))
                    }
                </g>
                <g id="mutationClassesLabelSymbols">
                    {
                        mutationClassesData
                            .map(({ id, mutClass, ...props }) => (
                                <MutationsClassesLabelSymbols
                                    key={`${id}_${mutationsGroup}_${mutClass}_symbol`}
                                    id={id} classNamePrefix="mutationLabel" {...props}
                                />
                            ))
                    }
                </g>
                <g id="mutationClassesLabels">
                    {
                        mutationClassesData.filter(({ showLabel }) => showLabel).map(({ id, mutClass, ...props }) => (
                            <MutationsClassesLabels
                                key={`${id}_${mutationsGroup}_${mutClass}_label`}
                                id={id} classNamePrefix="mutationLabel" {...props}
                            />
                        ))
                    }
                </g>
            </>)}
    </g>;
};

MutationsClassesLayer.propTypes = {
    loading: PropTypes.bool,
    // mutationClassesData: PropTypes.ar({}),
    mutationsGroup: PropTypes.string,
    renderStatus: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized || state.genotype.mutationGroupValuesStatus === 'loading';
    const mutationClassesData = getMutationClassesDataLabels(state);
    return {
        loading,
        showMutationsGroups: state.parameters.showMutationsGroups,
        strainTreeStatus: getStrainTreeStatus(state),
        mutationsGroup: state.parameters.mutationsGroup,
        mutationClassesData,
        renderStatus: state.render.viewToRender.components[componentId] || RENDER_STATUS.NONE,
        visibleMutationClassesLabels: state.parameters.visibleMutationClassesLabels,
        // mutationGroupValuesStatus: state.genotype.mutationGroupValuesStatus,
    };
};


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
            resetLabels
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MutationsClassesLayer);
