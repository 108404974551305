import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { setRenderStatus } from '../../../redux/actions/renderActions';
import { getRanges } from '../../../redux/selectors/rangeDataSelector';
import { antigenicRawDataSeletor } from '../../../redux/selectors/antigenicDataSelector';
import { emptyObject } from '../../../functions/utils';
import { getScaledValue } from '../../../functions/scales';
import { antigenicDetailStyles } from '../styles/antigenicDetailsStyles';

const useStyles = makeStyles(antigenicDetailStyles);

const AntigenicDetailsData = props => {

    const { alpha, rho, alphas, rhos, rhosLength, data,
        clades,
        matrixCellHandleMouseOver, exportMode, antigenicTiterType, rawAntigenicModel } = props;
    
    const getClasses = (classes) => {
        const exportPropsMap = Object.keys(classes)
            .filter((key) => key.match('Export$'))
            .reduce((tmp, key) => {
                tmp[key] = key;
                return tmp;
            }, {});
        const regProps = Object.keys(classes)
            .filter((key) => !key.match('Export$'))
            .map((key) => ({ key, exportKey: exportPropsMap[`${key}Export`] }));
        return regProps.reduce((tmpProps, prop) => {
            tmpProps[prop.key] =
                    exportMode && exportPropsMap[prop.exportKey] ? classes[prop.exportKey] : classes[prop.key];
            return tmpProps;
        }, {});
    };
    
    const getTiterValue = (value) => {    
        switch (antigenicTiterType) {
            case 'drop':  return 11 - value;
            case 'fold_reduction': return Math.pow(2, 11 - value);
            default: return value;
        }
    };
    
    
    const getValue = (rho, alpha) => {
        const value = get(rawAntigenicModel, `${alpha}.${rho}`);
        return value ? getTiterValue(value) : null;
    };
    const classes = getClasses(useStyles());


    const sumValuesPerColumn = (isDiagonal) => {
        const startIndex = isDiagonal ? 0 : rhosLength;

        const newAlphas = alphas.slice(startIndex);

        return newAlphas.reduce((acc, [alphaId]) => {
            return rhos.map(([rhoId], columnIndex) => {
                const currentVal = getValue(rhoId, alphaId) || 0;
                const prev = acc[columnIndex] || { sum: 0, counter: 0 };

                return {
                    sum: prev.sum + currentVal,
                    counter: currentVal ? prev.counter + 1 : prev.counter
                };
            });
        }, []);
    };

    // const getAverage = (isDiagonal) => {
    //     const startIndex = isDiagonal ? 0 : rhosLength;
    //     //console.log('startIndex', startIndex, rhosLength, isDiagonal);
    //     const newAlphas = alphas.slice(startIndex);

    //     const sumVal = newAlphas.reduce((acc, [alphaId]) => {

    //         rhos.forEach(([rhoId], columnIndex) => {
    //             const currentVal = getValue(rhoId, alphaId) || 0;
    //             acc.sum += currentVal;
    //             acc.counter += currentVal ?  1 : 0;

    //         });
    //         return acc;
    //     }, { sum: 0, counter: 0});
    //     return sumVal;
    // };

    const isDiagonal = useMemo(() => rho === alpha, [rho, alpha]);

    const averageValues = useMemo(() => {
        const sums = sumValuesPerColumn(isDiagonal);
        return sums.map(elem => elem.counter ? elem.sum / elem.counter : null);
    }, [isDiagonal]);

    const _element = useRef(null);

    


  

 
    if (emptyObject(data)) 
        return <div className={classes.rootData}><div className={classes.doDataMsg}>No data for this combination of parameters</div></div>;

    if (!rhos || !alphas) {
        return <div className={classes.doDataMsg}>rhos or alphas clades are empty</div>;
    }

    return (
        <div id="antigenic_data" className={classes.rootData} ref={_element} width="100%" height="100%">
            <table className={classes.tableDetails} id='table_details'>
                <tbody>
                    <tr>
                        <th className={classes.parentClade}>
                            <div>{clades[alpha].label} </div>
                            <div>{clades[rho].label} </div>
                        </th>
                        {rhos.map(([rhoId, rhoValue]) => (
                            <th
                                className={classes.headerTh}
                                key={`1_aHeaderCol${rhoId}`}
                            >
                                <div className={classes.headerDiv}>{rhoValue}</div>
                            </th>
                        ))}
                    </tr>
                    {alphas.map(([alphaId, alphaValue], index) => (
                        <tr
                            key={`aRow${alphaId}`}
                            style={{
                                display: 'table-row',
                                borderBottom: index === rhosLength - 1 && '3px solid black',
                            }}
                        >
                            <td className={classes.name}>{alphaValue}</td>
                            {rhos.map(([rhoId, rhoValue]) => (
                                <td
                                    key={`aVal${alphaId}_${rhoId}`}
                                    className={classes.val}
                                    onMouseOver={() => {
                                        matrixCellHandleMouseOver(alphaValue, rhoValue, getValue(rhoId, alphaId));
                                    }}
                                    onMouseOut={() => {
                                        matrixCellHandleMouseOver();
                                    }}
                                    style={{
                                        backgroundColor:
                                                getScaledValue('antigenicColorScale', getValue(rhoId, alphaId)) ||
                                                '#FFFFFF',
                                        display: 'table-cell',
                                    }}
                                    title={getValue(rhoId, alphaId)}
                                ></td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td className={classes.name}>AVERAGE VALUE</td>
                        {rhos.map(([rhoId, rhoValue], index) => (
                            <td
                                key={`average_${rhoId}`}
                                className={classes.val}
                                onMouseOver={() => {
                                    matrixCellHandleMouseOver('AVERAGE VALUE', rhoValue, averageValues[index]);
                                }}
                                style={{
                                    backgroundColor:
                                            getScaledValue('antigenicColorScale', averageValues[index]) ||
                                            '#FFFFFF',
                                    display: 'table-cell',
                                }}
                                title={averageValues[index]}
                            ></td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div >
    );
    
};

AntigenicDetailsData.propTypes = {
    exportMode: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const { alpha, rho, rawAntigenicModel } = state.antigenic;
    const { exportMode, antigenicTiterType } = state.parameters;
    const { clades } = state.cladeData;
    const ranges = getRanges(state);

    const { data, alphas, rhos } = antigenicRawDataSeletor(state);

    const rhosLength = rhos.length;
    return {
        data,
        alphas,
        rhos,
        ranges,
        exportMode,
        antigenicTiterType,
        alpha,
        rho,
        clades,
        rhosLength,
        rawAntigenicModel,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            // selectAntigenicDetailsValues,
            setRenderStatus,
            // toggleAntigenicDetails,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AntigenicDetailsData);
