import React, { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { connect } from 'react-redux';
import Signin from '../components/Auth/SignIn';
import Frequencies from '../pages/Frequencies/Frequencies';
import RegionalFrequenciesReport from '../pages/RegionalFrequenciesReport/connect';
import { AdminPanel } from '../pages/AdminPanel/AdminPanel';
import { SettingsPanel } from '../pages/SettingsPanel/SettingsPanel';
import NewPassword from '../components/Auth/NewPassword/NewPassword';
import StrainTree from '../pages/StrainTree/StrainTree';
import Error404 from '../pages/Error404/Error404';
import Antigenic from '../pages/Antigenic/Antigenic';
import GeoMap from '../pages/GeoMap/GeoMap';
import Vaccines from '../pages/Vaccines/Vaccines';
import config from '../config/envConfig';
import Fitness from '../pages/Fitness/Fitness';
import LegalNotice from '../pages/LegalNotice/LegalNotice';
import auth from '../functions/auth-helper';
import { isNil } from 'lodash';
import { usePrevious } from '../functions/custom-hooks';
import { resetSession } from '../redux/actions/sessionActions';
import { bindActionCreators } from 'redux';
import { getCurrentModule, getPathname } from '../functions/utils';


const AppRoutes = (props) => {
    const { isAuthenticated, modules, isLineageLoaded, adminRights, dataAdminRights, userStatus, resetSession } = props;
    const Redirecor = (path, location) => <Navigate to={{ pathname: `${path}`, state: { from: location } }} />;
    // console.log('isAuthenticated', modules);
    const navigate = useNavigate();
    const location = useLocation();

    const currentModuleTab= getCurrentModule(location);
    const pathname = getPathname(location);

    const previousUserStatus = usePrevious(userStatus);

    // If the location changes, check if the current module tab is permitted
    useEffect(() => {
        // console.log('[AppRoutes] LOCATION or LINEAGE_STATUSCHANGED',{pathname: location.pathname, userStatus, currentModuleTab, modules, isLineageLoaded});
        // If the user is signed out and the location is not the signin page, reset the session
        if (['signedOut', 'error'].includes(userStatus) && pathname !== '/signin') resetSession();
       
        // If the lineage is not loaded and module tab is selected, do not render the component
        if (!isLineageLoaded && currentModuleTab) return;
       
        // Define the logic to determine if the current tab is permitted
        const isCurrentTabPermitted = (!currentModuleTab || modules[currentModuleTab]);
        if (!isCurrentTabPermitted) {
            console.log('[AppRoutes] Attempting redirect to:', '/');
            navigate(`${config.frontendPrefix}/`, { replace: true });
        }
    }, [isLineageLoaded, pathname]);

    // If the user is signed out or session is expired, redirect to the signin page or intro page
    useEffect(() => {
      
        if (userStatus === 'signedOut' && previousUserStatus !== 'signedOut') {
            const path = (previousUserStatus === 'loaded' || previousUserStatus === 'signingOut')
                ? `${config.frontendPrefix}/signin`
                : `${config.frontendPrefix}/intro`;
            // console.log('[AppRoutes] Attempting redirect to:', path);
            navigate(path, { replace: true });
        }
    }, [userStatus, previousUserStatus]);
      
      

    // console.log('[AppRoutes]', props);
    if (!isLineageLoaded && currentModuleTab && userStatus !== 'none') return null;
    const isProperlySignedOut = !isAuthenticated && userStatus === 'none';
    
    // console.log(`ROUTE ${location.pathname}`, {isAuthenticated, userStatus, previousUserStatus, isProperlySignedOut}); 
    return (
        <Routes>
            <Route path={`${config.frontendPrefix}/signin`} Component={(props) => !isAuthenticated 
                ? <Signin /> 
                : Redirecor(`${config.frontendPrefix}/`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/intro`} Component={(props) => !isAuthenticated 
                ? <StrainTree intro={true} /> 
                : Redirecor(`${config.frontendPrefix}/`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/intro-freq`} Component={() => <Frequencies intro={true} />} exact />
            <Route path={`${config.frontendPrefix}/newPassword`} Component={NewPassword} exact />
            <Route path={`${config.frontendPrefix}/intro-map`} Component={GeoMap} exact />
            <Route path={`${config.frontendPrefix}/notice`} Component={LegalNotice} exact />
            <Route path={`${config.frontendPrefix}/`} Component={(props) => !isProperlySignedOut 
                ? <StrainTree /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/antigenic`} Component={(props) => !isProperlySignedOut 
                ? <Antigenic /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/fitness`} Component={(props) => !isProperlySignedOut 
                ? <Fitness /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/vaccines`} Component={(props) => !isProperlySignedOut 
                ? <Vaccines /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact /> 
            <Route path={`${config.frontendPrefix}/geomap`} Component={(props) => !isProperlySignedOut 
                ? <GeoMap /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/frequencies`} Component={(props) => !isProperlySignedOut 
                ? <Frequencies /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/settings`} Component={(props) => isAuthenticated && dataAdminRights 
                ? <SettingsPanel /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/manage`} Component={(props) => isAuthenticated && adminRights 
                ? <AdminPanel /> 
                : Redirecor(`${config.frontendPrefix}/intro`, props.location)}
            exact />
            <Route path={`${config.frontendPrefix}/export/frequencies/`} Component={Frequencies} exact />
            <Route path={`${config.frontendPrefix}/export/strainTree/`} Component={StrainTree} exact />
            <Route path={`${config.frontendPrefix}/export/antigenic/`} Component={Antigenic} exact />
            <Route path={`${config.frontendPrefix}/export/fitness/`} Component={Fitness} exact />
            <Route path={`${config.frontendPrefix}/export/regionalFrequenciesReport/`} Component={RegionalFrequenciesReport} exact />
            <Route path={`${config.frontendPrefix}/export/geomap/`} Component={GeoMap} exact />
            <Route path={`${config.frontendPrefix}/export/vaccines/`} Component={Vaccines} exact />
            <Route path='/*' Component={Error404} />
        </Routes>
    );
};


const mapStateToProps = ({ user, metadata, lineages }) => {
    return ({
        isAuthenticated: auth.isAuthenticated(),
        adminRights: isNil(user.permissions.adminRights) ? true : user.permissions.adminRights,
        dataAdminRights: isNil(user.permissions.dataAdminRights) ? true : user.permissions.dataAdminRights,
        modules: metadata.modules,
        userStatus: user.status,
        isLineageLoaded: lineages.lineageStatus === 'loaded',
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({resetSession}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
