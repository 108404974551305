import React, { useEffect, useState } from 'react';
import { styles } from '../Styles/panelStyles';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { emptyObject } from '../../../functions/utils';
import { fetchAllMeasures, fetchAllScales } from '../../../redux/actions/settingsActions';
import Table from '../../../components/Table/Table';

const headers = [
    { name: 'name', label: 'Name' },
    { name: 'lineage', label: 'Lineage' },
    { name: 'label', label: 'Label' },
    { name: 'element', label: 'Element' },
    { name: 'scale', label: 'Default scale' },
    { name: 'actions', label: 'Actions' },
];

const orderInitState = {
    name: 'desc',
    lineage: 'none',
    label: 'none',
    element: 'none',
    scale: 'none',
    actions: 'none'
};

const searchState = {
    name: '',
    lineage: '',
    label: '',
    element: ''
};

const searchLabels = {
    name: 'Name',
    lineage: 'Lineage',
    label: 'Label',
    element: 'Element'
};

const searchOptionsState = {
    name: [],
    lineage: [],
    label: [],
    element: [],
};

function createData(row) {
    const { measure, lineage, label, element, scale } = row;

    return {
        name: measure,
        lineage: lineage || '(all)',
        label,
        element: element ? 'custom' : 'standard',
        scale: scale,
        measure: row,
        custom: !emptyObject(element) || lineage
    };
}

const MeasuresPanel = ({ fetchAllMeasures, allMeasures, fetchAllScales }) => {
    const [tableData, setTableData] = useState([]);
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);
    const classes = styles();

    useEffect(() => {
        fetchAllScales();
        fetchAllMeasures();
    }, []);

    useEffect(() => {
        setTableMeasures();
    }, [allMeasures]);

    const setTableMeasures = () => {
        const rows = allMeasures
            .filter(el => el.scale)
            .map(el => createData(el));

        const newOptions = {
            name: [...new Set(rows.map(el => el.name))],
            lineage: [...new Set(rows.map(el => el?.lineage || '(all)'))],
            label: [...new Set(rows.map(el => el.label))],
            element: ['custom', 'standard'],
        };
        setTableData(rows);
        setSearchOptions({ ...newOptions });
    };

    return (
        <Grid container className={classes.container}>
            <Grid size={12} className={classes.item}>
                {!emptyObject(allMeasures) &&
                    <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                        <Table
                            name='measures'
                            searchOptions={searchOptions}
                            orderInitState={orderInitState}
                            headers={headers}
                            tableData={tableData}
                            searchState={searchState}
                            searchLabels={searchLabels}
                        />
                    </Paper>
                }
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { measures } = state.settings;

    return {
        status: measures.status,
        allMeasures: measures.data,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllMeasures,
            fetchAllScales
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(MeasuresPanel);
