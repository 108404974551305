import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() => ({
    switch: {
        marginTop: '10px',
        marginBottom: '25px'
    },
    switch_base: {
        color: '#6F6CFF',
        '&:hover': {
            color: '#6F6CFF',
        },
        '&.Mui-disabled': {
            color: '#6F6CFF',
            backgroundColor: '#6F6CFF',
        },
        '&.Mui-checked': {
            color: '#6F6CFF'
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#6F6CFF',
        }
    },
    switch_track: {
        backgroundColor: '#6F6CFF',
    },
    // dataButton: {
    //     backgroundColor: '#6F6CFF',
    //     padding: '4px',
    //     color: 'white',
    //     textTransform: 'none',
    //     border: 'none',
    //     borderRadius: '4px',
    //     cursor: 'pointer',
    //     outline: 'none',
    // },
    dataButton: {
        backgroundColor: '#DADADA',
        fontSize: '12px',
        padding: '8px',
        color: 'black',
        textTransform: 'none',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginLeft: '7px',
        display: 'flex',
        alignItems: 'center'
    },
    formControlLineage: {
        marginBottom: '15px'
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        padding: '5px 12px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        padding: '5px 12px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    helpText: {
        maxWidth: '360px'
    },
    tooltip: {
        margin: 0,
        padding: 0,
        fontSize: '14px'
    },
    head: {
        marginBottom: '15px',
        marginTop: '15px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    expandbut: {
        marginTop: '7px'
    },
    buttonRemove: {
        marginTop: '5px',
        marginLeft: '140px'
    },
    cardFull: {
        margin: '0 0 10px 0',
    },
    card: {
        margin: 0,
        paddingBottom: 0
    },
    line: {
        display: 'flex',
        alignItems: 'center'
    },
    metaButton: {
        backgroundColor: '#DADADA',
        fontSize: '12px',
        padding: '8px',
        color: 'black',
        textTransform: 'none',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    uploadDataContainer: {
        marginLeft: '15px',
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center'
    },


}));

export const DialogSX = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            borderRadius: '12px',
        },
    },
};

export const ScaleDialogSX = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            borderRadius: '12px',
            width: '500px',
            maxWidth: '500px',
        },
    }
};
