import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize } from 'lodash';

// Custom components
import SelectInput from '../Common/SelectInput';

// Config
import appConfig from '../../config/appConfig';

// Functions
import { shouldFetch } from '../../functions/data-helpers';

// Redux actions
import { fetchVpMethods, resetVpValues } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';

const VpMethodSelector = props => {
    const { 
        vpMethod,
        vpMethods,
        lineage,
        lineageStatus,
        vpMethodsStatus,
        setParameters,
        resetVpValues,
        fetchVpMethods
    } = props;

    const handleVpMethodChange = async vpMethod => {
        setParameters({ vpMethod });
        resetVpValues();
    };

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(vpMethodsStatus)) fetchVpMethods({ lineage });
    }, [lineageStatus]);

    const _vpMethod = vpMethods.includes(vpMethod) ? vpMethod : appConfig.default.vpMethod;

    return (
        vpMethods && vpMethods.length > 0 && (
            <SelectInput
                id="vpMethod"
                label="Vp method"
                value={_vpMethod}
                onChange={handleVpMethodChange}
                options={vpMethods}
                getOptionValue={el => el}
                getOptionLabel={el => capitalize(el)}
            />
        )
    );
};

VpMethodSelector.propTypes = {
    fetchVpMethods: PropTypes.func.isRequired,
    lineage: PropTypes.string,
    lineageStatus: PropTypes.string.isRequired,
    resetVpValues: PropTypes.func.isRequired,
    setParameters: PropTypes.func.isRequired,
    vpMethod: PropTypes.string,
    vpMethods: PropTypes.array.isRequired,
    vpMethodsStatus: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    lineage: state.parameters.lineage,
    lineageStatus: state.lineages.lineageStatus,
    vpMethod: state.parameters.vpMethod,
    vpMethods: state.metadata.vpMethods,
    vpMethodsStatus: state.metadata.vpMethodsStatus
});

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchVpMethods,
    resetVpValues,
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VpMethodSelector);
