import React, { useCallback, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useEventListener } from 'usehooks-ts';
import { treeD3 } from '../../d3/TreeD3';
import { selectNodeData } from '../../../../redux/actions/nodeActions';
import { setLabelMovement } from '../../../../redux/actions/renderActions';

import { useDrag } from '../../hooks/useDrag';
import { getMultilineTextMetrics, getTextMetrics } from '../../../../functions/utils';

import { styles } from './styles';
import { makeStyles } from '@mui/styles';
import { RENDER_STATUS } from '../../../../config/consts';
import { getX, getY, getYOrder } from '../helpers/functions';

export const r = 3;
export const rMargin = 3;
const w = 16;
const h = 8;

export const getCladeLabelDimensions = (labelText, newNomenclature) => {
    const lines = [labelText];
    if (newNomenclature) {
        lines.push(`[${newNomenclature}]`);
    }
    const { width, height } = getMultilineTextMetrics(lines, `12px 'Inter'`);
    return { labelWidth: width + w, labelHeight: height + h };
};


export const getCladeLabelPosition = (id, labelHeight, minOrder, maxOrder) => {
    const x = getX(id);
    const y = getY(id);
    return {
        x: x + r + rMargin,
        y: y + labelHeight / 2,
        xAnchor: x,
        yAnchor: y,
        minY: getYOrder(minOrder),
        maxY: getYOrder(maxOrder),
        xMod: 0,
        yMod: 0
    };
};

const useStyles = makeStyles(styles);


const CladeLabelText = props => {

    const _textRef = useRef();
    const _nomenclatureRef = useRef();
    const _element = useRef();
    const classes = useStyles();

    const { id, labelText, newNomenclature, classNamePrefix, xAnchor, x, yAnchor, y, labelHeight, labelWidth, setLabelMovement, renderCount } = props;

    
    const handleDrag = (data) => {
        setLabelMovement({ id, type: classNamePrefix, xMod: data.x, yMod: data.y });
    };

    const { isDragging, dragOffset, onMouseDown } = useDrag(handleDrag);

    useEffect(() => {
        dragOffset.x = 0;
        dragOffset.y = 0;
    }, [renderCount]);
    
    const textHeight = getTextMetrics(labelText, `12px 'Inter'`).height+h;
    const xMove = (x || xAnchor) - xAnchor + props.xMod;//dragOffset.x;
    const yMove = (y || yAnchor) - yAnchor + props.yMod;//dragOffset.y;

    return (
        <g id={`${classNamePrefix}_${id}`} className={classes.label} ref={_element}
            transform={`translate(${xMove}, ${-labelHeight + yMove})`}
            style={{
                // opacity: renderStatus === RENDER_STATUS.DONE ? 1 : 0,
                cursor: isDragging ? 'grabbing' : 'pointer'
            }}
            onMouseDown={onMouseDown}
        >
            <rect className={classes.labelBorder}
                height={labelHeight}
                width={labelWidth}
            ></rect>
            <text className={classes.labelText}
                transform={`translate(8, ${4 + textHeight / 2})`}
                ref={_textRef}
            >
                <tspan textAnchor="start">{labelText}</tspan>
                {newNomenclature && (
                    <tspan x="0" y="16" textAnchor="start" ref={_nomenclatureRef}>
                        [{newNomenclature}]
                    </tspan>
                )}
            </text>
        </g>
    );
};



const CladeLabel = (props) => {
    const { id, xAnchor, yAnchor, selectNodeData } = props;
    const _element = useRef();

    const selectedNode = useCallback(() => {
        if (!treeD3.selectedNode) props.selectNodeData({ nodeId: +id });
    }, [id, selectNodeData]);

    const clearSelectedNode = useCallback(() => {
        if (!treeD3.selectedNode) props.selectNodeData();
    }, [selectNodeData]);

    useEventListener('mouseover', selectedNode, _element);
    useEventListener('mouseleave', clearSelectedNode, _element);
        

    // console.log('[CladeLabel]', {id, xMod:props.xMod, yMod:props.yMod, renderStatus:props.renderStatus});

    // useEffect(() => {
    //     return () => {
    //         console.log('[CladeLabel] useEffect cleanup', id);
    //     };
    // }, []);
    return (
        <g ref={_element} transform={`translate(${xAnchor}, ${yAnchor})`}>
            <CladeLabelText {...props} />
        </g>
    );
};

// const viewToRender = 'strainTree';
const componentId = 'cladeLabels';

const mapStateToProps = (state, ownProps) => {
    const labelPos = state.render.labels?.[ownProps.classNamePrefix]?.[ownProps.id] || {};

    // console.log('[CladeLabel] labelPos', labelPos);
    // console.log('[CladeLabel] ownProps', ownProps);
    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        showCladeBarLabels: state.parameters.showCladeBarLabels,      
        xMod: labelPos?.xMod || 0,
        yMod: labelPos?.yMod || 0,
        ...labelPos,
        id: parseInt(ownProps.id),
        renderStatus: state.render.viewToRender.components[componentId] || RENDER_STATUS.NONE,
        renderCount: state.render.labels.renderCount || 0,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setLabelMovement
        },
        dispatch,
    );
export default connect(mapStateToProps, mapDispatchToProps)(CladeLabel);

