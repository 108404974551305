import React from 'react';
import { getX, getY } from '../helpers/functions';
import { connect } from 'react-redux';

const r = 3;

const CladeLabelAnchorPoint = (props) => {
    const { id } = props;
    const xPos = getX(id);
    const yPos = getY(id);


    return (
        <circle
            transform={`translate(${xPos}, ${yPos})`} id={`${id}_cladeLabelAnchorPoint`}
            r={r}
            style={{
                fill: '#ffffff',
                stroke: '#4F4F4F',
                strokeWidth: 1
            }}
        ></circle>
    );
};

const mapStateToProps = (state, ownProps) => {
    //const treeAttrs = getTreeNodeAttrs(state);
    const labelPos = state.render.labels?.[ownProps.classNamePrefix]?.[ownProps.id] || {};
    const xPos = getX(ownProps.id);
    const yPos = getY(ownProps.id);

    return {
        strainTreeWidth: state.ui.strainTreeWidth,
        strainTreeHeight: state.ui.strainTreeHeight,
        xPos,
        yPos,
        x: labelPos?.x || xPos,
        y: labelPos?.y || yPos,
    };
};

export default connect(mapStateToProps)(CladeLabelAnchorPoint);
