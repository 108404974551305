// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Internal imports - Components
import Alerts from '../../Alerts/Alerts';
import BuildSelector from '../../OptionsSelector/LineageSelector/BuildSelector';
import Category from '../helpers/Category';
import ChartLayoutSelector from '../../OptionsSelector/ChartLayoutSelector';
import CheckboxInput from '../../Common/CheckboxInput';
import ColorScaleSelector from '../../OptionsSelector/ColorScaleSelector/ColorScaleSelector';
import DatesSelector from '../../OptionsSelector/DatesSelector';
import FrequenciesCategorySelector from '../../OptionsSelector/FrequenciesCategorySelector';
import FrequenciesLegend from '../../ColorLegend/Legends/FrequenciesLegend';
import HamburgerIcon from '../../SvgIcons/HamburgerIcon';
import MultiplicityMethodSelector from '../../OptionsSelector/MultiplicityMethodSelector';
import MultipleModelsSelector from '../../MultipleModelsSelector/MultipleModelsSelector';
import PlotTypeSelector from '../../OptionsSelector/PlotTypeSelector';
import PredictionBaselinePicker from '../../DatePickers/PredictionBaselinePicker';
import RegionalFreqButton from '../../Reports/connect';
import StrainsSubsetSelector from '../../OptionsSelector/StrainsSubsetSelector';
import YScaleSelector from '../../OptionsSelector/YScaleSelector';
import FrequencyTypeSelector from '../../OptionsSelector/FrequencyTypeSelector';
import SubmissionDatePicker from '../../DatePickers/SubmissionDatePicker';

// Internal imports - Redux
import { resetPredictions } from '../../../redux/actions/predictionsActions';
import { resetFrequencies } from '../../../redux/actions/frequenciesActions';
import { setHiddenMenu } from '../../../redux/actions/uiActions';
import { predictionBaselineSelector, trackingFromSelector, trackingToSelector } from '../../../redux/selectors/parametersSelector';

// Internal imports - Styles
import { HideIcon } from '../../Alerts/styles';
import { styles } from './styles';
import { setParameters } from '../../../redux/actions/parametersActions';

import { getIsMobile } from '../../../functions/utils';

const useStyles = makeStyles((theme) => styles(theme));

const ChartOptions = (props) => {
   
    const { setHiddenMenu, resetFrequenciesAndPredictions, setParameters } = props;
    const { intro, isMobile, permissions, hiddenMenu, showAlert, freqCategory, isStacked, plotType, showPrediction, 
        displayErrorBars, displayGreyZone } = props;

    const classes = useStyles();

    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    };

    const handleChange = (id) => (value) => {
        setParameters({ [id]: value });
    };


    const showPredictionSection = showPrediction && !intro; // && permissions.modelSelector;
    return (
        <>
            {!hiddenMenu ? (
                <div className={showAlert ? classes.rootWithAlerts : classes.rootWithoutAlerts}>
                    <div style={{ width: '100%' }}>
                        <Alerts module="frequencies" />
                        <form autoComplete="off" className={classes.form}>
                            {!isMobile && (
                                <div className={classes.buttonWrapper}>
                                    <IconButton className={classes.hideButton} onClick={handleHideClick}>
                                        <HideIcon />
                                    </IconButton>
                                </div>
                            )}
                            <BuildSelector />
                            {permissions.frequenciesCategorySelector && (
                                <FrequenciesCategorySelector intro={intro} />
                            )}
                            <Grid container columnSpacing={2}>
                                <Grid size={isStacked ? 6 : 12}>
                                    {(permissions.layoutSelector || intro) && (
                                        <ChartLayoutSelector intro={intro} />
                                    )}
                                </Grid>
                                <Grid size={isStacked ? 6 : 12}>
                                    {(permissions.plotTypeSelector || intro) &&
                                        isStacked && (
                                        <PlotTypeSelector />
                                    )}
                                </Grid>
                            </Grid>
                            { !intro && <SubmissionDatePicker view='frequencies'/>}
                            <FrequencyTypeSelector />
                            {(permissions.plotTypeSelector || intro) &&
                                !isStacked &&
                                plotType === 'frequencies' && (
                                <YScaleSelector />
                            )}
                           
                            <FrequenciesLegend mouseEventsEnabled={true} />
                            
                            <ColorScaleSelector colorBy={freqCategory} onlyDiscrete resetData={resetFrequenciesAndPredictions} />
                            
                            <Category label='Tracking' />
                            {(permissions.trackingDatesSelector || intro) && (
                                <DatesSelector intro={intro} />
                            )}
                            
                            {!intro && (
                                <Grid container columnSpacing={2}>
                                    <Grid size={12}>
                                        {permissions.strainsSubsetSelector && (
                                            <StrainsSubsetSelector
                                                context="frequencies"
                                            />
                                        )}
                                    </Grid>
                                    <Grid size={12}>
                                        {permissions.multiplicityMethodSelector && (
                                            <MultiplicityMethodSelector />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                           
                            <Grid container>
                                {permissions.displayErrorBarsSelector && (
                                    <Grid size={12}>
                                        <CheckboxInput
                                            id="displayErrorBars"
                                            label="Error bars"
                                            value={displayErrorBars}
                                            onChange={handleChange('displayErrorBars')}
                                        />
                                    </Grid>
                                )}
                                {(permissions.displayGreyZoneSelector || intro) && (
                                    <Grid size={12}>
                                        <CheckboxInput
                                            id="displayGreyZone"
                                            label="Count density"
                                            value={displayGreyZone}
                                            onChange={handleChange('displayGreyZone')}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            {showPredictionSection && (
                                <>
                                    <Category label='Prediction' />
                                    <Grid size={12}>
                                        <PredictionBaselinePicker type={'frequencies'} />
                                    </Grid>
                                    {permissions.modelSelector  && (
                                        <MultipleModelsSelector />
                                    )} 
                                </>
                            )}
                            
                            {permissions.generateReportButton && !intro && (
                                <RegionalFreqButton />
                            )}  
                        </form>
                    </div> 
                </div>
            ) : (
                <IconButton
                    className={classes.expandButton}
                    onClick={handleHideClick}
                >
                    <HamburgerIcon />
                </IconButton>
            )}
        </>
    );
};

ChartOptions.propTypes = {
    freqCategory: PropTypes.string,
    hiddenMenu: PropTypes.bool,
    isMobile: PropTypes.bool,
    isStacked: PropTypes.bool,
    lineage: PropTypes.string,
    permissions: PropTypes.object,
    plotType: PropTypes.string,
    predictionBaseline: PropTypes.instanceOf(Date),
    resetFrequenciesAndPredictions: PropTypes.func,
    setHiddenMenu: PropTypes.func,
    setParameters: PropTypes.func,
    showAlert: PropTypes.bool,
    showPrediction: PropTypes.bool,
    trackingTo: PropTypes.instanceOf(Date),
};

const mapStateToProps = (state) => ({
    freqCategory: state.parameters.freqCategory,
    displayErrorBars: state.parameters.displayErrorBars,
    displayGreyZone: state.parameters.displayGreyZone,
    hiddenMenu: state.ui.hiddenMenu,
    hiddenMenuMobile: state.ui.hiddenMenuMobile,
    isMobile: getIsMobile(),
    isStacked: state.parameters.chartLayout === 'stacked',
    lineage: state.parameters.lineage,
    permissions: state.user.permissions.frequenciesPermissions,
    plotType: state.parameters.plotType,
    predictionBaseline: predictionBaselineSelector(state),
    showAlert: state.alert.strainTree.show,
    showPrediction: state.parameters.showPrediction,
    trackingFrom: trackingFromSelector(state),
    trackingTo: trackingToSelector(state),
    ...state.models,
});

const resetFrequenciesAndPredictions = dispatch => bindActionCreators(
    {
        resetFrequencies,
        resetPredictions,
        setParameters,
    },
    dispatch
);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            setHiddenMenu,
            resetFrequenciesAndPredictions,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ChartOptions);
