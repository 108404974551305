import { createSelector } from 'reselect';
import { daysToDate, isNull } from '../../functions/utils';
import { getAntigenicData, getAntigenicDataType, getAntigenicTiterType } from './antigenicDataSelector';
import { getTreeNodeAttrs, getActiveLegendOption, getClades } from './treeDataSelector';
import { prepareMutationsInfo } from '../../components/Tree/d3/displayValue';
import { getScaledValue } from '../../functions/scales';
import { getColorBy } from './parametersSelector';
import { getAntigenicValue } from '../../functions/data-helpers';


const getNodeType = ({ nodeData }) => nodeData.nodeType;
const getNodeId = ({ nodeData }) => nodeData.nodeId;
// const getModel = ({ modelData }) => modelData.model;
const getCladeType = ({ parameters }) => parameters.cladeType;
const getTreeDataStatus = ({ treeData }) => treeData.treeDataStatus;
const getNodeData = ({ nodeData }) => nodeData;
const getCladeData = ({ cladeData }) => cladeData;


const getNodeTreeAttrs = createSelector([getNodeId, getTreeNodeAttrs], (nodeId, treeAttrs) => {
    return treeAttrs && treeAttrs[nodeId];
});


const getRootNodeAdvance = ({ treeData, modelData }) => {
    const { rootNodeId, treeDataStatus } = treeData;
    const { model, modelStatus } = modelData;
    return treeDataStatus === 'loaded' &&
        modelStatus === 'loaded' &&
        rootNodeId &&
        model[rootNodeId] &&
        model[rootNodeId].advance
        ? model[rootNodeId].advance
        : 0;
};

const getCladeByType = (cladeId, cladeType, clades) => {
    let clade = clades[cladeId];
    if (cladeType !== 'clade') {
        clade = clades[cladeId]?.cladeMapping?.[cladeType];
    }
    return clade;
};

const getCladeIdByType = (id, cladeType, clades) => getCladeByType(id, cladeType, clades)?.alpha;

const getNodeClade = createSelector([getTreeDataStatus, getTreeNodeAttrs, getNodeData, getCladeData, getCladeType], (treeDataStatus, treeAttrs, nodeData, cladeData, cladeType) => {
    try {
        const { clades, cladesStatus } = cladeData;
        const { nodeId } = nodeData;

        if (treeDataStatus !== 'loaded' || cladesStatus !== 'loaded' || !treeAttrs || !clades || isNull(nodeId))
            return undefined;
        const clade = treeAttrs[nodeId]?.clade;
        const id = clade ? clades[clade].id : null;

        if (!id)
            return null;

        const cladeId = getCladeIdByType(id, cladeType, clades);
        const res = clades[cladeId];

        return res;

    } catch (e) {
        console.log(e);
        return null;
    }
});


const getNodeSigAlphaCladeId = ({ treeData, nodeData, cladeData }) => {
    const { treeAttrs, treeDataStatus } = treeData;
    const { clades, cladesStatus } = cladeData;
    const { nodeId } = nodeData;
    if (treeDataStatus !== 'loaded' || cladesStatus !== 'loaded' || !treeAttrs || !clades || isNull(nodeId))
        return undefined;
    //return treeAttrs[nodeId].cladeAlpha || treeAttrs[nodeId].cladeSigAlpha;


    const nodeAlphaClade = treeAttrs && treeAttrs[nodeId] ? treeAttrs[nodeId].cladeSigAlpha : null;
    const nodeAlpha = nodeAlphaClade && clades[nodeAlphaClade] ? clades[nodeAlphaClade].antigenicAlpha : null;
    //console.log('[getNodeSigAlphaCladeId] nodeAlpha = ',nodeAlpha);
    //const retValue = emptyObject(nodeAlpha) ? null : antigenicModel?.[nodeAlpha];
    //console.log('[getNodeSigAlphaCladeId] retValue = ',retValue);
    return nodeAlpha;
};

const getNodeAntigenic = createSelector(
    [getNodeId, getNodeSigAlphaCladeId, getAntigenicData, getAntigenicDataType, getAntigenicTiterType],
    (nodeId, sigAlphaCladeId, antigenicData, antigenicDataType, antigenicTiterType) => {
        const strainCladeId =
            antigenicDataType === 'epitope_clades' || antigenicDataType === 'raw_strain' ? nodeId : sigAlphaCladeId;
        const paramName = `antigenic_titer_${antigenicTiterType}`;
        const res =
            antigenicData && strainCladeId
                ? { [paramName]: antigenicData[strainCladeId], antigenic: antigenicData[strainCladeId] }
                : undefined;
        return res;
    },
);


const nodeTypeAttrs = {
    mutations: { id: true, mutations: true, NSMutations: true, SMutations: true, ALLMutations: true },
};
const nodeTypeAttrFilter = (nodeType, name) => !nodeTypeAttrs[nodeType] || nodeTypeAttrs[nodeType][name];

// const getSelectedNodeData = ({ nodeData }) => nodeData;
// export const getNodeMutations = createSelector(getSelectedNodeData, (mutations) => prepareMutationsInfo(mutations));

const getNodeMutations = ({ nodeData }) => nodeData.nodeData;

const getOnlyHighlight = ({ nodeData }) => nodeData.onlyHighlight;



const getNodeDataById = createSelector(
    [
        getNodeId,
        getNodeType,
        getNodeTreeAttrs,
        getNodeClade,
        getNodeAntigenic,
        getRootNodeAdvance,
        getNodeMutations,
        getOnlyHighlight,
        getClades,
        getAntigenicData,
        getAntigenicDataType,
        getTreeNodeAttrs
    ],
    (
        nodeId,
        nodeType,
        nodeTreeAttrs,
        clade,
        antigenic,
        rootNodeAdvance,
        //tcellAntigenicity,
        nodeMutations,
        onlyHighlight,
        clades,
        antigenicModel,
        antigenicDataType,
        treeAttrs
    ) => {
        try {
            if (onlyHighlight) return {};
            const attrData = (attrs, name, valueGetter, allowNull) => {
                if (!allowNull && !nodeTypeAttrFilter(nodeType, name)) return null;
                const _valueGetter = valueGetter || ((val) => val);
                return _valueGetter(attrs[name]);
            };

            if (!nodeTreeAttrs) return {};
            const mutations = nodeMutations.length > 0 ? nodeMutations : attrData(nodeTreeAttrs, 'mutations');
            const { nucMut, aminoMut } = prepareMutationsInfo(mutations);
            const cladeId = clade ? clade.id : nodeTreeAttrs.clade;
            // console.log(nodeId, cladeId, clades, antigenicModel, antigenicDataType, treeAttrs[nodeId]);
            const antigenicValue = getAntigenicValue(nodeId, cladeId, clades, antigenicModel, antigenicDataType, treeAttrs[nodeId]);

            return {
                id: nodeId,
                ...nodeTreeAttrs,
                name: attrData(nodeTreeAttrs, 'name', (v) => (v ? v.split('_')[0] : null)),
                EPI: attrData(nodeTreeAttrs, 'name', (v) => (v ? v.split('_').slice(1).join('_') : null)),
                date: attrData(nodeTreeAttrs, 'time', (v) => (v ? daysToDate(v).toLocaleDateString() : null)),
                submissionDate: attrData(nodeTreeAttrs, 'subtime', (v) => (v ? daysToDate(v).toLocaleDateString() : null)),
                advance: attrData(nodeTreeAttrs, 'advance', (v) => (v || 0) - rootNodeAdvance, true), //nodeTreeAttrs ? (nodeTreeAttrs.advance || 0) - rootNodeAdvance : null,
                nucMut: nucMut && nucMut.length > 0 ? nucMut : null,
                aminoMut: aminoMut && aminoMut.length > 0 ? aminoMut : null,
                frequency: nodeTreeAttrs.frequency || 0,
                clade: cladeId,
                cladeLabel: clade ? `${clade.label}` : null,
                antigenic: antigenicValue,
                ...antigenic,
                //tcellAntigenicity
            };
        }
        catch (e) {
            console.log(e);
            return {};
        }
    },
);

export const getLegendSelectedNodes = createSelector(
    [
        getTreeNodeAttrs,
        getColorBy,
        getActiveLegendOption,
        getCladeType,
        getClades,
        getAntigenicData,
        getAntigenicDataType
    ],
    (treeAttrs, colorBy, activeLegendOption, cladeType, clades, antigenicModel, antigenicDataType) => {
        if (
            !treeAttrs ||
            !activeLegendOption ||
            isNull(activeLegendOption.value) ||
            activeLegendOption.value.length === 0
        )
            return null;
        const scaleName = `${colorBy}ValueScale`;

        const value = Number.isNaN(+activeLegendOption.value)
            ? activeLegendOption.value
            : +activeLegendOption.value;

        const selectedNodes = Object.entries(treeAttrs)
            .filter(([id, val]) => {
                const res = val.name && (() => {
                    switch(colorBy) {
                        case 'clade':
                            return getCladeIdByType(val[colorBy], cladeType, clades) == value;
                        case 'antigenic': {
                            const antigenicValue = getAntigenicValue(id, val.clade, clades, antigenicModel, antigenicDataType, treeAttrs[id]);
                            return value === getScaledValue(scaleName, antigenicValue);
                        }
                        default:
                            return value === getScaledValue(scaleName, val[colorBy]);
                    }
                })();
                return res;
            })
            .map(([id]) => id);
        // console.log(cladeType, colorBy, clades, selectedNodes);

        return selectedNodes;
    }
);

export { getNodeDataById };
