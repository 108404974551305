import { makeStyles } from '@mui/styles';
import { isFirefox } from '../../../functions/browser-check';

export const styles = makeStyles({
    root: {
        height: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    formControl: {
        margin: '4px 0px',
        paddingRight: '8px',
        minWidth: '120px'
    },
    button: {
        width: '80px',
        height: '32px',
        margin: '5px 0 10px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    hideButton: {
        padding: 0,
        float: 'right'
    },
    buttonWrapper: {
        width: '100%',
        height: '30px'
    }
});
