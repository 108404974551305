// External imports
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';

// Internal imports - Components
import NumberInput from '../Common/NumberInput';

const TauSelector = (props) => {
    const { tau, updateModelParameter, index } = props;

    const updateTau = (value) => {
        updateModelParameter(value, index);
    };
 
    return (
        <Grid size={6}>
            <NumberInput
                id="tau"
                label="Tau" 
                value={tau}
                onAccept={updateTau}
                allowFloat={true}
            />
        </Grid>
    );
};

TauSelector.propTypes = {
    // Required props
    index: PropTypes.number.isRequired,
    tau: PropTypes.number.isRequired,
    updateModelParameter: PropTypes.func.isRequired
};



export default TauSelector;
