// React and Redux
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

// Constants
import { RENDER_STATUS } from '../../../config/consts';
import { LAYOUT } from '../../../config/dictionaries';

// Redux Actions & Selectors
import { resetLabels, setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { cladeLabelsSelector } from '../../../redux/selectors/treeDataSelector';

// Components
import { treeD3 } from '../d3/TreeD3';
import CladeLabel, { getCladeLabelDimensions, getCladeLabelPosition } from '../labels/CladeLabel/CladeLabel';
import CladeLabelAnchorPoint from '../labels/CladeLabel/CladeLabelAnchorPoint';
import CladeLabelLink from '../labels/CladeLabel/CladeLabelLink';

const viewToRender = 'strainTree';
const componentId = 'cladeLabels';

const CladeLabelsLayer = (props) => {

    const {
        loading,
        cladeLabels,
        setComponentStatus,
        layout,
        renderStatus,
    } = props;

    // console.log('[CladeLabelsLayer] props', props);


    const labelsCnt = useMemo(() => cladeLabels ? cladeLabels.length : 0, [cladeLabels]);

    // const cladeLabelsRendered = (symbolRenderCount === labelsCnt && linkRenderCount === labelsCnt && labelRenderCount === labelsCnt) || labelsCnt === 0;
    //console.log(`[CladeLabelsLayer] useEffect`, {renderStatus, cladeLabelsRendered, loading});
    // const rerenderLabels =  renderStatus === RENDER_STATUS.NONE; // && isRendering;

    const renderedCladeLabels = useMemo(() => {
        if (!cladeLabels || !cladeLabels?.length || renderStatus !== RENDER_STATUS.NONE) return null;
        const labels = cladeLabels.reduce((acc, { id, labelText, newNomenclature, minOrder, maxOrder }) => {
            const dimensions = getCladeLabelDimensions(labelText, newNomenclature);
            const position = getCladeLabelPosition(id, dimensions.labelHeight, minOrder, maxOrder);
            acc[id] = { ...dimensions, ...position };
            return acc;
        }, {});
        return labels;
    }, [cladeLabels, renderStatus]);


    // console.log('[CladeLabelsLayer] renderedCladeLabels', {renderedCladeLabels, renderStatus, cladeLabels});

    const startRender = () => {
        // console.log(`[startRender] => ${viewToRender} ${componentId} ${RENDER_STATUS.START}`);
        if (renderedCladeLabels) {
            setTimeout(() => setComponentStatus(viewToRender, componentId, RENDER_STATUS.START, { labels: renderedCladeLabels, type: 'cladeLabel' }), 0);
            // resetLabels();
        }
        else setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };

    useEffect(() => {
        if (Object.keys(renderedCladeLabels||{}).length > 0) {
            setComponentStatus(viewToRender, componentId, RENDER_STATUS.NONE);
        // setComponentStatus(viewToRender, 'mutationsClasses', RENDER_STATUS.NONE);
        // resetLabels();
        }
        // else {
        // console.log('[CladeLabelsLayer] useEffect SETTING DONE');
        // setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
        // }
    }, [renderedCladeLabels]);

    useEffect(() => {
        // console.log('[MutationsClassesLayer] useEffect', {loading, rerenderLabels, mutationsLabels, mutationClassesData: mutationClassesData.length});
        if (loading || renderStatus !== RENDER_STATUS.NONE) return;
        startRender();
    }, [loading, renderStatus]);

    
    // console.log('[CladeLabelsLayer] renderStatus', { renderStatus, show:!loading && layout !== LAYOUT.FAN.value && labelsCnt > 0 && renderStatus === RENDER_STATUS.DONE});
    
    return (
        <g id="cladeLabels" transform={treeD3.translate(false)}>
            {!loading && layout !== LAYOUT.FAN.value && labelsCnt > 0 && renderStatus === RENDER_STATUS.DONE && (
                <>
                    <g id="cladeLabelLinks">
                        {cladeLabels.map(({ id }) => (
                            <CladeLabelLink
                                key={`${id}_cladeLabelLink`}
                                id={id}
                                classNamePrefix="cladeLabel"
                                // onElementRendered={handleLinkRendered}
                                // rerenderLabels={rerenderLabels}
                            />
                        ))}
                    </g>
                    <g id="cladeLabelAnchorPoints">
                        {cladeLabels.map(({ id }) => (
                            <CladeLabelAnchorPoint
                                key={`${id}_cladeLabelAnchorPoint`}
                                id={id}
                                classNamePrefix="cladeLabel"
                            />
                        ))}
                    </g>
                    <g id="cladeLabels">
                        {cladeLabels.map(({ id, ...props }) => (
                            <CladeLabel
                                key={`${id}_cladeLabel`}
                                id={id}
                                classNamePrefix="cladeLabel"
                                {...props}
                            />
                        ))}
                    </g>
                </>)
            }
        </g >
    );
};

CladeLabelsLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
    //showCladeLabels: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
    loading: getStrainTreeStatus(state) || !ownProps.initialized,
    layout: state.parameters.layout,
    cladeLabels: cladeLabelsSelector(state),
    renderStatus: state.render.viewToRender.components[componentId] || RENDER_STATUS.NONE
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
            resetLabels
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CladeLabelsLayer);
