import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { styles } from '../Styles/panelStyles';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { fetchAllScales } from '../../../redux/actions/settingsActions';
import Table from '../../../components/Table/Table';

const headers = [
    { name: 'scaleId', label: 'Scale id' },
    { name: 'lineage', label: 'Lineage' },
    { name: 'range', label: 'Color palette' },
    { name: 'numeric', label: 'Numeric' },
    { name: 'discrete', label: 'Discrete' },
    { name: 'actions', label: 'Actions' },
];

const orderInitState = {
    scaleId: 'asc',
    lineage: 'none',
    range: 'none',
    numeric: 'none',
    discrete: 'none',
    actions: 'none',
};

const searchState = {
    scaleId: '',
    lineage: '',
    numeric: '',
    discrete: '',
};

const searchLabels = {
    scaleId: 'Scale id',
    lineage: 'Lineage',
    numeric: 'Numeric',
    discrete: 'Discrete',
};

const searchOptionsState = {
    scaleId: [],
    lineage: [],
    numeric: [],
    discrete: [],
};

function createData(scale) {
    const { scaleId, discrete, numeric, range, lineage } = scale;
    const lineageString = lineage && lineage !== 'none' ? lineage : 'all';
    const numString = typeof numeric === 'undefined' ? 'default (false)' : numeric.toString();

    if (typeof range === 'object')
        return { scale, scaleId, discrete: discrete.toString(), numeric: numString, lineage: lineageString, range: 'custom' };
    else if (typeof range === 'undefined')
        return { scale, scaleId, discrete: discrete.toString(), numeric: numString, lineage: lineageString, range: '' };
    else
        return { scale, scaleId, discrete: discrete.toString(), numeric: numString, lineage: lineageString, range };
}

const ScalesPanel = (props) => {
    const { colorScales, fetchAllScales, colorScalesLength } = props;
    const [tableData, setTableData] = useState([]);
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);
    const classes = styles();

    useEffect(() => {
        if (colorScalesLength === 0)
            fetchAllScales();
    }, []);

    useEffect(() => {
        if (colorScalesLength === 0) return;

        const rows = colorScales.map((scale) => {
            return createData(scale);
        });

        const newOptions = {
            scaleId: [...new Set(colorScales.map(el => el.scaleId))],
            lineage: [...new Set(...[colorScales.map(el => el?.lineage || 'all'), 'all'])],
            numeric: ['true', 'false'],
            discrete: ['true', 'false'],
        };
        setSearchOptions({ ...newOptions });
        setTableData(rows);
    }, [colorScales]);

    return (
        <Grid container className={classes.container}>
            <Grid size={12} className={classes.item}>
                {tableData && tableData.length > 0 &&
                    <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                        <Table
                            name='scales'
                            searchOptions={searchOptions}
                            orderInitState={orderInitState}
                            headers={headers}
                            tableData={tableData}
                            searchState={searchState}
                            searchLabels={searchLabels}
                        />
                    </Paper>
                }
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { colorScales } = state.settings;

    return {
        colorScales,
        colorScalesLength: colorScales.length
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllScales,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ScalesPanel);

