import { FETCH_FITNESS_MODEL_REQUEST } from '../actions/actionTypes';
import { ofType } from 'redux-observable';
import { map, mergeMap } from 'rxjs/operators';
import { prepareUrl } from '../../functions/url-parameters';
import {
    fetchFitnessModelSuccess,
    fetchFitnessModelError
} from '../actions/fitnessActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axios-requests';
import { from } from 'rxjs';
import { handleErrors } from '../operators/error';

export const fetchFitnessModelEpic = (action$) => action$.pipe(
    ofType(FETCH_FITNESS_MODEL_REQUEST),
    mergeMap(action => {
        const { lineage, antigenicFitnessModelId } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/fitnessModel`, { lineage, antigenicFitnessModelId });

        return from(fetchAxios(url))
            .pipe(
                map((response) => fetchFitnessModelSuccess(response.data)),
                handleErrors(fetchFitnessModelError, 'An error has occurred during downloading fitness data')
            );
    })
);
