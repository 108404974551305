import { produce } from 'immer';
import {
    FETCH_LINEAGES_SUCCESS,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_REQUEST,
    FETCH_LINEAGE_SUCCESS,
    FETCH_LINEAGE_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    GET_SIGNED_USER_SUCCESS,
    SIGNIN_SUCCESS,
    GET_SIGNED_USER_ERROR,
    SIGNIN_ERROR,
    GET_SIGNED_USER_REQUEST,
    SIGNIN_REQUEST,
    SIGNOUT_SUCCESS,
} from '../actions/actionTypes';


let lineagesInitialState = {};
export const setLineagesInitialState = (state) => {
    lineagesInitialState = state;
};

export const lineagesDataReducer = (state = lineagesInitialState, action) => {
    if (action.payload && action.payload.settings) return state; 
    return produce(state, (draft) => {
        switch (action.type) {
            case SIGNOUT_REQUEST: {
                return lineagesInitialState;
            }
            case RESET_SESSION: {
                draft.lineageStatus = 'none';
                break;
            }
            case SIGNOUT_SUCCESS: {
                draft.lineagesStatus = 'none';
                break;
            }
  
            case GET_SIGNED_USER_REQUEST: 
            case SIGNIN_REQUEST: {
                draft.lineagesStatus = 'loading';
                break;
            }
            case FETCH_LINEAGES_SUCCESS: {
                const { lineages } = action.payload;
                draft.lineages = lineages;
                draft.lineagesStatus = 'loaded';
                break;
            }
            case GET_SIGNED_USER_SUCCESS:
            case SIGNIN_SUCCESS: {
                const { user } = action.payload;
                const { lineages } = user;
                draft.lineages = lineages;
                draft.lineagesStatus = 'loaded';
                break;
            }
            case FETCH_LINEAGES_ERROR: 
            case GET_SIGNED_USER_ERROR:
            case SIGNIN_ERROR: {
                draft.lineagesStatus = 'error';
                break;
            }
            case FETCH_LINEAGE_REQUEST: {
                draft.lineageStatus = 'loading';
                break;
            }
            case FETCH_LINEAGE_SUCCESS: {
                draft.lineageStatus = 'loaded';
                break;
            }
            case FETCH_LINEAGE_ERROR: {
                draft.lineageStatus = 'error';
                break;
            }
            default:
                break;
        }
    });   
};

