import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteMutClassDialog from '../Dialogs/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axios-requests';
import { fetchMutationClassesArray } from '../../../../redux/actions/settingsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionsStyles } from '../../Styles/actionsStyles';

const Delete = ({ setInfoDialog, setAction, setInfo, fetchMutationClassesArray, name, lineage, mutGroup}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = actionsStyles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteMutClass = async () => {
        try {
            const url = `${config.serverLink}/api/admin/deleteMutationClass`;
            const body = { lineage, name, mutGroup };
            await postAxios(url, body);
            setInfo(`Mutation class ${name} deleted sucesfully.`);
        }
        catch (error) {
            console.error(error);
            const message = error.response?.data?.message || error.message;
            setInfo(`An error has occurred during deleting ${name}.: ${message}.`);
        }
        finally {
            handleCloseDialog();
            setAction('Delete mutation class');
            setInfoDialog(true);
            fetchMutationClassesArray();
        }
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <DeleteMutClassDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteMutClass} name={name} />
        </>

    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMutationClassesArray
        },
        dispatch,
    );

export default connect(undefined, mapDispatchToProps)(Delete);
