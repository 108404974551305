import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ScalesLegend from './ScalesLegend';
import { selectedCladesSelector, visibleCladesSelector } from '../../../redux/selectors/vaccinesSelector';
import { toggleSelectedVaccineClades, revertSelectedVaccineClades } from '../../../redux/actions/vaccinesActions';
 
const VaccinesLegend = (props) => {
        
    const { visibleClades, selectedClades, toggleSelectedVaccineClades, revertSelectedVaccineClades, valueGetter, fullHeight, mouseEventsEnabled } = props;
    // console.log('VaccinesLegend', { selectedClades });
    // console.log('FrequenciesLegend', { selectedBins });
    // Handle the focus-out event

    const handleClick = (value) => {
        console.log('handleClick', value, selectedClades[value]);
        toggleSelectedVaccineClades(value);
    
    };
    const handleDoubleClick = (value) => {
        console.log('handleDoubleClick', value);
        revertSelectedVaccineClades(selectedClades);
    };
    return (
        <ScalesLegend
            measureName='clade'
            // inContainer
            selectedBins={selectedClades}
            visibleBins={visibleClades || {}}
            handleClick={handleClick}
            handleDoubleClick={handleDoubleClick}
            selectable={true}
            valueGetter={valueGetter}
            fullHeight={fullHeight || false}
            mouseEventsEnabled={mouseEventsEnabled || false}
            type='vaccines'
            //onMouseLeave={handleMouseLeave} // Pass the onMouseOut handler to ScalesLegend
        />
    );
};


const mapStateToProps = (state) => {
    const { exportMode } = state.parameters;
    const selectedClades = selectedCladesSelector(state);
    const visibleClades = exportMode ? selectedClades : visibleCladesSelector(state);
    return {
        visibleClades,
        selectedClades,
        exportMode: state.parameters.exportMode,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ toggleSelectedVaccineClades, revertSelectedVaccineClades }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesLegend);

VaccinesLegend.propTypes = {
    freqCategory: PropTypes.string,
    chartLayout: PropTypes.string,
    selectedClades: PropTypes.objectOf(PropTypes.any),
    visibleClades: PropTypes.objectOf(PropTypes.any),
    valueGetter: PropTypes.func,
    toggleSelectedVaccineClades: PropTypes.func,
};
