import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowHorizontalIcon, ArrowVerticalIcon, BracketIcon, MinusIcon, MinusIconMobile, PlusIcon, PlusIconMobile } from '../SvgIcons/ZoomIcons';
import { IconButton } from '@mui/material';
import { isSafari } from '../../functions/browser-check';
import Draggable from '../Common/Draggable';


export const ZoomMenu = props => {
    const { handleChange, classes, updateAxes, nHeight, nWidth, isMobile } = props;

    const [isBraceOn, setBraceOn] = useState(true);

    const switchBracket = () => {
        setBraceOn(!isBraceOn);
    };

    return (
        <>
            {isMobile ?
                <div className={classes.zoomMenu}>
                    <IconButton onClick={() => updateAxes('', 'minus', true)}>
                        <MinusIconMobile />
                    </IconButton>
                    <IconButton onClick={() => updateAxes('', 'plus', true)} style={{ padding: '8px 5px 8px 5px' }}>
                        <PlusIconMobile />
                    </IconButton>

                </div>
                :
               
                
             
                <Draggable style={{ transform: 'translate(50px, 0px)' }}>
                    <div className={classes.zoomMenu}>
                        <div>
                            <ArrowVerticalIcon />
                            <MinusIcon styleM='6px 3px 2px 3px' updateAxes={updateAxes} isBraceOn={isBraceOn} type='vertical' />
                            <PlusIcon styleP='6px 3px 2px 3px' updateAxes={updateAxes} isBraceOn={isBraceOn} type='vertical' />
                            <input id='xZoomVal' type='text' style={{ marginTop: isSafari ? '2px' : '4px' }} onChange={(e) => handleChange(e, 'vertical', isBraceOn)} value={nHeight} className={classes.counter} />
                        </div>
                        <div onClick={switchBracket}>
                            <BracketIcon isBraceOn={isBraceOn} />
                        </div>
                        <div>
                            <ArrowHorizontalIcon />
                            <MinusIcon styleM='0px 3px 2px 3px' updateAxes={updateAxes} isBraceOn={isBraceOn} type='horizontal' />
                            <PlusIcon styleP='0px 3px 2px 3px' updateAxes={updateAxes} isBraceOn={isBraceOn} type='horizontal' />
                            <input id='yZoomVal' type='text' style={{ marginTop: isSafari ? '-3px' : '-1px' }} onChange={(e) => handleChange(e, 'horizontal', isBraceOn)} value={nWidth} className={classes.counter} />
                        </div>
                    </div>
                </Draggable>
          
    
            }
        </>
    );


};

ZoomMenu.propTypes = {
    handleChange: PropTypes.func,
    updateAxes: PropTypes.func,
    nHeight: PropTypes.number,
    nWidth: PropTypes.number,
    isMobile: PropTypes.bool,
    switchBracket: PropTypes.func,
    classes: PropTypes.object
};

