import { createSelector } from 'reselect';
import { getTreeFromTreeArray } from './treeDataSelector';
import { getVaccineCandidates } from './metadataSelector';
import {
    emptyObject,
    treePreOrder,
    isNull,
    compare,
} from '../../functions/utils';
import { median } from 'd3-array';
import { get } from 'lodash';

const getTiterValue = (value, antigenicTiterType) => {
    switch (antigenicTiterType) {
        case 'drop':
            return 11.0 - value;
        case 'fold_reduction':
            return Math.pow(2, 11.0 - value);
        default:
            return value;
    }
};

const antigenicModule = ({ metadata }) =>
    metadata && metadata.modules
        ? metadata.modules.antigenic || metadata.modules.fitness
        : false;
const getAntigenicModel = ({ antigenic }) => antigenic.antigenicModel;
const getAntigenicClades = ({ antigenic }) => antigenic.antigenicClades;
const getAntigenicTiterType = ({ parameters }) => parameters.antigenicTiterType;
const getAntigenicDataType = ({ parameters }) => parameters.antigenicDataType;
const getRefClade = ({ parameters }) => parameters.refClade;
const getRefStrain = ({ parameters }) => parameters.refStrain;
const getAntigenicModelStatus = ({ antigenic }) =>
    antigenic.antigenicModelStatus;
const getAntigenicCladesStatus = ({ antigenic }) =>
    antigenic.antigenicCladesStatus;
const getAntigenicObservedData = ({ antigenic }) =>
    antigenic.antigenicObservedData;
const getAntigenicObservedDataStatus = ({ antigenic }) =>
    antigenic.antigenicObservedDataStatus;

const getAntigenicFitnessModelStatus = ({ fitness }) =>
    fitness.fitnessModelStatus;
const getAntigenicFitnessModel = ({ fitness }) => fitness.fitnessModel;

const getTreeAttrs = ({ treeData }) => treeData && treeData.treeAttrs;

const getRawAntigenicStrains = (state) => state.antigenic.rawStrains;
const getRawAntigenicModel = ({ antigenic }) => antigenic.rawAntigenicModel;
const getAntigenicRawModelStatus = ({ antigenic }) =>
    antigenic.antigenicRawModelStatus;

/**
 * Returns a list of strains with raw measurements for antigenic distance. T
 * @param {Object} rawAntigenicModel
 * @param {Object} rawStrains
 * @param {Array} vaccineCandidates
 * @returns {Array} list ofstrains with names
 */

const getRawAntigenicReferenceStrains = createSelector(
    [getRawAntigenicModel, getRawAntigenicStrains, getVaccineCandidates],
    (rawAntigenicModel, rawStrains, vaccineCandidates) => {
        const vcDict = (vaccineCandidates || []).reduce((acc, v) => {
            acc[v.id] = 1;
            return acc;
        }, {});
        const refStrains = Object.keys(rawAntigenicModel).reduce((acc, key) => {
            Object.keys(rawAntigenicModel[key]).forEach((strainId) => {
                if (rawStrains[strainId]) acc[strainId] = true;
            });
            return acc;
        }, {});

        //    console.log(Object.keys(refStrains).map(id => ({id, n: vcDict[id] ? `*${rawStrainNames[id]}` : rawStrainNames[id]})))
        const sortedRefStrains = Object.keys(refStrains)
            .map((id) => ({ id:+id, n: rawStrains[id].name, vaccine: vcDict[id] }))
            .sort((s1, s2) => s1.n.localeCompare(s2.n));

        return sortedRefStrains;
    }
);

// const getReferenceStrainsDictByName = createSelector(getRawAntigenicReferenceStrains, refStrainNames =>
//     refStrainNames.reduce((acc, refStrain) => {
//         const name = refStrain.n.split('_')[0];
//         if (!acc[name]) acc[name] = [];
//         acc[name].push(refStrain);
//         return acc;
//     }, {})
// );

const getReferenceStrainsByName = createSelector(getRefStrain, (refStrain) => {
    const _refStrains = `${refStrain || ''}`
        .split(',')
        .filter((rs) => rs)
        .map((rs) => +rs);
    return _refStrains;
});

const epitopeMutationsGroupsSelector = createSelector(
    getTreeAttrs,
    (treeAttrs) => {
        if (!treeAttrs) return null;
        return Object.keys(treeAttrs).reduce((acc, id) => {
            if (treeAttrs[id].epitopeMutationsGroups) {
                const epitopeMutDict = acc === null ? {} : acc;
                epitopeMutDict[id] = treeAttrs[id].epitopeMutationsGroups;
                return epitopeMutDict;
            }
        }, null);
    }
);

const getModelType = (antigenicDataType, antigenicTiterType) => {
    if (antigenicDataType === 'inferred' && antigenicTiterType === 'absolute')
        return 'inferred';
    if (antigenicDataType === 'inferred' && antigenicTiterType === 'drop')
        return 'inferred_drop';
    if (
        antigenicDataType === 'inferred' &&
        antigenicTiterType === 'fold_reduction'
    )
        return 'inferred_fold_reduction';
    if (antigenicDataType === 'observed' && antigenicTiterType === 'absolute')
        return 'observed';
    if (antigenicDataType === 'observed' && antigenicTiterType === 'drop')
        return 'observed_drop';
    if (
        antigenicDataType === 'observed' &&
        antigenicTiterType === 'fold_reduction'
    )
        return 'observed_fold_reduction';
    return null;
};

// let prevModel = null;

const antigenicModelMemoSelector = createSelector(
    [
        antigenicModule,
        getAntigenicModelStatus,
        getAntigenicCladesStatus,
        getAntigenicModel,
    ],
    (
        antigenicModuleOn,
        antigenicModelStatus,
        antigenicCladesStatus,
        antigenicModel
    ) => {
        return !antigenicModuleOn ||
            antigenicModelStatus !== 'loaded' ||
            antigenicCladesStatus !== 'loaded' ||
            emptyObject(antigenicModel)
            ? null
            : antigenicModel;
    }
);

const antigenicFitnessModelMemoSelector = createSelector(
    [
        antigenicModule,
        getAntigenicFitnessModelStatus,
        getAntigenicCladesStatus,
        getAntigenicFitnessModel,
    ],
    (
        antigenicModuleOn,
        antigenicFitnessModelStatus,
        antigenicCladesStatus,
        antigenicFitnessModel
    ) => {
        return !antigenicModuleOn ||
            antigenicFitnessModelStatus !== 'loaded' ||
            antigenicCladesStatus !== 'loaded' ||
            emptyObject(antigenicFitnessModel)
            ? null
            : antigenicFitnessModel;
    }
);

const selectedCladesSelector = createSelector(
    [
        antigenicModule,
        getAntigenicClades,
        getAntigenicCladesStatus,
        getAntigenicModel,
    ],
    (antigenicModuleOn, antigenicClades, antigenicCladesStatus) => {
        // console.log(`selectedCladesSelector:
        // antigenicModuleOn = ${antigenicModuleOn},
        // antigenicCladesStatus = ${antigenicCladesStatus}
        // antigenicClades = ${antigenicClades}`);
        if (!antigenicModuleOn || antigenicCladesStatus !== 'loaded')
            return { alphas: null, rhos: null, alhasY: null, rhosR: null };
        const alphas = antigenicClades?.selectedAlphas || [];
        const rhos = antigenicClades?.selectedRhos || [];
        const alphasY = alphas.map((alpha) => antigenicClades?.alphas[alpha]); // frequency values for selected alphas
        const rhosR = rhos.map((rho) => ({
            id: rho,
            value: antigenicClades?.rhos[rho],
        })); // immune cohort weights for selected rhos
        // console.log(alphas);
        return { alphas, rhos, alphasY, rhosR };
    }
);

const getAntigenicValueByType = (
    antigenicModel,
    antigenicDataType,
    type,
    rho,
    alpha
) => {
    //console.log(`rho = ${rho}, alpha = ${alpha}, type = ${type}`);
    const val = antigenicModel[antigenicDataType]?.[rho]?.[alpha];
    // if (!val) console.log(`rho = ${rho}, alpha = ${alpha}, type = ${type}, val = ${val}`);
    switch (type) {
        case 'observed_drop':
        case 'inferred_drop':
            return val ? 11.0 - val : null;
        case 'observed_fold_reduction':
        case 'inferred_fold_reduction':
            return val ? Math.pow(2, 11.0 - val) : null;
        default:
            return val;
    }
};

const getAntigenicMatrixData = createSelector(
    [
        antigenicModelMemoSelector,
        getAntigenicTiterType,
        getAntigenicDataType,
        getAntigenicClades,
    ],
    (
        antigenicModel,
        antigenicTiterType,
        antigenicDataType,
        antigenicClades
    ) => {
        //console.log(`1. [getAntigenicModel]`)
        // console.log(antigenicModel);
        const type = getModelType(antigenicDataType, antigenicTiterType);
        //console.log(`1.1. [getAntigenicModel] type`, type, 'empty', emptyObject(antigenicModel), antigenicModel)
        if (emptyObject(antigenicModel)) return null;

        const alphas = antigenicClades.selectedAlphas;
        const rhos = antigenicClades.selectedRhos;

        if (!rhos || !alphas) return null;
        const data = rhos.map((rho) =>
            alphas.map((alpha) => {
                //console.log(`rho = ${rho}, alpha = ${alpha}`);
                return {
                    val: getAntigenicValueByType(
                        antigenicModel,
                        antigenicDataType,
                        type,
                        rho,
                        alpha
                    ),
                    alpha,
                    rho,
                };
            })
        );
        return data;
    }
);

// const getClades = ({ cladeData }) => cladeData.clades;

const getAntigenicFitnessMatrixData = createSelector(
    [
        antigenicFitnessModelMemoSelector,
        getAntigenicClades,
        // getClades,
    ],
    (
        antigenicFitnessModel,
        antigenicClades
        // clades
    ) => {
        if (emptyObject(antigenicFitnessModel)) return null;

        const alphas = antigenicClades.selectedAlphas;
        const rhos = antigenicClades.selectedRhos;
        if (!rhos || !alphas) return null;
        const data = rhos.map((rho) =>
            alphas.map((alpha) => {
                //console.log(`rho = ${rho}, alpha = ${alpha}`);
                return {
                    val: antigenicFitnessModel[alpha]?.[rho], //getAntigenicValueByType(antigenicModel, antigenicDataType, type, rho, alpha),
                    alpha,
                    rho,
                    // alphaClade: clades?.[alpha].label,
                    // rhoClade: clades?.[rho].label,
                };
            })
        );
        return data;
    }
);

const getAntigenicValuesForReferenceClade = createSelector(
    [
        antigenicModule,
        getAntigenicModelStatus,
        getAntigenicCladesStatus,
        getAntigenicTiterType,
        getAntigenicDataType,
        getAntigenicModel,
        getRefClade,
    ],
    (
        antigenicModuleOn,
        antigenicModelStatus,
        antigenicCladesStatus,
        antigenicTiterType,
        antigenicDataType,
        antigenicModel,
        refClade
    ) => {
        const type = getModelType(antigenicDataType, antigenicTiterType);

        if (
            !antigenicModuleOn ||
            !type ||
            antigenicModelStatus !== 'loaded' ||
            antigenicCladesStatus !== 'loaded' ||
            !antigenicModel ||
            emptyObject(refClade)
        )
            return null;

        const data = Object.keys(
            antigenicModel[antigenicDataType]?.[refClade] || {}
        ).reduce((acc, alphaClade) => {
            acc[alphaClade] = getAntigenicValueByType(
                antigenicModel,
                antigenicDataType,
                type,
                refClade,
                alphaClade
            );
            return acc;
        }, {});

        return data;
    }
);

const getAntigenicValuesForReferenceStrain = createSelector(
    [
        getAntigenicObservedData,
        getAntigenicObservedDataStatus,
        getRefStrain,
        getTreeFromTreeArray,
        epitopeMutationsGroupsSelector,
        getAntigenicTiterType,
    ],
    (
        antigenicObservedData,
        antigenicObservedDataStatus,
        refStrain,
        tree,
        epitopeMutationsGroups,
        antigenicTiterType
    ) => {
        if (
            antigenicObservedDataStatus !== 'loaded' ||
            !tree ||
            !antigenicObservedData ||
            !antigenicObservedData[refStrain]
        )
            return {};
        const antigenicDataValues = {};
        const antigenicData = antigenicObservedData[refStrain];

        const _epitopeMutationsGroups1 = epitopeMutationsGroups || {};
        treePreOrder(tree, (node, parent) => {
            const value =
                getTiterValue(antigenicData[node.id]) ||
                (parent && !_epitopeMutationsGroups1[node.id]
                    ? antigenicDataValues[parent.id]
                    : null,
                antigenicTiterType);
            if (!isNull(value)) {
                antigenicDataValues[node.id] = value;
            }
        });
        return antigenicDataValues;
    }
);

const getRawAntigenicValuesForReferenceStrain = createSelector(
    [
        getRawAntigenicModel,
        getAntigenicRawModelStatus,
        getRefStrain,
        getAntigenicTiterType,
    ],
    (
        rawAntigenicModel,
        antigenicRawModelStatus,
        refStrains,
        antigenicTiterType
    ) => {
        // console.log('[getRawAntigenicValuesForReferenceStrain] rawAntigenicModel', Object.keys(rawAntigenicModel||{}).length, antigenicRawModelStatus);
        if (antigenicRawModelStatus !== 'loaded' || !rawAntigenicModel || !refStrains)
            return {};
        //const getTiterValue = (value) => (antigenicTiterType === 'absolute') ? value : 11 - value;
        // const refStrains = refStrain;//`${refStrain || ''}`.split(',').filter((rs) => rs);

        // console.log('[getRawAntigenicValuesForReferenceStrain] refStrains', refStrains, typeof refStrains);
        const antigenicDataValuesArr = Object.keys(rawAntigenicModel).reduce(
            (acc, strainId) => {
                refStrains.forEach((rS) => {
                    if (!isNull(rawAntigenicModel[strainId][rS])) {
                        if (!acc[strainId]) acc[strainId] = [];
                        acc[strainId].push(
                            getTiterValue(
                                rawAntigenicModel[strainId][rS],
                                antigenicTiterType
                            )
                        );
                    }
                });
                return acc;
            },
            {}
        );

        const antigenicDataValues = Object.keys(antigenicDataValuesArr).reduce(
            (acc, strainId) => {
                acc[strainId] = median(antigenicDataValuesArr[strainId]);
                return acc;
            },
            {}
        );

        return antigenicDataValues;
    }
);

const getObservedStrainAntigenicValuesForReferenceStrain = createSelector(
    [
        getRawAntigenicModel,
        getAntigenicRawModelStatus,
        getAntigenicTiterType,
        getReferenceStrainsByName,
        getRawAntigenicStrains,
    ],
    (
        rawAntigenicModel,
        antigenicRawModelStatus,
        antigenicTiterType,
        refStrains,
        rawStrains
    ) => {
        if (antigenicRawModelStatus !== 'loaded' || !rawAntigenicModel)
            return {};

        const antigenicDataValuesArr = Object.keys(rawAntigenicModel).reduce(
            (acc, alphaStrain) => {
                refStrains.forEach((rhoStrain) => {
                    if (!isNull(rawAntigenicModel[alphaStrain][rhoStrain])) {
                        const { cladeAlpha } = rawStrains[alphaStrain];
                        if (!acc[cladeAlpha]) acc[cladeAlpha] = [];
                        acc[cladeAlpha].push(
                            getTiterValue(
                                rawAntigenicModel[alphaStrain][rhoStrain],
                                antigenicTiterType
                            )
                        );
                    }
                });
                return acc;
            },
            {}
        );

        const antigenicDataValues = Object.keys(antigenicDataValuesArr).reduce(
            (acc, cladeAlpha) => {
                acc[cladeAlpha] = median(antigenicDataValuesArr[cladeAlpha]);
                return acc;
            },
            {}
        );

        return antigenicDataValues;
    }
);

const getAntigenicData = createSelector(
    [
        getAntigenicDataType,
        getAntigenicValuesForReferenceStrain,
        getRawAntigenicValuesForReferenceStrain,
        getAntigenicValuesForReferenceClade,
        getObservedStrainAntigenicValuesForReferenceStrain,
        getAntigenicModelStatus,
        getRefStrain,
    ],
    (
        antigenicDataType,
        antigenicValuesForReferenceStrain,
        antigenicRawStrainModelData,
        antigenicValuesForReferenceClade,
        observedStrainAntigenicValuesForReferenceStrain
    ) => {
        const getData = () => {
            switch (antigenicDataType) {
                case 'epitope_clades': {
                    return antigenicValuesForReferenceStrain;
                }
                case 'raw_strain': {
                    return antigenicRawStrainModelData;
                }
                case 'observed_strain': {
                    return observedStrainAntigenicValuesForReferenceStrain;
                }
                default: {
                    return antigenicValuesForReferenceClade;
                }
            }
        };
        const data = getData();
        return data;
    }
);

const getObservedAntigenicRefeferenceStrains = (state) =>
    state.antigenic.antigenicObservedRefStrains;

export const getLabeledEpitopeCladesStrains = createSelector(
    [getObservedAntigenicRefeferenceStrains, getVaccineCandidates],
    (epitopeCladeStrains, vaccineCandidates) => {
        const vcDict = (vaccineCandidates || []).reduce((acc, v) => {
            acc[v.id] = 1;
            return acc;
        }, {});
        const sortedRefStrains = epitopeCladeStrains
            .map((strain) => ({ ...strain, vaccine: vcDict[strain.id] }))
            .sort((s1, s2) => s1.n.localeCompare(s2.n));

        //console.log(sortedRefStrains);
        return sortedRefStrains;
    }
);

const getRawAntigenicCladeStrains = ({ antigenic }) =>
    antigenic.rawAntigenicCladeStrains;
const getAlpha = ({ antigenic }) => antigenic.alpha;
const getRho = ({ antigenic }) => antigenic.rho;

const antigenicRawDataSeletor = createSelector(
    [getRawAntigenicCladeStrains, getAlpha, getRho, getRawAntigenicStrains],
    (rawAntigenicCladeStrains, alpha, rho, rawStrains) => {
        const data = get(rawAntigenicCladeStrains, `${alpha}.${rho}`, {});
        const _alphas = new Map();
        Object.keys(data).forEach((strainId) =>
            _alphas.set(strainId, rawStrains[strainId].name)
        );
        const _rhos = new Map();

        const strainIdsSet = Object.values(data)
            .flat()
            .reduce((set, elem) => {
                set.add(elem);
                return set;
            }, new Set());
        const strainsIds = [...strainIdsSet];
        strainsIds.forEach((strainId) =>
            _rhos.set(strainId, rawStrains[strainId].name)
        );

        const sortedRhos = new Map(
            [..._rhos].sort(([_k1, v1], [_k2, v2]) => compare(v1, v2))
        );
        const sortedAlphas = new Map(
            [..._alphas].sort(([_k1, v1], [_k2, v2]) => compare(v1, v2))
        );
        return {
            data,
            rhos: [...sortedRhos],
            alphas: [...new Map([...sortedRhos, ...sortedAlphas])],
        };
    }
);

export {
    getAntigenicMatrixData,
    getAntigenicFitnessMatrixData,
    selectedCladesSelector,
    getAntigenicData,
    getAntigenicValuesForReferenceClade,
    getRawAntigenicReferenceStrains,
    getAntigenicDataType,
    getAntigenicTiterType,
    antigenicRawDataSeletor,
};
