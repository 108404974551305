import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid2';
import CheckboxInput from '../Common/CheckboxInput';
import SelectInput from '../Common/SelectInput';
import MutationClassesColorLegend from '../Tree/MutationClassesColorLegend';
import { setLayoutParameters, setParameters, setShowMutationsGroups, setShowCladeLabels } from '../../redux/actions/parametersActions';
import { getMutationsGroupsOptions, getMutationClasses } from '../../redux/selectors/metadataSelector';
import { LAYOUT } from '../../config/dictionaries';
import { resetTree } from '../../redux/actions/treeDataActions';

const mutationsTypes = [
    { id: 'NS', label: 'Non-synonymous' },
    { id: 'S', label: 'Synonymous' },
    { id: 'ALL', label: 'All' },
];

const NodeVisibilitySelector = props => {

    const { setParameters, showLinks, showInternalNodes, showLeaves, showColoredNodesOnly, showMutations, layout,
        showVaccines, showReassortments, showMutationsGroups, mutationsType, mutationsGroups, mutationsGroup,
        showCladeLabels, showCladeBar, showCladeBarLabels, intro, showReferenceStrains,
        treeScaleTypeY, setShowMutationsGroups, setShowCladeLabels, setLayoutParameters, subsetBySubmission, submissionDate, resetTree
    } = props;

    const handleChange = name => value => {
        const _value = value === '' ? null : value;
        setParameters({ [name]: _value });
    };

    const handleShowCladeBarChange = value => {
        const showCladeBarLabels = !value ? { showCladeBarLabels: false } : {};
        setLayoutParameters({ showCladeBar: value, ...showCladeBarLabels });
    };

    const handleLayoutParameterChange = name => value => {
        const _value = value === '' ? null : value;
        setLayoutParameters({ [name]: _value });
    };

    const handleChangeShowMutationsGroups = value => {
        const _value = value === '' ? null : value;
        setShowMutationsGroups(_value);
    };

    const handleChangeShowCladeLabels = value => {
        const _value = value === '' ? null : value;
        setShowCladeLabels(_value);
    };

    const handleSubsetBySubmissionChange = value => {
        const _value = value === '' ? null : value;
        setParameters({ subsetBySubmission: _value });
        resetTree();
    };

    return (
        <>
            <Grid container pt={1} columnSpacing={2} rowSpacing={0}>
                {
                    layout !== LAYOUT.FAN.value && treeScaleTypeY === 'order' &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showCladeBar"
                            label="Clade bar"
                            value={showCladeBar}
                            onChange={handleShowCladeBarChange}
                        />
                    </Grid>
                }

                {layout !== LAYOUT.FAN.value && showCladeBar &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showCladeBarLabels"
                            label="Clade labels"
                            value={showCladeBarLabels}
                            onChange={handleLayoutParameterChange('showCladeBarLabels')}
                        />
                    </Grid>
                }
                {layout !== LAYOUT.FAN.value && !intro &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showCladeLabels"
                            label="Tree labels"
                            value={showCladeLabels}
                            onChange={handleChangeShowCladeLabels}
                        />
                    </Grid>
                }
                {!intro &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showInternalNodes"
                            label="Internal nodes"
                            value={showInternalNodes}
                            onChange={handleChange('showInternalNodes')}
                        />
                    </Grid>}
                <Grid size={6}>
                    <CheckboxInput
                        id="showLeaves"
                        label="Leaves"
                        value={showLeaves}
                        onChange={handleChange('showLeaves')}
                    />
                </Grid>
                <Grid size={6}>
                    <CheckboxInput
                        id="showLinks"
                        label="Links"
                        value={showLinks}
                        onChange={handleChange('showLinks')}
                    />
                </Grid>
                {!intro && <>
                    <Grid size={6}>
                        <CheckboxInput
                            id="showMutations"
                            label="Mutation counts"
                            value={showMutations}
                            onChange={handleChange('showMutations')}
                        />
                    </Grid>
                    {!intro &&
                        <Grid size={6}>
                            <CheckboxInput
                                id="showColoredNodesOnly"
                                label="Colored nodes only"
                                value={showColoredNodesOnly}
                                onChange={handleChange('showColoredNodesOnly')}
                            />
                        </Grid>}
                </>
                }
                {!intro &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showVaccines"
                            label="Vaccines"
                            value={showVaccines}
                            onChange={handleChange('showVaccines')}
                        />
                    </Grid>}
                {!intro &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showReassortments"
                            label="Reassortments"
                            value={showReassortments}
                            onChange={handleChange('showReassortments')}
                        />
                    </Grid>}
                {mutationsGroups.length > 1 && (
                    <Grid size={6}>
                        <CheckboxInput
                            id="showMutationsGroups"
                            label="Mutations"
                            value={showMutationsGroups} 
                            onChange={handleChangeShowMutationsGroups}
                        />
                    </Grid>
                )}

                {!intro &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="showReferenceStrains"
                            label="Reference sera"
                            value={showReferenceStrains}
                            onChange={handleChange('showReferenceStrains')}
                        />
                    </Grid>}
                {submissionDate &&
                    <Grid size={6}>
                        <CheckboxInput
                            id="subsetBySubmission"
                            label="Subset by submission"
                            value={subsetBySubmission}
                            onChange={handleSubsetBySubmissionChange}
                        />
                    </Grid>}
            </Grid>

            {showMutations && (
                <Grid size={12}>
                    <SelectInput
                        id="mutationsType"
                        label="Mutations type"
                        value={mutationsType}
                        onChange={handleChange('mutationsType')}
                        options={mutationsTypes}
                        getOptionValue={el => el.id}
                        getOptionLabel={el => el.label}
                    />
                </Grid>
            )}

            {showMutationsGroups && mutationsGroups.length > 1 && (
                <Grid size={12}>
                    <SelectInput
                        id="mutationsGroup"
                        label="Mutations group"
                        value={mutationsGroup}
                        onChange={handleChange('mutationsGroup')}
                        options={mutationsGroups}
                        getOptionValue={el => el}
                        getOptionLabel={el => el}
                    />
                    <MutationClassesColorLegend />
                </Grid>
            )}
        </>
    );
};

NodeVisibilitySelector.propTypes = {
    intro: PropTypes.bool,
    layout: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    mutationsGroup: PropTypes.string,
    mutationsGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
    mutationsType: PropTypes.string,
    setLayoutParameters: PropTypes.func.isRequired,
    setParameters: PropTypes.func.isRequired,
    setShowCladeLabels: PropTypes.func.isRequired,
    setShowMutationsGroups: PropTypes.func.isRequired,
    showCladeBar: PropTypes.bool.isRequired,
    showCladeBarLabels: PropTypes.bool.isRequired,
    showCladeLabels: PropTypes.bool.isRequired,
    showColoredNodesOnly: PropTypes.bool.isRequired,
    showInternalNodes: PropTypes.bool.isRequired,
    showLeaves: PropTypes.bool.isRequired,
    showLinks: PropTypes.bool.isRequired,
    showMutations: PropTypes.bool.isRequired,
    showMutationsGroups: PropTypes.bool.isRequired,
    showReassortments: PropTypes.bool.isRequired,
    showReferenceStrains: PropTypes.bool.isRequired,
    showVaccines: PropTypes.bool.isRequired,
    treeScaleTypeY: PropTypes.string.isRequired,
    subsetBySubmission: PropTypes.bool,
    submissionDate: PropTypes.string
};

const mapStateToProps = (state) => ({
    layout: state.parameters.layout,
    lineage: state.parameters.lineage,
    mutationClasses: getMutationClasses(state),
    mutationsGroup: state.parameters.mutationsGroup || '',
    mutationsGroups: getMutationsGroupsOptions(state),
    mutationsType: state.parameters.mutationsType,
    showCladeBar: state.parameters.showCladeBar,
    showCladeBarLabels: state.parameters.showCladeBarLabels,
    showCladeLabels: state.parameters.showCladeLabels,
    showColoredNodesOnly: state.parameters.showColoredNodesOnly,
    showInternalNodes: state.parameters.showInternalNodes,
    showLeaves: state.parameters.showLeaves,
    showLinks: state.parameters.showLinks,
    showMutations: state.parameters.showMutations,
    showMutationsGroups: state.parameters.showMutationsGroups,
    showReassortments: state.parameters.showReassortments,
    showReferenceStrains: state.parameters.showReferenceStrains,
    showVaccines: state.parameters.showVaccines,
    subsetBySubmission: state.parameters.subsetBySubmission,
    treeScaleTypeY: state.parameters.treeScaleTypeY,
    submissionDate: state.parameters.submissionDate,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLayoutParameters,
    setParameters,
    setShowCladeLabels,
    setShowMutationsGroups,
    resetTree
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NodeVisibilitySelector);
