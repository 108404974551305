// Import required dependencies
import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

// Import Material-UI components
import { Autocomplete, Box, Chip, FormControl } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce';
import { makeStyles } from '@mui/styles';

// Import custom components and utilities
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { isNil } from 'lodash';

// Import Redux related dependencies
import { connect } from 'react-redux';
import { setAutompleteOptions } from '../../redux/actions/autocompleteActions';
import { bindActionCreators } from 'redux';

// Define styles for the component
const styles = (theme) => ({
    // Style for the autocomplete component
    autocomplete: {
        marginBottom: '10px',
    },
    // Style for the form control wrapper
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
    // Style for the text field
    textField: {
        margin: '0px',
        fontSize: '14px',
    },
    // Style for error messages
    error: {
        color: 'red',
        fontSize: '12px',
        marginTop: '3px',
    },
    // Style for the container holding multiple selected chips
    chipsContainer: {
        maxHeight: 284,
        overflowY: 'overlay',
        overflowX: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        width: '98%',
        paddingRight: theme.spacing(1),
        // Custom scrollbar styling
        '&::-webkit-scrollbar': {
            display: 'block',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px #cccccc',
        },
        '&::-webkit-scrollbar-thumb': {
            display: 'block',
        },
    },
    // Add new chip style
    chip: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
        height: 'auto',
        padding: '8px 4px',
        '& .MuiChip-label': {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            flexGrow: 1,
            height: 'auto',
        },
        '& .MuiChip-deleteIcon': {
            marginLeft: 'auto'
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
        }
    },
});

// Create styles hook using makeStyles
const useStyles = makeStyles(styles);

// Default error renderer that splits error messages on newlines
const defaultErrorRenderer = (errorMsg) => (
    <span>
        {errorMsg.split('\n').map((msg, index) => (
            <div key={index}>{msg}</div>
        ))}
    </span>
);

// Default empty options array
const emptyOptions = [];

/**
 * AutocompleteInput Component
 * A reusable autocomplete input component with support for single/multiple selection,
 * async option loading, and custom styling
 */
const AutocompleteInput = ({
    valueIds, // Selected value(s) identifier(s)
    fetchOptions, // Function to fetch options from server
    onSelect, // Callback when selection changes
    getOptionLabel, // Function to get label from option
    label, // Input label
    errorTxt: propsErrorTxt = '', // Error text from props
    onMouseEnter, // Mouse enter handler
    onMouseLeave, // Mouse leave handler
    isOptionEqualToValue, // Function to compare options
    multiple = false, // Enable multiple selection
    debounceTime = 300, // Debounce time for search
    minTextLength = 3, // Minimum text length before search
    showMinTextLength = true, // Show minimum text length helper
    initialOptions = emptyOptions, // Initial options list
    shouldFetchOptions = true, // Whether to fetch options
    fetchOptionLabel, // Function to fetch option label
    helperText: propsHelperText = null, // Helper text from props
    autocompleteOptions, // Options from Redux store
    setAutompleteOptions, // Action to set options in store
    formatId = (id) => id, // Function to format IDs
    ...props
}) => {
    const classes = useStyles();
    const autocompleteId = props.id;

    // Local state
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorTxt, setErrorTxt] = useState(propsErrorTxt);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(multiple ? [] : null);
    const [isPendingValueUpdate, setIsPendingValueUpdate] = useState(false);

    // Ref to track current request
    const requestIdRef = useRef(0);

    // Memoized options based on props and store
    const options = useMemo(() => 
        autocompleteOptions?.length && shouldFetchOptions ? autocompleteOptions : initialOptions,
    [autocompleteOptions, initialOptions, shouldFetchOptions]
    );

    // Create dictionary of options for quick lookup
    const optionsDict = useMemo(() => 
        options.reduce((acc, option) => {
            acc[option.id] = option;
            return acc;
        }, {}), 
    [options]
    );

    // Debounced function to fetch options
    const debouncedFetchOptions = useMemo(() => {
        return debounce(async (txtValue, currentFetchId) => {
            setLoading(true);
            setErrorTxt('');
            try {
                const fetchedOptions = await fetchOptions(txtValue);
                if (currentFetchId === requestIdRef.current) {
                    setAutompleteOptions({autocompleteId, options: fetchedOptions, inputValue});
                }
            } catch (err) {
                if (currentFetchId === requestIdRef.current) {
                    setErrorTxt(err.message || 'Error fetching options');
                    setAutompleteOptions({autocompleteId, options: initialOptions});
                }
            } finally {
                if (currentFetchId === requestIdRef.current) {
                    setLoading(false);
                }
            }   
        }, debounceTime);
    }, [fetchOptions, debounceTime]);

    // Effect to handle pending value updates
    useEffect(() => {
        if (isPendingValueUpdate) {
            setIsPendingValueUpdate(false);
        }
    }, [valueIds]);

    // Cleanup debounced function on unmount
    useEffect(() => {
        return () => debouncedFetchOptions.cancel();
    }, [debouncedFetchOptions]);

    // Update error text when props change
    useEffect(() => {
        setErrorTxt(propsErrorTxt);
    }, [propsErrorTxt]);

    const isInitialMount = useRef(true);

    // Effect to handle option fetching
    useEffect(() => {
        if (!shouldFetchOptions || isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        if (inputValue === '' && !isNil(valueIds)) return;
        
        const currentFetchId = ++requestIdRef.current;

        if (!inputValue || inputValue.length < minTextLength) {
            debouncedFetchOptions?.cancel();
            setAutompleteOptions({autocompleteId, options: initialOptions});
            setLoading(false);
            setErrorTxt('');
            return;
        }

        if (inputValue.length === minTextLength || options.length === 0) {
            debouncedFetchOptions(inputValue, currentFetchId);
        }
    }, [inputValue, valueIds, debouncedFetchOptions]);

    // Effect to handle selected option updates
    useEffect(() => {
        if (isPendingValueUpdate) return;

        if (isNil(valueIds) || (multiple && !valueIds?.length)) {
            setSelectedOption(multiple ? [] : null);
            return;
        }

        const getOption = async () => {
            try {
                if (multiple) {
                    if (!Array.isArray(valueIds)) return;

                    const promises = valueIds
                        .filter(id => id && (optionsDict[id] || fetchOptionLabel))
                        .map(async id => ({
                            id,
                            n: optionsDict[id] ? getOptionLabel(optionsDict[id]) : await fetchOptionLabel?.(id)
                        }));

                    const selectedOptions = (await Promise.all(promises))
                        .map(option => ({ ...option, error: !optionsDict[option.id] }));

                    setSelectedOption(selectedOptions || []);
                    setInputValue('');
                    return;
                }

                if (!valueIds) {
                    setSelectedOption(multiple ? [] : null);
                    return;
                }

                const option = {
                    id: formatId(valueIds),
                    n: optionsDict[valueIds] ?
                        getOptionLabel(optionsDict[valueIds]) :
                        await fetchOptionLabel?.(valueIds) || ''
                };
               
                setSelectedOption(multiple ? [option] : option);
                setInputValue(option.n);

            } catch (error) {
                console.error('Error processing options:', error);
                setSelectedOption(multiple ? [] : null);
            }
        };
        getOption();
    }, [valueIds, multiple, options, isPendingValueUpdate]);

    // Handler for input changes
    const handleInputChange = useCallback((event, newInputValue) => {
        if (!event?.type || ['blur'].includes(event?.type)) return;

        setInputValue(newInputValue);
   
        if (newInputValue === '') {
            debouncedFetchOptions.cancel();
            setAutompleteOptions({autocompleteId, options: initialOptions});
            setLoading(false);
            setErrorTxt('');
        }
    }, [debouncedFetchOptions]);

    // Handler for selection changes
    const handleChange = useCallback((_event, newValue) => {
        const valueIds = multiple ? 
            (newValue || []).map((opt) => opt.id) : 
            newValue ? newValue.id : null;
        setIsPendingValueUpdate(true);
        onSelect(valueIds);
    }, [onSelect, multiple]);

    // Mouse enter handler for hover effects
    const handleMouseEnterInternal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (multiple && Array.isArray(selectedOption) && selectedOption.length > 0) {
            onMouseEnter?.(selectedOption.map((v) => v.id));
        } else if (!multiple && selectedOption) {
            onMouseEnter?.(selectedOption.id);
        }
    };

    // Mouse leave handler for hover effects
    const handleMouseLeaveInternal = (event) => {
        event.preventDefault(); 
        event.stopPropagation();
        if (!isOpen) {
            onMouseLeave?.();
        }
    };

    // Custom option renderer
    const renderOptionInternal = useCallback((props, option) => (
        <li
            {...props}
            key={option.id}
            style={{
                fontFamily: option.vaccine ? 'Inter Bold' : 'Inter',
            }}
        >
            {getOptionLabel(option)}
        </li>
    ), [getOptionLabel]);

    // Determine if error should be shown
    const showError = (errorTxt || propsErrorTxt) && (errorTxt?.length > 0 || propsErrorTxt?.length > 0);
    const helperText = propsHelperText || 
        (showMinTextLength ? (inputValue.length < minTextLength && !loading ? 'Type at least 3 characters' : '') : '');

    // Guard against invalid selectedOption type
    if ((multiple && !Array.isArray(selectedOption)) || (!multiple && Array.isArray(selectedOption))) return null;

    return (
        <FormControl fullWidth className={classes.formControl}>
            <Autocomplete
                {...props}
                id={props.id}
                multiple={multiple}
                value={selectedOption}
                inputValue={inputValue}
                options={options?.length ? options : initialOptions}
                getOptionLabel={getOptionLabel}
                onInputChange={handleInputChange}
                onChange={handleChange}
                loading={loading}
                autoSelect={true}
                renderOption={renderOptionInternal}
                onMouseEnter={handleMouseEnterInternal}
                onMouseLeave={handleMouseLeaveInternal}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                className={classes.autocomplete}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth
                renderTags={() => null}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        id={props.id}
                        label={label}
                        variant="standard"
                        helperText={helperText}
                        onKeyDown={(e) => { if (e.key === 'Enter')  e.preventDefault();}}
                        className={classes.textField}
                        multiline
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                id: props.id,
                                endAdornment: (
                                    <>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            },  
                        }}
                    />
                )}
            />
            {/* Render selected chips for multiple selection mode */}
            {Array.isArray(selectedOption) && selectedOption.length > 0 && (
                <Box className={classes.chipsContainer}>
                    {selectedOption.map((option) => (
                        <Chip 
                            key={option.id}
                            label={option.n}
                            onDelete={() => handleChange(null, selectedOption.filter(o => o.id !== option.id))}
                            className={classes.chip}
                        />
                    ))}
                </Box>
            )}
            {/* Render error messages if present */}
            {showError && (
                <div className={classes.error}>
                    {defaultErrorRenderer(errorTxt || propsErrorTxt)}
                </div>
            )}
        </FormControl>
    );
};

// PropTypes for type checking
AutocompleteInput.propTypes = {
    fetchOptions: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    errorTxt: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isOptionEqualToValue: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    debounceTime: PropTypes.number,
};

// Redux connection
const mapStateToProps = ({ autocomplete }, ownProps) => ({
    autocompleteOptions: autocomplete?.[ownProps.id]
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setAutompleteOptions,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteInput);
